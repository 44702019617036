import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const TermsArrowDown = ({onClick, style}) => {

  const customStyle= style || {}
    return (
        <ArrowForwardIosIcon
        onClick={onClick}
        
        style={{
          position: 'fixed',
          bottom: 40,
          right: 15,
          width: 20,
          height: 'auto',
          transform: 'rotate(90deg)',
          cursor: 'pointer',
          ...customStyle
        }}
      ></ArrowForwardIosIcon>
    )
}

export default TermsArrowDown
