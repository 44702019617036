import React, { useEffect } from 'react';

import {
  UiWrapper,
  View,
  Text,
  Button,
  TextField,
  Page,
  InstitutionsWrapper,
  NavigationWrapper,
  FinancialWrapper,
} from '@coinscrap/webapp-core';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import routes from '../../config/routes';

const BankUpdate = () => {
  const { institutions } = InstitutionsWrapper.use();
  const { useHeader, openModal } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const { updateInstitutionProducts } = FinancialWrapper.use();
  const identifier = Page.use().params.idBank;
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. BANCO." left="atras" />
    </View>,
    [],
  );

  useEffect(() => {
    if (institutions) {
      updateInstitutionProducts(
        identifier,
        { mode: 'AWAIT_FULL_PROCESS' },
        (message, otpSetter, discard) => {
          openModal(
            (close, context, setContext) => (
              <View>
                <Text>{message}</Text>
                <TextField
                  label="OTP"
                  value={context.value}
                  onChange={(e) => setContext({ value: e.target.value })}
                />
                <View>
                  <Button
                    onClick={() => {
                      otpSetter(context.value);
                      close();
                    }}
                  >
                    Aceptar
                  </Button>
                  <Button
                    onClick={() => {
                      discard();
                      close();
                    }}
                  >
                    Cancelar
                  </Button>
                </View>
              </View>
            ),
            { disableClickAway: true },
          );
        },
        {
          onSuccess: (arg) => {
            navigateTo(routes.bankConnect, { mode: 'replace', handleRedirection: true });
          },
          onFailure: (arg) => {
            goBack();
          },
          onDiscard: (arg) => {
            goBack();
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier, institutions]);

  return (
    <View sparcBwSt fullHeightSt>
      <View style={{ width: '80%' }}>
        <Text centerSt>Estamos recuperando los datos de las cuentas de Morpheus Aiolos </Text>
        <Text centerSt>Espere por favor</Text>
      </View>
      <View style={{ width: '80%' }}>
        <Text centerSt>Es posible que durante el proceso se le pidan credenciales de acceso</Text>
      </View>
    </View>
  );
};

export default BankUpdate;
