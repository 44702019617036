/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { CircularProgress } from '@material-ui/core';

import { Text, View } from '@coinscrap/webapp-core';

import colors from 'config/colors';

/* **************************************************************************
 * UrlModal
 */
export default ({ title, url, close, test }) => {
  const [iFrameRef, setIFrameRef] = useState();
  const [iFrameLoaded, setIFrameLoaded] = useState(false);
  const [spinnerColor, setSpinnerColor] = useState(colors.green);
  //! Wizink URL
  const urlGoogle = test
    ? url
    : 'https://docs.google.com/viewer?url=' + encodeURIComponent(url) + '&embedded=true#:0.page.1';

  // TODO: Si es ios carga directamente, y en android cargar google docs.
  // const urlGoogle = url;

  useEffect(() => {
    if (iFrameRef) {
      iFrameRef.src = urlGoogle;
    }

    const timer = setInterval(() => {
      if (iFrameRef && !iFrameLoaded) {
        iFrameRef.src = urlGoogle;
        if (spinnerColor === colors.orange) {
          setSpinnerColor(colors.green);
        } else {
          setSpinnerColor(colors.orange);
        }
      }
      if (iFrameLoaded) {
        clearTimeout(timer);
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <View
        spaceBetweenSt
        fullWidthPaddingSt
        // marginTopSt
        horizontalSt
        style={{ flexDirection: 'row', height: 40 }}
      >
        {!test ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <SaveAltIcon
              style={{
                width: 30,
                color: colors.black,
              }}
            />
          </a>
        ) : (
          <div style={{ width: 30 }}></div>
        )}

        <Text>{title}</Text>
        <CloseIcon
          id="closeDoc"
          style={{
            width: 25,
          }}
          onClick={() => close()}
        />
      </View>
      <View shrinkSt fullHeightSt fullWidthSt>
        {!iFrameLoaded && (
          <CircularProgress
            style={{
              // marginTop: '50%',
              position: 'absolute',
              top: '50%',
              width: 40,
              height: 40,
              color: spinnerColor,
            }}
          />
        )}
        <iframe
          ref={(node) => setIFrameRef(node)}
          title="Docs"
          frameBorder="0"
          // height={iFrameLoaded ? '100%' : '0px'}
          height="100%"
          width="100%"
          onLoad={(e) => {
            setIFrameLoaded(true);
          }}
        />
      </View>
    </>
  );
};
