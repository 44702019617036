import React, { useEffect } from 'react';

import { UiWrapper, View, Text } from '@coinscrap/webapp-core';

/*import { View } from '../components/View';
import { Enfatize } from '../components/Text';
import { Button, TextButton } from '../components/Button';
import { Text } from '../components/Text';*/

import colors from '../config/colors';

const TokenError = (props) => {
  const { useBackground } = UiWrapper.use();
  //Erased the swipe and "welcomeSlide", "futureSlide"
  useBackground(colors.greyLight);
  console.log('SANTANDER ERROR TOKEN: RENDERING VARIOUS');
  useEffect(() => {
    console.log('SANTANDER ERROR TOKEN: EFFECTING SOLO UNA');
  }, []);
  return (
    <View fullHeightSt style={{ padding: '5vh 0 5vh 0' }}>
      <View style={{ width: '80%' }} vAlign="center">
        <View cardSt>
          <Text h1St style={{ textAlign: 'center' }}>
            ¡No sabemos quién eres!
          </Text>
          <Text style={{ textAlign: 'center' }}>
            Por alguna razón no hemos podido validar tus datos de sesión. Por favor, inténtalo de
            nuevo más tarde
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TokenError;
