import Circles from 'assets/images/CirclesRed.png';
import StopSmoking from 'assets/images/SmokingRed.png';
import Football from 'assets/images/BallRed.png';
import Clock from 'assets/images/Clock.png';
import Money from 'assets/images/Money.png';
import IncomeRed from 'assets/images/IncomeRed.png';
import CaffeineRed from 'assets/images/CaffeineRed.png';

import * as Types from 'data/types';

export const ruleData = [
  {
    ruleName: 'Redondeo',
    identifier: Types.COINSCRAP_ROUNDS,
    image: Circles,
  },
  {
    ruleName: 'Aportación Periódica',
    identifier: Types.PERIODIC_SAVING,
    image: Clock,
  },
  {
    ruleName: 'Aportación Puntual',
    identifier: Types.AHORRO_HOT,
    image: Money,
  },
  {
    ruleName: 'Ingresos',
    identifier: Types.EARNINGS_SAVING,
    image: IncomeRed,
  },
  {
    ruleName: 'Sin Cafeína',
    identifier: Types.CAFEINE,
    image: CaffeineRed,
  },
  {
    ruleName: 'Sin Nicotina',
    identifier: Types.NICOTINE,
    image: StopSmoking,
  },
  {
    ruleName: 'Equipo',
    identifier: Types.TEAM,
    image: Football,
  },
];
