import React, { useState } from 'react';
import moment from 'moment';
import {
  Image,
  Text,
  View,
  NavigationWrapper,
  Button,
  PickerHOC,
  TargetsWrapper,
  UiWrapper,
} from '@coinscrap/webapp-core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ruleData } from 'data/ruleData';

import filter from 'assets/images/filter.png';
import MovementList from './MovementList';
import MovementItem from './MovementItem';
import Warning from 'common/svgs/Warning';

// libs
import routes from 'config/routes';
import colors from 'config/colors';
import { getCurrency } from 'libs/Currency';

import { filterController } from 'libs/filter';

const Movements = PickerHOC({
  target: {
    arrayItemMatcher: {
      type: 'param',
      itemPropertyPath: 'id',
      paramName: 'idTarget',
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
})(({ data, title, finished, ruleIdentifier, ruleConfig }) => {
  const { target } = PickerHOC.use();
  const { openModal } = UiWrapper.use();

  const { filterStart, filterEnd } = filterController.filter;
  const { navigateTo } = NavigationWrapper.use();
  const [buttonClick, setButtonClick] = useState();

  console.log({ data });

  let dataArray = data;
  if (filterStart) {
    dataArray = data.map((item) => {
      const filteredDates = item.movements.filter(
        (movement) =>
          moment(movement.createDate).unix() >= filterStart &&
          moment(movement.createDate).unix() <= filterEnd,
      );
      return {
        ...item,
        movements: filteredDates,
      };
    });
  }
  let nextSavings = target?.nextSaving?.ruleDetails || {};
  console.log({ nextSavings });
  let isEmptyNextSavings = true;

  for (const property in nextSavings) {
    if (ruleIdentifier && nextSavings[ruleIdentifier]?.detailLines?.length > 0) {
      isEmptyNextSavings = false;
    } else if (nextSavings[property]?.detailLines?.length > 0 && !ruleIdentifier) {
      isEmptyNextSavings = false;
    }
  }

  console.log({ target });

  // commit
  return (
    <View>
      {!finished && (
        <View
          spaceBetweenSt
          horizontalSt
          pointerSt
          topSt
          style={{
            padding: 20,
            backgroundColor: '#fef7f6',
            borderBottom: `3px solid ${colors.grey3}`,
          }}
          onClick={() => {
            openModal(
              (close) => (
                <View startSt style={{ overflow: 'auto', height: '100%' }}>
                  <View
                    relativeSt
                    grid3St
                    horizontalSt
                    justifyCenterSt
                    style={{ background: colors.grey3, height: 60, position: 'fixed', top: 0 }}
                  >
                    <View autoWidthSt justifySelfStartSt pointerSt>
                      <View horizontalSt style={{ marginLeft: 17 }} onClick={() => close()}>
                        <ArrowBackIcon style={{ height: 25, width: 'auto' }} />
                      </View>
                    </View>
                    <View selectNoneSt></View>
                    <View />
                  </View>
                  {/* body */}
                  <View style={{ paddingTop: 60 }}>
                    {Object.keys(target?.nextSaving?.ruleDetails || {})
                      .filter((item) => {
                        if (ruleIdentifier) {
                          return (
                            target?.nextSaving?.ruleDetails[item].ruleDefinitionIdentifier ===
                            ruleIdentifier
                          );
                        } else return true;
                      })
                      .map((key) => {
                        console.log(target?.nextSaving?.ruleDetails[key]);
                        const ruleObject = target?.nextSaving?.ruleDetails[key];
                        const ruleName = ruleData.find(
                          (rule) => rule?.identifier === ruleObject?.ruleDefinitionIdentifier,
                        )?.ruleName;

                        return (
                          ruleName &&
                          ruleObject.detailLines.length !== 0 && (
                            <View style={{ marginTop: 20 }}>
                              <Text
                                style={{
                                  textAlign: 'left',
                                  fontSize: 18,
                                  width: '100%',
                                  padding: '10px 20px',
                                }}
                                boldSt
                              >
                                {ruleName}
                              </Text>
                              {ruleObject.detailLines.map((movement) => {
                                console.log({ movement });
                                return (
                                  <MovementItem
                                    date={moment(movement.date).format('DD MMM YYYY / dddd')}
                                    amount={movement.amount}
                                    type={movement.title}
                                    total={
                                      movement?.metadata?.transactionAmount
                                        ? -1 * movement?.metadata?.transactionAmount
                                        : undefined
                                    }
                                  // total={3223}
                                  />
                                );
                              })}
                            </View>
                          )
                        );
                      })}
                    {isEmptyNextSavings && (
                      <View style={{ width: '90%', paddingTop: 50 }}>
                        <Text style={{ textAlign: 'center' }}>
                          No hay ahorros acumulados virtuales.
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              ),
              {
                fullScreen: true,
              },
            );
          }}
        >
          <Text style={{ fontSize: 13, textTransform: 'uppercase', marginBottom: 0 }}>
            Estás Acumulando
          </Text>
          <View spaceBetweenSt horizontalSt autoWidthSt>
            {/* <View autoWidthSt>
              <Text style={{ fontSize: 11 }}>
                {moment(target?.nextSaving?.from).format('DD-MM')} A{' '}
                {moment(target?.nextSaving?.to).format('DD-MM')} DE{' '}
                {moment(target?.nextSaving?.to).format('YY')}
              </Text>
            </View> */}
            <View autoWidthSt horizontalSt>
              <Text style={{ fontSize: 11 }}>
                {ruleIdentifier
                  ? getCurrency(target?.nextSaving?.ruleDetails[ruleIdentifier]?.amount || 0, 2)
                  : getCurrency(target?.nextSaving?.amount || 0, 2)}
              </Text>
              <ArrowForwardIosIcon style={{ fontSize: 16, marginLeft: 20 }} />
            </View>
          </View>
        </View>
      )}
      {data.length === 0 && target?.creationData?.initialContribution && !ruleConfig && (
        <View
          topSt
          style={{
            padding: 20,
            backgroundColor: '#fef7f6',
            borderBottom: `3px solid ${colors.grey3}`,
          }}
        >
          <Text style={{ fontSize: 13, textTransform: 'uppercase', marginBottom: 8 }}>
            MOVIMIENTOS PENDIENTES
          </Text>
          <View spaceBetweenSt horizontalSt>
            <View autoWidthSt>
              <Text style={{ fontSize: 11 }}>Aportación inicial</Text>
            </View>
            <View autoWidthSt horizontalSt>
              <Text style={{ fontSize: 11 }}>
                {getCurrency(target?.creationData?.initialContribution || 0, 2)}
              </Text>
              <ArrowForwardIosIcon style={{ fontSize: 16, marginLeft: 20, visibility: 'hidden' }} />
            </View>
          </View>
        </View>
      )}
      <View spaceBetweenSt horizontalSt style={{ padding: 20, backgroundColor: '#fef7f6' }}>
        <Text style={{ fontSize: 13, textTransform: 'uppercase' }}>
          {title || 'Movimientos de objetivo'}
        </Text>

        <View horizontalSt autoWidthSt>
          {/* <Image style={{ height: 23, width: 'auto', marginRight: 20 }} src={download}></Image> */}
          <Image
            onClick={() => {
              navigateTo(routes.filter, {
                mode: 'replace',
                // routeParams: { idTarget: data.id },
                queryParams: {
                  from: { name: routes.followUp, routeParams: { tab: 'movements' } },
                },
              });
            }}
            style={{ height: 23, width: 'auto' }}
            src={filter}
            pointerSt
          ></Image>
        </View>
      </View>
      <WarningMessage>
        <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
          Las aportaciones se realizarán desde la cuenta asociada a tu producto de ahorro/inversión.
        </Text>
      </WarningMessage>
      {filterStart && (
        <View spaceBetweenSt horizontalSt style={{ padding: 20, backgroundColor: '#fef7f6' }}>
          <View autoWidthSt topSt>
            <Text style={{ fontSize: 14, color: 'red', marginBottom: 4 }}>{`Filtro activado: 
          `}</Text>
            <Text style={{ fontSize: 13 }}>
              {`${moment(filterStart, 'X').format('YYYY-MM-DD')} a
              ${moment(filterEnd, 'X').format('YYYY-MM-DD')}`}
            </Text>
          </View>
          <Button
            clearFiltersBtnSt
            onClick={() => {
              setButtonClick(!buttonClick);
              filterController.clear();
            }}
          >
            Eliminar Filtros
          </Button>
        </View>
      )}
      {dataArray.map((movementByMonth, index) => {
        return (
          movementByMonth.movements.length !== 0 && (
            <MovementList
              date={movementByMonth.date.charAt(0).toUpperCase() + movementByMonth.date.slice(1)}
            >
              {movementByMonth.movements.map((movement) => {
                let movementReturn = (
                  <MovementItem
                    date={moment(movement.createDate).format('DD MMM / dddd')}
                    amount={movement.amount}
                    type={movement.concept}
                  // total={3223}
                  />
                );
                if (movement.movementLines) {
                  const findTypeName = (title) => {
                    const name = ruleData.find((rule) => rule.identifier === title);
                    if (name) return name.ruleName;
                    else return title;
                  };
                  movementReturn = movement.movementLines.map((lines) => {
                    return (
                      <MovementItem
                        date={moment(lines.createDate).format('DD MMM / dddd')}
                        amount={lines.amount}
                        type={findTypeName(lines.title)}
                      // total={3223}
                      />
                    );
                  });
                }
                return movementReturn;
              })}
            </MovementList>
          )
        );
      })}
    </View>
  );
});

const WarningMessage = ({ children }) => (
  <View style={{ background: '#FEF9F8 0% 0% no-repeat padding-box', padding: '10px 0px' }}>
    <View width90St horizontalSt startSt topSt>
      <View autoWidthSt>
        <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
      </View>
      <View topSt>{children}</View>
    </View>
  </View>
);

export default Movements;
