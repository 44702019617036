import React from 'react';

export default function XMark(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.812"
      height="35.718"
      viewBox="0 0 28.812 35.718"
      style={{ ...props.style }}
    >
      <defs>
        <style></style>
      </defs>
      <path
        className="a"
        d="M985.91,610.353l8.613-11.468a3.993,3.993,0,0,0-6.385-4.8l-7.221,9.615-7.22-9.615a3.993,3.993,0,0,0-6.385,4.8l8.613,11.468-8.613,11.469a3.992,3.992,0,1,0,6.385,4.795l7.22-9.615,7.221,9.615a3.992,3.992,0,1,0,6.385-4.795Z"
        transform="translate(-966.512 -592.494)"
      />
    </svg>
  );
}
