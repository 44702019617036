import React, { useEffect } from 'react';

import { NavigationWrapper, BackendWrapper, SessionWrapper } from '@coinscrap/webapp-core';
import { View, Text } from '@coinscrap/webapp-core';

import routes from '../config/routes';
import { startUpController } from '../libs/startUpController';
import Splash from 'common/Splash';
const TokenEntry = (props) => {
  const { getInstanceApi } = BackendWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { startSession, clearSession } = SessionWrapper.use();
  //Erased the swipe and "welcomeSlide", "futureSlide"
  //useBackground(colors.greyLight);
  const { refId, token } = startUpController.params;
  useEffect(() => {
    clearSession();
    if (refId) {
      (async () => {
        let response;
        try {
          response = await getInstanceApi().loginUser(
            {
              refId: refId || token,
            },
            'generali',
          );

          await startSession({ token: response.accessToken });

          navigateTo(routes.onBoarding, {
            clearItems: ['refId'],
            handleRedirection: true,
          });
        } catch (e) {
          console.log('error', e);
          navigateTo(routes.tokenError);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refId]);
  return (
    <View fullHeightSt style={{ padding: '5vh 0 5vh 0' }}>
      <View style={{ width: '80%' }} vAlign="center">
        <View cardSt>
          {refId ? (
            <Splash />
          ) : (
            <>
              <Text h1St style={{ textAlign: 'center' }}>
                Identificación
              </Text>
              {/* <Text style={{ textAlign: 'center' }}>
                Estamos obteniendo tus datos de usuario, espere por favor.
              </Text> */}
              <Text style={{ textAlign: 'center' }}>
                No se han encontrado datos de inicio de sesión. Asegurese que esta accediendo
                correctamente a la plataforma
              </Text>
            </>
          )}
        </View>
      </View>
    </View>
  );
};

export default TokenEntry;
