const legalData = [
  {
    title: 'Responsable del tratamiento',
    description: 'Generali España S.A, de Seguros y Reaseguros (“GENERALI”).',
  },
  {
    title: 'Finalidades del tratamiento',
    description: `1. Facilitarle el acceso a un servicio de ahorro, complementario a sus
              Seguros, a través de My Pocket.
              2. Ofrecerle otros productos y servicios de GENERALI, a través del
              envío de comunicaciones comerciales. Estas comunicaciones se
              basan en su información de pagos, pero únicamente en la medida
              de lo estrictamente necesario para identificar nuestros productos o
              servicios que puedan ser de su interés.
              3. Comunicar sus datos a terceros únicamente cuando sea necesario
              para cumplir con una obligación legal o para gestionar este servicio.`,
  },
  {
    title: 'Legitimación del tratamiento',
    description:
      'Sus Datos Personales serán tratados con base en la ejecución de un contrato. Asimismo, sus Datos Personales serán tratados con base en su consentimiento para el envío de comunicaciones comerciales, caso lo facilite.',
  },
  {
    title: 'Potenciales destinatarios de los datos',
    description:
      'Administraciones Públicas y proveedores de GENERALI como Encargados del Tratamiento de los Datos Personales necesarios para gestionar este servicio.',
  },
  {
    title: 'Procedencia de los datos',
    description:
      'Tratamos los siguientes Datos Personales, facilitados por usted en el momento en que se da de alta en My Pocket: o Sus datos de usuario (nombre, apellidos, correo electrónico, numero de móvil) o Sus objetivos (fecha, importe a obtener, cantidad ahorrada) o Sus reglas de ahorro (detalles, movimientos, cantidades ahorradas, liquidación de objetivos y aportaciones). Adicionalmente, tratamos su información sobre cuentas de pago facilitada por la Entidad de Pagos MORPHEUS AIOLOS, S.L., con CIF B-86556420, domiciliada en la Calle de San Andrés 8, Madrid, a quien usted ha solicitado que nos facilite sus Datos Personales. Todos los Datos Personales identificados son necesarios para la prestación del servicio a través de My Pocket, por lo que en caso que no los facilite no podremos prestarle el servicio de ahorro.',
  },
  {
    title: 'Derechos de protección de datos',
    description:
      'El consentimiento prestado para el envío de comunicaciones comerciales, en caso de haberlo facilitado, podrá ser revocado por usted en cualquier momento. Usted puede ejercitar sus derechos de acceso, rectificación, supresión, limitación, portabilidad de datos y retirada de su consentimiento, tal como se detalle en la “Información Adicional”.',
  },
  {
    title: 'Información adicional',
    description:
      'Puede consultar la información adicional detallada sobre Protección de Datos en la siguiente web: https://www.generali.es/quienes-somos/privacidad',
  },
];

export default legalData;
