import { UiWrapper } from '@coinscrap/webapp-core';
import colors from 'config/colors';

UiWrapper.defineComponent('inputSwitch', {
  styles: {
    default: {
      containerComponent: {},
      switchContainerComponent: {
        padding: 0,
        height: 26,
        width: 45,
        paddingBottom: 1,
      },
      switchComponent: {
        padding: 2,
      },
      trackComponent: {
        backgroundColor: '#78788029',
        opacity: 1,
        height: '100%',
        borderRadius: 30,
        padding: 0,
      },
      thumbComponent: {
        height: 21,
        width: 21,
        padding: 0,
      },
      checkedMode: {
        trackComponent: {
          backgroundColor: '#30bd47',
        },
      },
    },

    smallCircleSt: {
      labelComponent: {},
      containerComponent: {
        padding: 0,
        margin: 0,
        width: 65,
        marginBottom: 8,
      },
      switchContainerComponent: {
        width: 100,
        position: 'relative',
      },
      switchComponent: {
        // top: '-50%',
        left: 7.5,
        top: 3.5,
      },
      thumbComponent: {
        width: 18,
        height: 18,
        backgroundColor: colors.grey9,
        // transform: ' translateX(8px)',
      },
      trackComponent: {
        height: 24,

        marginTop: -3,
        // borderRadius: 26 / 2,
        borderRadius: 12,
        width: '48px',
        padding: 0,
        backgroundColor: colors.white,
      },
      checkedMode: {
        labelComponent: {},
        containerComponent: {
          padding: 0,
          margin: 0,
          width: 65,
          marginBottom: 8,
        },
        switchContainerComponent: {
          width: 100,
          position: 'relative',
        },
        switchComponent: {
          // top: '-50%',
          left: 7.5,
          top: 3.5,
        },
        thumbComponent: {
          width: 18,
          height: 18,
          backgroundColor: colors.green4,
          // transform: ' translateX(8px)',
        },
        trackComponent: {
          height: 24,

          marginTop: -3,
          // borderRadius: 26 / 2,
          borderRadius: 12,
          width: '48px',
          padding: 0,
          backgroundColor: colors.white,
        },
      },
    },
  },
});
