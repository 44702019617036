import React, { useState } from 'react';

import { Button, Image, Text, TextField, View } from '@coinscrap/webapp-core';

import search from 'assets/images/search.png';

import colors from 'config/colors';
import CheckMark from 'common/svgs/CheckMark';

const BanksModal = ({ hide, clickHandler, data }) => {
  const [selectedBank, setSelectedBank] = useState('');
  const [filterData, setFilterData] = useState(data);

  return (
    <>
      <View
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          backgroundColor: '#000000aa',
        }}
        onClick={hide}
      />
      <View
        style={{
          backgroundColor: 'white',
          borderRadius: '15px 15px 0 0',
          zIndex: 5,
          position: 'absolute',
          bottom: 0,
          height: '80%',
          justifyContent: 'flex-start',
        }}
      >
        <View
          style={{
            width: 40,
            height: 4,
            background: '#CFCFCF',
            borderRadius: 2,
            marginTop: 10,
          }}
        />
        <Text style={{ fontSize: 16, marginTop: 30 }}>Selecciona tu banco</Text>
        <View style={{ flexDirection: 'row', position: 'relative', width: '80%', marginTop: 20 }}>
          <Image
            src={search}
            style={{ position: 'absolute', zIndex: 5, left: 10, width: 20, height: 20 }}
          ></Image>
          <TextField
            style={{
              containerComponent: { width: '100%' },
              inputComponent: {
                backgroundColor: colors.grey5,
                borderRadius: 0,
                borderBottom: `1px solid ${colors.red}`,
                fontSize: 20,
              },
              noLabelMode: {
                inputComponent: {
                  padding: '12px 50px 12px 50px',
                },
              },
            }}
            placeholder="Busca tu banco"
            onChange={(e) => {
              const filteredBanks = data.filter((bank) =>
                bank.name.toLowerCase().includes(e.target.value.toLowerCase()),
              );
              setFilterData(filteredBanks);
            }}
          ></TextField>
        </View>
        <View style={{ marginTop: 25, padding: 10 }} gridSt>
          {(filterData || []).map((bank) => {
            return (
              <BankCard
                data={bank}
                onClick={(identifier) => setSelectedBank(identifier)}
                selected={bank.identifier === selectedBank}
              />
            );
          })}
          {/* <View
            style={{
              height: 160,
              width: 160,
              backgroundColor: colors.grey5,
              position: 'relative',
              border: `1px solid ${colors.red}`,
            }}
          >
            <View
              style={{
                backgroundColor: colors.red,
                height: 20,
                width: 20,
                borderRadius: '50%',
                position: 'absolute',
                bottom: 10,
                right: 10,
              }}
            >
              <CheckMark style={{ fill: colors.white, height: 8, width: 'auto' }} />
            </View>
            <Image src={santander} style={{ width: '70%', height: 'auto' }}></Image>
          </View>
          <View
            style={{
              height: 160,
              width: 160,
              backgroundColor: colors.grey5,
              border: `1px solid white`,
            }}
          >
            <Image src={abanca} style={{ width: '70%', height: 'auto' }}></Image>
          </View> */}
        </View>
        <Button
          style={{ marginTop: 'auto', marginBottom: 60 }}
          borderWhiteButtonSt
          disabled={!selectedBank}
          onClick={() => clickHandler(selectedBank)}
        >
          Aceptar
        </Button>
      </View>
    </>
  );
};

const BankCard = ({ selected, data, onClick }) => {
  return (
    <View
      style={{
        height: 160,
        width: 160,
        backgroundColor: colors.grey5,
        position: 'relative',
        border: selected ? `1px solid ${colors.red}` : `1px solid white`,
      }}
      onClick={() => onClick(data.identifier)}
    >
      {selected && (
        <View
          style={{
            backgroundColor: colors.red,
            height: 20,
            width: 20,
            borderRadius: '50%',
            position: 'absolute',
            bottom: 10,
            right: 10,
          }}
        >
          <CheckMark style={{ fill: colors.white, height: 8, width: 'auto' }} />
        </View>
      )}
      <Image src={data.image} style={{ width: '70%', objectFit: 'contain' }}></Image>
    </View>
  );
};

export default BanksModal;
