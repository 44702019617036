/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  UiWrapper,
  NavigationWrapper,
  PickerHOC,
  TargetsUtilsWrapper,
  EnvelopesWrapper,
  BackendWrapper,
  Page,
  InstitutionsWrapper,
  UserWrapper,
  FinancialWrapper,
  OperationsWrapper,
  SoftSwitcher,
  Text,
  View,
  Button,
} from '@coinscrap/webapp-core';
import { LoadingData } from 'common/LoadingData';
import { DocuSign } from 'common/DocuSign';
import routes from 'config/routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import colors from 'config/colors';
import InfoModal from 'common/InfoModal';
import ErrorModal from 'common/ErrorModal';

let continueFunc;

// eslint-disable-next-line react/prop-types
export default PickerHOC(
  {
    generaliInstitution: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        value: 'generali',
      },
      sourcePath: 'institutions',
      sourceWrapper: InstitutionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    generaliUserIdentityProvider: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        value: 'generali',
      },
      sourcePath: 'user.identityProviders',
    },
  },
  () => {
    UiWrapper.use().useLoading(true);
    return null;
  },
)((props) => {
  const { useLoading, openModal } = UiWrapper.use();
  const { updateUser } = UserWrapper.use();
  const { refresh } = EnvelopesWrapper.use();
  const { createOperation, sendAction } = OperationsWrapper.use();
  const { navigateTo, /*goBack*/ } = NavigationWrapper.use();
  const goBack = () => {
    navigateTo(routes.finished);
  }
  const { createOrUpdateCredentialsInstitution } = FinancialWrapper.use();
  useLoading(false);
  const { generaliInstitution, generaliUserIdentityProvider, user } = PickerHOC.use();
  const token = generaliUserIdentityProvider.externalTokens[0].sessionData.generaliToken;
  const [error, setError] = useState(false);

  const [institutionUpserted, setInstitutionUpserted] = useState();
  const [processingSign, setProcessingSign] = useState();
  const [docuSignUrl, setDocuSignUrl] = useState(null);

  useEffect(() => {
    (async () => {
      console.log(' CHEKING INSTITUTION ', generaliInstitution);
      try {
        const response = await createOrUpdateCredentialsInstitution(
          generaliInstitution.identifier,
          {
            method: generaliInstitution.authenticationMethods[0].identifier,
            values: {
              token: token,
            },
          },
          { mode: 'AWAIT_FULL_PROCESS' },
        );
        console.log(' CHEKING INSTITUTION RESPONSE ', response);
        if (response.status !== 'SUCCESS' || !response.institutionAccountId) {
          throw new Error('Error ocurred validating credentials');
        }
        setInstitutionUpserted(response.institutionAccountId);
      } catch (e) {
        console.log('Error', e);
        setError(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generaliInstitution?.identifier]);

  const func = (actionContinue, onUserHandled, operationId) => {
    console.log(' ACTION CONTINUE ', actionContinue, onUserHandled, operationId);
    setDocuSignUrl(actionContinue.payload.signingUrl);

    continueFunc = async () => {
      try {
        await sendAction(
          operationId,
          { payloadType: undefined },
          undefined
        );
      } catch (e) { }
      onUserHandled();

    }
  };

  useEffect(() => {
    (async () => {
      if (institutionUpserted) {
        console.log(' SENDING SIGN INSTITUTION');
        try {
          const op = await createOperation(
            'SIGN_ACCEPTANCE',
            {
              institutionAccountId: institutionUpserted,
            },
            'AWAIT_FULL_PROCESS',
            {
              onPending: func,
              onDiscard: goBack,
              onSuccess: async (operation) => {
                const status = operation.currentAction.output.payload.status
                switch (status) {
                  case "SIGNED":
                    await updateUser({
                      metadata: { ...user?.metadata, signCompleted: true, signDate: new Date() },
                    });
                    navigateTo(routes.fetchPolicies);
                    break;
                  default:
                    openModal(
                      (close) => (
                        <ErrorModal>
                          <Text>
                            Para poder continuar usando la aplicación debe aceptar y firmar las condiciones
                          </Text>
                          <Button
                            onClick={() => { close(); goBack() }}
                            style={{ width: 150, marginTop: 25 }}
                          >
                            Volver
                          </Button>
                        </ErrorModal>
                      ),
                      { disableClickAway: true },
                    );

                }
              },
              onFailure: () => {
                openModal(
                  (close) => (
                    <ErrorModal>
                      <Text>
                        {' '}
                        Ha ocurrido un error con la firma. Revise los documentos y vuelva a
                        internarlo
                      </Text>
                      <Button
                        onClick={() => { close(); goBack() }}
                        style={{ width: 150, marginTop: 25 }}
                      >
                        Volver
                      </Button>
                    </ErrorModal>
                  ),
                  { disableClickAway: true },
                );
              },
            },
          );
          console.log(' OPERATION ', op);
        } catch (e) {
          console.log('Error', e);
          setError(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionUpserted]);

  return (
    <View fullHeightSt>
      <SoftSwitcher fullSt evaluate={docuSignUrl ? (processingSign ? 2 : 1) : 0}>
        <View fullHeightSt style={{ position: 'relative', backgroundColor: colors.grey1 }}>
          <View
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress style={{ color: colors.red }} />
          </View>
          <Text>Generando documentos de firma</Text>
        </View>
        <View fullHeightSt>
          <DocuSign
            url={docuSignUrl}
            onProcessFinished={async (status) => {
              setProcessingSign(true)
              console.log(" FINALIZADO COMO ", status)
              try {
                await refresh()
              } catch (e) {
              }
              continueFunc()
              //navigateTo(routes.fetchPolicies);
            }}
          />
        </View>
        <View fullHeightSt style={{ position: 'relative', backgroundColor: colors.grey1 }}>
          <Text>Alamcenando datos de firma</Text>
        </View>
      </SoftSwitcher>
    </View>
  );
});
