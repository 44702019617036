import React, { useCallback, useState, useEffect, useRef } from 'react';

import {
  UiWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  NavigationWrapper,
  RuleDefinitionsWrapper,
  UserWrapper,
  TargetsWrapper,
  RulesWrapper,
  Page,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';
import { LoadingData } from 'common/LoadingData';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';

// images
import Circles from 'common/svgs/Circles';
import CheckMark from 'common/svgs/CheckMark';
import Back from 'common/svgs/Back';

// configs
import colors from 'config/colors';
import routes from 'config/routes';
import RulesTooltip from 'common/RulesTooltip';

// data
import * as Types from 'data/types';
import { OTP_ACTIONS } from 'libs/common';
import { useRuleController } from './hooks/useRuleController';
import { useRuleActivation } from './hooks/useRuleActivation';

const ruleIdentifier = Types.COINSCRAP_ROUNDS;

export default PickerHOC(
  {
    ruleDefinition: {
      arrayItemMatcher: {
        type: 'value',
        value: ruleIdentifier,
        itemPropertyPath: 'identifier',
      },
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, user } = PickerHOC.use();
  const { getRule, extractValue, desactivateRule, activateRule, upsertRule } = RulesWrapper.use();

  const rule = getRule(ruleIdentifier, target.id);

  const { useBackground, useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const {
    query: { from },
  } = Page.use();
  const [isScroll, setIsScroll] = useState(false);

  const lastRef = useRef(null);
  const observer = useRef(null);
  const [selectedValue, setSelectedValue] = useState(1);
  // const [activeRule, setActiveRule] = useState(ruleDefinition.required || false);
  useBackground(colors.red);
  useLoading(false);

  useEffect(() => {
    if (rule) {
      const value = extractValue(rule, 'rounds_round');
      console.log(' SET SELECTAED VALUE', value);
      setSelectedValue(Number(value || 1));
      // setActiveRule(rule.isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule?.id]);

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  const activateAndContinue = useRuleActivation(
    ruleIdentifier,
    rule,
    target.id,
    user.id,
    !!from,
    routes.incomeRule,
  );
  const handleNext = useCallback(
    async (isActive) => {
      console.log('GOing to activate', selectedValue);
      await activateAndContinue(isActive, [
        {
          identifier: 'rounds_round',
          data: { value: Number(selectedValue) },
        },
        // TODO: BANK ACCOUNT
        // {
        //   identifier: 'rounds_accounts',
        //   data: { value: Object.keys(selected).filter((k) => selected[k]) },
        // },
      ]);
    },
    [activateAndContinue, selectedValue],
  );

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        left={
          <View autoWidthSt style={{ marginLeft: 18 }} onClick={() => goBack()}>
            <Back style={{ fill: colors.white, width: 11, height: 'auto' }} />
          </View>
        }
        style={{ backgroundColor: colors.red }}
        right="none"
      />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.red }}>
      <View style={{ padding: 25 }}>
        <Text boldSt font15St whiteSt style={{ marginBottom: 20 }}>
          {' '}
          ¿Quieres activar esta regla?{' '}
        </Text>
        <View horizontalSt>
          <View
            pointerSt
            style={{
              height: 54,
              width: 54,
              borderRadius: '50%',
              background: colors.white,
              marginRight: 32,
            }}
            onClick={() => {
              handleNext(false);
            }}
          >
            <View
              autoWidthSt
              boldSt
              style={{
                fontSize: 45,
                color: colors.red,
                transform: 'rotate(45deg)',
                paddingBottom: 10,
              }}
            >
              +
            </View>
          </View>
          <View
            pointerSt
            onClick={() => {
              handleNext(true);
            }}
            style={{ height: 54, width: 54, borderRadius: '50%', background: colors.white }}
          >
            <CheckMark style={{ width: 20, height: 'auto', fill: colors.green }} />
          </View>
        </View>
      </View>
    </Footer>,
    [handleNext],
  );

  return (
    <Layout.Container>
      <ScrollArrow
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View
        startSt
        fullHeightSt
        style={{ backgroundColor: colors.red, paddingTop: 10, paddingBottom: 10 }}
      >
        <View width79St>
          <Circles style={{ width: 80, height: 'auto', fill: colors.white }} />
          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <Text font27St boldSt whiteSt style={{ textAlign: 'center', marginRight: 5 }}>
              Redondeo
            </Text>
            <RulesTooltip
              content={
                <Text style={{ fontSize: 14 }}>
                  Esta regla es nuestra preferida porque te permite ahorrar sin darte cuenta ¿Cómo
                  funciona? Es muy fácil, solo tenemos que leer los movimientos de tus cuentas y
                  tarjetas para calcular los redondeos.
                  <Text style={{ fontSize: 14, marginTop: 10 }}>
                    Este ahorro se acumula semanalmente y se añade al resto de reglas. Si la suma de
                    todas las reglas es superior a 40€, pasaremos una aportación a tu póliza. Si tu
                    ahorro no llega al mínimo, no te preocupes, seguirás acumulado hasta la
                    siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál
                    sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.
                  </Text>
                </Text>
              }
            ></RulesTooltip>
          </View>
          <Text textCenterSt font15St whiteSt lineHeight16St style={{ marginTop: 27 }}>
            Esta regla permite escoger cómo quieres hacer el redondeo de todos las cargos de la
            cuenta o tarjeta asignada: Al euro más cercano, a los 2€, 5€ o 10€.
          </Text>
          <Text font15St boldSt textCenterSt whiteSt style={{ marginTop: 20, width: 200 }}>
            Selecciona el importe y te lo explicamos mejor
          </Text>
          <View horizontalSt style={{ marginTop: 25 }}>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 1}
              onClick={() => setSelectedValue(1)}
            >
              1€
            </View>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 2}
              onClick={() => setSelectedValue(2)}
            >
              2€
            </View>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 5}
              onClick={() => setSelectedValue(5)}
            >
              5€
            </View>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 10}
              onClick={() => setSelectedValue(10)}
            >
              10€
            </View>
          </View>
          <Text font15St textCenterSt whiteSt style={{ marginTop: 38, width: 220 }}>
            Si pagas 1,25€ en una compra y estableces el redondeo a 1€ (ahorrarás 0,75€) en tu
            tarjeta de forma automática.
          </Text>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});
