import React from 'react';

import { View } from '@coinscrap/webapp-core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

//import { Text } from '../../components/Text';

export default function NotificationComp({ children, style }) {
  return (
    <View
      horizontalSt
      style={{
        height: 59,
        padding: '15px 20px 15px 20px',
        backgroundColor: '#FFF9E9',
        borderBottom: '1px solid #EAEAEA',
        ...style,
      }}
    >
      <ErrorOutlineIcon />
      {children}
    </View>
  );
}
