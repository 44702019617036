import React, { useState, useEffect } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  PickerHOC,
  Layout,
  TargetsWrapper,
  RulesWrapper,
  RuleDefinitionsWrapper,
  Page,
  UserWrapper,
  SoftSwitcher,
  Text,
  Enfatize,
  Button,
} from '@coinscrap/webapp-core';

// components
import Header from 'common/Header';
import HeaderRedPrimary from 'common/HeaderRedPrimary';

import Tutorial from './components/Tutorial';
import Tabs from './components/Tabs';
import Resumen from './components/Resumen';
import Reglas from './components/Reglas';
import { LoadingData } from 'common/LoadingData';
import moment from 'moment';
// images
import ButtonCircle from '../../common/svgs/Button';

// configs
import colors from '../../config/colors';
import routes from '../../config/routes';
import { useFilterActiveTargets } from './hooks/useFilterActiveTargets';
import NotificationComp from 'common/NotificationComp';
import { useInstitutionAccounts } from 'libs/hooks/useInstitutionAccounts';

export default PickerHOC(
  {
    parentTarget: {
      arrayItemMatcher: {
        allowNoExistence: true,
        itemPropertyPath: 'level',
        type: 'value',
        value: 0,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    ruleDefinition: {
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    userRules: {
      sourcePath: 'rules',
      sourceWrapper: RulesWrapper,
    },
    userTargets: {
      arrayItemFilter: {
        itemPropertyPath: 'level',
        type: 'value',
        value: 1,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  // TODO: FRAN, permitir valores por defecto en la ruta cuando no se especifica
  const {
    params: { tab: rawTab },
  } = Page.use();
  const tab = rawTab.replace(':tab', '') || 'resumen';
  // END TODO
  const { userTargets: userTargetsRaw, parentTarget } = PickerHOC.use();

  const userTargets = useFilterActiveTargets(userTargetsRaw);

  const institutionAccounts = useInstitutionAccounts();
  console.log('data', { institutionAccounts });

  const { useBackground, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  const [section, setSection] = useState(tab === 'resumen' ? 0 : 1);
  const [isMaximum, setIsMaximum] = useState(false);

  const [localStorage, setLocalStorage] = useState(JSON.parse(global.localStorage.getItem('data')));

  useEffect(() => {
    let sectionIndex = 0;
    switch (tab) {
      case 'reglas':
        sectionIndex = 1;
        break;
      default:
    }
    setSection(sectionIndex);
  }, [tab]);

  useBackground(colors.white);
  useLoading(false);
  useBackRoute(undefined);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        // left={
        //   <p onClick={() => navigateTo(routes.history)} style={{ marginLeft: 15 }}>
        //     Histórico
        //   </p>
        // }
        center="GENERALI My Pocket "
      />
    </View>,
    [],
  );

  const continueToNewObjective = () => {
    navigateTo(routes.targetInstanciation, {
      routeParams: {
        idParentTarget: parentTarget.id,
      },
    });
  };

  let tutorialTrial = true;
  if (localStorage?.tutorial) {
    tutorialTrial = false;
  }

  if (!global.localStorage) {
    tutorialTrial = false;
  }

  const saveTutorialSettingInLocalHost = () => {
    const data = { tutorial: true };
    const mergedData = { ...localStorage, ...data };

    global.localStorage.setItem('data', JSON.stringify(mergedData));
    setLocalStorage(mergedData);
  };

  let pendingOperationsBank = false;
  pendingOperationsBank = institutionAccounts.find((bank) => !!bank.pendingOperations);
  let findActiveTarget = userTargets.find((target) => target.status === 'ACTIVE');
  return tutorialTrial ? (
    <Tutorial
      onClick={(value) => {
        saveTutorialSettingInLocalHost();
      }}
      continueToNewObjective={() => {
        saveTutorialSettingInLocalHost();
        continueToNewObjective();
      }}
    />
  ) : (
    <Layout.Container>
      {pendingOperationsBank && findActiveTarget && (
        <NotificationComp>
          <Text
            font14St
            style={{
              marginLeft: 15,
              lineHeight: '18px',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigateTo(routes.edit, {
                routeParams: {
                  idTarget: findActiveTarget.id,
                },
                queryParams: {
                  openTab: 'cuentas',
                },
              });
            }}
          >
            {`Tienes operaciones pendientes en alguno de tus bancos,  `}
            <Enfatize
              font14St
              santanderRegularBoldSt
              style={{ textDecoration: 'underline', color: '#137e84' }}
            >
              por favor revísalo
            </Enfatize>{' '}
          </Text>
        </NotificationComp>
      )}
      {parentTarget?.savingData?.disabled && (
        <NotificationComp style={{ height: 'auto' }}>
          <Text
            font14St
            style={{
              marginLeft: 15,
              lineHeight: '18px',
            }}
          // onClick={() => {
          //   navigateTo(routes.edit, {
          //     routeParams: {
          //       idTarget: findActiveTarget.id,
          //     },
          //     queryParams: {
          //       openTab: 'cuentas',
          //     },
          //   });
          // }}
          >
            <Enfatize font14St santanderRegularBoldSt style={{ fontWeight: 700 }}>
              ¡INCREÍBLE!
            </Enfatize>{' '}
            Has llegado al máximo de aportaciones a tu póliza de ahorro este año. Seguirás
            acumulando en tus objetivos y realizaremos una aportación por el total a partir del{' '}
            {moment().endOf('year').add(1, 'day').format('D [de] MMMM [de] YYYY')}
            {/* 1 de enero de
            2022. */}
          </Text>
        </NotificationComp>
      )}
      <Tabs
        section={tab || 'resumen'}
        onChange={(value) => {
          if (tab !== value) {
            navigateTo(routes.home, {
              mode: 'replace',
              // routeParams: { idTarget: data.id },
              routeParams: {
                tab: value,
              },
            });
          }
        }}
      />
      <SoftSwitcher evaluate={section}>
        <Resumen key={1} />
        <Reglas key={2} />
      </SoftSwitcher>
      {userTargets.length !== 0 && [
        <View key={'separator'} style={{ height: '130px' }} />,
        <View
          key={'button'}
          style={{ position: 'fixed', bottom: '25px', zIndex: 7, width: 'auto' }}
        >
          <ButtonCircle
            onClick={continueToNewObjective}
            style={{ width: 68, height: 68, cursor: 'pointer' }}
          />
          <Button
            regularButtonHistorySt
            onClick={() => navigateTo(routes.history)}
            style={{ marginTop: 15 }}
          >
            Histórico
          </Button>
          {/* <p onClick={() => navigateTo(routes.history)} style={{ marginTop: 5 }}>
            Histórico
          </p> */}
        </View>,
      ]}
    </Layout.Container>
  );
});
