import React, { useState, useRef, useEffect, useCallback } from 'react';

import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { View, Button, Text, TextField, Layout } from '@coinscrap/webapp-core';

import colors from '../config/colors';

import { CloseButton } from './closeButton';

export const ImageSelector = ({
  onChange,
  defaultSearch,
  value,
  style,
  // ...props
}) => {
  const imagesPerPage = 5;

  const [openBuscarFotos, setOpenBuscarFotos] = useState(false);
  const [queryImages, setQueryImages] = useState(defaultSearch || '');
  const [mensajeError, setMensajeError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [showImages, setShowImages] = useState(false);
  const [images, setImages] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [maxPages, setMaxPages] = useState();
  const [loading, setLoading] = useState(false);
  const [searchHideLabel, setSearchHideLabel] = useState(false);

  const inputRef = useRef(null);

  const getListImages = useCallback((query = '') => {
    setLoading(true);
    if (query === '') {
      setShowImages(false);
      setQueryImages('');
      setImages([]);
      setLoading(false);
    } else {
      fetch(
        'https://pixabay.com/api/?key=10465050-661faffb220a43b9f64d560a2&q=' +
        query +
        '&orientation=horizontal',
      )
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            return response.json();
          } else {
            setLoading(false);
            throw response.status;
          }
        })
        .then((data) => {
          let pages = 0;
          if (data.totalHits > data.hits) {
            pages = 500 / imagesPerPage + 1;
          } else {
            pages = data.totalHits / imagesPerPage + 1;
          }
          setImages(data.hits);
          setMaxPages(pages);
          setShowImages(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (queryImages && inputRef.current && queryImages === inputRef.current.value) {
        getListImages(queryImages);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [queryImages, inputRef, getListImages]);

  const handleFocusSearch = useCallback(() => {
    setSearchHideLabel(true);
  }, [setSearchHideLabel]);

  console.log({ images });

  const handleBlurSearch = useCallback(() => {
    if (queryImages) {
      setSearchHideLabel(true);
    } else {
      setSearchHideLabel(false);
    }
  }, [setSearchHideLabel, queryImages]);

  useEffect(() => {
    if (inputRef.current) {
      const { current: currentSearch } = inputRef;

      currentSearch.addEventListener('focus', handleFocusSearch);
      currentSearch.addEventListener('blur', handleBlurSearch);

      if (queryImages) {
        handleFocusSearch();
      }

      return () => {
        currentSearch.removeEventListener('focus', handleFocusSearch);
        currentSearch.removeEventListener('blur', handleBlurSearch);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleFocusSearch, handleBlurSearch, inputRef, openBuscarFotos]);

  return (
    <Layout.Container>
      <View
        fullWidth
        onClick={() => {
          setQueryImages(defaultSearch);
          setOpenBuscarFotos(true);
          getListImages(defaultSearch);
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: 'url(' + value + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',

          width: 78,
          height: 78,
          minHeight: 78,
          borderRadius: 100,
          boxShadow: 0,

          backgroundColor: colors.grey5,
          position: 'relative',
        }}
        pointerSt
      >
        <View
          autoWidthSt
          style={{
            color: colors.red,
            fontSize: 30,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          +
        </View>
      </View>
      <View
        style={{ marginTop: 24 }}
        onClick={() => {
          setQueryImages(defaultSearch);
          setOpenBuscarFotos(true);
          getListImages(defaultSearch);
        }}
      >
        <Text font15St grey1St>
          Añade imagen
        </Text>
      </View>
      {/* Buscador de Fotos ---------------------------------------------------------------*/}
      <Modal disablePortal open={openBuscarFotos} onClose={() => setOpenBuscarFotos(false)}>
        <View
          startSt
          style={{
            backgroundColor: colors.white,
            height: '80%',
            position: 'absolute',
            bottom: 0,
            borderRadius: '15px 15px 0 0',
            overflow: 'hidden',
          }}
        >
          <View
            style={{
              width: 27,
              height: 4,
              background: '#CFCFCF',
              borderRadius: 2,
              margin: '10px 0px -10px',
            }}
          />
          <View horizontalSt fullWidthSt endSt>
            <CloseButton
              color={'#A1A1A1'}
              action={() => {
                setOpenBuscarFotos(false);
              }}
            />
          </View>
          <View fullWidth style={{ backgroundColor: colors.white }}>
            <View
              style={{
                width: '92%',
                padding: 0,
              }}
            >
              <TextField
                inputRef={inputRef}
                mediumSt
                inputImgModalBackgroundSt
                paddingTopSt
                label="Busca una imagen para tu objetivo"
                value={queryImages}
                onChange={(event) => {
                  event.preventDefault();
                  setQueryImages(event.target.value);
                  // getListImages(event.target.value);
                }}
                style={{
                  containerComponent: {
                    width: '92%',
                  },
                }}
                onBlurWithTextSt={searchHideLabel ? true : false}
              />
            </View>
          </View>

          <View
            flexSt
            style={{
              justifyContent: 'start',
              alignItems: 'center',
              overflow: 'scroll',
              borderTop: '1px solid #D8D8D8',
              height: 'calc(100% - 120px)',
            }}
            linearGradientSplitSt
          >
            {loading ? (
              <View style={{ paddingTop: '50px' }}>
                {' '}
                <CircularProgress />{' '}
              </View>
            ) : images?.length === 0 && images !== null ? (
              <View style={{ maxWidth: '321px', marginTop: '17px' }}>
                <Text style={{ textAlign: 'center' }}>
                  No se encontraron imágenes. Por favor, intente una búsqueda distinta.
                </Text>
              </View>
            ) : (
              <View
                gridSt
                style={{
                  maxWidth: '321px',
                  marginTop: '17px',
                  rowGap: '20px',
                  columnGap: '15px',
                }}
              >
                {images &&
                  images.map((image) => {
                    return (
                      <View
                        key={image.id}
                        onClick={(e, i) => {
                          onChange(image.webformatURL);
                          setShowImages(false);
                          setOpenBuscarFotos(false);
                        }}
                      >
                        <img
                          style={{
                            width: 153,
                            height: 128,
                            objectFit: 'cover',
                            borderRadius: 7,
                          }}
                          src={image.webformatURL}
                          alt={image.tags}
                        />
                      </View>
                    );
                  })}
              </View>
            )}

            {/* </Collapse> */}
          </View>
        </View>
      </Modal>

      {/* Mensaje Error --------------------------------------------------------------*/}
      <Modal
        disablePortal
        open={mensajeError ? true : false}
        onClose={() => {
          return null;
        }}
      >
        <div>
          <Text>{mensajeError}</Text>
          <Button onClick={() => setMensajeError(false)}>Aceptar</Button>
        </div>
      </Modal>
    </Layout.Container>
  );
};
