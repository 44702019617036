import React from 'react';

import { Text, View } from '@coinscrap/webapp-core';
import { useState } from 'react';

import ChevronDown from 'common/svgs/ChevronDown';
import colors from 'config/colors';

const BanksCollapse = ({ name, children, setDefaultVisible = false }) => {
  const [visible, setVisible] = useState(setDefaultVisible);

  return (
    <View style={{ justifyContent: 'start' }}>
      <View
        style={{
          flexDirection: 'row',
          padding: '20px 35px',
          borderTop: `1px solid ${colors.grey3}`,
          borderBottom: `1px solid ${colors.grey3}`,
          justifyContent: 'space-between',
        }}
        onClick={() => setVisible((v) => !v)}
        pointerSt
      >
        <View horizontalSt startSt style={{ width: 114, marginBottom: 8 }}>
          <Text leftSt>{name}</Text>
        </View>
        <View style={{ height: 16, width: 'auto', marginTop: 4 }}>
          <ChevronDown
            targetMovementsCollapseIconSt
            style={{
              fill: colors.red,
              transform: visible && 'rotate(-180deg)',
            }}
          />
        </View>
      </View>
      <View fullWidthSt style={{ maxHeight: visible ? '100%' : 0, overflow: 'hidden' }}>
        <View
          style={{ flexDirection: 'row', padding: 20, borderBottom: `1px solid ${colors.grey3}` }}
        >
          <View style={{ width: 'auto', flexGrow: 1, flexBasis: '0%' }}>
            <Text style={{ fontSize: 13, marginRight: 'auto' }}>Producto</Text>
          </View>
          <View style={{ width: 'auto', flexGrow: 1, flexBasis: '0%' }}>
            <Text style={{ fontSize: 13 }}>Saldo</Text>
          </View>
          <View style={{ width: 'auto', flexGrow: 1, flexBasis: '0%' }}>
            <Text style={{ fontSize: 13, marginLeft: 'auto' }}>Activar</Text>
          </View>
        </View>
        {children}
      </View>
    </View>
  );
};

export default BanksCollapse;
