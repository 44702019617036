import React from 'react';
import colors from '../config/colors';
import { View, Image } from '@coinscrap/webapp-core';
import HeaderWhiteClose from '../assets/images/HeaderWhiteClose.png';
import LogoGenerali from '../assets/images/LogoGenerali.png';

const HeaderRedPrimary = ({ left, center, right, style }) => {
  return (
    <>
      <View
        relativeSt
        grid3St
        horizontalSt
        justifyCenterSt
        style={{ background: colors.red, minHeight: 60, ...style }}
      >
        <View />
        <View>
          <Image src={LogoGenerali} style={{ width: 139, height: 'auto', cursor: 'pointer' }} onClick={() => {
            window.parent.postMessage(
              { id: 'MYPOCKET', event: 'goMiGenerali', origin: window.location.origin },
              '*',
            );
          }} />
        </View>
        <View
          justifySelfEndSt
          autoWidthSt
          style={{
            height: 30,
            width: 30,
            borderRadius: '50%',
            marginRight: 17,
          }}
        >
          <Image
            onClick={() => {
              window.parent.postMessage(
                { id: 'MYPOCKET', event: 'goMiGenerali', origin: window.location.origin },
                '*',
              );
            }}
            src={HeaderWhiteClose}
            style={{ width: 30, height: 'auto', cursor: 'pointer' }}
          />
        </View>
      </View>
    </>
  );
};

export default HeaderRedPrimary;
