import React from 'react';

import { Text, View } from '@coinscrap/webapp-core';
import { getCurrency } from 'libs/Currency';
import colors from 'config/colors';

const MovementItem = ({ date, type, amount, total }) => {
  return (
    <View style={{ padding: '10px 20px', border: `0.5px solid ${colors.grey3}` }}>
      <View topSt>
        <Text style={{ fontSize: 10, color: colors.red }}>{date}</Text>
      </View>
      <View horizontalSt spaceBetweenSt style={{ padding: '10px 0' }}>
        <Text style={{ fontSize: 16 }}>{type}</Text>
        <Text>{getCurrency(amount, 2)}</Text>
      </View>
      {total && (
        <View bottomSt>
          <Text style={{ fontSize: 10, color: colors.grey4 }}>{getCurrency(total, 2)}</Text>
        </View>
      )}
    </View>
  );
};

export default MovementItem;
