import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  UiWrapper,
  View,
  Button,
  Text,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  TargetsUtilsWrapper,
  InstitutionAccountsWrapper,
  Page,
} from '@coinscrap/webapp-core';

// components
import Header from '../../common/Header';
import HeaderRedPrimary from '../../common/HeaderRedPrimary';
import Footer from '../../common/Footer';
import Tabs from './components/Tabs';
import ObjectiveCard from './components/ObjectiveCard';
import Objective from './components/Objective';
import Cuenta from './components/Cuenta';
import Reembolso from './components/Reembolso';

// configs
import colors from 'config/colors';
import routes from 'config/routes';

// libs
import { onChangeAmountHandler } from '../../libs/ToInputCurrency';
import { getCurrency, setCurrency } from '../../libs/Currency';
import { useInstitutionAccounts } from 'libs/hooks/useInstitutionAccounts';

import { LoadingData } from 'common/LoadingData';
import { OTP_ACTIONS } from 'libs/common';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    institutionAccounts: {
      sourcePath: 'institutionAccounts',
      sourceWrapper: InstitutionAccountsWrapper,
    },
  },
  LoadingData,
)(function FollowUpEdit() {
  const {
    query: { openTab },
  } = Page.use();
  const { target } = PickerHOC.use();
  const institutionAccounts = useInstitutionAccounts();

  const { saveTargetProperty, saveTargetData, finalize } = TargetsUtilsWrapper.use();

  const { useBackground, useHeader, useFooter, useLoading, showAlert, openModal } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  console.log(' DATA ', target.targetRulesConfiguration?.metadata?.ruleAccounts);
  const [activeRuleAccounts, setActiveRuleAccounts] = useState(
    (target.targetRulesConfiguration?.metadata?.ruleAccounts || []).reduce(
      (acc, act) => ({ ...acc, [act]: true }),
      {},
    ),
  );

  console.log({ target });

  const [valuesObject, setValuesObject] = useState({
    price: target.creationData?.price ? getCurrency(target.creationData?.price, 2, true) : '',
    date: target.creationData?.date
      ? Math.abs(moment(target.createDate).diff(moment(target.creationData.date), 'months'))
      : '',
    name: target.name,
    image: target.image,
  });

  const [section, setSection] = useState('objetivo');
  // const [data] = useState(dataMock);

  function futureDate(months) {
    let date = new Date();

    date = new Date(date.setMonth(date.getMonth() + Number(months)));
    return date;
  }

  useEffect(() => {
    if (openTab) {
      setSection(openTab);
    }
  }, [openTab]);

  const saveTarget = async () => {
    try {
      const formatPrice = setCurrency(valuesObject.price);
      const formatDate = futureDate(valuesObject.date);

      await Promise.all([
        saveTargetProperty(target.id, 'creationData', { price: formatPrice, date: formatDate }),
        saveTargetData(target.id, {
          name: valuesObject.name,
          // image: valuesObject.image
        }),
      ]);
      showAlert('Cambios guardados');
      goBack();
    } catch (error) {
      console.log(error);
    }
  };

  const finalizeTarget = async () => {
    openModal((close) => (
      <View style={{ padding: 10 }}>
        <Text font18St style={{ marginBottom: 20 }}>
          ¿Quieres finalizar el objetivo?
        </Text>
        <View horizontalSt spaceBetweenSt>
          <Button
            borderWhiteButtonSt
            onClick={async () => {
              try {
                await finalize(target.id);
                navigateTo(routes.home);
              } catch (error) {
                console.log(error);
              }

              close();
            }}
          >
            Aceptar
          </Button>
          <Button
            borderWhiteButtonSt
            onClick={() => {
              close();
            }}
          >
            Cancelar
          </Button>
        </View>
      </View>
    ));
  };

  useLoading(false);

  useBackground(colors.white);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="EDITAR OBJETIVO" left="atras" />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 10 }}>
      {section === 'reembolso' ? (
        <Button
          style={{
            marginTop: 15,
            width: 175,
            textComponent: {
              fontSize: 16,
            },
          }}
          borderWhiteButtonSt
          onClick={() => finalizeTarget()}
        >
          {'Finalizar Objetivo'}
        </Button>
      ) : (
        <Button
          style={{ marginTop: 15 }}
          borderWhiteButtonSt
          onClick={async () => {
            if (section === 'cuentas') {
              console.log('## NAVEGANDO A', routes.otpSms, {
                queryParams: {
                  action: OTP_ACTIONS.SAVE_ACCOUNTS,
                  payload: {
                    targetId: target.id,
                    accounts: Object.keys(activeRuleAccounts).filter((x) => activeRuleAccounts[x]),
                  },
                },
              });
              navigateTo(routes.otpSms, {
                queryParams: {
                  action: OTP_ACTIONS.SAVE_ACCOUNTS,
                  payload: {
                    targetId: target.id,
                    accounts: Object.keys(activeRuleAccounts).filter((x) => activeRuleAccounts[x]),
                  },
                },
              });
            } else {
              console.log('## Guardando target directamente');
              saveTarget();
            }
          }}
        >
          {'Aplicar'}
        </Button>
      )}
    </Footer>,
    [section, valuesObject, activeRuleAccounts],
  );

  const objectiveOnchangeHandler = (e) => {
    setValuesObject({
      ...valuesObject,
      [e.target.name]:
        e.target.name === 'price' ? onChangeAmountHandler(e.target.value) : e.target.value,
    });
  };
  return (
    <>
      <View fullHeightSt startSt>
        <ObjectiveCard data={valuesObject} section={section} />
        <Tabs section={section} onChange={(value) => setSection(value)} />
        {/* Tabs body */}
        {section === 'objetivo' && (
          <Objective data={valuesObject} onChange={objectiveOnchangeHandler} />
        )}
        {section === 'cuentas' && (
          <Cuenta
            target={target}
            institutionAccounts={institutionAccounts}
            activeRuleAccounts={activeRuleAccounts}
            onChange={(value) => setActiveRuleAccounts(value)}
          />
        )}
        {section === 'reembolso' && <Reembolso target={target} />}
      </View>
    </>
  );
});
