import React, { useRef } from 'react';

import { UiWrapper, View, Text } from '@coinscrap/webapp-core';

// components
import { CloseButton } from 'common/closeButton';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TermsArrowDown from 'common/TermsArrowDown';

// configs
import colors from 'config/colors';
import Collapse from './Collapse';
import fonts from 'config/fonts';

// data
import faqsData from 'data/faqsData';

const SubItemCollapse = ({ txt, children }) => (
  <View
    fullWidthSt
    rowSpaceBtwSt
    style={{ padding: '7px 30px', backgroundColor: '#fff', alignItems: 'flex-start' }}
  >
    <Text style={{ fontSize: 15, color: '#888', textAlign: 'left' }}>{txt}</Text>
    {children}
  </View>
);

const FAQsModal = ({ setIsOpenFAQsModal }) => {
  const faqRef = useRef();
  const { useBackground } = UiWrapper.use();
  useBackground(colors.white);

  return (
    <View spaceBetweenSt style={{ height: '100vh' }}>
      <View>
        <View horizontalSt endSt style={{ marginTop: 20 }}>
          <CloseButton
            color={colors.dark}
            action={() => {
              setIsOpenFAQsModal(false);
            }}
          />
        </View>
        <View>
          <Text font27St style={{ marginBottom: 10 }}>
            FAQs
          </Text>
          <View startSt style={{ overflowY: 'scroll', maxWidth: 850, height: '75vh', padding: 5 }}>
            {faqsData.map((section) => (
              <View>
                <Text
                  style={{
                    fontSize: 15,
                    marginBottom: 15,
                    marginTop: 20,
                    fontFamily: fonts.HelveticaNeueBold,
                    textTransform: 'uppercase',
                  }}
                >
                  {section.title}
                </Text>
                {section.questions.map((item) => (
                  <Collapse title={item.question} style={{ borderTop: '1px solid #f0f0f0' }}>
                    <SubItemCollapse txt={item.answer}>{item?.answerInJsx}</SubItemCollapse>
                  </Collapse>
                ))}
                <View ref={faqRef} />
              </View>
            ))}
          </View>
          <TermsArrowDown
            onClick={() => {
              faqRef.current.scrollIntoView({ behavior: 'smooth' });
            }}
          />
          {/* <ArrowForwardIosIcon
            onClick={() => {
              faqRef.current.scrollIntoView({ behavior: 'smooth' });
            }}
            style={{
              position: 'fixed',
              bottom: 40,
              right: 15,
              width: 20,
              height: 'auto',
              transform: 'rotate(90deg)',
            }}
          ></ArrowForwardIosIcon> */}
        </View>
      </View>
    </View>
  );
};

export default FAQsModal;
