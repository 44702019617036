import React from 'react';

import { View, Image } from '@coinscrap/webapp-core';

// images
import Target from 'assets/images/Target.png';
import Pig from 'common/svgs/Pig';
import Payback from 'assets/images/Payback.png';
import scooter from 'assets/images/scooter.jpg';

const ObjectiveCard = ({ data, section }) => {
  return (
    <View style={{ position: 'relative', height: 135 }}>
      <Image
        src={data.image || scooter}
        style={{
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
          opacity: 0.3,
          zIndex: 5,
        }}
      ></Image>
      <View
        style={{
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
          opacity: 0.7,
          zIndex: 4,
        }}
      />
      {section === 'cuentas' ? (
        <Pig
          style={{
            height: 'auto',
            width: 70,
            zIndex: 6,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <Image
          src={section === 'objetivo' ? Target : Payback}
          style={{
            height: 'auto',
            width: 70,
            zIndex: 6,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </View>
  );
};

export default ObjectiveCard;
