import React, { useEffect, useState } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  Enfatize,
  PickerHOC,
  Layout,
  Button,
  TargetsWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import InfoHeader from 'common/InfoHeader';
import InfoModal from 'common/InfoModal';
// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

import { LoadingData } from 'common/LoadingData';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target } = PickerHOC.use();
  const { useBackground, useHeader, useFooter, useLoading, openModal } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const [openModalState, setOpenModal] = useState(false);
  useBackground(colors.white);
  useLoading(false);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. APORTAC." left="atras" />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 10 }}
        borderWhiteButtonSt
        onClick={() => {
          setOpenModal(true);
          // navigateTo(routes.contributionStep1Initial, {
          //   routeParams: {
          //     idTarget: target.id,
          //   },
          // });
        }}
      >
        ¡Vamos!
      </Button>
    </Footer>,
    [],
  );

  useEffect(() => {
    if (openModalState) {
      openModal(
        (close) => (
          <InfoModal onClose={() => close()}>
            <View style={{ justifyContent: 'space-between', height: '100%' }}>
              <View style={{ padding: 15 }}>
                <Text whiteSt textCenterSt style={{ lineHeight: 1.6 }}>
                  Recuerda que todas las aportaciones que hagas para tu objetivo se traspasarán
                  automáticamente{` `}
                  <Enfatize whiteSt boldSt>
                    a tu producto vinculado con My Pocket
                  </Enfatize>{' '}
                  cada viernes si has conseguido ahorrar un mínimo de 40€ durante la semana. En caso
                  contrario el traspaso se producirá el siguiente viernes tras haber obtenido el
                  ahorro mínimo de 40€
                </Text>
              </View>
              <View>
                <Button
                  // style={{ marginBottom: 30 }}
                  whiteFooterButtonSt
                  onClick={() => {
                    navigateTo(routes.contributionStep1Initial, {
                      routeParams: {
                        idTarget: target.id,
                      },
                    });
                    close();
                  }}
                >
                  Siguiente
                </Button>
              </View>
            </View>
          </InfoModal>
        ),
        {
          fullScreen: true,
        },
      );
    }
    // eslint-disable-next-line
  }, [openModalState]);

  return (
    <Layout.Container>
      <View fullHeightSt spaceBetweenSt>
        <View>
          <InfoHeader text="2" />

          <View style={{ marginTop: 50 }}>
            <View width75St>
              <Text font27St textCenterSt>
                Configuración de aportaciones
              </Text>
            </View>
            <View width65St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
                Necesitamos que configures los parámetros de ahorro para lograr tu objetivo.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});
