/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState } from 'react';

import { motion } from 'framer-motion';

import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import { NavigationWrapper, UiWrapper, View, Image, Text } from '@coinscrap/webapp-core';

import routes from 'config/routes';

import colors from 'config/colors';
import ShowDocs from './ShowDocs';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   // eslint-disable-next-line react/jsx-props-no-spreading
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// TODO: REFACTORING Separar esto en varios componentes
export default ({ children }) => {
  // const [openDialog, setOpenDialog] = useState(false);
  const [showWaitingIcon, setShowWaitingIcon] = useState(false);
  // const [childrenDialog, setChildrenDialog] = useState(false);
  // const [childrenModalType, setChildrenModalType] = useState();

  const { openModal } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  function showWaiting(value) {
    setShowWaitingIcon(value);
  }

  /*
    Se puede pasar:
     a. Un texto directamente.
     b. Un JSX.
     c. Un {type: 'url|error|jsxOnCard|jsxOnScreen', text: ""}
        'url', Muestra un doc desde una url en googledocs
        'error', Muestra un error.
  */
  function showModal(childrenModal) {
    if (typeof childrenModal === 'string') {
      // Si se pasa un string
      openModal(
        (close) => (
          <FullCardModal close={close}>
            <Text greenSt centerAlignSt style={{ paddingBottom: 0 }}>
              {childrenModal}
            </Text>
          </FullCardModal>
        ),
        {
          fullScreen: true,
        },
      );

      // Si se pasa una url
    } else if (childrenModal.type === 'url') {
      // ShowDocs(childrenModal.url);
      openModal(
        (close) => <ShowDocs close={close} url={childrenModal.url} title={childrenModal.title} />,
        {
          fullScreen: true,
        },
      );

      // Si se pasas un objeto JSX y lo mete en una card
    } else if (childrenModal.type === 'jsxOnCard') {
      openModal((close) => <FullCardModal close={close}>{childrenModal.jsx}</FullCardModal>, {
        fullScreen: true,
      });

      // Si se pasas un objeto JSX y lo mete a full en la ventana
    } else if (childrenModal.type === 'jsxOnScreen') {
      openModal((close) => <ScreenModal close={close}>{childrenModal.jsx}</ScreenModal>, {
        fullScreen: true,
      });
    } else if (childrenModal.type === 'error') {
      if (childrenModal.code === 'serverError') {
        // Forfidden, prohibido o caduco la sesión
        if (childrenModal.status === 403) {
          // eslint-disable-next-line no-param-reassign
          childrenModal.text = 'La sesión ha caducado, tiene que volver a hacer login.';
          navigateTo(routes.login);
        } else {
          // eslint-disable-next-line no-param-reassign
          childrenModal.text =
            'Ha habido un problema. Por favor, inténtalo de nuevo más tarde, si el problema persiste, dirígete al telf. 915 909 603 (Lunes a Viernes de 8 a 21 horas). [' +
            childrenModal.status.replace(/\//g, ' /') +
            ']';
        }
      } else {
        console.error("Dialog type='error', code no definido", childrenModal);
      }

      openModal(
        (close) => (
          <DefaultModal
            close={close}
            type={childrenModal.type}
            title={childrenModal.title}
            text={childrenModal.text}
          />
        ),
        { fullScreen: true },
      );

      // Si se pasas un objeto con parametro type
    } else if (childrenModal.type === 'passwordChange') {
      // openModal((close) => <PasswordModal close={close} />, {
      //   fullScreen: true,
      // });
    } else {
      openModal(
        (close) => (
          <DefaultModal close={close} type={childrenModal.type} text={childrenModal.text} />
        ),
        {
          fullScreen: true,
        },
      );
    }
  }

  /* **************************************************************************
   * DefaultModal
   */
  function DefaultModal({ type, title, text, close, nested }) {
    let iconAux;
    let titleAux;

    if (type === 'error') {
      iconAux = <Image filename="cara-triste-icon.png" style={{ height: 60, width: 60 }} />;
      titleAux = title || '¡Vaya!';
    }

    const content = (
      <View
        style={{ height: '100%', width: '100%', padding: 20, backgroundColor: colors.greenDark }}
        onClick={() => close()}
      >
        <motion.div
          animate={{
            scale: [1, 1.3, 1],
          }}
          whileTap={{ scale: 1.3 }}
        >
          <View modalCardSt onClick={() => close()}>
            <View
              fullWidthPaddingSt
              // marginTopSt
              horizontalSt
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                height: 10,
                width: 'calc(100% - 10px)',
              }}
            >
              <CloseIcon
                id="closeDoc"
                style={{
                  width: 25,
                  color: colors.greenDark,
                }}
                onClick={() => close()}
              />
            </View>

            {iconAux}
            {<Text h1St>{titleAux}</Text>}
            <Text greenSt centerAlignSt style={{ paddingBottom: 0, maxWidth: '100%' }}>
              {text}
            </Text>
          </View>
        </motion.div>
      </View>
    );
    return nested ? (
      content
    ) : (
      <View
        style={{
          height: '100%',
          width: '100%',
          padding: 20,
          backgroundColor: nested ? 'trasnparent' : colors.greenDark,
        }}
        onClick={() => close()}
      >
        {content}
      </View>
    );
  }

  function ScreenModal({ title, children, close }) {
    return (
      <View
        style={{ height: '100%', width: '100%', padding: 20, backgroundColor: colors.greenDark }}
        onClick={() => close()}
      >
        {title && <Text h1St>{title}</Text>}
        {children}
      </View>
    );
  }

  return (
    <>
      <DialogContext.Provider value={{ showModal, showWaiting }}>
        {children}
        {/* {openDialog} */}

        {/* openDialog && childrenModalType === 'url' && <>{childrenDialog}</> */}

        {/* openDialog && childrenModalType !== 'url' && (
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 100,
              width: '100%',
              height: '100%',
              transition: 'transform ease-out 0.45s',
              backgroundColor: colors.greenDark,
            }}
          >
            {childrenDialog}
          </View>
          ) */}
        {showWaitingIcon && (
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              zIndex: 100,
              width: '100%',
              height: '100%',
              transition: 'transform ease-out 0.45s',
              backgroundColor: 'rgba(255, 255, 255, 0.62)',
            }}
          >
            <CircularProgress
              style={{
                // marginTop: '50%',
                width: 40,
                height: 40,
                color: colors.orange,
              }}
            />
          </View>
        )}
      </DialogContext.Provider>

      {/* <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenDialog(false);
        }}
        // aria-labelledby="alert-dialog-slide-title"
        // aria-describedby="alert-dialog-slide-description"
        // style={{ backgroundColor: colors.green, height: '100vh' }}
      >
        {childrenDialog}
      </Dialog> */}
    </>
  );
};

export const DialogContext = React.createContext({
  showModal: () => {
    // eslint-disable-next-line no-plusplus
    throw new Error(`Open Modal not implemented`);
  },
});

export function CardModal({ children: childrenAux, close }) {
  return (
    <View
      style={{
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        padding: 20,
        backgroundColor: colors.white,
      }}
    >
      <View
        fullWidthPaddingSt
        // marginTopSt
        horizontalSt
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          height: 10,
          width: 'calc(100% - 10px)',
          marginBottom: 15,
        }}
      >
        <CloseIcon
          id="closeDoc"
          style={{
            width: 25,
            color: colors.greenDark,
          }}
          onClick={() => close()}
        />
      </View>

      {childrenAux}
    </View>
  );
}

export function FullCardModal({ children: childrenAux, close }) {
  return (
    <View
      style={{ height: '100%', width: '100%', padding: 20, backgroundColor: colors.greenDark }}
      onClick={() => close()}
    >
      <motion.div
        animate={{
          scale: [1, 1.3, 1],
        }}
        whileTap={{ scale: 1.3 }}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <View modalCardSt>
          <View
            fullWidthPaddingSt
            // marginTopSt
            horizontalSt
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              height: 10,
              width: 'calc(100% - 10px)',
              marginBottom: 15,
            }}
          >
            <CloseIcon
              id="closeDoc"
              style={{
                width: 25,
                color: colors.greenDark,
              }}
              onClick={() => close()}
            />
          </View>
          {childrenAux}
        </View>
      </motion.div>
    </View>
  );
}
