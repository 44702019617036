import React, { useState, useRef, useCallback } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Button,
  Image,
  TargetsWrapper,
  TargetsUtilsWrapper,
} from '@coinscrap/webapp-core';

// components
import { ImageSelector } from '../../../common/ImageSelector';
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';

// images
import ImagePic from '../../../assets/images/ImagePic.png';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

// import routes from "../config/routes";

import { LoadingData } from 'common/LoadingData';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target } = PickerHOC.use();

  const [isScroll, setIsScroll] = useState(false);

  const lastRef = useRef(null);
  const observer = useRef(null);

  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const [name] = useState(target?.name || '');
  const [image, setImage] = useState(target.image || null);
  const { saveTargetData } = TargetsUtilsWrapper.use();
  console.log(target);
  const saveTarget = async () => {
    try {
      await Promise.all([image && saveTargetData(target.id, { image })]);

      navigateTo(routes.objectiveResumen, {
        routeParams: {
          idTarget: target.id,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  useBackground(colors.white);
  useLoading(false);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. OBJETIVO" left="atras" />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button style={{ marginTop: 30 }} borderWhiteButtonSt onClick={() => saveTarget()}>
        Siguiente
      </Button>
    </Footer>,
    [image],
  );

  return (
    <Layout.Container>
      <ScrollArrow
        color="black"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <View topSt>
            <Text boldSt font11St style={{ marginLeft: 16 }}>
              1 de 4
            </Text>
          </View>
          <View horizontalSt>
            <DotComponent active />
            <DotComponent active />
            <DotComponent active />
            <DotComponent active />
          </View>
          <View style={{ height: 115, marginTop: 28 }}>
            <View
              style={{
                backgroundColor: colors.grey5,
                height: 114,
                width: 114,
                borderRadius: '50%',
                position: 'relative',
              }}
            >
              <Image
                src={ImagePic}
                style={{
                  width: 60,
                  height: 'auto',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </View>
          </View>
          <View style={{ marginTop: 19 }}>
            <View width80St>
              <Text font27St textCenterSt>
                Ponle imagen a tu objetivo
              </Text>
            </View>
            <View width80St style={{ marginBottom: 28 }}>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
                Selecciona una imagen para ilustrar tu objetivo.
              </Text>
            </View>
            <ImageSelector
              defaultSearch={name}
              value={image}
              label="¡Elige una foto!"
              onChange={(v) => {
                setImage(v);
              }}
              SearchFieldProps={{
                disableUnderline: false,
              }}
              SearchButtonProps={{
                pillSt: true,
              }}
              circleSt
              style={{
                cameraComponent: {
                  color: image ? 'transparent' : colors.green,
                },
              }}
            />
          </View>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});

const DotComponent = ({ active }) => {
  return (
    <View
      dotsSt
      dotsActiveSt={active}
      style={{ margin: '0 4px 0 4px' }}
    // onClick={() => switchInfo(id)}
    />
  );
};
