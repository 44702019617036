import React, { useRef } from 'react';

import { UiWrapper, View, Text } from '@coinscrap/webapp-core';

// components
// import { CloseButton } from 'common/closeButton';
import InfoSvg from 'common/svgs/Info';
// configs
import colors from 'config/colors';

// data

const FAQsModal = ({ onClose, children }) => {
  const faqRef = useRef();
  const { useBackground } = UiWrapper.use();
  useBackground(colors.white);

  return (
    <View
      spaceBetweenSt
      style={{ height: '100vh', backgroundColor: '#C5281C', opacity: 0.95 }}
    // onClick={onClose}
    >
      <View >
        <View horizontalSt endSt style={{ marginTop: 20 }}>
          {/* <CloseButton color={colors.white} action={onClose} /> */}
        </View>
        <View>
          <InfoSvg style={{ width: 71, height: 'auto', fill: 'white', marginBottom: 10 }} />
          <Text font27St style={{ marginBottom: 10, color: 'white' }}>
            Información
          </Text>
          <View ref={faqRef} startSt style={{ overflowY: 'scroll', maxWidth: 850, height: '75vh', padding: 5 }}>
            {children}
          </View>
        </View>
      </View>
    </View>
  );
};

export default FAQsModal;
