import React, { useState } from 'react';
import {
  Button,
  InstitutionAccountsWrapper,
  NavigationWrapper,
  PickerHOC,
  TargetsUtilsWrapper,
  TargetsWrapper,
  Text,
  UiWrapper,
  View,
} from '@coinscrap/webapp-core';
import colors from 'config/colors';

// components
import Footer from 'common/Footer';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';

import card from 'assets/images/card.png';
import account from 'assets/images/account.png';
import routes from 'config/routes';
import BanksCollapse from 'common/BanksCollapse';
import BanksItem from 'common/BanksItem';

export const accountsType = ['card', 'checking', 'saving'];

const Rules = PickerHOC({
  target: {
    arrayItemMatcher: {
      type: 'param',
      itemPropertyPath: 'id',
      paramName: 'idTarget',
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
  institutionAccounts: {
    sourcePath: 'institutionAccounts',
    sourceWrapper: InstitutionAccountsWrapper,
  },
})(() => {
  const { saveTargetRulesConfiguration } = TargetsWrapper.use();
  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { target, institutionAccounts } = PickerHOC.use();

  const [activeRuleAccounts, setActiveRuleAccounts] = useState(
    (target.targetRulesConfiguration?.metadata?.ruleAccounts || []).reduce(
      (acc, act) => ({ ...acc, [act]: true }),
      {},
    ),
  );

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. BANCO." left="atras" />
    </View>,
    [],
  );
  const numberRulesActive = Object.values(activeRuleAccounts).filter((x) => x).length;
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        borderWhiteButtonSt
        disabled={!numberRulesActive}
        onClick={async () => {
          await saveTargetRulesConfiguration(target.id, {
            ruleAccounts: Object.keys(activeRuleAccounts).filter((x) => activeRuleAccounts[x]),
          });

          navigateTo(routes.bankResumen, {
            mode: 'push',
            routeParams: {
              idTarget: target.id,
            },
          });
        }}
      >
        Continuar
      </Button>
    </Footer>,
    [numberRulesActive],
  );

  return (
    <>
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <View topSt>
            <Text boldSt font11St style={{ marginLeft: 16 }}>
              4 de 4
            </Text>
          </View>
          <View style={{ marginTop: 30, marginBottom: 30 }}>
            <Text style={{ fontSize: 16 }}>Selecciona cuenta de reglas</Text>
          </View>
        </View>
        {(institutionAccounts || [])
          ?.filter((ia) => ia.institutionIdentifier !== 'generali')
          .map((iAc) => (
            <BanksCollapse name={iAc.name}>
              {(iAc.products || [])
                .filter((item) => !item.isDeleted && accountsType.includes(item.type))
                .map((item, i) => {
                  return (
                    <BanksItem
                      logo={['debit', 'card'].includes(item.type) ? card : account}
                      balance={item.balance}
                      identifier={item.identifier}
                      active={activeRuleAccounts[iAc.id + '-' + item.id]}
                      onChange={(value) =>
                        setActiveRuleAccounts({
                          ...activeRuleAccounts,
                          [iAc.id + '-' + item.id]: value,
                        })
                      }
                      dark={i % 2 === 0}
                    />
                  );
                })}
              {!(iAc.products || []).filter(
                (item) => !item.isDeleted && accountsType.includes(item.type),
              ).length && (
                  <View style={{ padding: 5 }}>
                    <Text>Sin productos</Text>
                  </View>
                )}
            </BanksCollapse>
          ))}
        <View
          style={{
            marginTop: 30,
            background: colors.grey5,
            width: 68,
            height: 68,
            borderRadius: '50%',
          }}
          onClick={() =>
            navigateTo(routes.bankConnect, {
              queryParams: {
                from: {
                  name: routes.targetAccountsConfigure,
                  routeParams: {
                    idTarget: target.id,
                  },
                },
              },
            })
          }
        >
          <Text style={{ fontSize: 30, color: colors.red, marginTop: -5 }}>+</Text>
        </View>
        <Text
          onClick={() =>
            navigateTo(routes.bankConnect, {
              queryParams: {
                from: {
                  name: routes.targetAccountsConfigure,
                  routeParams: {
                    idTarget: target.id,
                  },
                },
              },
            })
          }
          pointerSt
          style={{ fontSize: 16, marginTop: 20 }}
        >
          Añade otro banco
        </Text>
      </View>
    </>
  );
});

export default Rules;
