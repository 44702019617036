import React, { useState, useRef, useEffect } from 'react';

import { Text, View, Image, TextField } from '@coinscrap/webapp-core';

// images
import Warning from '../../../common/svgs/Warning';
import EditRed from '../../../assets/images/EditRed.png';
// configs
import colors from '../../../config/colors';

const Objective = ({ data, onChange }) => {
  return (
    <View>
      <View style={{ backgroundColor: '#fef7f6', height: 53 }}>
        <View topSt width90St>
          <Text style={{ fontSize: 13 }}>CONFIGURADOR DE OBJETIVO</Text>
        </View>
      </View>
      <Card
        onChange={onChange}
        label="Nombre del objetivo"
        value={data.name}
        name="name"
        type="text"
      />
      <Card
        onChange={onChange}
        label="Importe del objetivo"
        value={data.price}
        name="price"
        type="text"
      />
      <Card
        onChange={onChange}
        label="Tiempo para conseguir el objetivo"
        value={data.date}
        name="date"
        type="number"
      />
      <View style={{ background: '#FEF9F8 0% 0% no-repeat padding-box', height: 33 }}>
        <View width90St horizontalSt startSt>
          <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
          <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
            Puedes editar los parámetros pinchando encima.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Objective;

const Card = ({ label, value, name, onChange, type }) => {
  const [inputConfig, setInputConfig] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    if (name === 'price') {
      if (
        e.target.value.toString().split(',')[1]?.length <= 2 ||
        !e.target.value.toString().split(',')[1]?.length
      ) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputConfig]);
  return (
    <View style={{ borderBottom: `1px solid ${colors.grey7}` }}>
      <View width90St horizontalSt spaceBetweenSt style={{ padding: '18px 0px' }}>
        <View topSt autoWidthSt style={{ position: 'relative' }}>
          <Text font13St boldSt>
            {label}
          </Text>
          {inputConfig ? (
            <TextField
              inputRef={inputRef}
              internalProps={{
                inputProps: { name: name },
              }}
              onBlur={(e) => {
                setInputConfig(false);
              }}
              type={type}
              value={value}
              onChange={handleChange}
              smallInputSt
              style={{
                containerComponent: {
                  paddingTop: 10,
                  width: '100%',
                },
                inputComponent: {
                  color: colors.dark,
                  fontSize: 15,
                },
              }}
            />
          ) : (
            <Text onClick={() => setInputConfig(true)} font15St grey4St style={{ marginTop: 10 }}>
              {value}
            </Text>
          )}
          {name === 'price' && (
            <Text font15St grey4St style={{ position: 'absolute', bottom: 0, right: 30 }}>
              €
            </Text>
          )}
        </View>
        <View autoWidthSt onClick={() => setInputConfig(true)}>
          <Image pointerSt src={EditRed} style={{ width: 18, height: 'auto' }} />
        </View>
      </View>
    </View>
  );
};
