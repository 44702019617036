import React from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Button,
  TargetsWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';
import InfoHeader from 'common/InfoHeader';

// configs
import colors from 'config/colors';
import routes from 'config/routes';

import { LoadingData } from 'common/LoadingData';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target } = PickerHOC.use();

  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. REGLAS" left="atras" />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 30 }}
        borderWhiteButtonSt
        onClick={() => {
          navigateTo(routes.roundRule, {
            routeParams: {
              idTarget: target.id,
            },
          });
        }}
      >
        ¡Adelante!
      </Button>
    </Footer>,
    [],
  );

  return (
    <Layout.Container>
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <InfoHeader text="3" />
          <View style={{ marginTop: 50 }}>
            <View width65St>
              <Text font27St textCenterSt>
                Configuración de reglas de ahorro
              </Text>
            </View>
            <View width65St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
                Las reglas de ahorro que elijas se van a sumar a tu aportación periódica y
                permitirán que ahorres de una forma automática y aún más rápida para tu objetivo.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});
