import React from 'react';

import { UiWrapper, View, Text, PickerHOC } from '@coinscrap/webapp-core';

// images

// configs
import colors from '../../../config/colors';

export default PickerHOC()(({ section, onChange, finished }) => {
  const { useBackground } = UiWrapper.use();

  useBackground(colors.white);

  return (
    <View horizontalSt>
      {finished ? (
        <View
          onClick={() => onChange('movements')}
          flexGrowSt
          autoWidthSt
          style={{ height: 67, flexGrow: 1.5 }}
          homeSectionBorderBottomGreySt
          homeSectionBorderBottomSt={section === 'movements'}
          pointerSt
        >
          <Text
            tabTextSt
            style={{ fontSize: 14, color: section === 'movements' ? colors.dark : colors.grey1 }}
            boldSt={section === 'movements'}
          >
            Movimientos
          </Text>
        </View>
      ) : (
        <>
          <View
            onClick={() => onChange('graph')}
            flexGrowSt
            autoWidthSt
            style={{ height: 67 }}
            homeSectionBorderBottomGreySt
            homeSectionBorderBottomSt={section === 'graph'}
            pointerSt
          >
            <Text
              tabTextSt
              style={{ fontSize: 14, color: section === 'graph' ? colors.dark : colors.grey1 }}
              boldSt={section === 'graph'}
            >
              Gráficos
            </Text>
          </View>
          <View
            onClick={() => onChange('movements')}
            flexGrowSt
            autoWidthSt
            style={{ height: 67, flexGrow: 1.5 }}
            homeSectionBorderBottomGreySt
            homeSectionBorderBottomSt={section === 'movements'}
            pointerSt
          >
            <Text
              tabTextSt
              style={{ fontSize: 14, color: section === 'movements' ? colors.dark : colors.grey1 }}
              boldSt={section === 'movements'}
            >
              Movimientos
            </Text>
          </View>
          <View
            onClick={() => onChange('contributions')}
            flexGrowSt
            autoWidthSt
            style={{ height: 67, flexGrow: 1.5 }}
            homeSectionBorderBottomGreySt
            homeSectionBorderBottomSt={section === 'contributions'}
            pointerSt
          >
            <Text
              tabTextSt
              style={{
                fontSize: 14,
                color: section === 'contributions' ? colors.dark : colors.grey1,
              }}
              boldSt={section === 'contributions'}
            >
              Aportaciones
            </Text>
          </View>
          <View
            onClick={() => onChange('rules')}
            flexGrowSt
            autoWidthSt
            style={{ height: 67 }}
            homeSectionBorderBottomGreySt
            homeSectionBorderBottomSt={section === 'rules'}
            pointerSt
          >
            <Text
              tabTextSt
              style={{ fontSize: 14, color: section === 'rules' ? colors.dark : colors.grey1 }}
              boldSt={section === 'rules'}
            >
              Reglas
            </Text>
          </View>
        </>
      )}
    </View>
  );
});
