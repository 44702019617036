import moment from 'moment';

export function futureDate(months) {
  let date = new Date();

  date = new Date(date.setMonth(date.getMonth() + Number(months)));
  return date;
}

export function dateToNumberOfMonths(targetCreationDate, targetGoalDate) {
  return Math.abs(moment(targetCreationDate).diff(moment(targetGoalDate), 'months'));
}
