export default {
  HelveticaNeue: 'HelveticaNeue',
  HelveticaNeueBold: 'HelveticaNeueBold',
  HelveticaNeueBoldItalic: 'HelveticaNeueBoldItalic',
  HelveticaNeueCondensedBlack: 'HelveticaNeueCondensedBlack',
  HelveticaNeueCondensedBold: 'HelveticaNeueCondensedBold',
  HelveticaNeueItalic: 'HelveticaNeueItalic',
  HelveticaNeueLight: 'HelveticaNeueLight',
  HelveticaNeueLightItalic: 'HelveticaNeueLightItalic',
  HelveticaNeueMedium: 'HelveticaNeueMedium',
  HelveticaNeueMediumItalic: 'HelveticaNeueMediumItalic',
  HelveticaNeueThin: 'HelveticaNeueThin',
  HelveticaNeueThinItalic: 'HelveticaNeueThinItalic',
  HelveticaNeueUltraLight: 'HelveticaNeueUltraLight',
  HelveticaNeueUltraLightItalic: 'HelveticaNeueUltraLightItalic',
};
