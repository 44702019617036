import { UiWrapper } from '@coinscrap/webapp-core';
import colors from "../colors";

UiWrapper.defineComponent("inputImage", {
  styles: {
    default: {
      backgroundSize: "cover",
      marginBottom: "10px",
      textComponent: {
        fontWeight: 600,
      },
      containerComponent: {
        // border: "1px solid #fff",
        height: 120,
        width: 200,
      },
    },

    yellowBorderSt: {
      containerComponent: {
        border: "1px solid " + colors.orange,
        backgroundColor: "rgba(0, 0, 0, 0)",
        backgroundSize: "cover",
      },
      cameraComponent: {
        color: colors.orange,
      },
    },
  },
});
