import { Image, InputSwitch, Text, View } from '@coinscrap/webapp-core';
import colors from 'config/colors';
import { getCurrency } from 'libs/Currency';
import React from 'react';

const BanksItem = ({ logo, dark, balance, identifier, active, onChange }) => {
  return (
    <View horizontalSt style={{ padding: '5px 10px', backgroundColor: dark && colors.grey3 }}>
      <View horizontalSt autoWidthSt flexGrowSt style={{ flexBasis: '0%' }}>
        <View style={{ width: 23 }}>
          <Image src={logo} style={{ width: '100%', height: 'auto' }}></Image>
        </View>
        <Text style={{ fontSize: 16, marginLeft: 20 }}>**** {(identifier || '').substr(-4)}</Text>
      </View>
      <View autoWidthSt flexGrowSt style={{ flexBasis: '0%' }}>
        <Text style={{ fontSize: 16 }}>{getCurrency(balance, 2, false)}</Text>
      </View>
      <View autoWidthSt flexGrowSt style={{ flexBasis: '0%' }}>
        <View autoWidthSt style={{ marginLeft: 'auto' }}>
          <InputSwitch value={active} onChange={onChange}></InputSwitch>
        </View>
      </View>
    </View>
  );
};

export default BanksItem;
