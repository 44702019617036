import React from 'react';

export default function Back(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.49"
      height="61.878"
      viewBox="0 0 33.49 61.878"
      style={{ ...props.style }}
    >
      <defs></defs>
      <g transform="translate(-4954.954 -3104.613)">
        <path
          className="a"
          d="M25.873,59.535c-.018-.017-.033-.032-.048-.049L1.184,33.216A3.459,3.459,0,0,1,0,30.438a3.461,3.461,0,0,1,1.184-2.779L25.825,1.389A3.7,3.7,0,0,1,28.462,0a3.877,3.877,0,0,1,2.7,1.389A3.929,3.929,0,0,1,32.49,4.123a4.066,4.066,0,0,1-1.451,2.826L9.239,30.438l21.8,23.493a4.055,4.055,0,0,1,1.451,2.821,3.929,3.929,0,0,1-1.332,2.734,3.876,3.876,0,0,1-2.7,1.392A3.644,3.644,0,0,1,25.873,59.535Z"
          transform="translate(4955.454 3105.113)"
        />
      </g>
    </svg>
  );
}
