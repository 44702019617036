import { Text, View } from '@coinscrap/webapp-core';
import colors from 'config/colors';
import React from 'react';

const MovementList = ({ date, children }) => {
  return (
    <View>
      <View topSt style={{ backgroundColor: colors.grey5 }}>
        <Text style={{ fontSize: 13, padding: '10px 20px' }}>{date}</Text>
      </View>
      <View>{children}</View>
    </View>
  );
};

export default MovementList;
