import React from 'react';

import { View, Text } from '@coinscrap/webapp-core';

import colors from 'config/colors';

const InfoHeader = ({ text }) => {
  return (
    <View style={{ height: 140, position: 'relative' }}>
      <Text
        boldSt
        style={{ WebkitTextStroke: `2px ${colors.red}`, fontSize: 160, color: colors.white }}
      >
        {text}
      </Text>

      <View
        style={{
          border: `2px solid ${colors.red}`,
          borderRadius: '50%',
          position: 'absolute',
          top: '-150px',
          height: '320px',
          width: '140%',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </View>
  );
};

export default InfoHeader;
