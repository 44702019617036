import React from 'react';

export default function Circles(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="101.651"
      height="71.554"
      viewBox="0 0 101.651 71.554"
      style={{ ...props.style }}
    >
      <defs>
        <style></style>
      </defs>
      <path
        className="a"
        d="M835.122,1097.91a35.522,35.522,0,0,0-15.049,3.363,35.777,35.777,0,1,0,0,64.827,35.76,35.76,0,1,0,15.049-68.19Zm-30.1,68.113a32.336,32.336,0,1,1,11.171-62.64,35.677,35.677,0,0,0,0,60.61A32.1,32.1,0,0,1,805.024,1166.023Zm15.049-60.933a32.3,32.3,0,0,1,0,57.195,32.3,32.3,0,0,1,0-57.195Zm15.049,60.933a32.1,32.1,0,0,1-11.171-2.03,35.677,35.677,0,0,0,0-60.61,32.319,32.319,0,1,1,11.171,62.64Z"
        transform="translate(-769.247 -1097.91)"
      />
    </svg>
  );
}
