import React, { useCallback, useState } from 'react';

import {
  UiWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  NavigationWrapper,
  TextField,
  Image,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';

// images
import ClockWhite from 'assets/images/ClockWhite.png';
import CheckMark from 'common/svgs/CheckMark';
import Back from 'common/svgs/Back';

// configs
import colors from 'config/colors';
import routes from 'config/routes';

// libs
import { onChangeAmountHandler } from '../../../libs/currencyInput';

export default PickerHOC()(() => {
  const { useBackground, useFooter, useHeader } = UiWrapper.use();
  const { navigateTo, goBack, query } = NavigationWrapper.use();
  const [amount, setAmount] = useState('');

  useBackground(colors.white);

  const continueHandler = useCallback(() => {
    let route = query.from ? query.from : routes.incomeRule;

    navigateTo(route);
  }, [query, navigateTo]);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        left={
          <View autoWidthSt style={{ marginLeft: 18 }} onClick={() => goBack()}>
            <Back style={{ fill: colors.white, width: 11, height: 'auto' }} />
          </View>
        }
        style={{ backgroundColor: colors.red }}
        right="none"
      />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.red }}>
      <View style={{ padding: 25 }}>
        <Text boldSt font15St whiteSt style={{ marginBottom: 20 }}>
          {' '}
          ¿Quieres activar esta regla?{' '}
        </Text>
        <View horizontalSt>
          <View
            style={{
              height: 54,
              width: 54,
              borderRadius: '50%',
              background: colors.white,
              marginRight: 32,
            }}
            onClick={continueHandler}
          >
            <View
              autoWidthSt
              boldSt
              style={{
                fontSize: 45,
                color: colors.red,
                transform: 'rotate(45deg)',
                paddingBottom: 10,
              }}
            >
              +
            </View>
          </View>
          <View
            onClick={continueHandler}
            style={{ height: 54, width: 54, borderRadius: '50%', background: colors.white }}
          >
            <CheckMark style={{ width: 20, height: 'auto', fill: colors.green }} />
          </View>
        </View>
      </View>
    </Footer>,
    [continueHandler],
  );

  return (
    <Layout.Container>
      <View
        startSt
        fullHeightSt
        style={{ backgroundColor: colors.red, paddingTop: 10, paddingBottom: 10 }}
      >
        <View width79St>
          <Image src={ClockWhite} style={{ width: 80, height: 'auto' }} />

          <Text font27St boldSt whiteSt style={{ marginTop: 20, textAlign: 'center' }}>
            Aportación periódica
          </Text>
          <Text textCenterSt font15St whiteSt lineHeight16St style={{ marginTop: 27 }}>
            Representa el complemento fijo del ahorro mensual que quieres añadir para alcanzar tu
            objetivo cuanto antes.
          </Text>
          <Text font15St boldSt textCenterSt whiteSt style={{ marginTop: 20, width: 300 }}>
            Pon el importe que deseas ahorrar de manera periódica
          </Text>
          <View style={{ marginTop: 30 }}>
            <TextField
              createInputSt
              ruleTransparentBackgroundSt
              onChange={(e) => onChangeAmountHandler(e.target.value, setAmount)}
              value={amount}
              startAdornment={
                <View
                  style={{
                    backgroundColor: colors.white,
                    height: 20,
                    width: 20,
                    borderRadius: '50%',
                    opacity: amount ? 1 : 0.7,
                  }}
                >
                  <CheckMark style={{ fill: colors.red, height: 8, width: 'auto' }} />
                </View>
              }
              endAdornment={
                <Text style={{ paddingTop: 2, marginLeft: 5, color: colors.white }}> €</Text>
              }
            />
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});
