import {
  AnalyticsWrapper,
  EnvelopesWrapper,
  FinancialWrapper,
  InstitutionAccountsWrapper,
  InstitutionsWrapper,
  OperationsWrapper,
  RuleDefinitionsWrapper,
  RulesWrapper,
  TargetsUtilsWrapper,
  TargetsWrapper,
  TargetTypesWrapper,
  UserWrapper,
} from '@coinscrap/webapp-core';
import React from 'react';

export const EntityWrappers = ({ children, ...props }) => {
  const transportsAnalytics = [
    {
      engine: AnalyticsWrapper.TRANSPORTS.COINSCRAP,
      initProps: {
        debugEnabled: false,
      },
    },
    {
      engine: AnalyticsWrapper.TRANSPORTS.GOOGLE_TAG_MANAGER,
      initProps: {
        debugEnabled: false,
      },
    },
  ];
  return (
    <UserWrapper>
      <AnalyticsWrapper transports={transportsAnalytics}>
        <OperationsWrapper>
          <InstitutionsWrapper>
            <InstitutionAccountsWrapper>
              <FinancialWrapper>
                <RuleDefinitionsWrapper>
                  <TargetTypesWrapper>
                    <TargetsWrapper enableNextSaving enableTargetRulesConfiguration>
                      <RulesWrapper>
                        <TargetsUtilsWrapper>
                          <EnvelopesWrapper>{children}</EnvelopesWrapper>
                        </TargetsUtilsWrapper>
                      </RulesWrapper>
                    </TargetsWrapper>
                  </TargetTypesWrapper>
                </RuleDefinitionsWrapper>
              </FinancialWrapper>
            </InstitutionAccountsWrapper>
          </InstitutionsWrapper>
        </OperationsWrapper>
      </AnalyticsWrapper>
    </UserWrapper>
  );
};
