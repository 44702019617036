import React from 'react';

export default function Info(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="215"
      height="215"
      viewBox="0 0 215 215"
      style={{ ...props.style }}
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(-698.695 -1098.985)">
        <path
          className="a"
          d="M806.2,1098.985a107.5,107.5,0,1,0,107.5,107.5A107.624,107.624,0,0,0,806.2,1098.985Zm0,204.043a96.543,96.543,0,1,1,96.541-96.546A96.654,96.654,0,0,1,806.2,1303.028Z"
          transform="translate(0 0)"
        />
        <path
          className="a"
          d="M748.163,1194.555a15.381,15.381,0,0,1-3.809,3.289c-1.341.794-2.413,1.045-3.207.744a2.5,2.5,0,0,1-1.422-1.27,3.555,3.555,0,0,1-.372-2.162l19.705-65.244a14.41,14.41,0,0,0-2.014-11.042q-3.209-4.934-10.228-5.675-7.461,0-17.09,6.943a66.085,66.085,0,0,0-16.351,16.943c0,.8-.022,2.013-.071,3.655a37.382,37.382,0,0,0,.071,4.257l11.946-14.183a16.93,16.93,0,0,1,3.655-3.284q1.872-1.2,3.059-.744a2.432,2.432,0,0,1,1.57,1.417,3.38,3.38,0,0,1,.225,2.315l-19.705,64.939a12.259,12.259,0,0,0,1.489,10.523q3.135,4.859,10.9,6.2,11.491,0,19.032-6.8a87.9,87.9,0,0,0,14.26-16.942c0-.8.071-2.042.225-3.732a17.613,17.613,0,0,0-.077-4.329Z"
          transform="translate(65.334 63.719)"
        />
        <path
          className="a"
          d="M732.253,1106.676a13.5,13.5,0,0,0-9.626,3.732,12.728,12.728,0,0,0-3.956,9.7,12.918,12.918,0,0,0,3.956,9.855,14.187,14.187,0,0,0,19.18,0,12.8,12.8,0,0,0,4.033-9.855,12.614,12.614,0,0,0-4.033-9.7A13.58,13.58,0,0,0,732.253,1106.676Z"
          transform="translate(89.479 34.451)"
        />
      </g>
    </svg>
  );
}
