import {
  InstitutionAccountsWrapper,
  NavigationWrapper,
  Page,
  PickerHOC,
  SoftSwitcher,
  TargetsUtilsWrapper,
  TargetsWrapper,
  TargetTypesWrapper,
  Text,
  UiWrapper,
  View,
} from '@coinscrap/webapp-core';

import HeaderRedPrimary from 'common/HeaderRedPrimary';

import routes from 'config/routes';
import React, { useEffect, useState } from 'react';

const CreateGlobalTarget = PickerHOC(
  {
    targetType: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        value: 'SMARTAHORRO',
      },
      sourcePath: 'targetTypes',
      sourceWrapper: TargetTypesWrapper,
    },
    parentTarget: {
      arrayItemMatcher: {
        allowNoExistence: true,
        itemPropertyPath: 'level',
        type: 'value',
        value: 0,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    userTargets: {
      arrayItemFilter: {
        itemPropertyPath: 'level',
        type: 'value',
        value: 1,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    // This awaits accounts for getDestProduct() in inner home
    userBanks: {
      sourcePath: 'institutionAccounts',
      sourceWrapper: InstitutionAccountsWrapper,
    },
  },
  () => {
    UiWrapper.use().useLoading(true);
    return null;
  },
)(() => {
  const { useLoading } = UiWrapper.use();
  const {
    params: { institutionAccountId, productId },
  } = Page.use();
  const { useHeader } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();
  const { instanciateNewTarget, activate, saveTargetProperty } = TargetsUtilsWrapper.use();
  const [error, setError] = useState(0);

  const {
    userBanks,
    parentTarget,
    targetType: { id: idTargetType },
  } = PickerHOC.use();

  const parentTargetCorrectlyInicializated =
    parentTarget?.status === 'ACTIVE' && parentTarget?.creationData?.destProduct?.identifier;

  // Efectting for create a parent target if not exsits
  useEffect(() => {
    if (!parentTarget?.id) {
      (async () => {
        try {
          await instanciateNewTarget({
            targetType: idTargetType,
          });
        } catch (e) {
          setError(1);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTarget?.id]);

  // Efectting set destination charge account on target if does not done
  useEffect(() => {
    if (parentTarget?.id && !parentTarget?.creationData?.destProduct?.identifier) {
      (async () => {
        const institution = userBanks.find((iA) => iA.id === institutionAccountId);
        const productDestino = (institution?.products || []).find((p) => p.id === productId);
        if (productDestino) {
          try {
            await saveTargetProperty(parentTarget?.id, 'creationData', {
              destProduct: {
                combinedId: productDestino.institutionAccountId + '-' + productDestino.id,
                identifier: productDestino.identifier,
                typeIdentifier: productDestino.typeIdentifier,
              },
            });
          } catch (e) {
            setError(1);
          }
        } else {
          setError(2);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTarget?.id, parentTarget?.creationData?.destProduct?.combinedId]);

  // Efectting activating target if is not done
  useEffect(() => {
    if (
      parentTarget?.status === 'TEMPORARY' &&
      parentTarget?.creationData?.destProduct?.combinedId
    ) {
      (async () => {
        try {
          await activate(parentTarget.id);
        } catch (e) {
          setError(1);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTarget?.status, parentTarget?.creationData?.destProduct?.combinedId]);

  useEffect(() => {
    if (parentTargetCorrectlyInicializated) {
      navigateTo(routes.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTargetCorrectlyInicializated]);

  useBackRoute(undefined);
  useLoading(false);

  useHeader(
    <View>
      <HeaderRedPrimary />
    </View>,
    [],
  );
  return (
    <View style={{ marginTop: 40 }}>
      <SoftSwitcher evaluate={error}>
        <>
          <Text font27St>Tu ahorro</Text>
          <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
            Estamos inicializando tu ahorro
          </Text>
        </>
        <>
          <Text font27St>Error</Text>
          <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
            Algo ha sucedido mientras intentabamos inicializar tu ahorro. Por favor inténtelo de
            nuevo mas tarde.
          </Text>
        </>
        <>
          <Text font27St>Póliza Incorrecta</Text>
          <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
            No se puede crear el objetivo de ahorro sobre la póliza seleccionada. Por favor
            inténtelo de nuevo mas tarde.
          </Text>
        </>
      </SoftSwitcher>
    </View>
  );
});

export default CreateGlobalTarget;
