export default (img, setImageFile, setImageUrl) => {
  if (
    img.type === 'image/png' ||
    img.type === 'image/jpeg' ||
    img.type === 'image/jpg' ||
    img.type === 'image/x-png' ||
    img.type === 'image/gif' ||
    img.type === 'image/svg+xml'
  ) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setImageFile(img);
        setImageUrl(reader.result);
      },
      false,
    );
    reader.readAsDataURL(img);
  }
};
