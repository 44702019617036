import React from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Enfatize,
  Image,
  Button,
} from '@coinscrap/webapp-core';

// components
import MyChartJS from '../../../common/MyChartJS';

// images
import Hand from '../../../assets/images/Hand.png';
import CheckMark from '../../../common/svgs/CheckMark';
import Warning from '../../../common/svgs/Warning';
import XMark from '../../../common/svgs/XMark';
import Arrow1 from '../../../assets/images/Arrow1.png';
import Arrow2 from '../../../assets/images/Arrow2.png';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

// libs
import { getCurrency } from '../../../libs/Currency';

const optionsGraph = {
  rotation: 20,
  cutoutPercentage: 70,
  legend: {
    display: false,
    position: 'left',
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    animateRotate: true,
    animateScale: false,
  },
};

const dataGraph = (total, saved) => {
  const remain = total - saved;
  return {
    datasets: [
      {
        data: [remain, saved === 0 ? total * 0.001 : saved],
        backgroundColor: ['#EBEBEB', colors.red],
        borderWidth: 0,
        hoverBorderWidth: 0,
        weight: 20,
      },
    ],
  };
};

// import routes from "../config/routes";
export default PickerHOC()(({ backdrop, data, onClick }) => {
  const { useBackground } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useBackground(colors.white);

  useBackRoute(undefined);

  return (
    <View onClick={onClick}>
      {data.length === 0 ? (
        <View alignCenterSt>
          <View style={{ marginTop: 60 }}>
            <Image src={Hand} style={{ height: 'auto', width: 145 }} />
          </View>
          <View width80St style={{ marginTop: 43 }}>
            <Text font27St textCenterSt style={{ marginBottom: 15 }}>
              ¡Bienvenido Carlos!
            </Text>
            <Text grey1St lineHeight16St font15St textCenterSt>
              Aún no has empezado a utilizar GENERALI My Pocket. Es muy fácil ya verás.
            </Text>
            <Text grey1St lineHeight16St boldSt font15St textCenterSt>
              ¿Quieres que creemos nuestro primer objetivo de ahorro?.
            </Text>
          </View>
          <Button
            onClick={() => navigateTo(routes.objectiveInfo)}
            regularButtonSt
            style={{ marginBottom: 50, marginTop: 25 }}
          >
            Crear Objetivo
          </Button>
        </View>
      ) : (
        data.map((item) => {
          return <Card key={item.name} item={item} backdrop={backdrop} />;
        })
      )}
    </View>
  );
});

const Card = ({ item, backdrop }) => {
  return (
    <View>
      <View homeSectionBorderBottomGreySt style={{ paddingBottom: 16 }}>
        <View style={{ marginTop: 20 }} width83St>
          <View horizontalSt spaceBetweenSt style={{ marginBottom: 0 }}>
            <View autoWidthSt style={{ position: 'relative' }}>
              <Text font17St>{item.name}</Text>
              {backdrop && (
                <View boldSt tutorialCardObjectiveASt>
                  Tarjeta de objetivo creado
                  <View autoWidthSt tutorialCardObjectiveBSt>
                    <img src={Arrow1} style={{ width: 25 }} alt="" />
                  </View>
                </View>
              )}
            </View>
            <View autoWidthSt style={{ position: 'relative' }}>
              <ArrowForwardIosIcon style={{ width: 'auto', height: 12 }} />
              {backdrop && (
                <View boldSt tutorialInfoDetalleASt>
                  Info + detalle
                  <View autoWidthSt tutorialInfoDetalleBSt>
                    <img src={Arrow2} style={{ height: 30 }} alt="" />
                  </View>
                </View>
              )}
            </View>
          </View>
          <View horizontalSt spaceBetweenSt>
            <View topSt autoWidthSt style={{ position: 'relative' }}>
              <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
                <CheckMark style={{ fill: '#c5281c', width: 12, height: 'auto' }} />
                <Text style={{ fontSize: 17, marginLeft: 8, color: colors.grey1 }}>
                  {getCurrency(item.amount)}
                </Text>
              </View>
              <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
                <CheckMark style={{ fill: '#c5281c', width: 12, height: 'auto' }} />
                <Text style={{ fontSize: 17, marginLeft: 8, color: colors.grey1 }}>
                  {item.months} Meses
                </Text>
              </View>
              <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
                {item.rules ? (
                  <CheckMark style={{ fill: '#c5281c', width: 12, height: 'auto' }} />
                ) : (
                  <XMark style={{ opacity: 0.4, fill: colors.grey1, width: 12, height: 'auto' }} />
                )}

                <Text style={{ fontSize: 17, marginLeft: 8, color: colors.grey1 }}>
                  Reglas de ahorro
                </Text>
              </View>
              {backdrop && (
                <View boldSt tutorialNameConfigurationASt>
                  Nombre y configuración del objetivo
                  <View autoWidthSt tutorialNameConfigurationBSt>
                    <img src={Arrow1} style={{ width: 25, transform: 'rotate(180deg)' }} alt="" />
                  </View>
                </View>
              )}
            </View>
            <View
              style={{
                width: 80,
                height: 80,
                marginRight: 40,
                marginBottom: 15,
                position: 'relative',
              }}
            >
              <MyChartJS
                type={'doughnut'}
                data={dataGraph(item.total, item.saved)}
                options={optionsGraph}
                style={{ margin: 0 }}
              />
              {backdrop && (
                <View boldSt tutorialProgressASt>
                  % Progreso
                  <View autoWidthSt tutorialProgressBSt>
                    <img src={Arrow1} style={{ width: 25, transform: 'rotate(-75deg)' }} alt="" />
                  </View>
                </View>
              )}

              <View
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  width: 50,
                }}
              >
                <Text style={{ fontSize: 18 }}>
                  {((item.saved / item.total) * 100).toFixed(0)}{' '}
                  <Enfatize
                    style={{
                      transform: 'translate(-1px,-5px)',
                      display: 'inline-block',
                      fontSize: 18,
                    }}
                  >
                    %
                  </Enfatize>
                </Text>

                <Text style={{ fontSize: 8.5 }}>Objetivo</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      {!item.rules && (
        <View style={{ background: '#FEF9F8 0% 0% no-repeat padding-box', height: 33 }}>
          <View width95St horizontalSt startSt>
            <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
            <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
              ¡Aún no has activado ninguna regla de ahorro para este objetivo!
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};
