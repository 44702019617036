import React from 'react';
import classes from './Splash.module.css';
const Splash = () => {
  return (
    <div className={classes['splash-screen']}>
      <div className={classes['loading-dot']}>.</div>
    </div>
  );
};

export default Splash;
