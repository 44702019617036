import React from 'react';

export default function StopSmoking(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107.244"
      height="81.507"
      viewBox="0 0 107.244 81.507"
      style={{ ...props.style }}
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(-915.891 -1103.188)">
        <path
          className="a"
          d="M920.173,1103.922a2.509,2.509,0,0,0-3.547,3.55l35.11,35.11H926.79a3.668,3.668,0,0,0-3.662,3.663v13.5a3.668,3.668,0,0,0,3.662,3.662h45.768l20.556,20.557a2.508,2.508,0,0,0,3.547-3.547Zm7.972,43.676h17.228v10.789H928.145Zm22.244,10.789V1147.6h6.364l10.789,10.789Z"
          transform="translate(0)"
        />
        <path
          className="a"
          d="M953.829,1132.287a2.507,2.507,0,0,0,4.325,2.538c.394-.647,3.63-6.617-2.857-16.443a7.672,7.672,0,0,1,.228-9.015,2.507,2.507,0,1,0-3.883-3.173,12.786,12.786,0,0,0-.534,14.952C955.773,1128.213,953.957,1132.036,953.829,1132.287Z"
          transform="translate(49.844 3.143)"
        />
        <path
          className="a"
          d="M949.152,1131.27a2.507,2.507,0,0,0,.883,3.394,2.481,2.481,0,0,0,1.292.359,2.508,2.508,0,0,0,2.152-1.214c.391-.647,3.627-6.617-2.86-16.443a7.661,7.661,0,0,1,.231-9.01,2.509,2.509,0,0,0-3.881-3.181,12.786,12.786,0,0,0-.539,14.955C951.1,1127.2,949.28,1131.021,949.152,1131.27Z"
          transform="translate(42.792 1.61)"
        />
        <path
          className="a"
          d="M985.147,1118.911a2.473,2.473,0,0,1-4.483-.018H938.219a2.508,2.508,0,1,0,0,5.017h42.445a2.457,2.457,0,0,0,1.974-1.034v12.854a2.462,2.462,0,0,0-1.974-1.031H956.195a2.508,2.508,0,0,0,0,5.017h24.469a2.462,2.462,0,0,1,4.483.331,2.506,2.506,0,0,0,2.508-2.508v-16.119A2.508,2.508,0,0,0,985.147,1118.911Z"
          transform="translate(29.897 23.689)"
        />
        <path
          className="a"
          d="M959.153,1118.9a2.508,2.508,0,0,0-2.508,2.508v16.119a2.508,2.508,0,1,0,5.017,0v-16.119A2.509,2.509,0,0,0,959.153,1118.9Z"
          transform="translate(61.474 23.699)"
        />
      </g>
    </svg>
  );
}
