import React from 'react';

export default function Button(props) {
  return (
    <div onClick={props.onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="204"
        height="204"
        viewBox="0 0 204 204"
        style={{ ...props.style }}
      >
        <defs></defs>
        <circle style={{ fill: '#c5281c' }} cx="102" cy="102" r="102" />
        <text
          style={{ fill: '#fff', fontSize: '100px', fontFamily: 'HelveticaNeue' }}
          transform="translate(23 30)"
        >
          <tspan x="49" y="95">
            +
          </tspan>
        </text>
      </svg>
    </div>
  );
}
