import colors from 'config/colors';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Image,
  Enfatize,
  InputCheck,
  NavigationWrapper,
  Text,
  TextField,
  View,
  InstitutionsWrapper,
  FinancialWrapper,
  UiWrapper,
} from '@coinscrap/webapp-core';
import morpheusLogo from 'assets/images/MorpheusLogo.min.svg';
import avatar from 'assets/images/afterbanks-avatar.webp';
import routes from 'config/routes';
import AfterBanksLegalModal from './AfterBanksLegalModal';
import AfterBanksPrivacyModal from './AfterBanksPrivacyModal';
import BankTermsGeneraliModal from './BankTermsGeneraliModal';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import CancelIcon from '@material-ui/icons/Cancel';

const AfterBanksModal = ({ isOpen, onClose, selectedBank }) => {
  const context = InstitutionsWrapper.use();
  const { institutions } = context;
  const { useLoading, openModal } = UiWrapper.use();
  const { createOrUpdateCredentialsInstitution } = FinancialWrapper.use();
  const institution = (institutions || []).find((i) => i.identifier === selectedBank) || null;
  const [accept, setAccept] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [loginMode, setLoginMode] = useState(0);
  const [value, setValue] = useState({ 0: {} });

  const { navigateTo } = NavigationWrapper.use();

  useLoading(!institution);

  useEffect(() => {
    console.log('SOLO UNA VEZ AF MODAL');
  }, []);

  const optionalFields = institution?.authenticationMethods[loginMode].fields
    .filter((item) => item.label.toLowerCase().includes('opcional'))
    .map((item) => item.code);

  const allFieldsFilled =
    institution &&
    Object.keys(value[loginMode] || {})
      .filter((k) => value[loginMode][k])
      .filter((item) => !optionalFields.includes(item)).length ===
    institution.authenticationMethods[loginMode].fields.filter(
      (item) => !item.label.toLowerCase().includes('opcional'),
    ).length;

  const startSessionHandler = async () => {
    try {
      await createOrUpdateCredentialsInstitution(
        selectedBank,
        {
          method: institution?.authenticationMethods[loginMode].identifier,
          values: value[loginMode],
        },
        { mode: 'AWAIT_FULL_PROCESS' },
        (message, otpSetter, discard) => {
          openModal(
            (close, context, setContext) => (
              <View style={{ padding: 10 }}>
                <Text style={{ marginBottom: 25 }}>{message}</Text>
                <TextField
                  afterBanksInputSt
                  label="Código de activación"
                  value={context.value}
                  onChange={(e) => setContext({ value: e.target.value })}
                />
                <View style={{ marginTop: 25 }}>
                  <Button
                    style={{ padding: 7 }}
                    onClick={() => {
                      otpSetter(context.value);
                      close();
                    }}
                  >
                    Aceptar
                  </Button>
                  <Button
                    style={{ padding: 7 }}
                    onClick={() => {
                      discard();
                      close();
                    }}
                  >
                    Cancelar
                  </Button>
                </View>
              </View>
            ),
            { disableClickAway: true },
          );
        },
      );
      //console.log("NEXT");
      // setLogin(false);
      navigateTo(routes.bankUpdate, {
        mode: 'replace',
        routeParams: {
          idBank: selectedBank,
        },
      });
    } catch (e) {
      console.log('error message', e);
      openModal((close) => (
        <View style={{ padding: 10 }}>
          <Text>{e.message}</Text>
          <View style={{ marginTop: 10 }}>
            <Button
              style={{ padding: 7 }}
              onClick={() => close()}
            >
              Aceptar
            </Button>
          </View>
        </View>
      ));
    }
  };

  return isOpen ? (
    <View>
      <View
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          backgroundColor: '#000000aa',
        }}
        onClick={onClose}
      />

      <View
        style={{
          backgroundColor: 'white',
          // borderRadius: '15px 15px 0 0',
          position: 'absolute',
          bottom: 0,
          height: '100%',
          justifyContent: 'flex-start',
          overflow: 'auto',
        }}
      >
        <View style={{ alignItems: 'flex-end' }}>
          <View autoWidthSt style={{ padding: 10 }}>
            <CancelIcon style={{ color: colors.red }} onClick={() => onClose()} />
          </View>
        </View>
        <View
          style={{
            width: 40,
            height: 4,
            background: '#CFCFCF',
            borderRadius: 2,
            marginTop: 10,
          }}
        />

        <View
          style={{
            backgroundColor: colors.white,

            width: '90%',
            maxWidth: 850,
            padding: '30px 10px',
            justifyContent: 'start',
          }}
        >
          <Image
            style={{ width: '90%', height: 'auto', marginBottom: 30 }}
            src={morpheusLogo}
          ></Image>
          <View style={{ border: '2px solid #d7d7d7' }}>
            <Text
              style={{
                fontSize: 18,
                padding: 10,
                borderBottom: '2px solid #d7d7d7',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Acceso banca electrónica
            </Text>
            <Image
              src={avatar}
              style={{
                height: 80,
                width: 80,
                borderRadius: '50%',
                marginTop: 20,
                marginBottom: 30,
              }}
            ></Image>
            {institution?.authenticationMethods[loginMode].fields.map((f, i) => (
              <TextField
                afterBanksInputSt
                key={i}
                id={f.code}
                type={f.code.indexOf('pass') >= 0 ? 'password' : 'text'}
                label={f.label}
                placeholder={f.description}
                value={(value[loginMode] && value[loginMode][f.code]) || ''}
                onChange={(e) =>
                  setValue({
                    ...value,
                    [loginMode]: {
                      ...value[loginMode],
                      [f.code]: e.target.value,
                    },
                  })
                }
              />
            ))}
            {/* <TextField afterBanksInputSt placeholder="Email o Teléfono" />
            <TextField afterBanksInputSt placeholder="Password" /> */}
            <Button
              style={{
                padding: '9px',
                backgroundColor: '#303ba7',
                borderRadius: '2px',
                width: '90%',
                marginTop: 20,
                marginBottom: 20,
              }}
              disabled={
                !institution || !allFieldsFilled || !accept || !acceptPrivacy || !acceptConditions
              }
              onClick={startSessionHandler}
            >
              Conectar
            </Button>

            <View horizontalSt style={{ paddingRight: 10, marginBottom: 5 }} startSt>
              <InputCheck
                checkedIcon={<CheckBoxOutlinedIcon />}
                value={accept}
                onChange={() => {
                  openModal((close) => (
                    <AfterBanksLegalModal
                      onClick={() => setAccept(true)}
                      isOpen={true}
                      onClose={close}
                    />
                  ));
                }}
              />
              <Text
                pointerSt
                font13St
                onClick={() => {
                  openModal((close) => (
                    <AfterBanksLegalModal
                      onClick={() => setAccept(true)}
                      isOpen={true}
                      onClose={close}
                    />
                  ));
                }}
              >
                Acepto los términos y condiciones del servicio
              </Text>
            </View>
            <View horizontalSt style={{ paddingRight: 10, marginBottom: 5 }} startSt>
              <InputCheck
                checkedIcon={<CheckBoxOutlinedIcon />}
                value={acceptPrivacy}
                onChange={() => {
                  openModal((close) => (
                    <AfterBanksPrivacyModal
                      onClick={() => setAcceptPrivacy(true)}
                      isOpen={true}
                      onClose={close}
                    />
                  ));
                }}
              />
              <Text
                pointerSt
                font13St
                onClick={() => {
                  openModal((close) => (
                    <AfterBanksPrivacyModal
                      onClick={() => setAcceptPrivacy(true)}
                      isOpen={true}
                      onClose={close}
                    />
                  ));
                }}
              >
                Acepto la política de privacidad de Morpheus Aiolos
              </Text>
            </View>
            <View horizontalSt style={{ paddingRight: 10, marginBottom: 5 }} startSt>
              <InputCheck
                checkedIcon={<CheckBoxOutlinedIcon />}
                value={acceptConditions}
                onChange={() => {
                  openModal((close) => (
                    <BankTermsGeneraliModal
                      onClick={() => setAcceptConditions(true)}
                      isOpen={true}
                      onClose={close}
                    />
                  ));
                }}
              />
              <Text
                pointerSt
                font13St
                onClick={() => {
                  openModal((close) => (
                    <BankTermsGeneraliModal
                      onClick={() => setAcceptConditions(true)}
                      isOpen={true}
                      onClose={close}
                    />
                  ));
                }}
              >
                Con carácter revocable, autorizo a Morpheus Aiolos a la utilización de mis
                credenciales bancarias para el funcionamiento de “My Pocket”
              </Text>
            </View>
            <Text paragraphMarginSt style={{ textAlign: 'center', margin: 10, fontSize: 13 }}>
              A continuación serás de nuevo redirigido a MI GENERALI
            </Text>
            {(institution?.authenticationMethods || []).length > 1 && (
              <View horizontalSt style={{ width: '80%' }}>
                <Text font14St>
                  Método de inicio:{' '}
                  <Enfatize>{institution?.authenticationMethods[loginMode].name}</Enfatize>
                </Text>
                <Button
                  style={{
                    width: 'auto',
                    minWidth: '0',
                    textComponent: { textTransform: 'none', fontSize: 12 },
                  }}
                  onClick={() =>
                    openModal((close) => (
                      <View style={{ minHeight: 200, backgroundColor: 'white', padding: 10 }}>
                        {institution.authenticationMethods.map((m, i) => (
                          <Button
                            style={{
                              padding: '6px 0',
                              // border: `1px solid ${colors.orange}`,
                              backgroundColor: colors.red,
                            }}
                            onClick={() => {
                              setLoginMode(i);
                              close();
                            }}
                          >
                            {m.name}
                          </Button>
                        ))}
                      </View>
                    ))
                  }
                >
                  Cambiar
                </Button>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  ) : null;
};

export default AfterBanksModal;
