import React from 'react';
import RuleList from 'views/FollowUp/components/RuleList';
import RuleItem from 'views/FollowUp/components/RuleItem';
import {
  NavigationWrapper,
  PickerHOC,
  RuleDefinitionsWrapper,
  RulesWrapper,
  TargetsWrapper,
} from '@coinscrap/webapp-core';
import routes from 'config/routes';
import { stuctureDataListAllRules } from '../../../libs/structureData';

const Reglas = PickerHOC({
  ruleDefinition: {
    sourcePath: 'ruleDefinitions',
    sourceWrapper: RuleDefinitionsWrapper,
  },
  userRules: {
    sourcePath: 'rules',
    sourceWrapper: RulesWrapper,
  },
  userTargets: {
    arrayItemFilter: {
      itemPropertyPath: 'level',
      type: 'value',
      value: 1,
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
})(() => {
  const { navigateTo } = NavigationWrapper.use();

  const { userTargets, userRules, ruleDefinition } = PickerHOC.use();

  const listRules = stuctureDataListAllRules(ruleDefinition, userTargets, userRules);
  return (
    <RuleList>
      {listRules.map((rule) => {
        return (
          <RuleItem
            key={rule.id}
            data={console.log("RUE PROVIDED", rule) || rule}
            onClick={() =>
              navigateTo(routes.ruleListByType, {
                routeParams: {
                  ruleIdentifier: rule.ruleIdentifier,
                },
              })
            }
          />
        );
      })}
    </RuleList>
  );
});

export default Reglas;
