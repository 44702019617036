import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';
import fonts from '../fonts';

UiWrapper.defineComponent('button', {
  styles: {
    default: {
      backgroundColor: colors.red,
      borderWidth: 0,
      borderStyle: 'solid',
      fontSize: 18,
      margin: 10,
      maxWidth: 350,
      width: '100%',
      textComponent: {
        color: colors.white,
        fontSize: 16,
        fontFamily: fonts.HelveticaNeue,
      },
      disabledMode: {
        opacity: 0.5,
      },
      cursor: 'pointer',
    },

    // Pildora hueca con borde rojo
    pillSt: {
      borderWidth: 1,
      borderColor: colors.red,
      margin: 0,
      padding: 0,
      backgroundColor: 'transparent',
      height: 40,
      textComponent: {
        color: colors.red,
        fontSize: 16,
        lineHeight: '23px',
        fontWeight: 400,
        fontFamily: fonts.SantanderTextRegular,
      },
    },

    clearFiltersBtnSt: {
      width: 'auto',
      padding: 5,
      textComponent: {
        fontSize: 13,
        lineHeight: 1.2,
      },
    },

    pillFillSt: {
      borderColor: colors.red,
      borderStyle: 'solid',
      borderRadius: 20,
      borderWidth: 1,
      margin: 0,
      padding: 0,
      backgroundColor: colors.red,
      height: 40,

      textComponent: {
        color: colors.white,
        padding: 0,
        fontWeight: 700,
        fontSize: 16,
        fontFamily: fonts.SantanderTextRegular,
      },
    },

    backButtonSt: {
      // width: "45%",
      height: 40,
      borderRadius: 24,
      alignSelf: 'flex-end',
      fontSize: 14,
      borderWidth: 0,
      backgroundColor: 'transparent',
      flexDirection: 'row',
      padding: 0,
      margin: '16px 0 16px 24px',
      textComponent: {
        display: 'flex',
        flexDirection: 'row',
        verticalAlign: 'center',
      },
    },

    nextButtonSt: {
      // width: "45%",
      maxWidth: 160,
      height: 40,
      borderRadius: 24,
      alignSelf: 'flex-end',
      fontSize: 16,
      margin: '16px 24px 16px 0',
    },

    // Para cuando se deshabilita
    disabledSt: {
      backgroundColor: 'white',
      borderColor: 'silver',

      textComponent: {
        color: colors.grey,
      },
    },

    autoWidthSt: {
      width: 'auto',
      margin: 0,
    },

    regularButtonSt: {
      height: 62,
      width: 175,

      textComponent: {
        fontSize: 20,
      },
    },
    regularButtonHistorySt: {
      backgroundColor: colors.grey3,

      padding: '10px 15px',
      textComponent: {
        fontSize: 16,
        color: colors.black,
      },
    },
    borderWhiteButtonSt: {
      height: 52,
      width: 155,
      border: `1px solid ${colors.white}`,
      textComponent: {
        fontSize: 18,
        color: colors.white,
      },
    },
    smallBorderWhiteButtonSt: {
      height: 28,
      width: 78,
      margin: 0,
      border: `1px solid ${colors.white}`,
      textComponent: {
        fontSize: 10,
        color: colors.white,
      },
    },
    whiteButtonSt: {
      backgroundColor: colors.white,
      textComponent: {
        fontSize: 20,
        color: colors.red,
      },
    },

    whiteFooterButtonSt: {
      backgroundColor: colors.white,
      height: 52,
      width: 155,
      border: `1px solid ${colors.white}`,
      textComponent: {
        fontSize: 18,
        color: colors.red,
      },
    },
  },
});
