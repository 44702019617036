import React from 'react';

import { Button, NavigationWrapper, Text, View, Enfatize } from '@coinscrap/webapp-core';

// components
import BanksCollapse from 'common/BanksCollapse';
import BanksItem from 'common/BanksItem';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// images
import card from 'assets/images/card.png';
import account from 'assets/images/account.png';

import Warning from '../../../common/svgs/Warning';

// configs
import colors from '../../../config/colors';
import routes from 'config/routes';

export const accountsType = ['card', 'checking', 'saving'];

const Cuenta = ({ target, institutionAccounts, onChange, activeRuleAccounts }) => {
  const { navigateTo } = NavigationWrapper.use();
  console.log({ institutionAccounts });
  return (
    <View>
      <View style={{ backgroundColor: '#fef7f6', height: 53 }}>
        <View horizontalSt spaceBetweenSt width90St>
          <Text style={{ fontSize: 13 }}>MIS CUENTAS DE REGLAS</Text>
          <Button
            smallBorderWhiteButtonSt
            onClick={() =>
              navigateTo(routes.bankConnect, {
                mode: 'replace',
                queryParams: {
                  from: {
                    name: routes.edit,
                    routeParams: {
                      idTarget: target.id,
                    },
                  },
                },
              })
            }
          >
            Añadir
          </Button>
        </View>
      </View>
      {(institutionAccounts || [])
        ?.filter((ia) => ia.institutionIdentifier !== 'generali')
        .map((iAc) => (
          <View style={{ position: 'relative' }}>
            {iAc.pendingOperations && (
              <View
                onClick={() => {
                  if (iAc.currentPendingOperation?.type === 'VALIDATE_CREDENTIALS')
                    navigateTo(routes.bankConnect, {
                      mode: 'replace',
                      queryParams: {
                        institutionIdentifier: iAc.institutionIdentifier,
                        from: {
                          name: routes.edit,
                          routeParams: {
                            idTarget: target.id,
                          },
                        },
                      },
                    });
                  else {
                    navigateTo(routes.operationResolution, {
                      mode: 'replace',
                      routeParams: {
                        idOperation: iAc.currentPendingOperation?.id,
                      },
                      queryParams: {
                        institutionIdentifier: iAc.institutionIdentifier,
                        from: {
                          name: routes.edit,
                          routeParams: {
                            idTarget: target.id,
                          },
                        },
                      },
                    });
                  }
                }}
                style={{
                  background: '#FFF9E9',
                  height: 30,

                  alignItems: 'flex-start',
                  paddingLeft: 35,
                }}
              >
                <View width90St horizontalSt startSt>
                  {/* <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} /> */}
                  <ErrorOutlineIcon style={{ width: 14, height: 'auto' }} />
                  {/* <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
                    Necesitamos actualizar tu banco, pulsa aquí.
                  </Text> */}
                  <Text
                    style={{
                      marginLeft: 15,
                      lineHeight: '18px',
                      fontSize: 10,
                      cursor: 'pointer',
                    }}
                  >
                    {`Tienes operaciones pendientes,  `}
                    <Enfatize
                      font14St
                      style={{ textDecoration: 'underline', color: '#137e84', fontSize: 10 }}
                    >
                      por favor revísalo
                    </Enfatize>{' '}
                  </Text>
                </View>
              </View>
            )}
            <BanksCollapse name={iAc.name}>
              {(iAc.products || [])
                .filter((item) => !item.isDeleted && accountsType.includes(item.type))
                .map((item, i) => {
                  return (
                    <BanksItem
                      logo={['debit', 'card'].includes(item.type) ? card : account}
                      balance={item.balance}
                      identifier={item.identifier}
                      active={activeRuleAccounts[iAc.id + '-' + item.id]}
                      onChange={(value) =>
                        onChange({ ...activeRuleAccounts, [iAc.id + '-' + item.id]: value })
                      }
                      dark={i % 2 === 0}
                    />
                  );
                })}
              {!(iAc.products || []).filter(
                (item) => !item.isDeleted && accountsType.includes(item.type),
              ).length && (
                  <View style={{ padding: 5 }}>
                    <Text>Sin productos</Text>
                  </View>
                )}
            </BanksCollapse>
          </View>
        ))}
      <View style={{ background: '#FEF9F8 0% 0% no-repeat padding-box', height: 33 }}>
        <View width90St horizontalSt startSt>
          <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
          <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
            Recuerda: Al menos una cuenta debe estar activa.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Cuenta;
