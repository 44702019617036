import React from 'react';

import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';

import { View, Text, Enfatize, Image, NavigationWrapper } from '@coinscrap/webapp-core';

// images
import XMark from 'common/svgs/XMark';
import Edit from 'assets/images/Edit.png';
import CheckMark from 'common/svgs/CheckMark';
import scooter from 'assets/images/scooter.jpg';

// configs
import colors from 'config/colors';

// libs
import { getCurrency } from 'libs/Currency';

import routes from 'config/routes';

const optionsGraph = {
  rotation: 20,
  cutoutPercentage: 70,
  legend: {
    display: false,
    position: 'left',
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    animateRotate: true,
    animateScale: false,
  },
};

const dataGraph = (total, saved) => {
  let remain = total - saved;
  if (remain < 0) {
    remain = 0;
  }
  return {
    datasets: [
      {
        data: [remain, saved === 0 ? total * 0.001 : saved],
        backgroundColor: ['#ebebeb44', colors.red],
        borderWidth: 0,
        hoverBorderWidth: 0,
        weight: 20,
      },
    ],
  };
};
const ObjectiveCard = ({ data, finished }) => {
  const { navigateTo } = NavigationWrapper.use();

  let isGoalNotConfigured =
    data.status !== 'ACTIVE' &&
    data.status !== 'FINISHED' &&
    data.creationData?.date &&
    data.status !== 'PENDING_FINALIZATION';

  return (
    <View style={{ position: 'relative', backgroundColor: 'black' }}>
      <Image
        // src={data.image}
        src={data.image || scooter}
        style={{
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
          filter: 'brightness(0.5)',
        }}
      ></Image>
      {!finished && (
        <View
          autoWidthSt
          style={{
            position: 'absolute',
            top: '15px',
            right: '19px',
          }}
          onClick={() => navigateTo(routes.edit)}
        >
          <Image
            src={Edit}
            style={{
              width: 18,
              height: 'auto',
            }}
            pointerSt
          />
        </View>
      )}
      <View style={{ margin: '20px 0' }} width83St>
        <View horizontalSt spaceBetweenSt style={{ marginBottom: 0 }}>
          <View autoWidthSt style={{ position: 'relative' }}>
            <Text font17St style={{ color: colors.white }}>
              {data.name}
            </Text>
          </View>
          {/* <View autoWidthSt style={{ position: 'relative' }}>
            <ArrowForwardIosIcon style={{ width: 'auto', height: 12 }} />
          </View> */}
        </View>
        <View
          horizontalSt
          spaceBetweenSt
        //  onClick={() => navigateTo(routes.objectiveDetails)}
        >
          <View topSt autoWidthSt style={{ position: 'relative' }}>
            <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
              <CheckMark style={{ fill: '#c5281c', width: 12, height: 'auto' }} />
              <Text style={{ fontSize: 17, marginLeft: 8, color: colors.white }}>
                {getCurrency(data.creationData?.price)}
              </Text>
            </View>
            <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
              <CheckMark style={{ fill: '#c5281c', width: 12, height: 'auto' }} />
              <Text style={{ fontSize: 17, marginLeft: 8, color: colors.white }}>
                {Math.abs(moment(data.createDate).diff(moment(data.creationData.date), 'months'))}{' '}
                Meses
              </Text>
            </View>
            <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
              {isGoalNotConfigured ? (
                <XMark style={{ opacity: 0.4, fill: colors.grey1, width: 12, height: 'auto' }} />
              ) : (
                <CheckMark style={{ fill: colors.red, width: 12, height: 'auto' }} />
              )}

              <Text style={{ fontSize: 17, marginLeft: 8, color: colors.white }}>
                Reglas de ahorro
              </Text>
            </View>
          </View>
          <View
            style={{
              width: 80,
              height: 80,
              marginRight: 40,
              marginBottom: 15,
              position: 'relative',
            }}
          >
            <Doughnut
              data={dataGraph(data.progress.total, data.progress.completed)}
              options={optionsGraph}
              style={{ margin: 0 }}
              height={80}
              width={80}
            />

            <View
              absoluteCenterSt
              style={{
                width: 50,
              }}
            >
              <Text style={{ fontSize: 16, transform: 'translateX(5px)', color: colors.white }}>
                {((data.progress.completed / data.progress.total) * 100).toFixed(0)}{' '}
                <Enfatize
                  style={{
                    transform: 'translate(-3px,-5px)',
                    display: 'inline-block',
                    fontSize: 16,
                    color: colors.white,
                  }}
                >
                  %
                </Enfatize>
              </Text>

              <Text style={{ fontSize: 8.5, color: colors.white }}>Objetivo</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ObjectiveCard;
