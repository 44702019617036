import { NavigationWrapper, PickerHOC, RulesWrapper, TargetsUtilsWrapper, TargetsWrapper, UiWrapper, UserWrapper } from '@coinscrap/webapp-core';

import { displayRuleAmount } from 'libs/ruleCases';
import { OTP_ACTIONS } from 'libs/common';
import routes from 'config/routes';
import { useConsentAccept } from "./useConsentAccept";
import { useRuleController } from "views/TargetCreation/3_Rules/hooks/useRuleController";



export const useAction = (action, payload) => {
  const { user, updateUser } = UserWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { saveTargetProperty } = TargetsUtilsWrapper.use();
  const { launchEvent, upsertRule } = RulesWrapper.use();
  const { saveTargetRulesConfiguration } = TargetsWrapper.use();
  const { ruleDefinitions } = PickerHOC.use();
  const { getRule, desactivateRule, activateRule } = RulesWrapper.use();
  const { accept } = useConsentAccept(payload.institutionAccountId, payload.productId);
  const { showAlert } = UiWrapper.use();

  const { ruleIdentifier, toggle, ruleValue } = payload
  const rule = ruleIdentifier && getRule(ruleIdentifier, payload.targetId);
  const ruleHandler = useRuleController(ruleIdentifier, rule, payload.targetId, user.id);
  const eventDefinitions = ruleDefinitions.reduce(
    (acc, act) => [...acc, ...(act.eventDefinitions || [])],
    [],
  );
  const activeRuleHandler = async () => {
    const rule = getRule(ruleIdentifier, payload.targetId);
    const { route } = displayRuleAmount(ruleIdentifier, rule);
    const act = toggle === 'false' ? false : true;
    await ruleHandler(act, ruleValue);
  }
  const doEvent = async () => {
    switch (action) {
      case OTP_ACTIONS.HOT: {
        const eventDefinition = eventDefinitions.find((def) => def.identifier === 'EVENT_AHORRO_HOT');
        console.log({ eventDefinition })
        console.log(payload.amount)
        try {

          await launchEvent(
            eventDefinition.identifier,
            [
              payload.amount && {
                identifier: 'hot_event_amount',
                data: { value: Number(payload.amount) },
              },
            ].filter((x) => x),
            payload.targetId,
          );
          showAlert('Cambios guardados');
        } catch (error) {
          console.error(error)
        }
        break;
      }
      case OTP_ACTIONS.SAVE_ACCOUNTS: {

        const array = Array.isArray(payload.accounts) ? payload.accounts : Object.keys(payload.accounts).reduce((acc, act) => [...acc, payload.accounts[act]])
        try {
          await saveTargetRulesConfiguration(payload.targetId, {
            ruleAccounts: array,
          });
          showAlert('Cambios guardados');
        } catch (error) {
          console.error(error)
        }
        break;
      }
      case OTP_ACTIONS.TOGGLE_RULE: {
        try {
          await activeRuleHandler()
          showAlert('Cambios guardados');
        } catch (error) {
          console.error(error)
        }
        break;
      }
      case OTP_ACTIONS.TARGET_FINISH: {
        // NOOP
        break;
      }
      case OTP_ACTIONS.SELECT_POLICY: {
        try {
          if (!user?.metadata?.consentInformed) {
            await accept();
            await updateUser({ metadata: { ...user?.metadata, consentInformed: true, consentDate: new Date() } })
          }
        } catch (error) {
        }
        break;
      }
      default: return
      // code block
    }

  }

  return { event: doEvent }
}