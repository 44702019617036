import { Button, Text, View, Enfatize } from '@coinscrap/webapp-core';

// images
import Warning from '../../../common/svgs/Warning';
import colors from 'config/colors';
import React from 'react';
import * as Types from 'data/types';

const ContributionsITem = ({ icon, onClick, name, global, disabledMessage, identifier }) => {
  console.log(identifier);
  return [
    global && <AlertGlobalEvent key={'alert'} />,
    <View key={'event'} style={{ padding: '0px 20px', border: `0.5px solid ${colors.grey3}` }}>
      <View horizontalSt spaceBetweenSt style={{ padding: '10px 0' }}>
        {icon}
        <Text style={{ fontSize: 16, textAlign: 'center' }}>{name}</Text>

        <Button
          style={{ padding: '10px 15px', width: 92 }}
          onClick={onClick}
          disabled={disabledMessage}
        >
          {identifier === Types.AHORRO_HOT
            ? 'Aportar'
            : disabledMessage || identifier === Types.NICOTINE
              ? 'he caído'
              : identifier === Types.CAFEINE
                ? 'he caído'
                : 'Aportar'}
        </Button>
      </View>
    </View>,
  ];
};

const AlertGlobalEvent = () => (
  <View style={{ background: '#FEF9F8 0% 0% no-repeat padding-box', padding: '10px 0px' }}>
    <View width90St horizontalSt startSt topSt>
      <View autoWidthSt>
        <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
      </View>
      <View topSt>
        <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
          Este evento es{' '}
          <Enfatize boldSt redSt>
            global
          </Enfatize>{' '}
          a todos tus objetivos con la regla activa.
        </Text>
      </View>
    </View>
  </View>
);

export default ContributionsITem;
