import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';
import fonts from '../fonts';
import commonStyles from './common';

UiWrapper.defineComponent('view', {
  styles: {
    default: {
      width: '100%',
      color: colors.dark,
      boxSizing: 'border-box',
      fontFamily: fonts.HelveticaNeue,
    },
    wSt: {
      backgroundColor: colors.white,
    },
    horizontalSt: {
      flexDirection: 'row',
    },

    startSt: {
      justifyContent: 'flex-start',
    },

    endSt: {
      justifyContent: 'flex-end',
    },

    justifyCenterSt: {
      justifyContent: 'center',
    },
    justifySelfEndSt: { justifySelf: 'flex-end' },
    justifySelfStartSt: { justifySelf: 'flex-start' },
    justifySelfCenterSt: { justifySelf: 'center' },

    spaceBetweenSt: {
      justifyContent: 'space-between',
    },

    spaceEvenlySt: {
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },

    topSt: {
      alignItems: 'flex-start',
    },

    bottomSt: {
      alignItems: 'flex-end',
    },

    selfStartSt: {
      alignSelf: 'flex-start',
    },

    selfEndSt: {
      alignSelf: 'flex-end',
    },

    alignCenterSt: {
      alignSelf: 'center',
    },

    textCenterSt: {
      textAlign: 'center',
    },

    fullHeightSt: {
      height: '100%',
    },

    fullWidthSt: {
      width: '100%',
    },

    width50St: {
      width: '50%',
    },
    width65St: {
      width: '65%',
    },
    width68St: {
      width: '68%',
    },
    width74St: {
      width: '74%',
    },
    width75St: {
      width: '75%',
    },
    width79St: {
      width: '79%',
    },
    width80St: {
      width: '80%',
    },
    width82St: {
      width: '82%',
    },
    width83St: {
      width: '83%',
    },
    width86St: {
      width: '86%',
    },

    width90St: {
      width: '90%',
    },

    width92St: {
      width: '92%',
    },

    width95St: {
      width: '95%',
    },

    autoWidthSt: {
      width: 'auto',
    },

    interitSt: {
      width: 'inherit',
    },

    containerSt: {
      height: '100%',
    },

    shrinkSt: {
      flexShrink: 1,
    },

    noBorderSt: {
      border: 0,
    },

    rowSt: {
      flexDirection: 'row',
    },

    flexGrowSt: {
      flex: 1,
    },

    gridSt: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '10px',
      justifyItems: 'center',
    },
    grid3St: {
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 1fr',
    },

    dotsActiveSt: {
      borderRadius: '50%',
      height: 7,
      width: 7,
      backgroundColor: colors.red,
      margin: '0 2px 0 2px',
    },
    dotsSt: {
      backgroundColor: colors.grey4,
      borderRadius: '50%',
      height: 7,
      width: 7,

      margin: '0 2px 0 2px',
    },

    absoluteCenterSt: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },

    homeSectionBorderBottomSt: {
      borderBottom: '4px solid red',
    },
    homeSectionBorderBottomGreySt: {
      borderBottom: `1px solid ${colors.grey2}`,
    },
    homeSectionBorderBottomTransparentSt: {
      borderBottom: `4px solid transparent`,
    },
    bgGrey5St: {
      backgroundColor: colors.grey5,
    },
    activeFilterDateSt: {
      backgroundColor: colors.white,
      border: '1px solid #B7B7B8',
      borderRadius: 6,
    },
    transparentBorderSt: {
      border: '1px solid transparent',
    },
    backdropSt: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      opacity: 0.7,
      backgroundColor: colors.black,
      zIndex: 5,
    },

    middleAbsoluteSt: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    relativeSt: {
      position: 'relative',
    },
    tutorialCardObjectiveASt: {
      color: colors.white,
      fontSize: 15,
      zIndex: 7,
      width: 250,
      position: 'absolute',
      top: -44,
      left: 0,
    },
    tutorialCardObjectiveBSt: {
      zIndex: 7,
      position: 'absolute',
      top: '15px',
      left: 0,
    },
    tutorialInfoDetalleASt: {
      color: colors.white,
      fontSize: 12,
      zIndex: 7,
      width: 100,
      position: 'absolute',
      top: -50,
      left: -80,
    },
    tutorialInfoDetalleBSt: {
      zIndex: 7,
      position: 'absolute',
      top: 10,
      left: 90,
    },
    tutorialNameConfigurationASt: {
      color: colors.white,
      fontSize: 12,
      zIndex: 7,
      width: 250,
      position: 'absolute',
      bottom: -48,
      left: -30,
    },
    tutorialNameConfigurationBSt: {
      zIndex: 7,
      position: 'absolute',
      top: -30,
      left: 40,
    },
    tutorialProgressASt: {
      color: colors.white,
      fontSize: 12,
      zIndex: 7,
      width: 150,
      position: 'absolute',
      top: -20,
      left: -70,
    },
    tutorialProgressBSt: {
      zIndex: 7,
      position: 'absolute',
      top: '20px',
      left: 50,
    },
    tutorialMenuASt: {
      color: colors.white,
      fontSize: 18.5,
      zIndex: 7,
      width: 250,
      position: 'absolute',
      top: -60,
      left: 40,
    },
    tutorialMenuBSt: {
      zIndex: 7,
      position: 'absolute',
      top: '15px',
      left: -30,
    },
    tutorialButtonASt: {
      color: colors.white,
      fontSize: 18.5,
      zIndex: 7,
      width: 250,
      position: 'relative',
      transform: 'translateY(-25px)',
    },
    tutorialButtonBSt: {
      zIndex: 7,
      position: 'absolute',
      top: '25px',
      left: 40,
    },

    smallRedCircleSt: {
      backgroundColor: colors.red,
      color: colors.white,
      borderRadius: '50%',
      height: 21,
      width: 21,
    },
    smallGreenCircleSt: {
      backgroundColor: colors.green,
      color: colors.white,
      borderRadius: '50%',
      height: 21,
      width: 21,
    },

    smallRedCirclePlusSt: {
      height: 15,
      width: 15,
      paddingBottom: 4,
      color: colors.white,
      fontSize: 15,
    },
    circleRoundingSt: {
      height: 55,
      width: 55,
      borderRadius: '50%',
      color: colors.white,
      border: `1px solid ${colors.white}`,
      transition: 'all 0.3s',
      cursor: 'pointer',
    },
    circleRoundingActiveSt: {
      height: 55,
      width: 55,
      borderRadius: '50%',
      color: colors.red,
      backgroundColor: colors.white,
    },
    roundingMarginSt: {
      marginLeft: 7.5,
      marginRight: 7.5,
    },

    paddingMainSt: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    boldSt: {
      fontFamily: fonts.HelveticaNeueBold,
    },
    boldItalicSt: {
      fontFamily: fonts.HelveticaNeueBoldItalic,
    },
    condensedSt: {
      fontFamily: fonts.HelveticaNeueCondensedBlack,
    },
    condensedBoldSt: {
      fontFamily: fonts.HelveticaNeueCondensedBold,
    },
    italicSt: {
      fontFamily: fonts.HelveticaNeueItalic,
    },

    lightSt: {
      fontFamily: fonts.HelveticaNeueLight,
    },
    lightItalicSt: {
      fontFamily: fonts.HelveticaNeueLightItalic,
    },
    regularSt: {
      fontFamily: fonts.HelveticaNeue,
    },

    mediumSt: {
      fontFamily: fonts.HelveticaNeueMedium,
    },
    mediumItalicSt: {
      fontFamily: fonts.HelveticaNeueMediumItalic,
    },
    thinSt: {
      fontFamily: fonts.HelveticaNeueThin,
    },
    thinItalicSt: {
      fontFamily: fonts.HelveticaNeueThinItalic,
    },
    ultraLightSt: {
      fontFamily: fonts.HelveticaNeueUltraLight,
    },
    ultraLightItalicSt: {
      fontFamily: fonts.HelveticaNeueUltraLightItalic,
    },

    faqsCollapseSt: {
      backgroundColor: colors.grey,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '8px 20px',
    },

    faqsCollapseContentSt: {
      overflow: 'hidden',
      transition: 'all 0.3s ease',
    },
    pointerSt: {
      cursor: 'pointer',
    },
    selectNoneSt: {
      userSelect: 'none',
    },
    ...commonStyles.styles,
  },
});
