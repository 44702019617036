import React, { useState } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  Layout,
  Button,
  Image,
  InputImage,
} from '@coinscrap/webapp-core';

import setImageUrlHandler from '../../../libs/ImagePickerHandler';

// components
import Footer from '../../../common/Footer';

// images
import RoundCard from '../../../assets/images/RoundCard.png';
import Camara from '../../../assets/images/Camara.png';
import CheckMark from '../../../common/svgs/CheckMark';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

export default () => {
  const { useBackground, useFooter } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const [, setImageFileFront] = useState(null);
  const [imageUrlFront, setImageUrlFront] = useState(null);
  const [, setImageFileBack] = useState(null);
  const [imageUrlBack, setImageUrlBack] = useState(null);

  useBackground(colors.white);

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        disabled={!imageUrlFront || !imageUrlBack}
        style={{ marginTop: 10 }}
        borderWhiteButtonSt
        onClick={() => navigateTo(routes.targetAccountsIntro)}
      >
        Siguiente
      </Button>
    </Footer>,
    [imageUrlBack, imageUrlFront],
  );

  return (
    <Layout.Container>
      <View fullHeightSt paddingMainSt startSt>
        <View style={{ marginTop: 20 }}>
          <Text grey1St font15St>
            Añade tu DNI
          </Text>
        </View>
        <View horizontalSt style={{ marginTop: 40 }}>
          <DotComponent active />
          <DotComponent />
        </View>

        <View
          style={{
            marginTop: 36,
          }}
        >
          <Image src={RoundCard} style={{ width: 116, height: 'auto' }} />
        </View>
        <View style={{ marginTop: 18 }}>
          <Text centerSt font27St>
            Saquemos unas fotos de tu DNI
          </Text>
        </View>
        <View width79St style={{ marginTop: 30 }}>
          <Text grey1St font15St centerSt>
            Necesitamos que digitalices tu DNI. Primero la parte frontal y luego la trasera, ya
            verás, es muy fácil.
          </Text>
        </View>
        <View horizontalSt style={{ marginTop: 25, paddingBottom: 35 }}>
          <View autoWidthSt style={{ marginRight: 30, position: 'relative' }}>
            {imageUrlFront ? (
              <View style={{ position: 'relative' }}>
                <View
                  style={{
                    width: 68,
                    height: 68,
                    background: `url(${imageUrlFront})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.5,
                  }}
                />
                <View smallRedCircleSt style={{ position: 'absolute' }}>
                  <CheckMark style={{ fill: colors.white, height: 10, width: 10 }} />
                </View>
              </View>
            ) : (
              <Image src={Camara} style={{ width: 68, height: 'auto' }} />
            )}

            <Text grey1St font15St style={{ marginTop: 15 }}>
              Partefrontal
            </Text>
            <InputImage
              disabled={false}
              defaultImage={undefined}
              onSelectImage={(img) => {
                setImageUrlHandler(img, setImageFileFront, setImageUrlFront);
              }}
              style={{
                containerComponent: {
                  border: 'none',
                  height: '100%',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  opacity: 0,
                },
                cameraComponent: {
                  display: 'none',
                },
                textComponent: {
                  display: 'none',
                },
              }}
            />
          </View>
          <View autoWidthSt style={{ position: 'relative' }}>
            {imageUrlBack ? (
              <View style={{ position: 'relative' }}>
                <View
                  style={{
                    width: 68,
                    height: 68,
                    background: `url(${imageUrlBack})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.5,
                  }}
                />
                <View smallRedCircleSt style={{ position: 'absolute' }}>
                  <CheckMark style={{ fill: colors.white, height: 10, width: 10 }} />
                </View>
              </View>
            ) : (
              <Image src={Camara} style={{ width: 68, height: 'auto' }} />
            )}

            <Text grey1St font15St style={{ marginTop: 15 }}>
              Partetrasera
            </Text>
            <InputImage
              disabled={false}
              defaultImage={undefined}
              onSelectImage={(img) => {
                setImageUrlHandler(img, setImageFileBack, setImageUrlBack);
              }}
              style={{
                containerComponent: {
                  border: 'none',
                  height: '100%',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                  opacity: 0,
                },
                cameraComponent: {
                  display: 'none',
                },
                textComponent: {
                  display: 'none',
                },
              }}
            />
          </View>
        </View>
      </View>
    </Layout.Container>
  );
};

const DotComponent = ({ active }) => {
  return (
    <View
      dotsSt
      dotsActiveSt={active}
      style={{ margin: '0 4px 0 4px' }}
    // onClick={() => switchInfo(id)}
    />
  );
};
