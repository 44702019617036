/* eslint-disable react/jsx-fragments */
/* eslint-disable no-console */
import React, { Component } from 'react';

import Home from './views/Home/Home';
import History from './views/Home/History';
import OnBoarding from './views/OnBoarding/OnBoarding';
import OnBoardingLegal from './views/OnBoarding/Legal';
import OnBoardingFinished from './views/OnBoarding/Finished';
import ObjectiveInfo from './views/TargetCreation/1_Objective/Info';
import ContributionInfo from './views/TargetCreation/2_Contributions/Info';
import ContributionStep1Initial from './views/TargetCreation/2_Contributions/Step1Initial';
import ContributionStep2Periodic from './views/TargetCreation/2_Contributions/Step2Periodic';
import RuleInfo from './views/TargetCreation/3_Rules/Info';
import Rules from './views/TargetCreation/3_Rules/Rules';
import RoundRule from './views/TargetCreation/3_Rules/RoundRule';
import PeriodicRule from './views/TargetCreation/3_Rules/PeriodicRule';
import IncomeRule from './views/TargetCreation/3_Rules/IncomeRule';
import CaffeineRule from './views/TargetCreation/3_Rules/CaffeineRule';
import NicotineRule from './views/TargetCreation/3_Rules/NicotineRule';
import FutbolRule from './views/TargetCreation/3_Rules/FutbolRule';
import RulesResumen from './views/TargetCreation/3_Rules/Resumen';
import DNI from './views/TargetCreation/3_Rules/DNI';
import TargetAccountsIntro from './views/TargetCreation/4_RulesAccounts/TargetAccountsIntro';
import TargetAccountsAdd from './views/TargetCreation/4_RulesAccounts/TargetAccountsAdd';
import BankUpdate from './views/Banks/BankUpdate';
import BankConnect from './views/Banks/BankConnect';
import TargetAccountsRules from './views/TargetCreation/4_RulesAccounts/Rules';
import TargetAccountsFinish from './views/TargetCreation/4_RulesAccounts/Finish';
import BankResumen from './views/TargetCreation/4_RulesAccounts/Resumen';
import FollowUp from './views/FollowUp/FollowUp';
import FollowUpEdit from './views/FollowUpEdit/FollowUpEdit';
import ObjectiveDetailsEditRules from './views/FollowUpEdit/components/Rules';
import RuleConfig from './views/FollowUpEdit/RuleConfig';
import Filter from './views/FollowUpEdit/Filter';
import RuleListByType from './views/Home/RuleListByType';
import Documents from './views/Settings/Documents';
import Dialog from './common/Dialog';
// token
import TokenEntry from './views/TokenEntry';
import TokenError from './views/TokenError';

import {
  ApplicationWrapper,
  SessionWrapper,
  Page,
  Module,
  Layout,
  WithSession,
} from '@coinscrap/webapp-core';
import routes from './config/routes';
import Step1Name from './views/TargetCreation/1_Objective/Step1Name';
import Step2Amount from './views/TargetCreation/1_Objective/Step2Amount';
import Step3Time from './views/TargetCreation/1_Objective/Step3Time';
import Step4Image from './views/TargetCreation/1_Objective/Step4Image';
import Resumen from './views/TargetCreation/1_Objective/Resumen';
import FetchPolicies from './views/OnBoarding/FetchPolicies';
import SelectPolicy from 'views/OnBoarding/SelectPolicy';
import SignDocuments from 'views/OnBoarding/SignDocuments';
import { OtpSms } from 'views/OTP/OtpSms';
import { OtpCode } from 'views/OTP/OtpCode';

// wrappers
import WrappersComponent from './wrappers/WrappersComponent';

// target creation
import TargetInstanciation from './views/TargetCreation/TargetInstanciation';
import { startUpController } from './libs/startUpController';
import CreateGlobalTarget from 'views/OnBoarding/CreateGlobalTarget';
import { OperationResolution } from 'views/Operations/OperationResolution';

require('intersection-observer');

export const validateSession = async (sessionData, coinscrapApi) => {
  try {
    if (sessionData && sessionData.token) {
      try {
        const user = await coinscrapApi.checkUser(sessionData.token);
        if (user && (user.id || user._id)) {
          return true;
        } else {
        }
      } catch (e) { }
    }
  } catch (e) { }
  return false;
};

ApplicationWrapper.init({
  projectName: 'smartahorro',
  contextTheme: 'light',
  disableNative: true,
  onStartupParams: ({ refId: reveivedRefId, token: receivedToken }) =>
    (startUpController.params = { refId: reveivedRefId, token: receivedToken }),
});

SessionWrapper.defineSessionChecker(validateSession);

const pushAnimation = {
  exit: {
    opacity: 0,
    x: '100%',
  },
  initial: {
    opacity: 0,
    x: 300,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  transition: {
    duration: 0.4,
  },
};

const backAnimation = {
  exit: {
    opacity: 0,
    x: '100%',
  },
  initial: {
    opacity: 0,
    x: -300,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  transition: {
    duration: 0.4,
  },
};

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <WrappersComponent>
          <Layout>
            <Dialog>
              {/* <Page  name={'/'} component={OnBoarding} /> */}
              <Page
                path="/"
                name={routes.tokenEntry}
                component={TokenEntry}
                pushAnimation={pushAnimation}
                backAnimation={backAnimation}
              />
              <Page
                name={routes.tokenError}
                component={TokenError}
                pushAnimation={pushAnimation}
                backAnimation={backAnimation}
              />
              {/* Session Routes */}
              <Module path={'/internal'}>
                <WithSession pageNoSession={routes.tokenError}>
                  <Module path="/onboarding">
                    <Page
                      name={routes.onBoarding}
                      path="/"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={OnBoarding}
                    />
                    <Page
                      name={routes.legal}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={OnBoardingLegal}
                    />
                    <Page
                      name={routes.finished}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={OnBoardingFinished}
                    />
                    <Page
                      name={routes.fetchPolicies}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={FetchPolicies}
                    />
                    <Page
                      name={routes.selectPolicy}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={SelectPolicy}
                    />
                    <Page
                      name={routes.signDocuments}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={SignDocuments}
                    />
                    <Module path="/confirmUssage">
                      <Page
                        name={routes.otpSms}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={OtpSms}
                      />
                      <Page
                        path="/otpCode/:operationId"
                        name={routes.otpCode}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={OtpCode}
                      />
                    </Module>
                    <Page
                      name={routes.createGlobalTarget}
                      path="/:institutionAccountId/:productId/createGlobalTarget"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={CreateGlobalTarget}
                    />
                  </Module>
                  <Module path="/history">
                    <Page
                      name={routes.history}
                      path="/"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={History}
                    />
                  </Module>
                  <Module path="/documents">
                    <Page
                      name={routes.documents}
                      path="/"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={Documents}
                    />
                  </Module>
                  <Module path="/home">
                    <Page
                      name={routes.home}
                      path="/:tab"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={Home}
                    />

                    <Page
                      name={routes.ruleListByType}
                      path="/reglas/identifier/:ruleIdentifier"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={RuleListByType}
                    />
                  </Module>

                  <Module path={'/targetCreation'}>
                    <Page
                      path={'/instanciation/:idParentTarget'}
                      name={routes.targetInstanciation}
                      component={TargetInstanciation}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                    />
                    <Module path={'/:idTarget'}>
                      <Page
                        name={routes.objectiveInfo}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={ObjectiveInfo}
                      />
                      <Page
                        name={routes.objectiveStep1Name}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={Step1Name}
                      />
                      <Page
                        name={routes.objectiveStep2Amount}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={Step2Amount}
                      />
                      <Page
                        name={routes.objectiveStep3Time}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={Step3Time}
                      />
                      <Page
                        name={routes.objectiveStep4Image}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={Step4Image}
                      />
                      <Page
                        name={routes.objectiveResumen}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={Resumen}
                      />
                      <Page
                        name={routes.contributionInfo}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={ContributionInfo}
                      />
                      <Page
                        name={routes.contributionStep1Initial}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={ContributionStep1Initial}
                      />
                      <Page
                        name={routes.contributionStep2Periodic}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={ContributionStep2Periodic}
                      />
                      <Page
                        name={routes.ruleInfo}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={RuleInfo}
                      />
                      <Page
                        name={routes.rules}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={Rules}
                      />
                      <Page
                        name={routes.roundRule}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={RoundRule}
                      />
                      <Page
                        name={routes.periodicRule}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={PeriodicRule}
                      />
                      <Page
                        name={routes.incomeRule}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={IncomeRule}
                      />
                      <Page
                        name={routes.caffeineRule}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={CaffeineRule}
                      />
                      <Page
                        name={routes.nicotineRule}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={NicotineRule}
                      />
                      <Page
                        name={routes.futbolRule}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={FutbolRule}
                      />
                      <Page
                        name={routes.rulesResumen}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={RulesResumen}
                      />
                      <Page
                        name={routes.targetAccountsIntro}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={TargetAccountsIntro}
                      />
                      <Page
                        name={routes.targetAccountsAdd}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={TargetAccountsAdd}
                      />
                      <Page
                        name={routes.targetAccountsConfigure}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={TargetAccountsRules}
                      />
                      <Page
                        name={routes.targetAccountsFinish}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={TargetAccountsFinish}
                      />
                      <Page
                        name={routes.bankResumen}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={BankResumen}
                      />
                      <Page
                        name={routes.dni}
                        pushAnimation={pushAnimation}
                        backAnimation={backAnimation}
                        component={DNI}
                      />
                    </Module>
                  </Module>
                  <Module path="/operations">
                    <Page
                      name={routes.operationResolution}
                      path="/resolve/:idOperation"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={OperationResolution}
                    />
                  </Module>
                  <Module path="/banks">
                    <Page
                      name={routes.bankConnect}
                      // path="/bankConnect/:idBank"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={BankConnect}
                    />
                    <Page
                      name={routes.bankUpdate}
                      path="/bankUpdate/:idBank"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={BankUpdate}
                    />
                  </Module>
                  <Module path={'/targetFollow/:idTarget'}>
                    {/* </Module>
                <Module path="/details"> */}
                    <Page
                      name={routes.followUp}
                      path="/section/:tab"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={FollowUp}
                    />
                    <Page
                      name={routes.edit}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={FollowUpEdit}
                    />
                    <Page
                      name={routes.objectiveDetailsEditRules}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={ObjectiveDetailsEditRules}
                    />
                    <Page
                      name={routes.followRules}
                      path="/rules/:ruleIdentifier"
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={RuleConfig}
                    />
                    <Page
                      name={routes.filter}
                      pushAnimation={pushAnimation}
                      backAnimation={backAnimation}
                      component={Filter}
                    />
                  </Module>
                </WithSession>
              </Module>
            </Dialog>
          </Layout>
        </WrappersComponent>
      </React.Fragment>
    );
  }
}
