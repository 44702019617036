import React from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Button,
  TargetsWrapper,
} from '@coinscrap/webapp-core';

// components
import Header from '../../../common/Header';
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Footer from '../../../common/Footer';
import InfoHeader from 'common/InfoHeader';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

export default PickerHOC({
  target: {
    arrayItemMatcher: {
      type: 'param',
      itemPropertyPath: 'id',
      paramName: 'idTarget',
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
})(() => {
  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const { target } = PickerHOC.use();

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. OBJETIVO" left="atras" />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 30 }}
        borderWhiteButtonSt
        onClick={() => {
          navigateTo(routes.objectiveStep1Name, {
            routeParams: {
              idTarget: target.id,
            },
          });
        }}
      >
        ¡Empecemos!
      </Button>
    </Footer>,
    [],
  );

  return (
    <Layout.Container>
      <View fullHeightSt spaceBetweenSt>
        <View>
          <InfoHeader text="1" />

          <View style={{ marginTop: 50 }}>
            <View width50St>
              <Text font27St textCenterSt>
                Configuración de objetivo
              </Text>
            </View>
            <View width65St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
                Vamos a hacerte unas preguntas para poder configurar tu objetivo.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});
