/*
Archivo de configuración donde se asocia una acción [key] con el nombre [valor]
Los valores que asignemos simplemente son nombres cortos de las pantallas para que la app las use internamente,
Las navegaciones no se realizan en base a paths completos si no a nombres cortos
*/

const routes = {
  tokenEntry: 'tokenEntry',
  tokenError: 'tokenError',
  home: 'home',
  history: 'history',
  onBoarding: 'onBoarding',
  legal: 'legal',
  finished: 'finished',
  selectPolicy: 'selectPolicy',
  signDocuments: 'signDocuments',
  fetchPolicies: 'fetchPolicies',
  otpSms: 'otpSms',
  otpCode: 'otpCode',
  createGlobalTarget: 'createGlobalTarget',

  objectiveInfo: 'objectiveInfo',
  objectiveStep1Name: 'objectiveStep1Name',
  objectiveStep2Amount: 'objectiveStep2Amount',
  objectiveStep3Time: 'objectiveStep3Time',
  objectiveStep4Image: 'objectiveStep4Image',
  objectiveResumen: 'objectiveResumen',
  contributionInfo: 'contributionInfo',
  contributionStep1Initial: 'contributionStep1Initial',
  contributionStep2Periodic: 'contributionStep2Periodic',
  ruleInfo: 'ruleInfo',
  rules: 'rules',
  roundRule: 'roundRule',
  periodicRule: 'periodicRule',
  incomeRule: 'incomeRule',
  caffeineRule: 'caffeineRule',
  nicotineRule: 'nicotineRule',
  futbolRule: 'futbolRule',
  rulesResumen: 'rulesResumen',
  targetAccountsIntro: 'targetAccountsIntro',
  banksInfo: 'banksInfo',
  targetAccountsAdd: 'targetAccountsAdd',
  bankConnect: 'bankConnect',
  bankUpdate: 'bankUpdate',
  banksCredentials: 'banksCredentials',

  operationResolution: 'operationResolution',

  targetAccountsConfigure: 'banksRules',
  targetAccountsFinish: 'banksFinish',
  bankResumen: 'bankResumen',
  followUp: 'followUp',

  edit: 'edit',
  objectiveDetailsEditRules: 'objectiveDetailsEditRules',
  followRules: 'followRules',
  filter: 'filter',
  ruleListByType: 'ruleListByType',
  targetInstanciation: 'targetInstanciation',
  documents: 'documents',
};

export default routes;
