import React, { useRef, useCallback, useState } from 'react';

import { UiWrapper, View, Text, Button } from '@coinscrap/webapp-core';

// components
import { CloseButton } from 'common/closeButton';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TermsArrowDown from 'common/TermsArrowDown';

import Modal from '@material-ui/core/Modal';
// configs
import colors from 'config/colors';
import Collapse from './Collapse';

// data
import legalData from 'data/legalData';

const SubItemCollapse = ({ txt }) => (
  <View fullWidthSt rowSpaceBtwSt style={{ padding: '7px 30px', backgroundColor: '#fff' }}>
    <Text style={{ fontSize: 15, color: '#888', width: '100%' }}>{txt}</Text>
  </View>
);

const LegalModal = ({ isOpen, close, children, onClick }) => {
  const faqRef = useRef();
  const { useBackground } = UiWrapper.use();
  const [isScroll, setIsScroll] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useBackground(colors.white);

  const observer = useRef();

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setButtonDisabled(false);
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  return (
    <Modal open={isOpen} onClose={close}>
      <View fullHeightSt style={{ backgroundColor: colors.white, overflowY: 'auto' }}>
        <View fullHeightSt style={{ justifyContent: 'flex-start' }}>
          <View startSt fullHeightSt style={{ paddingTop: 50 }}>
            <View horizontalSt endSt style={{ marginTop: 10, position: 'fixed', top: 0 }}>
              <CloseButton
                color={colors.dark}
                action={() => {
                  close();
                }}
              />
            </View>
            {children ? (
              <View startSt fullHeightSt style={{ maxWidth: 850 }}>
                {children}
              </View>
            ) : (
              <View startSt fullHeightSt>
                <Text font20St style={{ marginBottom: 10 }}>
                  Política de protección de datos
                </Text>
                <View
                  startSt
                  style={{
                    overflowY: 'scroll',
                    height: 'calc(100% - 120px)',
                    maxWidth: 850,
                    padding: 5,
                  }}
                >
                  {legalData.map((item, index, list) => {
                    return (
                      <div
                        style={{ width: '100%' }}
                        ref={index + 1 === list.length ? lastPlanElementRef : null}
                      >
                        <div
                          style={{ width: '100%' }}
                          ref={index + 1 === list.length ? faqRef : null}
                        >
                          <Collapse
                            title={item.title}
                            style={{ borderTop: '1px solid #f0f0f0', width: '100%' }}
                          >
                            <SubItemCollapse txt={item.description}></SubItemCollapse>
                          </Collapse>
                        </div>
                      </div>
                    );
                  })}
                </View>

                {/* <ArrowForwardIosIcon
                  onClick={() => {
                    faqRef.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                  style={{
                    position: 'fixed',
                    bottom: 40,
                    right: 15,
                    width: 20,
                    height: 'auto',
                    transform: 'rotate(90deg)',
                    opacity: isScroll ? 1 : 0,
                  }}
                ></ArrowForwardIosIcon> */}
                <TermsArrowDown
                  style={{ opacity: isScroll ? 1 : 0 }}
                  onClick={() => {
                    faqRef.current.scrollIntoView({ behavior: 'smooth' });
                  }}
                />
                <Button
                  style={{ marginTop: 10, position: 'fixed', bottom: 5 }}
                  borderWhiteButtonSt
                  disabled={!!buttonDisabled}
                  onClick={async () => {
                    await onClick();
                    close();
                  }}
                >
                  Aceptar
                </Button>
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default LegalModal;
