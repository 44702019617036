import React, { useState } from 'react';
import Tabs from './Tabs';
import TutorialResumen from './TutorialResumen';
import { UiWrapper, NavigationWrapper, View, PickerHOC } from '@coinscrap/webapp-core';

// images
import Arrow1 from '../../../assets/images/Arrow1.png';
import Button from '../../../common/svgs/Button';

// configs
import colors from '../../../config/colors';

const dataObjects = [
  {
    name: 'Lavadora',
    amount: 800,
    months: 6,
    rules: false,
    completion: 0,
    total: 1000,
    saved: 0,
  },
];

export default PickerHOC()(({ onClick, continueToNewObjective }) => {
  const { useBackground } = UiWrapper.use();
  const { useBackRoute } = NavigationWrapper.use();

  const [section, setSection] = useState('resumen');
  const [backdrop, setBackdrop] = useState(true);
  const [data] = useState(dataObjects);

  useBackground(colors.white);

  useBackRoute(undefined);

  return (
    <View style={{ paddingTop: 50 }}>
      <Tabs backdrop={backdrop} section={section} setSection={setSection} />
      {section === 'resumen' && (
        <TutorialResumen
          data={data}
          backdrop={backdrop}
          onClick={() => {
            setBackdrop(false);
            onClick(false);
          }}
        />
      )}
      <View style={{ position: 'fixed', bottom: '20px', zIndex: 7 }}>
        {backdrop && (
          <View boldSt tutorialButtonASt>
            Crear Objetivo
            <View autoWidthSt tutorialButtonBSt>
              <img src={Arrow1} style={{ width: 40, transform: 'rotate(-60deg)' }} alt="" />
            </View>
          </View>
        )}

        <Button onClick={continueToNewObjective} style={{ width: 68, height: 68 }} />
      </View>

      {backdrop && (
        <View
          backdropSt
          onClick={() => {
            setBackdrop(false);
            onClick(false);
          }}
        />
      )}
    </View>
  );
});
