import React, { useState, useEffect } from 'react';

import {
  UiWrapper,
  View,
  Image,
  Text,
  NavigationWrapper,
  InputSwitch,
  PickerHOC,
  RulesWrapper,
  TargetsWrapper,
  Page,
  UserWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from '../../common/HeaderRedPrimary';
import Header from '../../common/Header';
import Movements from 'common/Movements';

// images

// configs
import colors from 'config/colors';
import { stuctureDataMovementsForRule } from 'libs/structureData';
import { ruleData } from 'data/ruleData';
import routes from 'config/routes';

import { displayRuleAmount } from 'libs/ruleCases';
import { OTP_ACTIONS } from 'libs/common';
import { useRuleController } from 'views/TargetCreation/3_Rules/hooks/useRuleController';

export default PickerHOC(
  {
    userRules: {
      sourcePath: 'rules',
      sourceWrapper: RulesWrapper,
    },

    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  // LoadingData,
)(function RuleConfig(props) {
  const { target } = PickerHOC.use();
  const {
    params: { ruleIdentifier },
  } = Page.use();
  const { useBackground, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const { getRule } = RulesWrapper.use();

  const [activeRule, setActiveRule] = useState(false);

  const rule = getRule(ruleIdentifier, target.id);
  const activeRuleHandler = async (toggle) => {
    const { route } = displayRuleAmount(ruleIdentifier, rule);
    try {
      if (rule) {
        console.log('AQUI1');
        navigateTo(routes.otpSms, {
          mode: 'replace',
          queryParams: {
            action: OTP_ACTIONS.TOGGLE_RULE,
            payload: {
              targetId: target.id,
              ruleIdentifier,
              toggle: toggle,
            },
            from: {
              name: routes.followRules,
              routeParams: {
                ruleIdentifier: ruleIdentifier,
                idTarget: target.id,
              },
            },
          },
        });
      } else {
        console.log('CONFIG REPLACING');
        route &&
          navigateTo(route, {
            mode: 'replace',
            routeParams: {
              idTarget: target.id,
            },
            // mode: 'replace',
            queryParams: {
              from: {
                name: routes.followRules,
                routeParams: {
                  ruleIdentifier: ruleIdentifier,
                  idTarget: target.id,
                },
              },
            },
          });
        // setActiveRule(activate);
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  let ruleName = ruleData.find((rule) => rule.identifier === ruleIdentifier).ruleName;
  let ruleImage = ruleData.find((rule) => rule.identifier === ruleIdentifier).image;

  let movements = stuctureDataMovementsForRule(target, ruleIdentifier);

  const { amount, route } = displayRuleAmount(ruleIdentifier, rule);
  useEffect(() => {
    if (rule) {
      setActiveRule(rule.isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule?.id]);

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center={ruleName.toUpperCase()} left="atras" />
    </View>,
    [ruleName],
  );

  return (
    <>
      <View fullHeightSt startSt>
        <View
          spaceBetweenSt
          horizontalSt
          style={{ padding: '10px 20px', backgroundColor: '#fef7f6' }}
        >
          <View autoWidthSt horizontalSt>
            <Image src={ruleImage} style={{ width: 33, height: 'auto' }} />
            <Text style={{ fontSize: 13, marginLeft: 10 }}>{ruleName.toUpperCase()}</Text>
          </View>
          <InputSwitch
            value={activeRule}
            onChange={(value) => {
              activeRuleHandler(!activeRule);
            }}
          ></InputSwitch>
        </View>
        {rule && (
          <>
            {amount !== null && (
              <View
                bottomSt
                style={{ padding: '20px 20px' }}
                onClick={() => {
                  route &&
                    navigateTo(route, {
                      routeParams: {
                        idTarget: target.id,
                      },
                      // mode: 'replace',
                      queryParams: {
                        from: {
                          name: routes.followRules,
                          routeParams: { ruleIdentifier, idTarget: target.id },
                        },
                      },
                    });
                }}
              >
                <View spaceBetweenSt horizontalSt width92St>
                  <Text font15St>Selecciona importe</Text>
                  <Text font15St>{amount}</Text>
                </View>
              </View>
            )}
            <Movements
              data={movements}
              title="MOVIMIENTOS"
              ruleIdentifier={ruleIdentifier}
              ruleConfig
            />
          </>
        )}
      </View>
    </>
  );
});
