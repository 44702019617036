import { Tooltip } from '@coinscrap/webapp-core';
import React from 'react';

const RulesTooltip = ({ content }) => {
  return (
    <Tooltip
      style={{
        containerComponent: {
          border: '2px solid white',
          borderRadius: '50%',
          height: 20,
          width: 20,
          color: 'white',
          fontSize: 13,
          marginTop: 2,
        },
        popperContentComponent: { backgroundColor: 'white', padding: 20 },
      }}
      placement="bottom-left"
      content={content}
    >
      ?
    </Tooltip>
  );
};

export default RulesTooltip;
