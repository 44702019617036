import React from 'react';

import { Text, View } from '@coinscrap/webapp-core';

// images
import Warning from '../../../common/svgs/Warning';

// configs
import colors from '../../../config/colors';
import { getCurrency } from 'libs/Currency';

const Reembolso = ({ target }) => {
  return (
    <View>
      <View style={{ backgroundColor: '#fef7f6', height: 53 }}>
        <View topSt width90St>
          <Text boldSt style={{ fontSize: 13 }}>
            REEMBOLSO
          </Text>
        </View>
      </View>
      <View style={{ height: 53 }}>
        <View startSt width90St horizontalSt>
          <Text boldSt style={{ fontSize: 13 }}>
            Total ahorrado
          </Text>
          <Text style={{ fontSize: 13, marginLeft: 20 }}>
            {getCurrency(target.progress.completed)}
          </Text>
        </View>
      </View>

      <View style={{ background: '#FEF9F8 0% 0% no-repeat padding-box', padding: '10px 0px' }}>
        <View width90St horizontalSt startSt topSt>
          <View autoWidthSt>
            <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
          </View>
          <View topSt>
            <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
              Para solicitar este reembolso ponte en contacto con nuestro mediador.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Reembolso;
