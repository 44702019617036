import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';
import fonts from '../fonts';

import commonStyles from './common';

UiWrapper.defineComponent('enfatize', {
  styles: {
    default: {
      color: colors.dark,
      fontFamily: fonts.HelveticaNeue,
    },
    boldSt: {
      fontFamily: fonts.HelveticaNeueBold,
    },
    boldItalicSt: {
      fontFamily: fonts.HelveticaNeueBoldItalic,
    },
    condensedSt: {
      fontFamily: fonts.HelveticaNeueCondensedBlack,
    },
    condensedBoldSt: {
      fontFamily: fonts.HelveticaNeueCondensedBold,
    },
    italicSt: {
      fontFamily: fonts.HelveticaNeueItalic,
    },

    lightSt: {
      fontFamily: fonts.HelveticaNeueLight,
    },
    lightItalicSt: {
      fontFamily: fonts.HelveticaNeueLightItalic,
    },
    regularSt: {
      fontFamily: fonts.HelveticaNeue,
    },

    mediumSt: {
      fontFamily: fonts.HelveticaNeueMedium,
    },
    mediumItalicSt: {
      fontFamily: fonts.HelveticaNeueMediumItalic,
    },
    thinSt: {
      fontFamily: fonts.HelveticaNeueThin,
    },
    thinItalicSt: {
      fontFamily: fonts.HelveticaNeueThinItalic,
    },
    ultraLightSt: {
      fontFamily: fonts.HelveticaNeueUltraLight,
    },
    ultraLightItalicSt: {
      fontFamily: fonts.HelveticaNeueUltraLightItalic,
    },

    whiteSt: {
      color: colors.white,
    },
    blackSt: {
      color: colors.black,
    },
    black2St: {
      color: colors.black2,
    },
    blackLightSt: {
      color: colors.blackLight,
    },
    greenSt: {
      color: colors.green,
    },
    redSt: {
      color: colors.red,
    },
    faqTextSt: {
      fontSize: 15,
      color: '#888',
      fontWeight: 'bold',
    },

    ...commonStyles.styles,
  },
});
