import {
  Image,
  PickerHOC,
  RuleDefinitionsWrapper,
  RulesWrapper,
  TargetsWrapper,
  Text,
  UiWrapper,
  View,
  NavigationWrapper,
} from '@coinscrap/webapp-core';

// images
import colors from 'config/colors';
import React from 'react';
import StopSmoking from 'common/svgs/StopSmoking';
import CaffeineRed from 'assets/images/CaffeineRed.png';
import Money from 'assets/images/Money.png';
import ContributionsItem from './ContributionsItem';
import ContributionModal from './ContributionModal';
import HotModal from './HotModal';
import * as Types from 'data/types';
import routes from 'config/routes';
import { OTP_ACTIONS } from 'libs/common';
const Contributions = PickerHOC({
  ruleDefinitions: {
    sourceWrapper: RuleDefinitionsWrapper,
    sourcePath: 'ruleDefinitions',
  },
  target: {
    arrayItemMatcher: {
      type: 'param',
      itemPropertyPath: 'id',
      paramName: 'idTarget',
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
  rules: {
    sourceWrapper: RulesWrapper,
    sourcePath: 'rules',
    arrayItemFilter: { itemPropertyPath: 'targetId', paramName: 'idTarget', type: 'param' },
  },
})(({ user }) => {
  const { launchEvent } = RulesWrapper.use();
  const { rules, ruleDefinitions, target } = PickerHOC.use();
  const { openModal, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const eventDefinitions = ruleDefinitions.reduce(
    (acc, act) => [...acc, ...(act.eventDefinitions || [])],
    [],
  );

  const rulesWithDefinitions = rules.map((r) => {
    r.ruleDefinition = ruleDefinitions.find((rd) => rd.id === r.ruleDefinitionId);
    return r;
  });

  const doEvent = async (eventDefinition, { target, amount }) => {
    try {
      // openModal((close) => <ContributionModal isOpen={true} onClose={close} />);
      await launchEvent(
        eventDefinition.identifier,
        [
          amount && {
            identifier: 'hot_event_amount',
            data: { value: Number(amount) },
          },
        ].filter((x) => x),
        target,
      );
      let text = 'Aportación realizada con éxito';
      console.log(eventDefinition);
      if (eventDefinition.identifier === 'EVENT_CAFEINE') {
        text = 'caída registrada';
      }
      if (eventDefinition.identifier === 'EVENT_NICOTINE') {
        text = 'caída registrada';
      }
      openModal((close) => <ContributionModal isOpen={true} onClose={close} text={text} />);
    } catch (e) { }
  };

  const doCafeine = async () => {
    const eventDefinition = eventDefinitions.find((def) => def.identifier === 'EVENT_CAFEINE');
    await doEvent(eventDefinition, {});
  };
  const doNicotine = async () => {
    const eventDefinition = eventDefinitions.find((def) => def.identifier === 'EVENT_NICOTINE');
    await doEvent(eventDefinition, {});
  };

  const doHot = async () => {
    // const eventDefinition = eventDefinitions.find((def) => def.identifier === 'EVENT_AHORRO_HOT');
    openModal((close) => (
      <HotModal
        isOpen={true}
        onClose={close}
        user={user}
        onConfirmAmount={async (amount) => {
          navigateTo(routes.otpSms, {
            queryParams: {
              action: OTP_ACTIONS.HOT,
              payload: { targetId: target.id, amount },
            },
          });
          // amount && (await doEvent(eventDefinition, { target: target.id, amount }));
          close();
        }}
      />
    ));
    ////await doEvent(eventDefinition, {});
  };

  useLoading(false);

  return (
    <>
      <View>
        <View spaceBetweenSt horizontalSt style={{ padding: 20, backgroundColor: '#fef7f6' }}>
          <Text style={{ fontSize: 13 }}>APORTACIONES</Text>
        </View>
        <ContributionsItem
          name="Sin nicotina"
          identifier={Types.NICOTINE}
          global={true}
          disabledMessage={
            !rulesWithDefinitions.find(
              (r) => r.isActive && r.ruleDefinition.identifier === 'NICOTINE',
            ) && 'Actívame'
          }
          icon={<StopSmoking style={{ width: 30, fill: colors.red }} />}
          onClick={doNicotine}
        />
        <ContributionsItem
          name="Sin cafeína"
          identifier={Types.CAFEINE}
          global={true}
          disabledMessage={
            !rulesWithDefinitions.find(
              (r) => r.isActive && r.ruleDefinition.identifier === 'CAFEINE',
            ) && 'Actívame'
          }
          icon={<Image src={CaffeineRed} style={{ width: 30 }} />}
          onClick={doCafeine}
        />
        <ContributionsItem
          name="Aportación puntual"
          identifier={Types.AHORRO_HOT}
          disabledMessage={
            !rulesWithDefinitions.find(
              (r) => r.isActive && r.ruleDefinition.identifier === 'AHORRO_HOT',
            ) && 'Actívame'
          }
          icon={<Image src={Money} style={{ width: 30, height: 'auto' }} />}
          onClick={doHot}
        />
      </View>
    </>
  );
});

export default Contributions;
