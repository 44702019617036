import React from 'react';

import { UiWrapper, View, Text, PickerHOC } from '@coinscrap/webapp-core';

// images

// configs
import colors from '../../../config/colors';

export default PickerHOC()(({ section, onChange }) => {
  const { useBackground } = UiWrapper.use();

  useBackground(colors.white);

  return (
    <View horizontalSt style={{}}>
      <View
        onClick={() => onChange('objetivo')}
        flexGrowSt
        autoWidthSt
        style={{ height: 67, position: 'relative' }}
        homeSectionBorderBottomGreySt
        homeSectionBorderBottomSt={section === 'objetivo'}
        pointerSt
      >
        <Text
          tabTextSt
          style={{ fontSize: 15, color: section === 'objetivo' ? colors.dark : colors.grey1 }}
          boldSt={section === 'objetivo'}
        >
          Objetivo
        </Text>
      </View>
      <View
        onClick={() => onChange('cuentas')}
        flexGrowSt
        autoWidthSt
        style={{ height: 67 }}
        homeSectionBorderBottomGreySt
        homeSectionBorderBottomSt={section === 'cuentas'}
        pointerSt
      >
        <Text
          tabTextSt
          style={{ fontSize: 15, color: section === 'cuentas' ? colors.dark : colors.grey1 }}
          boldSt={section === 'cuentas'}
        >
          Cuentas
        </Text>
      </View>
      <View
        onClick={() => onChange('reembolso')}
        flexGrowSt
        autoWidthSt
        style={{ height: 67 }}
        homeSectionBorderBottomGreySt
        homeSectionBorderBottomSt={section === 'reembolso'}
        pointerSt
      >
        <Text
          tabTextSt
          style={{ fontSize: 15, color: section === 'reembolso' ? colors.dark : colors.grey1 }}
          boldSt={section === 'reembolso'}
        >
          Reembolso
        </Text>
      </View>
    </View>
  );
});
