import React from 'react';

import {
  View,
  Text,
  Image,
  // Button,
  // TextField,
  PickerHOC,
  UiWrapper,
  UserWrapper,
  BackendWrapper,
  NavigationWrapper,
  TargetsUtilsWrapper,
  EnvelopesWrapper,
  ProductParticipationsWrapper,
  InAppBrowserWrapper,
  TargetsWrapper,
} from '@coinscrap/webapp-core';
import { LoadingData } from 'common/LoadingData';
import flecha from '../../assets/images/flecha.png';
import contract from '../../assets/images/contract.svg';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { getCurrency } from 'libs/Currency';

import routes from '../../config/routes';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import colors from 'config/colors';
// import Footer from '../common/Footer';
// import colors from "./../../config/colors";
// import FooterHome from "./../common/FooterHome";

export default PickerHOC(
  {
    userData: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },

    envelopes: {
      sourcePath: 'envelopes',
      sourceWrapper: EnvelopesWrapper,
    },
  },
  LoadingData,
)(() => {
  const { /* navigateTo */ goBack } = NavigationWrapper.use();
  const { useBackground, useHeader, /* useFooter, */ useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { open } = InAppBrowserWrapper.use();
  const { getViewSignedUrl } = EnvelopesWrapper.use();

  const { envelopes } = PickerHOC.use();
  useLoading(false);
  console.log({ envelopes });

  // const { userData } = PickerHOC.use();
  // console.log('userData > ', userData);
  // const { user } = UserWrapper.use();
  // console.log('user > ', user);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="MIS DOCUMENTOS" left="atras" />
    </View>,
    [],
  );
  const Contract = ({ data, style }) => {
    const { envelope } = data;
    console.log({ envelope });

    const documents = envelope?.documents || [];
    return (
      <View>
        <View topSt>
          <Text boldSt>{envelope.title}</Text>
        </View>
        {documents
          .filter((item) => !!item?.signedFileUrl)
          .map((document) => {
            return (
              <View
                pointerSt
                horizontalSt
                spaceBetweenSt
                style={{ borderBottom: '1px dotted red', paddingBottom: 5, ...style }}
                onClick={() => {
                  window.open(`https://drive.google.com/viewer?url=${document.signedFileUrl}`);
                }}
              >
                <Text>{document.name}</Text>

                <PictureAsPdfIcon style={{ height: 25, width: 'auto', color: colors.red }} />
              </View>
            );
          })}
      </View>
    );
  };

  return (
    <View fullHeightSt sparceSt startSt>
      <View style={{ width: '90%', marginTop: 25 }}>
        {envelopes
          .filter((item) => item?.signStatus === 'SIGNED')
          .map((envelope, i) => {
            return (
              <Contract
                data={{
                  envelope: envelope,
                }}
                style={{ margin: '10px 0' }}
              />
            );
          })}
      </View>
    </View>
  );
});
