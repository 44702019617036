import React from 'react';

import { UiWrapper, View, PickerHOC, Layout } from '@coinscrap/webapp-core';

// components
import Header from 'common/Header';
import HeaderRedPrimary from 'common/HeaderRedPrimary';

import Resumen from './components/Resumen';

import { LoadingData } from 'common/LoadingData';

// configs
import colors from '../../config/colors';

export default PickerHOC(
  {},
  LoadingData,
)(() => {
  const { useBackground, useHeader, useLoading } = UiWrapper.use();

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />

      <Header center="HISTÓRICO" left="atras" />
    </View>,
    [],
  );

  return (
    <Layout.Container>
      {' '}
      <Resumen filterForHistory={true} />{' '}
    </Layout.Container>
  );
});
