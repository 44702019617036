import React from 'react';
import { View } from '@coinscrap/webapp-core';

import './arrow.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const ScrollArrow = ({ lastItem, style, color }) => (
  <View
    arrowSt
    style={{ ...style }}
    onClick={() =>
      lastItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  >
    {/* <Image style={{ width: '25%', transform: 'rotate(90deg)' }} src={arrow} /> */}
    <ExpandMoreIcon style={{ color: color ? color : 'white', fontSize: 50 }} />
  </View>
);

export default ScrollArrow;
