import React from 'react';

import { Enfatize } from '@coinscrap/webapp-core';

import * as Types from 'data/types';
import { getCurrency } from 'libs/Currency';
import routes from 'config/routes';
import { ruleData } from 'data/ruleData';

export const displayRuleAmount = (identifier, rule) => {
  switch (identifier) {
    case Types.PERIODIC_SAVING: {
      let amount = null;
      let amountRaw = null;
      if (rule) {
        amount = getCurrency(
          rule.value?.fieldValues?.find((fieldValue) => fieldValue.identifier === 'periodic_amount')
            ?.data?.value || 0,
          2,
        );
        amountRaw =
          rule.value?.fieldValues?.find((fieldValue) => fieldValue.identifier === 'periodic_amount')
            ?.data?.value || 0;
      }

      return {
        isActive: rule?.isActive || false,
        name: ruleData.find((rule) => rule.identifier === identifier).ruleName,
        amount,
        amountRaw,
        route: routes.contributionStep2Periodic,

        SummarySentence: () => (
          <>
            Has configurado una aportacion periódica de{' '}
            <Enfatize boldSt redSt>
              {amount}
            </Enfatize>
            /mes.{' '}
          </>
        ),
      };
    }
    case Types.COINSCRAP_ROUNDS: {
      let amount = null;
      let amountRaw = null;
      if (rule) {
        amount = getCurrency(
          rule?.value?.fieldValues?.find((fieldValue) => fieldValue.identifier === 'rounds_round')
            ?.data?.value || 0,
          2,
        );
        amountRaw =
          rule?.value?.fieldValues?.find((fieldValue) => fieldValue.identifier === 'rounds_round')
            ?.data?.value || 0;
      }

      return {
        isActive: rule?.isActive || false,
        name: ruleData.find((rule) => rule.identifier === identifier).ruleName,
        amount,
        amountRaw,
        route: routes.roundRule,

        SummarySentence: () => (
          <>
            Has activado la regla de redondeo a{' '}
            <Enfatize boldSt redSt>
              {amount}
            </Enfatize>
            .{' '}
          </>
        ),
      };
    }
    case Types.EARNINGS_SAVING: {
      let amount = null;
      let amountRaw = null;
      if (rule) {
        amount = `${rule.value.fieldValues.find((fieldValue) => fieldValue.identifier === 'earning_percent')
            ?.data.value
          }%`;
        amountRaw = rule.value.fieldValues.find(
          (fieldValue) => fieldValue.identifier === 'earning_percent',
        )?.data.value;
      }

      return {
        isActive: rule?.isActive || false,
        name: ruleData.find((rule) => rule.identifier === identifier).ruleName,
        amount,
        amountRaw,
        route: routes.incomeRule,

        SummarySentence: () => (
          <>
            Tienes la regla de ingresos activada a{' '}
            <Enfatize boldSt redSt>
              {amount}
            </Enfatize>{' '}
            de tus ingresos.{' '}
          </>
        ),
      };
    }
    case Types.TEAM: {
      let amount = null;
      let rawAmount = null;
      if (rule) {
        amount = rule.value.fieldValues.find(
          (fieldValue) => fieldValue.identifier === 'football_team',
        )?.data.value;
        rawAmount = rule.value.fieldValues.find(
          (fieldValue) => fieldValue.identifier === 'football_team',
        )?.data.value;
      }

      return {
        isActive: rule?.isActive || false,
        name: ruleData.find((rule) => rule.identifier === identifier).ruleName,
        amount,
        rawAmount,
        route: routes.futbolRule,

        SummarySentence: () => (
          <>
            Ahorras{' '}
            <Enfatize boldSt redSt>
              {getCurrency(
                rule.value.fieldValues.find(
                  (fieldValue) => fieldValue.identifier === 'football_amount',
                ).data.value,
                2,
              )}
            </Enfatize>{' '}
            cada vez que tu equipo gana.{' '}
          </>
        ),
      };
    }
    case Types.CAFEINE: {
      let amount = null;
      let rawAmount = null;
      if (rule) {
        amount = getCurrency(
          rule.value.fieldValues.find((fieldValue) => fieldValue.identifier === 'cafeine_amount')
            ?.data.value,
          2,
        );
        rawAmount = getCurrency(
          rule.value.fieldValues.find((fieldValue) => fieldValue.identifier === 'cafeine_amount')
            ?.data.value,
          2,
        );
      }

      return {
        isActive: rule?.isActive || false,
        name: ruleData.find((rule) => rule.identifier === identifier).ruleName,
        amount,
        rawAmount,
        route: routes.caffeineRule,

        SummarySentence: () => (
          <>
            Has activado la regla de Sin Cafeína a{' '}
            <Enfatize boldSt redSt>
              {amount}
            </Enfatize>
            .{' '}
          </>
        ),
      };
    }
    case Types.NICOTINE: {
      let amount = null;
      let rawAmount = null;
      if (rule) {
        amount = getCurrency(
          rule.value.fieldValues.find((fieldValue) => fieldValue.identifier === 'nicotine_amount')
            ?.data.value,
          2,
        );
        rawAmount = getCurrency(
          rule.value.fieldValues.find((fieldValue) => fieldValue.identifier === 'nicotine_amount')
            ?.data.value,
          2,
        );
      }

      return {
        isActive: rule?.isActive || false,
        name: ruleData.find((rule) => rule.identifier === identifier).ruleName,
        amount,
        route: routes.nicotineRule,
        rawAmount,
        SummarySentence: () => (
          <>
            Has activado la regla de Sin Nicotina a{' '}
            <Enfatize boldSt redSt>
              {amount}
            </Enfatize>
            .{' '}
          </>
        ),
      };
    }

    default:
      return {
        name: null,
        amount: null,
        route: undefined,
      };
  }
};
