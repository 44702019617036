import React from 'react';

import { Text, View, NavigationWrapper } from '@coinscrap/webapp-core';

// components
import RuleList from './RuleList';
import RuleItem from './RuleItem';

// libs
import routes from 'config/routes';

const Rules = ({ data }) => {
  const { navigateTo } = NavigationWrapper.use();

  return (
    <View>
      <View spaceBetweenSt horizontalSt style={{ padding: 20, backgroundColor: '#fef7f6' }}>
        <Text style={{ fontSize: 13 }}>REGLAS DE AHORRO</Text>
        {/* <View horizontalSt autoWidthSt>
          <Image style={{ height: 23, width: 'auto' }} src={download}></Image>
        </View> */}
      </View>
      <RuleList>
        {data.map((rule) => {
          return (
            <RuleItem
              key={rule.name}
              data={rule}
              onClick={() =>
                navigateTo(routes.followRules, {
                  mode: 'push',
                  // clearItems: ['from'],

                  routeParams: {
                    ruleIdentifier: rule.ruleIdentifier,
                  },
                })
              }
            />
          );
        })}
      </RuleList>
    </View>
  );
};

export default Rules;
