import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Button, Text, View } from '@coinscrap/webapp-core';
import colors from 'config/colors';

const ContributionModal = ({ isOpen, onClose, text }) => {
  return (
    <Modal open={isOpen}>
      <View style={{ height: '100%', outline: 'none' }}>
        <View style={{ backgroundColor: colors.white, width: '80%', padding: 30 }}>
          <Text style={{ fontSize: 16, marginBottom: 10 }}>
            {text || 'Aportación realizada con éxito'}
          </Text>
          <Button style={{ padding: '10px' }} onClick={onClose}>
            Aceptar
          </Button>
        </View>
      </View>
    </Modal>
  );
};

export default ContributionModal;
