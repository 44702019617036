import { View } from '@coinscrap/webapp-core';
import React from 'react';

export default function QuestionMark(props) {
  return (
    <View style={{ width: 30, height: 30 }} onClick={props.onClick}>
      <svg
        id="_"
        data-name="?"
        xmlns="http://www.w3.org/2000/svg"
        width="81.23"
        height="81.232"
        viewBox="0 0 81.23 81.232"
        style={{ ...props.style }}
      >
        <path
          id="Trazado_33"
          data-name="Trazado 33"
          d="M689.736,1181.574a40.616,40.616,0,1,1,40.615-40.615A40.662,40.662,0,0,1,689.736,1181.574Zm0-77.092a36.476,36.476,0,1,0,36.475,36.477A36.516,36.516,0,0,0,689.736,1104.482Z"
          transform="translate(-649.121 -1100.342)"
          fill="#20252b"
        />
        <path
          id="Trazado_34"
          data-name="Trazado 34"
          d="M669.944,1144.092q0-6.281,1.169-9.364a14.085,14.085,0,0,1,4.684-5.858l3.33-2.59a8.448,8.448,0,0,0,2.312-2.5,6.69,6.69,0,0,0,.648-3.171,6.977,6.977,0,0,0-1.853-4.87,6.119,6.119,0,0,0-4.744-2.037,6.292,6.292,0,0,0-5.212,2.192,8.536,8.536,0,0,0-1.755,5.512H662.61a12.7,12.7,0,0,1,3.513-9.3q3.512-3.574,9.488-3.575a11.786,11.786,0,0,1,8.969,3.606,12.211,12.211,0,0,1,3.422,8.723,11.3,11.3,0,0,1-.954,4.9,12.788,12.788,0,0,1-3.792,4.218l-3.637,2.84a10.782,10.782,0,0,0-3.33,3.941q-.8,1.913-.8,7.333Zm2.774,3.637a4.1,4.1,0,1,1-2.869,1.2A3.908,3.908,0,0,1,672.717,1147.728Z"
          transform="translate(-634.69 -1091.581)"
          fill="#20252b"
        />
      </svg>
    </View>
  );
}
