import React, { useState, useRef, useCallback } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Button,
  Image,
  TextField,
  TargetsWrapper,
  TargetsUtilsWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';

// images
import HourGlass from '../../../assets/images/HourGlass.png';
import CheckMark from '../../../common/svgs/CheckMark';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';
import { futureDate, dateToNumberOfMonths } from 'libs/date';

import { LoadingData } from 'common/LoadingData';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target } = PickerHOC.use();

  const [isScroll, setIsScroll] = useState(false);

  const lastRef = useRef(null);
  const observer = useRef(null);

  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { saveTargetProperty } = TargetsUtilsWrapper.use();

  const [time, setTime] = useState(
    target.creationData?.date
      ? dateToNumberOfMonths(target.createDate, target.creationData.date)
      : 12,
  );

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  useBackground(colors.white);
  useLoading(false);

  const onChangeMonths = (value) => {
    const isNotNumber = isNaN(value);
    if (isNotNumber) return;
    if (!value) {
      setTime('');
      return;
    }

    setTime(parseInt(value));
  };

  const saveTarget = async () => {
    try {
      await Promise.all([
        saveTargetProperty(target.id, 'creationData', { date: futureDate(time) }),
      ]);

      navigateTo(routes.objectiveStep4Image, {
        routeParams: {
          idTarget: target.id,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. OBJETIVO" left="atras" />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 30, opacity: time ? 1 : 0.7 }}
        borderWhiteButtonSt
        onClick={() => saveTarget()}
        disabled={!time}
      >
        Siguiente
      </Button>
    </Footer>,
    [time],
  );

  return (
    <Layout.Container>
      <ScrollArrow
        color="black"
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <View topSt>
            <Text boldSt font11St style={{ marginLeft: 16 }}>
              1 de 4
            </Text>
          </View>
          <View horizontalSt>
            <DotComponent active />
            <DotComponent active />
            <DotComponent active />
            <DotComponent />
          </View>
          <View style={{ height: 115, marginTop: 28 }}>
            <View
              style={{
                backgroundColor: colors.grey5,
                height: 114,
                width: 114,
                borderRadius: '50%',
                position: 'relative',
              }}
            >
              <Image
                src={HourGlass}
                style={{
                  width: 34,
                  height: 'auto',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </View>
          </View>
          <View style={{ marginTop: 19 }}>
            <View width80St>
              <Text font27St textCenterSt>
                ¿En cuánto tiempo quieres conseguirlo?
              </Text>
            </View>
            <View width80St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
                Indica el espacio de tiempo que quieres emplear para obtener tu objetivo.
              </Text>
              <View style={{ marginTop: 30 }}>
                <TextField
                  createInputSt
                  onChange={(e) => onChangeMonths(e.target.value)}
                  value={time}
                  startAdornment={
                    <View
                      style={{
                        backgroundColor: colors.red,
                        height: 20,
                        width: 20,
                        borderRadius: '50%',
                        opacity: time ? 1 : 0.7,
                      }}
                    >
                      <CheckMark style={{ fill: colors.white, height: 8, width: 'auto' }} />
                    </View>
                  }
                  endAdornment={<Text style={{ paddingTop: 2, marginLeft: 5 }}> Meses</Text>}
                />
              </View>
            </View>
          </View>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});

const DotComponent = ({ active }) => {
  return (
    <View
      dotsSt
      dotsActiveSt={active}
      style={{ margin: '0 4px 0 4px' }}
    // onClick={() => switchInfo(id)}
    />
  );
};
