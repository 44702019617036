import React, { useRef, useCallback, useState } from 'react';

import { UiWrapper, View, Text, Enfatize, Button } from '@coinscrap/webapp-core';

// components
import { CloseButton } from 'common/closeButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Modal from '@material-ui/core/Modal';
// configs
import colors from 'config/colors';

const LegalModal = ({ isOpen, onClose, children, onClick }) => {
  const faqRef = useRef();
  const { useBackground } = UiWrapper.use();
  useBackground(colors.white);
  const [isScroll, setIsScroll] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const observer = useRef();

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setButtonDisabled(false);
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <View fullHeightSt style={{ backgroundColor: colors.white, overflowY: 'auto' }}>
        <View fullHeightSt style={{ justifyContent: 'flex-start' }}>
          <View startSt fullHeightSt>
            <View horizontalSt endSt style={{ marginTop: 10 }}>
              <CloseButton
                color={colors.dark}
                action={() => {
                  onClose();
                }}
              />
            </View>

            <View
              startSt
              style={{
                overflowY: 'scroll',
                height: 'calc(100% - 150px)',
                maxWidth: 850,
                padding: 10,
                width: '90%',
              }}
            >
              <Text boldSt underLineSt font16St style={{ marginBottom: 10, paddingTop: 10 }}>
                CONDICIONES GENERALES APLICABLES AL SERVICIO DE INFORMACIÓN SOBRE CUENTAS
              </Text>
              <View
                startSt
                topSt
              // style={{ overflowY: 'scroll', height: '65vh', maxWidth: 850, paddingBottom: 100 }}
              >
                <Text paragraphMarginSt boldSt font16St>
                  PRIMERA.- DEFINICIÓN
                </Text>
                <Text paragraphMarginSt font14St>
                  A los efectos de lo dispuesto en estas Condiciones Generales, se entenderá por:
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt> Usuario o Cliente Activo o Cliente final: </Enfatize> En
                  relación con el servicio de información sobre cuentas, persona física o jurídica
                  titular de la/s cuenta/s sobre la/s que Morpheus Aiolos ofrece el servicio de
                  información agregada sobre cuentas
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt> Multicontrato: </Enfatize> Aquellos supuestos en los que las
                  entidades financieras permitan a sus usuarios conectar con distintos perfiles que,
                  a su vez, agrupan cuentas de distintos clientes.
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt> Comercio electrónico: </Enfatize> Es la entidad que (i) vende
                  bienes u ofrece servicios a los usuarios a través de su página web, o bien dispone
                  de una plataforma en la que los comercios electrónicos pueden ofertar sus bienes o
                  servicios y los usuarios adquirirlos, y desea poner a disposición de sus clientes
                  o usuarios la posibilidad de efectuar el pago mediante transferencia bancaria
                  ordenada por el cliente final e iniciada a través de Morpheus Aiolos, o bien (ii)
                  desea ofrecer a sus clientes o usuarios el servicio de información sobre cuentas o
                  agregación financiera, a través de Morpheus Aiolos
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt> Servicio de información sobre cuentas: </Enfatize> servicio en
                  línea cuya finalidad consiste en facilitar información agregada sobre una o varias
                  cuentas de pago de las que es titular el usuario del servicio de pago bien en otro
                  proveedor de servicios de pago, bien en varios proveedores de servicios de pago
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>Proveedor de servicios de pago gestor de cuenta: </Enfatize> un
                  proveedor de servicios de pago que facilita a un ordenante una o varias cuentas de
                  pago y se encarga de su mantenimiento.
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>
                    Credenciales de seguridad personalizadas/Claves/Contraseñas:{' '}
                  </Enfatize>{' '}
                  elementos personalizados que el proveedor de servicios de pago gestor de cuenta
                  proporciona al usuario de servicios de pago a efectos de autenticación, para
                  acceder a los datos de su cuenta de pago y/o iniciar una operación de pago en la
                  banca online de la página web oficial del proveedor de servicios de pago gestor de
                  cuenta.
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>Autenticación: </Enfatize> procedimiento que permita al proveedor
                  de servicios de pago gestor de la cuenta comprobar la identidad de usuario de un
                  servicio de pago o la validez de la utilización de determinado instrumento de
                  pago, incluida la utilización de credenciales de seguridad personalizadas del
                  usuario.
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>Consumidor: </Enfatize> una persona física que, en los contratos
                  de servicios de pago, actúa con fines ajenos a su actividad económica, comercial o
                  profesional.
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>Microempresa: </Enfatize> una empresa, considerando como tal
                  tanto a las personas físicas que realizan una actividad profesional o empresarial
                  como a las personas jurídicas, que, en la fecha de celebración del contrato de
                  servicios de pago ocupa a menos de diez personas y cuyo volumen de negocios anual
                  o cuyo balance general anual no supera los dos millones de euros, de conformidad
                  con lo establecido en los artículos 1 y 2, apartados 1 y 3, del anexo de la
                  Recomendación de la Comisión, de 6 de mayo de 2003, sobre la definición de
                  microempresas, pequeñas y medianas empresas.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  SEGUNDA.-DATOS DEL PRESTADOR DEL SERVICIO
                </Text>
                <Text paragraphMarginSt font14St>
                  MORPHEUS AIOLOS, S.L. (en lo sucesivo{' '}
                  <Enfatize boldSt>“Morpheus Aiolos”</Enfatize>), con CIF B-86556420, constituida en
                  Madrid, en fecha 27 de septiembre de 2012, inscrita en el Registro Mercantil de
                  Madrid, en el tomo 30408, folio 12, hoja número M-547305, inscripción 1ª. Está
                  autorizada por el Banco de España para la prestación de los servicios de
                  iniciación de pago e información sobre cuentas y sujeta a la supervisión del Banco
                  de España y del Servicio Ejecutivo de la Comisión de Prevención del Blanqueo de
                  Capitales e Infracciones Monetarias. Consta debidamente inscrita en el Registro
                  Especial de Proveedores de Servicios de Iniciación de Pagos Entidades de Pago y
                  Proveedores de Servicios de Información sobre Cuentas del Banco de España con el
                  número de codificación 6901. El domicilio social se encuentra en la calle San
                  Andrés, número 8, 28004 Madrid.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  TERCERA.- ÁMBITO DE ACTUACIÓN DEL USUARIO
                </Text>
                <Text paragraphMarginSt font14St>
                  Cuando el Usuario no tenga la condición de consumidor ni de microempresa a los
                  efectos previstos en la normativa de servicios de pago, no resultará de aplicación
                  lo dispuesto en el Título II del Real Decreto-Ley 19/2018, de 23 de noviembre, de
                  Servicios de Pago y otras medidas urgentes en materia financiera, así como sus
                  disposiciones de desarrollo, de conformidad con lo previsto en el art. 28.2 del
                  citado Real Decreto-Ley. Asimismo, de conformidad con lo dispuesto en el artículo
                  34.1 del Real Decreto-Ley 19/2018, de 23 de noviembre, se excluye de manera
                  expresa la aplicación de los artículos 35.1, 36.3, 44, 46, 48, 49, 52, 60 y 61 del
                  referido Real Decreto-Ley.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  CUARTA.- OBJETO
                </Text>
                <Text paragraphMarginSt font14St>
                  Las presentes condiciones generales recogen la información que Morpheus Aiolos, en
                  su condición de proveedor de servicios de información sobre cuentas debe facilitar
                  a los usuarios de servicios de pago. Asimismo, en el presente documento también se
                  regulan los derechos y obligaciones esenciales de los usuarios de estos servicios
                  de pago. Todo ello, de conformidad con lo previsto en el Real Decreto-ley 19/2018,
                  de 23 de noviembre, de servicios de pago y otras medidas urgentes en materia
                  financiera y su normativa de desarrollo.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  QUINTA.- CONDICIONES ASOCIADAS AL ACCESO A LA INFORMACIÓN DE LAS CUENTAS DE PAGO
                </Text>
                <Text paragraphMarginSt font14St>
                  MORPHEUS AIOLOS facilitará al Comercio Electrónico la información financiera
                  derivada de las posiciones que mantengan en cada momento el Usuario en cualquier
                  entidad de crédito o entidad financiera en general, siempre que se pueda acceder a
                  la misma a través de Internet mediante claves personales facilitadas por el
                  Usuario. Asimismo, MORPHEUS AIOLOS ofrecerá al Comercio Electrónico, previo
                  consentimiento expreso del Usuario, la posición global de las cuentas corrientes,
                  de los movimientos bancarios e información sobre las tarjetas del Usuario con el
                  fin de que el Comercio Electrónico pueda realizar la evaluación correspondiente
                  anterior a la concesión de préstamos
                </Text>
                <Text paragraphMarginSt font14St>
                  a) CLIENTE entidad de crédito que necesite hacer un perfilado para conceder un
                  préstamo: accederá una única vez a los datos del cliente final.
                </Text>
                <Text paragraphMarginSt font14St>
                  b) CLIENTE gestor de tesorería: accederá hasta un máximo de 4 veces diarias
                  durante un periodo máximo de 90 días.
                </Text>
                <Text paragraphMarginSt font14St>
                  c) CLIENTE cuyo servicio combine varias funcionalidades (por ejemplo, gestión de
                  tesorería con agregación bancaria y, además, emisión de facturas a clientes con
                  opción de pago a través del servicio de iniciación de pagos de MORPHEUS AIOLOS):
                  Podrá parametrizar los números de accesos y duración de los mismos.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  SEXTA.- OBLIGACIONES DE MORPHEUS AIOLOS
                </Text>
                <Text paragraphMarginSt boldSt font14St>
                  Obligaciones como proveedor de servicio de información sobre cuentas o agregación.
                </Text>
                <Text paragraphMarginSt font14St>
                  Morpheus Aiolos, en su condición de proveedor de servicios de información sobre
                  cuentas, cumplirá las siguientes obligaciones:
                </Text>
                <Text paragraphMarginSt font14St>
                  a) prestará sus servicios exclusivamente sobre la base del consentimiento
                  explícito del usuario del servicio de pago;
                </Text>
                <Text paragraphMarginSt font14St>
                  b) garantizará que las credenciales de seguridad personalizadas del usuario de
                  servicios de pago no sean accesibles a terceros, con excepción del usuario y del
                  emisor de las credenciales de seguridad personalizadas, y que, cuando las
                  transmita el proveedor de servicios de pago que preste el servicio de información
                  sobre cuentas, la transmisión se realice a través de canales seguros y eficientes;
                </Text>
                <Text paragraphMarginSt font14St>
                  c) en cada comunicación, se identificará ante el proveedor o proveedores de
                  servicios de pago gestores de cuenta del usuario de servicios de pago y se
                  comunicará de manera segura con el proveedor o proveedores de servicios de pago
                  gestores de cuenta y el usuario del servicio de pago, de conformidad con lo
                  previsto en el Reglamento Delegado 2018/389 y a los criterios que, dentro de las
                  disposiciones de la Autoridad Bancaria Europea que le resulten aplicables,
                  determine el Banco de España;
                </Text>
                <Text paragraphMarginSt font14St>
                  d) accederá únicamente a la información de las cuentas de pago designadas por el
                  usuario y las operaciones de pago correspondientes;
                </Text>
                <Text paragraphMarginSt font14St>
                  e) no solicitará datos de pago sensibles vinculados a las cuentas de pago;
                </Text>
                <Text paragraphMarginSt font14St>
                  f) no utilizará, almacenará o accederá a ningún dato, para fines distintos de la
                  prestación del servicio de información sobre cuentas expresamente solicitado por
                  el usuario del servicio de pago, de conformidad con las normas sobre protección de
                  datos.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  SÉPTIMA.-LIMITACIONES AL ACCESO A LAS CUENTAS DE PAGO POR PROVEEDORES DE SERVICIOS
                  DE PAGO.
                </Text>
                <Text paragraphMarginSt font14St>
                  El proveedor de servicios de pago gestor de cuenta podrá denegar el acceso a una
                  cuenta de pago a Morpheus Aiolos por razones objetivamente justificadas y
                  debidamente documentadas en el caso de acceso no autorizado o fraudulento a la
                  cuenta de pago por parte de Morpheus Aiolos, en particular con la iniciación no
                  autorizada o fraudulenta de una operación de pago. En tales casos, el proveedor de
                  servicios de pago gestor de cuenta informará al usuario, de la manera convenida,
                  de la denegación del acceso a la cuenta de pago y de los motivos para ello. Esa
                  información será facilitada al usuario, de ser posible, antes de denegar el acceso
                  y, a más tardar, inmediatamente después de la denegación, a menos que la
                  comunicación de tal información ponga en peligro medidas de seguridad
                  objetivamente justificadas o esté prohibida por otras disposiciones legales. El
                  proveedor de servicios de pago gestor de cuenta permitirá el acceso a la cuenta de
                  pago una vez dejen de existir los motivos para denegar el acceso.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  OCTAVA.- INFORMACIÓN
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>Transmisión de información o notificaciones:</Enfatize>
                  <br />
                  En su caso, si la transmisión de información o notificaciones al usuario se
                  efectuase por medios telemáticos, será informado previamente de los requisitos
                  técnicos aplicables al equipo y a los programas informáticos. También se le
                  proporcionará el procedimiento seguro de notificación en caso de sospecha de
                  fraude, fraude real o de amenazas para la seguridad, que se recoge a continuación:
                  Morpheus Aiolos notificará mediante correo electrónico en el momento en el que el
                  sistema informático detecte sospecha de fraude, fraude real o amenazas de
                  seguridad. El usuario tiene derecho de recibir previamente la información y
                  condiciones de la prestación del servicio de pago, con el objeto de dar su
                  conformidad con las mismas. Morpheus Aiolos facilitará al usuario toda la
                  información legalmente exigible en castellano y al menos una vez al mes
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  NOVENA.- MODIFICACIÓN DE LAS CONDICIONES
                </Text>
                <Text paragraphMarginSt font14St>
                  Morpheus Aiolos se reserva el derecho a instar la modificación de las presentes
                  condiciones. En caso de que el usuario tuviera la condición de microempresa o
                  consumidor, Morpheus Aiolos deberá comunicar cualquier modificación en las
                  condiciones con una antelación no inferior a dos meses respecto de la fecha en que
                  entre en vigor la modificación propuesta. Transcurridos dichos plazos sin
                  oposición del usuario se aplicará la modificación. Si el usuario se opone a las
                  modificaciones, podrá resolver las mismas sin coste alguno antes de la fecha en la
                  que las modificaciones vayan a surtir efecto. Si la modificación decidida fuera
                  claramente beneficiosa para el usuario, podrá ser aplicada de inmediato.
                </Text>
                <Text paragraphMarginSt font14St>
                  En el caso de que el usuario tenga la consideración de microempresa o consumidor,
                  Morpheus Aiolos deberá proponer cualquier modificación de las presentes
                  condiciones de manera clara, individualizada, sin acumularla a otra información o
                  a publicidad, en papel u otro soporte duradero, con una antelación no inferior a
                  dos meses respecto de la fecha en que entre en vigor la modificación propuesta. El
                  usuario podrá aceptar o rechazar las modificaciones de las condiciones antes de la
                  fecha propuesta para su entrada en vigor por el mismo medio que le sean
                  notificadas.
                </Text>
                <Text paragraphMarginSt font14St>
                  No obstante, Morpheus Aiolos podrá aplicar de manera inmediata todas aquellas
                  modificaciones que, inequívocamente, resulten más favorables para el usuario.
                  <br />
                  Ambas partes están de acuerdo en que cabrá considerar que el usuario ha aceptado
                  la modificación de las condiciones de que se trate en caso de no comunicar a
                  Morpheus Aiolos su no aceptación con anterioridad a la fecha propuesta de entrada
                  en vigor. En tal supuesto, el usuario tendrá el derecho a resolver las presentes
                  condiciones sin coste alguno y con efecto a partir de cualquier momento anterior a
                  la fecha en que se habría aplicado la modificación.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  DÉCIMA.- DURACIÓN Y EXTINCIÓN DE LAS CONDICIONES
                </Text>

                <Text paragraphMarginSt font14St>
                  La duración de estas condiciones será de un año natural desde su firma y será
                  prorrogado de forma tácita por períodos anuales en ausencia de comunicación
                  escrita en contrario por cualquiera de las partes intervinientes dirigido a la
                  otra con una antelación mínima de dos (2) meses anteriores a su vencimiento
                  inicial o a cualquiera de sus prórrogas. En caso de que el usuario tuviera la
                  condición de microempresa o consumidor, podrá resolver estas condiciones en
                  cualquier momento, sin necesidad de preaviso alguno, debiendo Morpheus Aiolos
                  proceder al cumplimiento de la orden de resolución de estas condiciones antes de
                  transcurridas 24 horas desde la recepción de la solicitud de resolución. La
                  finalización de las presentes condiciones por el transcurso del tiempo no dará a
                  derecho a indemnización para ninguna de las partes.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  UNDÉCIMA.-PROCEDIMIENTOS DE RECLAMACIÓN A DISPOSICIÓN DEL CLIENTE
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>i) Servicio de Atención al Cliente (SAC)</Enfatize>
                  <br />
                  Morpheus Aiolos dispone de un Servicio de Atención al Cliente, de conformidad con
                  las obligaciones previstas en la Orden ECO/734/2004, de 11 de marzo, sobre los
                  Departamentos y Servicios de Atención al Cliente y el Defensor del Cliente de las
                  Entidades Financieras, cuya finalidad es atender y resolver tanto las quejas como
                  las reclamaciones que formulen los usuarios. Los datos de contacto del SAC son los
                  siguientes:
                </Text>
                <Text paragraphMarginSt font14St>
                  MORPHEUS AIOLOS, S.L.
                  <br />
                  Servicio de Atención al Cliente
                  <br />
                  Calle San Andrés, número 8, 28004 Madrid
                  <br />
                  e-mail: sac@morpheusaiolos.com
                  <br />
                </Text>
                <Text paragraphMarginSt font14St>
                  El procedimiento para la resolución de quejas y reclamaciones se describe en el
                  Reglamento de funcionamiento del Servicio de Atención al Cliente, que se encuentra
                  a disposición de éstos tanto en todas las oficinas de Morpheus Aiolos, como en la
                  página web{' '}
                  <a
                    target="_blank"
                    without
                    rel="noopener noreferrer"
                    href="http://www.MorpheusAiolos.com"
                  >
                    (http://www.MorpheusAiolos.com)
                  </a>{' '}
                  y del Banco de España{' '}
                  <a target="_blank" without rel="noopener noreferrer" href="http://www.bde.es">
                    (www.bde.es)
                  </a>
                  .
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>
                    ii) Reclamación ante el Servicio de
                    <br />
                    Reclamaciones del Banco de España
                  </Enfatize>
                  <br />
                  En el caso de que la reclamación presentada ante el Servicio de Atención del
                  Cliente de la compañía fuese desestimada o bien hubiera transcurrido el plazo de
                  un mes sin que el citado Servicio de Atención al Cliente hubiera contestado, el
                  cliente podrá presentar su reclamación ante el Servicio de Reclamaciones del Banco
                  de España: Presencialmente, en la siguiente dirección:
                  <br />
                  Banco de España
                  <br />
                  Servicio de Reclamaciones
                  <br />
                  C/ Alcalá 48, 28014 Madrid
                  <br />
                  Telemáticamente, en el siguiente enlace:
                  <br />
                  <a
                    style={{ display: 'inline-block', 'word-break': 'break-all' }}
                    target="_blank"
                    without
                    rel="noopener noreferrer"
                    href="https://app.bde.es/psr_www/faces/psr_wwwias/jsp/op/InicioSesion/PantallaAsistenteForm.jsp"
                  >
                    https://app.bde.es/psr_www/faces/psr_wwwias/jsp/op/InicioSesion/PantallaAsistenteForm.jsp
                  </a>
                  .
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  DUODÉCIMA- PRECIO
                </Text>
                <Text paragraphMarginSt font14St>
                  El servicio de información sobre cuentas prestado por Morpheus Aiolos tendrá
                  carácter gratuito para el usuario, correspondiendo al comercio electrónico el pago
                  del precio en los términos convenidos con Morpheus Aiolos.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  DECIMOTERCERA.- GARANTÍAS DE MORPHEUS AIOLOS
                </Text>
                <Text paragraphMarginSt font14St>
                  <Enfatize boldSt>- Seguridad</Enfatize>
                  <br />
                  Morpheus Aiolos garantiza que la prestación del servicio se realizará con los
                  mecanismos de seguridad que aseguran el cifrado de las claves facilitadas y que
                  garantiza la imposibilidad de uso fraudulento de las mismas.
                  <br />
                  <Enfatize boldSt>- Información estructurada y normalizada</Enfatize>
                  Morpheus Aiolos garantiza que proporcionará la información financiera y no
                  financiera al usuario de una forma estructurada y normalizada. Por “estructurada”
                  se entiende que la información está bajo un contenedor fácilmente tratable de
                  forma electrónica (JSON o XML). Por “normalizada” se entiende que la información
                  de diferentes entidades se presenta exactamente con el mismo formato y que,
                  además, este formato está normalizado de acuerdo a los estándares del tipo de dato
                  que se trate
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  DECIMOCUARTA.- PROTECCIÓN DE DATOS PERSONALES
                </Text>
                <Text paragraphMarginSt font14St>
                  En el caso de que para la prestación del servicio de información sobre cuentas sea
                  necesario proceder al tratamiento de datos personales y en cumplimiento de lo
                  dispuesto por el Artículo 28 del Reglamento General 2016/679 de Protección de
                  Datos Personales, Morpheus Aiolos, como Encargado de Tratamiento, expresamente
                  manifiesta y se obliga a: (i) utilizar y a tratar los datos con el único y
                  exclusivo objeto de cumplir con las finalidades previstas en las presentes
                  condiciones generales y siguiendo en todo caso las instrucciones recibidas del
                  usuario, (ii) observar la máxima confidencialidad y reserva respecto de los datos
                  de carácter personal que le sean facilitados por el usuario, (iii) devolver al
                  usuario todos los documentos y archivos en los que se hallen reflejados los datos
                  a la finalización de la relación contractual, (iv) restringir el acceso y el uso
                  de los datos a aquellas personas que sea absolutamente imprescindible que tengan
                  acceso y conocimiento de los mismos previa confirmación del usuario por escrito
                  para el desarrollo de la prestación del servicio de información sobre cuentas y
                  (v) adoptar las medidas de seguridad de protección de los datos que correspondan
                  al nivel de seguridad necesario en función de los ficheros a los que tenga acceso.
                  Morpheus Aiolos, para la prestación del servicio anteriormente descrito, precisará
                  de la subcontratación de servicios de hosting y servicios de auditoría LOPD.
                </Text>
                <Text paragraphMarginSt boldSt font16St>
                  DECIMOQUINTA. NORMATIVA APLICABLE
                </Text>
                <Text paragraphMarginSt font14St>
                  La prestación del servicio de información sobre cuentas al usuario por parte de
                  Morpheus Aiolos se regirá por las condiciones establecidas en las presentes
                  condiciones generales y en lo no previsto en ellas, por las normas contenidas en
                  el Real Decreto-Ley 19/2018, de 23 de noviembre, de servicios de pago y otras
                  medidas urgentes en materia financiera, su normativa de desarrollo, y por las
                  demás disposiciones normativas españolas y de transparencia que resulten
                  aplicables.
                </Text>
                {/* <Text paragraphMarginSt>
                  En Morpheus Aiolos S.L., somos conscientes que la protección y privacidad de su
                  información personal es muy importante, por ello, estamos comprometidos con
                  estándares de seguridad de alto nivel para proteger la confidencialidad, e
                  integridad de sus datos.
                </Text>
                <Text paragraphMarginSt>
                  En esta política de privacidad, en adelante, ("​{' '}
                  <Enfatize>Política de Privacidad​</Enfatize> "), le mostraremos cómo Morpheus
                  Aiolos S.L. recopila, usa, divulga y protege la Información Personal que usted
                  como usuario nos envía al acceder y usar todos los sitios web de Arcopay, de forma
                  voluntaria.
                </Text>
                <Text paragraphMarginSt>
                  Nuestra Política de Privacidad ha sido elaborada para cumplir con las normativa
                  aplicable, en particular, el Reglamento (UE) 2016/679 de 27 de abril de 2016
                  relativo a la protección de las personas físicas en lo que respecta al tratamiento
                  de datos personales y a la libre circulación de estos datos, así como, la Ley
                  Orgánica 3/2018 de 5 de diciembre, de Protección de Datos Personales y garantía de
                  los derechos digitales.{' '}
                </Text>
                <Text boldSt underLineSt paragraphMarginSt>
                  Identidad y dirección del Responsable de Tratamiento
                </Text>
                <Text paragraphMarginSt>
                  El Responsable del Tratamiento de los datos personales que resultan del servicio
                  prestado en este sitio web es Morpheus Aiolos S.L, titular de la marca Arcopay.
                  Los datos de contacto son los siguientes:
                </Text>

                <Text paragraphMarginSt>-Calle De San Andrés 8, local Madrid (Madrid), 28004.</Text>
                <Text paragraphMarginSt>-CIF: B86556420.</Text>
                <Text paragraphMarginSt>-Tel.: 911126709.</Text>
                <Text paragraphMarginSt>
                  -El correo electrónico a los efectos de facilitarle cualquier información o
                  aclaración en materia de protección de datos, es{' '}
                  <a
                    target="_blank"
                    without
                    rel="noopener noreferrer"
                    href="mailto:gdpr@arcopay.io"
                  >
                    gdpr@arcopay.io
                  </a>{' '}
                  Morpheus Aiolos S.L. es una Entidad de Pago debidamente autorizada y registrada en
                  el Banco de España para la prestación de los servicios de iniciación de pagos e
                  información sobre cuentas. Su número de registro en el Registro Oficial de
                  Entidades del Banco de España es 6901
                </Text>
                <Text boldSt underLineSt paragraphMarginSt>
                  Finalidad y legitimación del tratamiento
                </Text>
                <Text paragraphMarginSt>
                  A continuación, le mostramos las diferentes situaciones en las que recopilamos su
                  información personal, indicándole la finalidad y la base jurídica del tratamiento:
                </Text>
                <Text paragraphMarginSt>
                  (i) Cuando se registra en la aplicación de Arcopay para recibir un servicio de
                  iniciación de pago o de información sobre cuenta, recopilamos los datos necesarios
                  para la prestación de los mencionados servicios. El tratamiento es necesario para
                  que podamos llevar a cabo de manera efectiva la prestación de nuestros servicios a
                  usted. La base jurídica es el consentimiento que se otorga a través de las
                  casillas habilitadas a tal efecto.
                </Text>
                <Text paragraphMarginSt>
                  (ii) Cuando usted haya sido redireccionado a cualquier site de la web de Morpheus
                  Aiolos porque esté utilizando un servicio a través de otra empresa que sea nuestro
                  cliente. El tratamiento es necesario para que los servicios prestados sean
                  efectivos. La base jurídica es el consentimiento que usted otorga previamente a
                  través de las casillas habilitadas a tal efecto.
                </Text>
                <Text paragraphMarginSt>
                  (iii) Cuando a través del chat de Arcopay, recopilamos su nombre, el nombre de su
                  empresa, su correo electrónico, y otra información de contacto. Estos datos serán
                  tratados con la finalidad de ponernos en contacto con usted a través de su
                  solicitud. La base jurídica de este tratamiento es el consentimiento que recibimos
                  de su propia solicitud.
                </Text>
                <Text paragraphMarginSt>
                  (iv) Cuando accede a la web de Morpheus Aiolos S.L., nosotros recibimos de usted
                  datos técnicos de información a través de la aceptación de las cookies. La
                  finalidad de este tratamiento es el diagnóstico de cualquier problema técnico,
                  preferencias personales, estadísticas de uso,etc. En la política de cookies de
                  Morpheus Aiolos S.L., se puede conocer el detalle de las cookies que usa Morpheus
                  Aiolos S.L., así como la manera en la que usted puede controlar ese tratamiento.
                  La base jurídica de este tratamiento es el consentimiento que usted presta al
                  aceptar el uso de cookies.
                </Text>
                <Text paragraphMarginSt>
                  Conforme al consentimiento otorgado, se le informa al usuario que tiene pleno
                  derecho a retirar el consentimiento, sin que ello afecte a la licitud del
                  tratamiento basado en el consentimiento previo a su retirada
                </Text>

                <Text boldSt underLineSt paragraphMarginSt>
                  Plazo de conservación de la información personal.
                </Text>
                <Text paragraphMarginSt>
                  El plazo de conservación de la información personal que almacenamos de usted, se
                  basará en el motivo por el que la hayamos recopilado. En concreto, conforme a lo
                  siguiente:
                </Text>
                <Text paragraphMarginSt>
                  (i) Cuando haya accedido con motivo de iniciar una solicitud de información a
                  través del chat o cualquier otro apartado así dispuesto en la web de Morpheus
                  Aiolos S.L., conservaremos sus datos hasta que la relación entre nosotros haya
                  cesado.
                </Text>
                <Text paragraphMarginSt>
                  (ii) Cuando se registre en la aplicación de Morpheus Aiolos S.L., conservaremos
                  sus datos hasta que decida darse de baja de la misma.
                </Text>
                <Text paragraphMarginSt>
                  (iii) Cuando usted haya sido redireccionado con la finalidad de obtención de otro
                  servicio por otra empresa que es nuestro cliente, conservaremos sus datos durante
                  el periodo necesario estipulado en los acuerdos con nuestros clientes.
                </Text>

                <Text boldSt underLineSt paragraphMarginSt>
                  Destinatarios de cesiones o transferencias internacionales de datos.
                </Text>
                <Text paragraphMarginSt>
                  No se prevé la cesión o transferencia de los datos de carácter personal a
                  destinatarios internacionales.
                </Text>
                <Text paragraphMarginSt>
                  En el caso de que transfiramos en un futuro información personal a países fuera
                  del Espacio Económico Europeo, utilizaremos las medidas de seguridad adecuadas
                  para la protección de su privacidad, derechos y libertades fundamentales, y se lo
                  notificaremos con la actualización de la presente política de privacidad.
                </Text>
                <Text boldSt underLineSt paragraphMarginSt>
                  Derechos que le asisten y formas de ejercicio.
                </Text>
                <Text>
                  Le informamos que usted tiene una serie de derechos que puede ejercer poniéndose
                  en contacto con Morpheus Aiolos S.L., como Responsable de tratamiento, en
                  concreto: derecho de acceso, derecho de supresión, derecho de limitación de su
                  tratamiento, derecho de oposición, derecho de revocación, derecho a la
                  portabilidad de sus datos y derecho a retirar el consentimiento prestado.
                </Text>
                <Text paragraphMarginSt>
                  La forma en la que puede ejercer estos derechos es remitiendo un correo
                  electrónico a la dirección{' '}
                  <a
                    target="_blank"
                    without
                    rel="noopener noreferrer"
                    href="mailto:gdpr@arcopay.io"
                  >
                    gdpr@arcopay.io
                  </a>
                  , con el asunto “datos personales”. Igualmente, tiene derecho a reclamar ante la
                  Autoridad de Control.
                </Text>
                <Text boldSt underLineSt paragraphMarginSt>
                  Revisión de la Política de Privacidad
                </Text>
                <Text paragraphMarginSt>
                  Morpheus Aiolos S.L. realizará una revisión de la Política de Privacidad
                  anualmente, salvo que existan exigencias normativas o de otra índole que hagan
                  necesario adaptar la Política con una periodicidad inferior. Por tanto, se
                  aconseja a los usuarios que consulten la Política en cada una de las ocasiones que
                  vaya a acceder o utilizar páginas, websites o herramientas web propiedad de
                  Morpheus Aiolos S.L. o, al menos, consulte periódicamente su contenido
                </Text>
                <Text>
                  <Enfatize boldSt>{'Fecha actualización: '}</Enfatize> 27 de noviembre de 2020.
                </Text> */}

                <View ref={lastPlanElementRef} style={{ height: 10 }}>
                  <View ref={faqRef} />
                </View>
              </View>

              <ArrowForwardIosIcon
                onClick={() => {
                  faqRef.current.scrollIntoView({ behavior: 'smooth' });
                }}
                style={{
                  position: 'fixed',
                  bottom: 15,
                  right: 15,
                  width: 20,
                  height: 'auto',
                  transform: 'rotate(90deg)',
                  opacity: isScroll ? 1 : 0,
                  cursor: 'pointer',
                }}
              ></ArrowForwardIosIcon>
              <Button
                style={{ marginTop: 10, position: 'fixed', bottom: 5 }}
                borderWhiteButtonSt
                disabled={!!buttonDisabled}
                onClick={() => {
                  onClick();
                  onClose();
                }}
              >
                Aceptar
              </Button>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default LegalModal;
