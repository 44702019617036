import React from 'react';

export default function CheckMark(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.63"
      height="37.515"
      viewBox="0 0 35.63 37.515"
      style={{ ...props.style }}
    >
      <defs>
        <style></style>
      </defs>
      <path
        className="a"
        d="M12.5,36.92a3.874,3.874,0,0,1-1.667-.367,3.3,3.3,0,0,1-1.3-1.1L0,22.78a3.533,3.533,0,0,1-.73-2.749A3.429,3.429,0,0,1,.73,17.6a3.489,3.489,0,0,1,2.735-.733,3.644,3.644,0,0,1,2.475,1.414L12.191,26.6,27.978,1.152A3.439,3.439,0,0,1,30.271-.5a3.559,3.559,0,0,1,2.813.5,3.547,3.547,0,0,1,1.667,2.3,3.526,3.526,0,0,1-.469,2.827L15.631,35.191a3.607,3.607,0,0,1-1.25,1.257,3.267,3.267,0,0,1-1.72.471H12.5Z"
        transform="translate(0.767 0.596)"
      />
    </svg>
  );
}
