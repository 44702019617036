import componentsConfig from "./components";
import colors from "./colors";
import fonts from "./fonts";

export default {
  ...componentsConfig,
  fontFamily: fonts,
  colors: {
    defaultFontColor: colors.white,
    main: colors.white,
    secondary: colors.white,
  },
};
