import React, { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import {
  UiWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  NavigationWrapper,
  TextField,
  Image,
  TargetsWrapper,
  RulesWrapper,
  UserWrapper,
  RuleDefinitionsWrapper,
  Page,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';
import ErrorIcon from '../../../common/svgs/Warning';

// images
import ClockWhite from '../../../assets/images/ClockWhite.png';
import CheckMark from '../../../common/svgs/CheckMark';
import Back from '../../../common/svgs/Back';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

// libs
import { amountStringToNumber, onChangeAmountHandler } from '../../../libs/currencyInput';
import RulesTooltip from 'common/RulesTooltip';

import { LoadingData } from 'common/LoadingData';
import { setCurrency, getCurrency } from 'libs/Currency';
import * as Types from 'data/types';
import { useRuleActivation } from '../3_Rules/hooks/useRuleActivation';

const ruleIdentifier = Types.PERIODIC_SAVING;

export default PickerHOC(
  {
    ruleDefinition: {
      arrayItemMatcher: {
        type: 'value',
        value: ruleIdentifier,
        itemPropertyPath: 'identifier',
      },
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const {
    query: { from },
  } = Page.use();

  const { target, ruleDefinition, user } = PickerHOC.use();
  const { getRule, extractValue, desactivateRule, activateRule, upsertRule } = RulesWrapper.use();

  const { useBackground, useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();

  const rule = getRule(ruleIdentifier, target.id);

  const [amount, setAmount] = useState(getCurrency(0, 2, true));
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const lastRef = useRef(null);
  const observer = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [activeRule, setActiveRule] = useState(ruleDefinition.required || false);

  useEffect(() => {
    if (rule) {
      setAmount(getCurrency(extractValue(rule, 'periodic_amount') || 0, 2, true));
      setActiveRule(rule.isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule?.id]);

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );
  console.log(' FROM IN PERIODIC', !!from, from);
  const activateAndContinue = useRuleActivation(
    ruleIdentifier,
    rule,
    target.id,
    user.id,
    !!from,
    routes.ruleInfo,
  );
  const handleNext = useCallback(
    async (isActive) => {
      const val = setCurrency(amount);
      await activateAndContinue(isActive, [
        {
          identifier: 'periodic_amount',
          data: { value: Number(val) },
        },
        // {
        //   identifier: 'periodic_chargeAccount',
        //   data: { value: account },
        // },
        {
          identifier: 'periodic_period',
          data: { value: 'monthly' },
        },
        {
          identifier: 'periodic_start',
          data: { value: moment().toISOString() },
        },
      ]);
    },
    [amount, activateAndContinue],
  );

  useLoading(false);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        left={
          <View autoWidthSt style={{ marginLeft: 18 }} onClick={() => goBack()}>
            <Back style={{ fill: colors.white, width: 11, height: 'auto' }} />
          </View>
        }
        style={{ backgroundColor: colors.red }}
        right="none"
      />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.red }}>
      <View style={{ padding: 25 }}>
        <Text boldSt font15St whiteSt style={{ marginBottom: 20 }}>
          {' '}
          ¿Quieres realizar esta aportación?{' '}
        </Text>
        <View horizontalSt>
          <View
            pointerSt
            style={{
              height: 54,
              width: 54,
              borderRadius: '50%',
              background: colors.white,
              marginRight: 32,
            }}
            onClick={() => handleNext(false)}
          >
            <View
              autoWidthSt
              boldSt
              style={{
                fontSize: 45,
                color: colors.red,
                transform: 'rotate(45deg)',
                paddingBottom: 10,
              }}
            >
              +
            </View>
          </View>
          <View
            pointerSt
            onClick={() => {
              const val = Number(setCurrency(amount));
              // if (!val || disabled) return;
              if (!val) return;
              if (val > 600) return;
              handleNext(true);
            }}
            style={{ height: 54, width: 54, borderRadius: '50%', background: colors.white }}
          >
            <CheckMark style={{ width: 20, height: 'auto', fill: colors.green }} />
          </View>
        </View>
      </View>
    </Footer>,
    [handleNext, amount, disabled],
  );

  useBackground(colors.red);

  return (
    <Layout.Container>
      <ScrollArrow
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View
        startSt
        fullHeightSt
        style={{ backgroundColor: colors.red, paddingTop: 10, paddingBottom: 10 }}
      >
        <View width79St>
          <Image src={ClockWhite} style={{ width: 80, height: 'auto' }} />

          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <Text font27St boldSt whiteSt style={{ textAlign: 'center', marginRight: 5 }}>
              Aportación periódica
            </Text>
            <RulesTooltip
              content={
                <Text style={{ fontSize: 14 }}>
                  ¿Qué tal si cada mes destinas una cantidad fija para tu objetivo y darle un
                  impulso extra? El primer día del mes se aportará a tu póliza la cantidad que
                  elijas.
                  {/* <Text style={{ fontSize: 14, marginTop: 10 }}>
                    Recuerda que el mínimo para esta aportación es 30€.
                  </Text> */}
                </Text>
              }
            ></RulesTooltip>
          </View>
          <Text textCenterSt font15St whiteSt lineHeight16St style={{ marginTop: 27 }}>
            Representa el complemento fijo del ahorro mensual que quieres añadir para alcanzar tu
            objetivo cuanto antes.
          </Text>
          <Text font15St boldSt textCenterSt whiteSt style={{ marginTop: 20, width: 300 }}>
            Pon el importe que deseas ahorrar de manera periódica
          </Text>
          <View style={{ marginTop: 30 }}>
            {ruleDefinition.fieldDefinitions.map((ruleDefinition) => {
              if (ruleDefinition.identifier === 'periodic_amount') {
                // const errorRule = value < Number(ruleDefinition.metadata.min);

                return (
                  <View>
                    <TextField
                      createInputSt
                      ruleTransparentBackgroundSt
                      onChange={(e) => {
                        // if (
                        //   Number(setCurrency(e.target.value)) <
                        //     Number(ruleDefinition.metadata.min) &&
                        //   !disabled
                        // ) {
                        //   setDisabled(true);
                        // } else {
                        //   setDisabled(false);
                        // }
                        onChangeAmountHandler(e.target.value, setAmount);
                      }}
                      value={amount}
                      startAdornment={
                        <View
                          style={{
                            backgroundColor: colors.white,
                            height: 20,
                            width: 20,
                            borderRadius: '50%',
                            opacity: amount ? 1 : 0.7,
                          }}
                        >
                          <CheckMark style={{ fill: colors.red, height: 8, width: 'auto' }} />
                        </View>
                      }
                      endAdornment={
                        <Text style={{ paddingTop: 2, marginLeft: 5, color: colors.white }}>
                          {' '}
                          €
                        </Text>
                      }
                    />

                    {Number(setCurrency(amount)) > 600 && (
                      <View horizontalSt style={{ marginTop: 5, justifyContent: 'flex-end' }}>
                        <ErrorIcon style={{ fill: 'white', marginRight: 2, height: 12 }} />
                        <Text font15St style={{ color: 'white' }}>
                          El importe máximo es de {getCurrency(600, 0, false)}
                        </Text>
                      </View>
                    )}
                  </View>
                );
              } else return null;
            })}
          </View>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});
