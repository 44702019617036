import { UiWrapper } from '@coinscrap/webapp-core';
import colors from "../colors";

UiWrapper.defineComponent("imageSelector", {
  styles: {
    default: {
      imageComponent: { margin: 15 },
      cameraComponent: {
        height: 50,
        width: 50,
        color: colors.yellow,
      },
      buttonComponent: {
        width: "auto",
        minWidth: 150,
        border: "1px solid " + colors.yellow,
        backgroundColor: "transparent",
        borderRadius: 30,
        paddingTop: 15,
        paddingBottom: 15,
        textComponent: {
          textTransform: "uppercase",
          color: colors.white,
          fontSize: "16px",
        },
        disabledMode: {
          border: "1px solid " + colors.grey,
          textComponent: {
            color: colors.grey,
          },
        },
      },
    },

    circleSt: {
      imageComponent: {
        width: 78,
        height: 78,
        minHeight: 78,
        borderRadius: 100,
        boxShadow: 0,
        borderColor: colors.greyLightBorders2,
        borderWidth: 1.4,
        borderStyle: "solid",
      },
      buttonComponent: {
        display: "none",
      },
    },
  },
});
