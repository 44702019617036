import {
  FinancialWrapper,
  InstitutionsWrapper,
  NavigationWrapper,
  PickerHOC,
  SoftSwitcher,
  TargetsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';

import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Splash from 'common/Splash';
import routes from 'config/routes';
import React, { useEffect, useState } from 'react';

const FetchPolicies = PickerHOC(
  {
    parentTarget: {
      arrayItemMatcher: {
        allowNoExistence: true,
        itemPropertyPath: 'level',
        type: 'value',
        value: 0,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    generaliInstitution: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        value: 'generali',
      },
      sourcePath: 'institutions',
      sourceWrapper: InstitutionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    generaliUserIdentityProvider: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        value: 'generali',
      },
      sourcePath: 'user.identityProviders',
    },
  },
  () => {
    UiWrapper.use().useLoading(true);
    return null;
  },
)(() => {
  const { useLoading } = UiWrapper.use();
  const { useHeader } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();
  const {
    createOrUpdateCredentialsInstitution,
    updateInstitutionAccountProducts,
  } = FinancialWrapper.use();

  const { generaliInstitution, generaliUserIdentityProvider, parentTarget } = PickerHOC.use();
  const token = generaliUserIdentityProvider.externalTokens[0].sessionData.generaliToken;
  const [error, setError] = useState(false);

  const [institutionUpserted, setInstitutionUpserted] = useState();

  const policySelected = parentTarget?.creationData?.destProduct?.combinedId;

  useEffect(() => {
    (async () => {
      try {
        const response = await createOrUpdateCredentialsInstitution(
          generaliInstitution.identifier,
          {
            method: generaliInstitution.authenticationMethods[0].identifier,
            values: {
              token: token,
            },
          },
          { mode: 'AWAIT_FULL_PROCESS' },
        );
        if (response.status !== 'SUCCESS' || !response.institutionAccountId) {
          throw new Error('Error ocurred validating credentials');
        }
        setInstitutionUpserted(response.institutionAccountId);
      } catch (e) {
        console.log('Error', e);
        setError(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generaliInstitution?.identifier]);
  useEffect(() => {
    (async () => {
      if (institutionUpserted) {
        try {
          await updateInstitutionAccountProducts(institutionUpserted, {
            mode: 'AWAIT_FULL_PROCESS',
          });
          if (policySelected) {
            navigateTo(routes.createGlobalTarget, {
              routeParams: {
                institutionAccountId: policySelected.split('-')[0],
                productId: policySelected.split('-')[1],
              },
            });
          } else {
            navigateTo(routes.selectPolicy, {
              clearItems: ['refId'],
            });
          }
        } catch (e) {
          console.log('Error', e);
          setError(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionUpserted, policySelected]);

  useBackRoute(undefined);
  useLoading(false);

  useHeader(
    <View>
      <HeaderRedPrimary />
    </View>,
    [],
  );

  console.log({ error });
  return (
    <View style={{ marginTop: 40 }}>
      <SoftSwitcher evaluate={error}>
        <>
          {/* <Text font27St>Recuperando datos</Text>
          <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 8, padding: 10 }}>
            Estamos obteniendo los datos de tus pólizas para poder continuar.
          </Text> */}
          <Splash />
        </>
        <>
          <Text font27St>Error</Text>
          <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 8, padding: 10 }}>
            No hemos podido recuperar los datos de sus pólizas. Por favor inténtelo de nuevo mas
            tarde.
          </Text>
        </>
      </SoftSwitcher>
    </View>
  );
});

export default FetchPolicies;
