import React from 'react';

import { EntityWrappers } from './WrapEntitiesHandlers';
import {
  ApplicationWrapper,
  NavigationWrapper,
  BackendWrapper,
  SessionWrapper,
  UiWrapper,
  InAppBrowserWrapper,
} from '@coinscrap/webapp-core';

import config from '../config';

const WrappersComponent = ({ children }) => {
  return (
    <ApplicationWrapper>
      <InAppBrowserWrapper>
        <BackendWrapper
        // enableNativeBridge
        //defaultBaseUrl={'https://cents-dev.coinscrap.com/'}
        >
          <UiWrapper config={config}>
            <NavigationWrapper>
              <SessionWrapper
              // entryPoint={{
              //   pageIfValid: routes.tokenEntry,
              //   pageIfInvalid: routes.tokenError,
              // }}
              >
                <EntityWrappers>{children}</EntityWrappers>
              </SessionWrapper>
            </NavigationWrapper>
          </UiWrapper>
        </BackendWrapper>
      </InAppBrowserWrapper>
    </ApplicationWrapper>
  );
};

export default WrappersComponent;
