import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import colors from '../config/colors';
import QuestionMark from './svgs/QuestionMark';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NavigationWrapper } from '@coinscrap/webapp-core';
import { View, Text } from '@coinscrap/webapp-core';
import FAQsModal from './FAQsModal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import routes from 'config/routes';
import InfoSvg from 'common/svgs/Info';

const Header = ({ left, center, right, style, goBackAction, hideFAQ }) => {
  const [isOpenFAQsModal, setIsOpenFAQsModal] = useState(false);
  const [openTippy, setOpenTippy] = useState(false);
  const { goBack, navigateTo } = NavigationWrapper.use();

  const atras = () => (
    <View horizontalSt style={{ marginLeft: 17 }} onClick={goBackAction || goBack}>
      <ArrowBackIcon style={{ height: 25, width: 'auto' }} />
      {/* <Text font13St style={{ marginLeft: 2 }}>
        Atrás
      </Text> */}
    </View>
  );
  return (
    <>
      <View
        relativeSt
        grid3St
        horizontalSt
        justifyCenterSt
        style={{ background: colors.grey3, minHeight: 60, ...style }}
      >
        <View autoWidthSt justifySelfStartSt pointerSt>
          {left === 'atras' ? atras() : left}
        </View>
        <View selectNoneSt>
          <Text boldSt style={{ fontSize: 15, textAlign: 'center' }}>
            {center ? center : ''}
          </Text>
        </View>
        <View
          justifySelfEndSt
          autoWidthSt
          style={{
            height: 30,
            width: 30,
            borderRadius: '50%',
            marginRight: 17,
          }}
        >
          {right ? (
            right === 'none' ? null : (
              right
            )
          ) : (
            <Tippy
              animation="fade"
              arrow={true}
              theme="light-border"
              trigger="click"
              interactive="true"
              placement="top"
              appendTo="parent"
              visible={openTippy}
              onClickOutside={() => setOpenTippy(false)}
              content={
                <View horizontalSt spaceBetweenSt style={{ width: 80 }}>
                  <View
                    autoWidthSt
                    alignCenterSt
                    spaceBetweenSt
                    style={{ height: 50, cursor: 'pointer' }}
                  >
                    <QuestionMark
                      style={{
                        visibility: hideFAQ ? 'hidden' : 'visible',
                      }}
                      onClick={() => {
                        setIsOpenFAQsModal(true);
                        setOpenTippy(false);
                      }}
                    />
                    <Text style={{ marginTop: 2, fontSize: 13 }}>FAQs</Text>
                  </View>
                  <View
                    autoWidthSt
                    alignCenterSt
                    spaceBetweenSt
                    style={{ height: 50, cursor: 'pointer' }}
                  >
                    <InsertDriveFileIcon
                      onClick={() => {
                        navigateTo(routes.documents);
                      }}
                      style={{ fontSize: 28 }}
                    />
                    <Text style={{ marginTop: 2, fontSize: 13 }}>Docs</Text>
                  </View>
                </View>
              }
            >
              <div onClick={() => setOpenTippy(true)}>
                <InfoSvg
                  style={{
                    height: 25,
                    width: 'auto',
                    cursor: 'pointer',
                    visibility: hideFAQ ? 'hidden' : 'visible',
                  }}
                />
              </div>
            </Tippy>
          )}
        </View>
      </View>
      <Modal
        disablePortal
        open={isOpenFAQsModal}
        onClose={() => {
          setIsOpenFAQsModal(false);
        }}
      >
        <View style={{ backgroundColor: colors.white }}>
          <FAQsModal setIsOpenFAQsModal={setIsOpenFAQsModal}></FAQsModal>
        </View>
      </Modal>
    </>
  );
};

export default Header;
