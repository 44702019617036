import { NavigationWrapper, Page } from '@coinscrap/webapp-core';
import routes from "config/routes";
import { OTP_ACTIONS } from "libs/common";
import { useCallback } from "react";
import { useRuleController } from "./useRuleController";

export const useRuleActivation = (ruleIdentifier, rule, targetId, userId, withOtp, nextRoute) => {
  const {
    query: { from },
  } = Page.use();
  console.log(" FROM INTERNALLU", withOtp, from)
  const continueHandler = useCallback(() => {
    if (nextRoute) {
      console.log(" NAVIGATING TYO NEXT ROUTE ", nextRoute, withOtp)
      /*from
        ? goBack()
        : */navigateTo(nextRoute, {
        mode: withOtp ? 'replace' : 'push',
        routeParams: {
          idTarget: targetId,
        },
        handleRedirection: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetId]);

  const { navigateTo, goBack } = NavigationWrapper.use();
  const ruleHandler = useRuleController(ruleIdentifier, rule, targetId, userId);
  return useCallback(withOtp && from ? ((isActive, value) => {
    console.log(" FORDWARDING", isActive, from);
    if (!isActive && !rule?.active) {
      goBack();
    } else {
      console.log("REPLACING", isActive, from);
      navigateTo(routes.otpSms, {
        mode: 'replace',
        queryParams: {
          action: OTP_ACTIONS.TOGGLE_RULE,
          payload: {
            targetId: targetId,
            ruleIdentifier,
            toggle: isActive,
            ruleValue: value || []
          },
          from,
        },
      });
    }
  }) :
    async (isActive, value) => {
      await ruleHandler(isActive, value || [])
      continueHandler && continueHandler();
    },
    [continueHandler, userId, rule?.id, targetId, from],
  );

}