import React, { useRef, useCallback, useState } from 'react';

import { UiWrapper, View, Text, Button } from '@coinscrap/webapp-core';

// components
import { CloseButton } from 'common/closeButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Modal from '@material-ui/core/Modal';
// configs
import colors from 'config/colors';

const LegalModal = ({ isOpen, onClose, children, onClick }) => {
  const faqRef = useRef();
  const { useBackground } = UiWrapper.use();
  useBackground(colors.white);
  const [isScroll, setIsScroll] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const observer = useRef();

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setButtonDisabled(false);
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <View style={{ backgroundColor: colors.white, overflowY: 'auto' }}>
        <View spaceBetweenSt style={{ height: '100vh' }}>
          <View>
            <View horizontalSt endSt style={{ marginTop: 20 }}>
              <CloseButton
                color={colors.dark}
                action={() => {
                  onClose();
                }}
              />
            </View>

            <View style={{ padding: 10 }}>
              {/* <Text boldSt underLineSt font16St style={{ marginBottom: 10, paddingTop: 10 }}>
                POLÍTICA DE PRIVACIDAD DE Morpheus Aiolos S.L.
              </Text> */}

              <View
                startSt
                topSt
                style={{ overflowY: 'scroll', height: '65vh', maxWidth: 850, paddingBottom: 100, justifyContent: 'space-between' }}
              >
                <View>
                  <Text paragraphMarginSt>
                    Con carácter revocable autorizo a Morpheus Aiolos a la utilización de mis credenciales bancarias para la prestación de un servicio de información sobre cuentas de pagos necesario para facilitar y agregar funcionalidades al servicio de “My Pocket” que previamente he contratado con Generali España S.A., de Seguros y Reaseguros (en adelante “Generali”).

                  </Text>
                  <Text paragraphMarginSt>
                    Le informamos que tiene pleno derecho a retirar el consentimiento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
                  </Text>

                </View>

                <View>



                  <View ref={lastPlanElementRef} style={{ height: 10 }}>
                    <View ref={faqRef} />
                  </View>

                </View>
              </View>
            </View>
            <ArrowForwardIosIcon
              onClick={() => {
                faqRef.current.scrollIntoView({ behavior: 'smooth' });
              }}
              style={{
                position: 'fixed',
                bottom: 15,
                right: 15,
                width: 20,
                height: 'auto',
                transform: 'rotate(90deg)',
                opacity: isScroll ? 1 : 0,
                cursor: 'pointer'
              }}
            ></ArrowForwardIosIcon>

            <Button
              style={{ marginTop: 10, position: 'fixed', bottom: 5 }}
              borderWhiteButtonSt
              disabled={!!buttonDisabled}
              onClick={() => {
                onClick();
                onClose();
              }}
            >
              Aceptar
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default LegalModal;
