import { RulesWrapper } from '@coinscrap/webapp-core';

export const useRuleController = (ruleIdentifier, rule, targetId, userId) => {
  const { desactivateRule, activateRule, upsertRule } = RulesWrapper.use();
  return async (isActive, value) => {
    console.log(" REQUESTING SAVING VALUE", value)
    if (!ruleIdentifier) return
    if (isActive) {
      console.log('## HANDLING 1');
      try {
        if (rule && !rule.isActive) {
          await activateRule(ruleIdentifier, targetId);
        }
        if (value) {
          await upsertRule(ruleIdentifier, userId, targetId, value);
        }
      } catch (e) {
        console.log('## ERROR', e);

        return;
      }
    } else {
      console.log('## HANDLING 2', rule);
      try {
        if (rule) {
          console.log('## HANDLING 2.1', rule);
          await desactivateRule(ruleIdentifier, targetId);
        }
      } catch (e) {
        console.log('## ERROR', e);

        return;
      }
    }
  }

}