import React from 'react';

import { UiWrapper, View, Text, PickerHOC } from '@coinscrap/webapp-core';

// images
import Arrow1 from '../../../assets/images/Arrow1.png';

// configs
import colors from '../../../config/colors';

export default PickerHOC()(({ section, onChange, backdrop }) => {
  const { useBackground } = UiWrapper.use();

  useBackground(colors.white);

  return (
    <View horizontalSt style={{}}>
      <View
        onClick={() => onChange('resumen')}
        flexGrowSt
        autoWidthSt
        style={{ height: 67, position: 'relative' }}
        homeSectionBorderBottomGreySt
        homeSectionBorderBottomSt={section === 'resumen'}
        pointerSt
      >
        <View autoWidthSt style={{ position: 'relative' }}>
          <Text
            tabTextSt
            style={{ fontSize: 15, color: section === 'resumen' ? colors.dark : colors.grey1 }}
            boldSt={section === 'resumen'}
          >
            Resumen
          </Text>
          <View>
            {backdrop && (
              <View boldSt tutorialMenuASt>
                Selector menú superior
                <View autoWidthSt tutorialMenuBSt>
                  <img src={Arrow1} style={{ width: 40 }} alt="" />
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
      <View
        onClick={() => onChange('reglas')}
        flexGrowSt
        autoWidthSt
        style={{ height: 67 }}
        homeSectionBorderBottomGreySt
        homeSectionBorderBottomSt={section === 'reglas'}
        pointerSt
      >
        <Text
          tabTextSt
          style={{ fontSize: 15, color: section === 'reglas' ? colors.dark : colors.grey1 }}
          boldSt={section === 'reglas'}
        >
          Reglas
        </Text>
      </View>
      {/* <View
        onClick={() => setSection('asesor')}
        flexGrowSt
        autoWidthSt
        style={{ height: 67 }}
        homeSectionBorderBottomGreySt
        homeSectionBorderBottomSt={section === 'asesor'}
      >
        <Text
          tabTextSt
          style={{ fontSize: 15, color: section === 'asesor' ? colors.dark : colors.grey1 }}
          boldSt={section === 'asesor'}
        >
          Asesor
        </Text>
      </View> */}
    </View>
  );
});
