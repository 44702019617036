import React from 'react';

import { Enfatize, Text } from '@coinscrap/webapp-core';

const faqsData = [
  {
    title: 'Empezando',
    questions: [
      {
        question: '¿Qué es GENERALI My Pocket?',
        answer: `Es una solución digital fácil y sencilla para ayudarte a ahorrar en tu día a día, sin que tu
          estilo de vida se vea resentido. Para empezar a ahorrar solo tienes que configurar tu
          objetivo y elegir qué reglas de ahorro quieres utilizar. Así ahorrarás sin darte cuenta. A
          través de las reglas que tú elijas se generará un ahorro virtual que, cada viernes si llegas
          al mínimo de 40€ acumulado, se cargará en la cuenta asociada a tu póliza
          transfiriéndose así en tu Seguro de Ahorro e Inversión de Generali.`,
      },
      {
        question: '¿Por qué darme de alta en GENERALI My Pocket?',
        answer: `Porque queremos que cumplas tus sueños y ahorrar para ellos no debe ser una tarea
          complicada.`,
      },
      {
        question: '¿Es seguro ahorrar con GENERALI My Pocket?',
        answer: `Todo el ahorro generado en GENERALI My Pocket se acumula en tu Seguro de Ahorro e Inversión de Generali, entidad autorizada y supervisada por la Dirección General de
          Seguros y Fondos de Pensiones (DGSFP) que hayas seleccionado para utilizar en este
          servicio.`,
      },
      {
        question: '¿Qué requisitos debo cumplir para usar GENERALI My Pocket?',
        answer: `Para  utilizar  GENERALI  My  Pocket  debes  tener  contratado  GENERALI  Multinversión  Fácil, GENERALI  Equilibrio  Protección  Plus,  GENERALI  Multinversión  Selección  y/o  GENERALI Fondoselección Flexible. Además, tendrás que estar registrado MI GENERALI y tener claves de banca electrónica en alguno de los bancos disponibles.`,
      },
      {
        question: '¿Cómo me doy de alta?',
        answer: `Para darte de alta en GENERALI My Pocket necesitarás acceder desde el banner situado en la pantalla principal o desde el botón que encontrarás en el detalle de tu producto de inversión dentro de la App MI GENERALI o desde la versión de escritorio. Haz clic en cualquiera de los accesos y sigue los pasos para darte de alta ¡Es muy sencillo!`,
      },
      {
        question: '¿Cómo funciona?',

        answerInJsx: (
          <>
            <Text faqTextSt>El funcionamiento es muy sencillo:</Text>
            <Text faqTextSt>
              1. Selecciona qué objetivo quieres conseguir. Te pediremos que pongas nombre a tu
              objetivo, elijas la cantidad que deseas ahorrar y en cuánto tiempo quieres
              conseguirlo.
            </Text>
            <Text faqTextSt>
              2. Configura cómo quieres ahorrar: selecciona las reglas que quieras para ayudarte a
              conseguir tu objetivo entre todas las que ponemos a tu disposición. Cuanto más
              ahorres, antes conseguirás tus objetivos.
            </Text>
            <Text faqTextSt>
              3. Añade las cuentas corrientes y tarjetas de crédito donde quieres que apliquemos las
              reglas de ahorro. Para ello, en el proceso de configuración del objetivo una entidad
              de pagos autorizada por el Banco de España para prestar servicios de información sobre
              cuentas y servicios de iniciación de pagos (Morpheus Aiolos) recibirá las claves de tu
              banca electrónica. En ningún caso GENERALI recibirá esta información.
            </Text>
            <Text faqTextSt>
              4. Cada viernes GENERALI My Pocket revisará el importe acumulado de tu ahorro. Si este
              importe es superior a 40€ se transferirá automáticamente desde la cuenta que tengas
              asociada a tu producto de inversión (o al que hayas elegido en caso de tener varios).
              Si llegado el viernes la cantidad acumulada de ahorro es inferior a 40€ seguirás
              acumulando hasta llegar a esta cantidad. Una vez que llegues o traspases los 40€ y sea
              viernes, el ahorro acumulado se traspasará a tu Seguro de Ahorro e Inversión de
              Generali desde la cuenta asociada a tu producto
            </Text>
            <Text faqTextSt>5. Todo listo! GENERALI My Pocket ya está ahorrando por ti.</Text>
          </>
        ),
      },
    ],
  },
  {
    title: 'Objetivos',
    questions: [
      {
        question: '¿Cómo establecer un objetivo?',

        answerInJsx: (
          <>
            <Text faqTextSt>
              Los objetivos representan las características financieras para alcanzar tus deseos
              Dentro de cada objetivo puedes ajustar tus preferencias y alcanzarlo a tu propio
              ritmo. Para ello tienes que ajustar:
            </Text>
            <Text faqTextSt>
              * Precio (importe) correspondiente al producto o servicio que te quieres comprar, o el
              importe que quieres ahorrar.
            </Text>
            <Text faqTextSt>
              * Periodo: el periodo representa en cuánto tiempo deseas conseguir el importe elegido
              antes.
            </Text>
            <Text faqTextSt>
              * Aportación inicial: cantidad con la que quieres comenzar tu objetivo de ahorro. No
              es obligatorio, pero te ayudará a conseguir tus objetivos antes.
            </Text>
            <Text faqTextSt>
              * Aportaciones mensuales: representa el complemento fijo del ahorro mensual que
              quieres añadir para alcanzar tu objetivo cuanto antes (cuanto más aportes al mes,
              antes completarás el objetivo). Al igual que la aportación inicial, no es obligatoria.
            </Text>
          </>
        ),
      },
      {
        question: '¿Se puede añadir más de un banco a mi objetivo?',
        answer:
          '¡Por supuesto! La idea es ahorrar lo máximo en un tiempo mínimo, por lo que, si dispones de otra tarjeta o de otra cuenta bancaria a tu nombre, mejor aprovechar tus gastos para redondear e incrementar tus ahorros.',
      },
      {
        question: '¿Puedo añadir una tarjeta o una cuenta bancaria?',
        answer:
          'Queremos darte la oportunidad de elegir a qué ritmo quieres ahorrar. Por ello, te damos la posibilidad de sincronizar tu objetivo a una tarjeta de crédito, con el fin de usar las reglas de ahorro sólo en las transacciones realizadas con dicha tarjeta. Podrás igualmente sincronizar tu cuenta bancaria de tal manera que aplicaremos las reglas de ahorro sobre cualquier transacción domiciliada en tu cuenta. Así que para completar un objetivo lo antes posible es recomendable añadir una cuenta bancaria más que una tarjeta. Y por supuesto, cuantas más cuentas, más ahorras.',
      },
      {
        question: '¿Puedo hacer modificaciones sobre mi objetivo?',
        answer:
          'Sí, una vez hayas creado un objetivo podrás modificar el nombre, cantidad y plazo en el que lo deseas conseguir así como las reglas que has activado para lograrlo.',
      },
    ],
  },
  {
    title: 'Reglas',
    questions: [
      {
        question: '¿Qué reglas tienes disponibles?',
        answerInJsx: (
          <>
            <Text faqTextSt>
              <Enfatize faqTextSt>Redondeo: </Enfatize> GENERALI My pocket calcula la diferencia
              entre cualquier movimiento contable de cargo en la cuenta/s y/o tarjetas de pago
              seleccionadas y 1 €, 2€, 5 € o 10 € al alza, en función de la cantidad que el usuario
              escoja. Por ejemplo, si el usuario ha escogido redondear al euro más cercano y este
              realiza una compra de 1,25€, serán añadidos a su lista de redondeos los 0,75€ de
              diferencia. Si ha escogido redondear a los 2€ más cercanos, serán añadidos 1,75€ y así
              sucesivamente.
            </Text>

            <Text faqTextSt>
              <Enfatize faqTextSt>Ingresos: </Enfatize> GENERALI My Pocket calcula el 1 %, 2%, 5% o
              10% de todos los ingresos percibidos en la cuenta de pagos del usuario, para
              posteriormente aportarlos a la póliza de Ahorro e Inversión seleccionada.
            </Text>
            <Text faqTextSt>
              <Enfatize faqTextSt> Sin cafeína: </Enfatize> si el usuario activa esta Regla, se
              sumará a la hucha virtual la cantidad de 1 € por cada día sin haber consumido cafeína.
              En el caso de que el usuario no cumpla este objetivo, deberá acceder a GENERALI My
              pocket para informar que ese día, no se debe contabilizar la cantidad de 1 €
              anteriormente mencionada. Así, esta cantidad será restada del total de la cuantía
              acumulada a través de esta regla.
            </Text>
            <Text faqTextSt>
              <Enfatize faqTextSt>Sin nicotina: </Enfatize> si el usuario activa esta Regla, se
              sumará a la hucha virtual la cantidad de 1 € por cada día sin haber fumado un
              cigarrillo. En el caso de que el usuario no cumpla este objetivo, deberá acceder a
              GENERALI My Pocket para informar que ese día, no se debe contabilizar la cantidad de 1
              € anteriormente mencionada. Así, esta cantidad será restada del total de la cuantía
              acumulada a través de esta regla.
            </Text>
            <Text faqTextSt>
              <Enfatize faqTextSt>Equipo de Fútbol: </Enfatize> el usuario podrá elegir su equipo de
              fútbol de la Liga Santander, de tal manera que, por cada victoria en un partido
              oficial de este, se sume la cantidad de 1 € sobre la hucha de ahorro virtual.
            </Text>
          </>
        ),
      },
      {
        question:
          '¿Cuándo se añade mi ahorro acumulado al Seguro de Ahorro e Inversión de Generali?',
        answer:
          'Las reglas de ahorro se calculan semanalmente (cada viernes) y se cargarán en la cuenta de ahorro asociada al producto de ahorro e inversión que has elegido siempre que hayas alcanzado un mínimo de 40€. Si el mínimo no es alcanzado se intentará el cargo la semana siguiente y así sucesivamente hasta que el importe mínimo se cumpla. El cargo de las reglas se hará 2 días hábiles después del último viernes de cálculo de las reglas aproximadamente.',
      },
      {
        question: '¿Cómo pedir el reembolso de mis ahorros?',
        answer:
          'Debes pedir el reembolso de tu ahorro a través del canal habitual. Dirígete A tu mediador.',
      },
      {
        question: '¿Qué bancos y tarjetas pueden utilizarse?',
        answer: `Disponemos de acceso exclusivo a más de 95% de los bancos y proveedores de tarjetas operando en España. Aquí puedes encontrar la lista completa de los bancos y proveedores de tarjeta para ahorrar.
        Es importante distinguir entre tarjeta de débito vs tarjeta de crédito. Ofrecemos GENERALI My Pocket de redondeo solo con tarjetas de crédito, y no con tarjetas de débito. Si solo tienes una tarjeta de débito, siempre puedes configurar tu objetivo para hacer los redondeos y aplicar el resto de reglas a partir de la cuenta bancaria con la cual está vinculada dicha tarjeta, ya que en la cuenta se reflejan los mismos movimientos que en la tarjeta.
        `,
      },
      {
        question: '¿Puedo utilizar la misma cuenta para aplicar las reglas y para cobros?',
        answer: `El ahorro virtual provendrá de las tarjetas o cuentas que hayas vinculado en tu objetivo. Sin embargo, los traspasos se harán siempre desde la cuenta asociada a tu Seguro de Ahorro e Inversión de Generali.`,
      },
      {
        question: '¿Cómo conecto mi banco?',
        answer: `Para conectar tu banco debes conocer tu usuario y tu clave de banca electrónica, que según la entidad bancaria variará. Por ejemplo: si tu banco es el BBVA deberás introducir tu DNI de usuario y tu clave de acceso a la banca electrónica. Una vez hayas introducido tus credenciales, te aparecerán tus cuentas y tarjetas disponibles, por tanto, en ningún momento debes introducir tu número de tarjeta y tu PIN ya que aparecen automáticamente.`,
      },
    ],
  },
  {
    title: 'Productos de Ahorro',
    questions: [
      {
        question: '¿En dónde se acumula mi ahorro?',
        answer: `El ahorro de tus objetivos se acumula en una hucha virtual y se va traspasando a tu GENERALI Multinversión Fácil, GENERALI Equilibrio Protección Plus, GENERALI Multinversión Selección o GENERALI Fondoselección Flexible. Los traspasos del ahorro acumulado se realizarán desde la cuenta asociada a tu producto de ahorro e inversión. Ten en cuenta que cuando se trate de  un  seguro  Unit  Linked  el  importe  ahorrado  estará  sujeto  a  las  fluctuaciones  de  los mercados.
        `,
      },
      {
        question: '¿Puedo retirar el dinero ahorrado?',
        answer:
          'Si, por supuesto. En cualquier momento puedes retirar el ahorro que llevas acumulado. Revisa las Condiciones particulares de tu producto para mayor información sobre los gastos de rescate.',
      },

      {
        question:
          '¿Cuánto puedo aportar a mi Seguro de Ahorro e Inversión de Generali a través de My Pocket?',
        answer: `Dentro del servicio My Pocket, podrás acumular aportaciones por un máximo de 5.000€ al año. Si llegas a
          este máximo antes del 31 de diciembre, se detendrán las aportaciones a tu producto de forma automática y
          todo el ahorro acumulado a través de las reglas configuradas en tus objetivos hasta el 31 de diciembre será
          aportarlo a tu producto a partir del 1 de enero del siguiente año`,
      },
    ],
  },
  {
    title: 'Seguridad',
    questions: [
      {
        question: '¿Es seguro introducir mis claves bancarias?',
        answer: `Para poder calcular los redondeos y el % de tus ingresos necesitamos conocer tus movimientos. Por eso en GENERALI My Pocket el último paso es introducir tus claves bancarias. Estas claves son sólo de lectura y están encriptadas con máxima seguridad de 256 bits. Los datos nunca son almacenados en ningún dispositivo. La seguridad es una prioridad para nosotros.

        Para este proceso, una entidad de pagos autorizada por el Banco de España para prestar servicios de información sobre cuentas y servicios de iniciación de pagos  (Morpheus Aiolos) recibirá las claves de tu banca electrónica. En ningún caso GENERALI recibirá esta información.
        `,
      },
      {
        question: '¿Cómo se accede a mis movimientos?',
        answer:
          'Según la directiva europea (Payment Service Directive 2015/2366) los datos bancarios pertenecen a los titulares de las cuentas y no a los bancos, por lo que se puede habilitar a terceros el acceso a los mismos. Para que GENERALI My pocket tenga acceso a los movimientos de tus cuentas bancarias o tarjetas es necesario que previamente hayas aceptado los términos y condiciones del servicio. Los movimientos se obtienen a través de un proceso denominado webscraping, utilizado por empresas de renombre del sector Fintech.',
      },
    ],
  },
];

export default faqsData;
