import React, { useState } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Button,
  Image,
  InputCheck,
  Enfatize,
  UserWrapper,
} from '@coinscrap/webapp-core';

// components
import Footer from '../../common/Footer';
import HeaderRedPrimary from '../../common/HeaderRedPrimary';
import LegalModal from '../../common/LegalModal';
import TermsModal from '../../common/TermsModal';

// images
import Trophy from '../../assets/images/Trophy.png';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

// configs
import colors from '../../config/colors';
import routes from '../../config/routes';

export default PickerHOC()(() => {
  const { user, updateUser } = UserWrapper.use();
  const [rejectingComunications, setRejectingComunications] = useState();
  const [showLegalModal, setShowLegalModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);

  const { useBackground, useFooter, useHeader } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.white);

  useHeader(
    <View>
      <HeaderRedPrimary />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 10 }}>
      <Button
        style={{ marginTop: 10 }}
        borderWhiteButtonSt
        disabled={!user?.metadata?.termsAccepted || !user?.metadata?.policyAccepted}
        onClick={async () => {
          //await accept();
          //await updateUser({ metadata: { ...user?.metadata, consentInformed: true, consentDate: new Date() } })
          // navigateTo(routes.fetchPolicies)
          navigateTo(routes.signDocuments);
        }}
      >
        Siguiente
      </Button>
      {/* <Text font15St style={{ marginTop: 5, textDecoration: 'underline' }}>
        Quizás en otro momento
      </Text> */}
    </Footer>,
    [
      user?.metadata?.termsAccepted,
      user?.metadata?.policyAccepted,
      user?.metadata?.comunicationsAccepted,
    ],
  );

  return (
    <Layout.Container>
      <View width90St fullHeightSt justifyCenterSt style={{ paddingTop: 10 }}>
        <View>
          <View style={{ height: 80 }}>
            <Image src={Trophy} style={{ width: 'auto', height: 80 }} />
          </View>
          <View style={{ marginTop: 15 }}>
            <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
              Ahora que ya conoces un poco en qué consiste GENERALI My Pocket, ya estás listo para
              crear tu nuevo plan de ahorro. ¿Comenzamos?
            </Text>
          </View>
          <View startSt horizontalSt style={{ marginTop: 20, paddingRight: 10 }}>
            <InputCheck
              checkedIcon={<CheckBoxOutlinedIcon />}
              value={user?.metadata?.termsAccepted}
              onChange={() => {
                setShowLegalModal(true);
              }}
            />
            <Text
              pointerSt
              font13St
              // onClick={() => {
              //   setAcceptTerms(true);
              //   // setShowLegalModal(true);
              // }}
              onClick={() => {
                setShowLegalModal(true);
              }}
            >
              He leído y acepto los Términos y Condiciones de{' '}
              <Enfatize boldSt>GENERALI My Pocket</Enfatize>.
            </Text>
          </View>
          <View startSt horizontalSt style={{ marginTop: 20, paddingRight: 10 }}>
            <InputCheck
              checkedIcon={<CheckBoxOutlinedIcon />}
              value={user?.metadata?.policyAccepted}
              onChange={() => {
                setShowPolicyModal(true);
                // setAcceptPolicy(true);
              }}
            />
            <Text
              pointerSt
              font13St
              onClick={() => {
                setShowPolicyModal(true);
                // setAcceptPolicy(true);
              }}
            >
              He leído y acepto la política de privacidad de <Enfatize boldSt>GENERALI</Enfatize>.
            </Text>
          </View>
          <View startSt horizontalSt style={{ marginTop: 20, paddingRight: 10 }}>
            <InputCheck
              disabled={rejectingComunications}
              checkedIcon={<CheckBoxOutlinedIcon />}
              value={user?.metadata?.comunicationsAccepted}
              onChange={async () => {
                try {
                  setRejectingComunications(true);
                  await updateUser({
                    metadata: {
                      ...user?.metadata,
                      comunicationsAccepted: !user?.metadata?.comunicationsAccepted,
                      comunicationsDate: !user?.metadata?.comunicationsAccepted ? new Date() : '',
                    },
                  });
                } catch (e) {
                } finally {
                  setRejectingComunications(false);
                }
                //setShowProductsModal(true);
                // setAcceptProducts(true);
              }}
            />
            <Text
              pointerSt
              font13St
              onClick={async () => {
                setShowProductsModal(true);
                // setAcceptProducts(true);
              }}
            >
              Con carácter revocable, consiento recibir comunicaciones comerciales sobre otros
              productos y servicios de <Enfatize boldSt>GENERALI</Enfatize> en base a mi información
              de pagos.
            </Text>
          </View>
        </View>
        {/* <View style={{ marginBottom: 15 }}>
          <Button disabled={!accept} regularButtonSt onClick={() => navigateTo(routes.home)}>
            Siguiente
          </Button>
          <Text font15St style={{ marginTop: 30, textDecoration: 'underline' }}>
            Quizás en otro momento
          </Text>
        </View> */}
      </View>

      <TermsModal
        isOpen={showLegalModal}
        close={() => setShowLegalModal(false)}
        onClick={async () => {
          if (!user?.metadata?.termsAccepted) {
            await updateUser({
              metadata: { ...user?.metadata, termsAccepted: true, termsDate: new Date() },
            });
          }
        }}
      />
      <LegalModal
        isOpen={showPolicyModal}
        close={() => setShowPolicyModal(false)}
        onClick={async () => {
          if (!user?.metadata?.policyAccepted) {
            await updateUser({
              metadata: { ...user?.metadata, policyAccepted: true, policyDate: new Date() },
            });
          }
        }}
      />
      <LegalModal isOpen={showProductsModal} close={() => setShowProductsModal(false)}>
        <View width90St style={{ padding: 10, marginTop: 25 }}>
          <Text font15St style={{ textAlign: 'justify', lineHeight: 1.5 }}>
            Necesitamos que nos facilite su consentimiento para poder enviarle ofertas comerciales
            de nuestros productos y servicios por cualquier medio, incluyendo mediante
            comunicaciones electrónicas o equivalentes basadas en su información de cuentas de
            pagos. Su consentimiento no es obligatorio para la prestación del servicio de ahorro,
            por lo que usted es libre de facilitarlo sin que ello afecte la prestación del servicio
            de My Pocket. En el caso de que nos facilite su consentimiento, puede retirarlo en
            cualquier momento sin cualquier inconveniente o coste adicional para usted.
          </Text>
        </View>
        <Button
          style={{ marginTop: 10, position: 'fixed', bottom: 5 }}
          borderWhiteButtonSt
          onClick={async () => {
            if (!user?.metadata?.comunicationsAccepted) {
              await updateUser({
                metadata: {
                  ...user?.metadata,
                  comunicationsAccepted: true,
                  comunicationsDate: new Date(),
                },
              });
            }
            setShowProductsModal(false);
          }}
        >
          Aceptar
        </Button>
      </LegalModal>
    </Layout.Container>
  );
});
