import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';
import fonts from '../fonts';

UiWrapper.defineComponent('inputDate', {
  styles: {
    default: {
      containerComponent: {
        width: '100%',
        color: 'black',
        backgroundColor: '#FAFBFC',
        display: 'flex',
      },

      inputContainerComponent: {
        height: '48px',
        border: 'none',
        boxSizing: 'border-box',
      },
      inputComponent: {
        // paddingBottom: '25px',
        boxSizing: 'border-box',
        paddingRight: 0,
        height: '100%',
        // paddingLeft: 5,
        fontSize: 17,
        color: colors.dark,
        lineHeight: '20px',
        fontFamily: fonts.HelveticaNeue,
        paddingBottom: 20,
      },
      iconContainerComponent: {
        padding: 0,
        height: 48,
        width: 26,
      },
      dayComponent: {
        color: colors.grey,
        fontFamily: fonts.HelveticaNeue,
      },

      daySelectedComponent: {
        backgroundColor: colors.red,
        color: colors.white,
      },
      actionTextComponent: {
        display: 'none',
      },
    },
    fullWidthSt: {
      containerComponent: {
        width: '100%',
      },
    },

    dateWithBorderSt: {
      inputContainerComponent: {
        backgroundColor: '#FAFBFC',
        borderBottom: `1px solid ${colors.red}`,
        paddingRight: 6,
      },
      containerComponent: {
        marginTop: 0,
        marginBottom: 0,
        borderBottom: 'none',
      },
      labelComponent: {
        color: '#727272',
        fontFamily: fonts.HelveticaNeue,
        fontWeight: 'normal',
        marginTop: '-2px',
        fontSize: 17,
      },
      iconComponent: {
        borderLeft: '1px solid #E5E5E5',
        paddingLeft: 10,
        paddingRight: 4,
        height: '28px',
        boxSizing: 'content-box',
        width: '24px',
      },
    },

    inputMovementSt: {
      inputContainerComponent: {
        height: '55px',
      },
    },
  },
});
