import React, { useState } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Button,
  InputCheck,
} from '@coinscrap/webapp-core';

// images
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

// configs
import colors from '../../config/colors';

// data
import { legalText } from './data';

export default PickerHOC()(() => {
  const [accept, setAccept] = useState(false);
  const { useBackground } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.white);

  return (
    <Layout.Container>
      <View spaceBetweenSt fullHeightSt width80St style={{ paddingTop: 50 }}>
        <View>
          <Text font27St>Política legal</Text>
          <View startSt style={{ overflowY: 'scroll', height: 400, marginTop: 25, padding: 5 }}>
            <Text grey1St style={{ textAlign: 'justify', fontSize: '16px' }}>
              {legalText}
            </Text>
          </View>
          <View startSt horizontalSt style={{ marginTop: 20 }}>
            <InputCheck
              checkedIcon={<CheckBoxOutlinedIcon />}
              value={accept}
              onChange={() => {
                setAccept((prevState) => !prevState);
              }}
            />
            <Text font13St>Acepto la política legal.</Text>
          </View>
        </View>
        <View>
          <Button
            regularButtonSt
            style={{ marginBottom: 50, opacity: accept ? 1 : 0.7 }}
            onClick={() => accept && navigateTo('finished')}
          >
            Siguiente
          </Button>
        </View>
      </View>
    </Layout.Container>
  );
});
