import React from 'react';

import {
  UiWrapper,
  View,
  Text,
  Image,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  RuleDefinitionsWrapper,
  RulesWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from '../../common/HeaderRedPrimary';

import Header from 'common/Header';
// import RuleItem from 'views/FollowUp/components/RuleItem';
import { LoadingData } from 'common/LoadingData';

// images
import GreenCheck from 'common/svgs/GreenCheck';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KO from 'assets/images/KO.png';

// libs
import { getCurrency } from 'libs/Currency';

// configs
import colors from 'config/colors';
import routes from 'config/routes';

import { stuctureDataListAllUserRulesByType } from 'libs/structureData'; // libs

// data
import { ruleData } from 'data/ruleData';

export default PickerHOC(
  {
    ruleDefinition: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        paramName: 'ruleIdentifier',
        type: 'param',
      },
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    userRules: {
      sourcePath: 'rules',
      sourceWrapper: RulesWrapper,
    },
    userTargets: {
      arrayItemFilter: {
        itemPropertyPath: 'level',
        type: 'value',
        value: 1,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)((ruleType = 'redondeo') => {
  const { ruleDefinition, userTargets, userRules } = PickerHOC.use();

  const { useBackground, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  let ruleName = ruleData.find((rule) => rule.identifier === ruleDefinition.identifier).ruleName;
  useLoading(false);

  useBackground(colors.white);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center={`Reglas ${ruleName}`} left="atras" />
    </View>,
    [ruleName],
  );

  const targetNotFinished = (target) => {
    let isGoalNotConfigured =
      target.status !== 'ACTIVE' &&
      target.status !== 'FINISHED' &&
      target.creationData?.date &&
      target.status !== 'PENDING_FINALIZATION';

    return isGoalNotConfigured;
  };
  let filterUserTargets = (userTargets || []).filter((target) => !targetNotFinished(target));

  let userRulesRemoveTargetNotFinished = (userRules || []).filter((userRule) => {
    const findTarget = userTargets.find((item) => item.id === userRule.targetId);
    if (findTarget && !targetNotFinished(findTarget)) return true;
    else return false;
  });
  const listRulesByType = stuctureDataListAllUserRulesByType(
    filterUserTargets,
    userRulesRemoveTargetNotFinished,
    ruleDefinition.identifier,
  );

  return (
    <>
      <View fullHeightSt startSt>
        <View style={{ marginTop: 20 }}>
          {listRulesByType.map((userRule) => {
            return (
              <View
                pointerSt
                topSt
                width90St
                style={{ borderRadius: 5, background: colors.grey3, marginTop: 10, padding: 10 }}
                onClick={() =>
                  navigateTo(routes.followRules, {
                    mode: 'push',
                    // clearItems: ['from'],
                    routeParams: { idTarget: userRule.target.id },
                  })
                }
              // onClick={() => navigateTo(routes.ruleConfig)}
              >
                <View style={{ marginBottom: 20 }} horizontalSt spaceBetweenSt>
                  <Text style={{ textAlign: 'left' }}> {userRule.name}</Text>
                  <ArrowForwardIosIcon
                    style={{ color: '#CCCCCC', fontSize: 13, marginRight: 10 }}
                  />
                </View>
                <RuleCard data={userRule} style={{ padding: 0, borderBottom: 'none' }} />
                {/* <RuleItem
                  style={{ padding: 0, borderBottom: 'none' }}
                  key={userRule.userRule.id}
                  data={userRule}
                  onClick={() => {}}
                /> */}
              </View>
            );
          })}
        </View>
      </View>
    </>
  );
});

const RuleCard = ({ data, onClick, style }) => {
  return (
    <View
      startSt
      horizontalSt
      style={{ borderBottom: `1px solid ${colors.grey7}`, ...style }}
      onClick={onClick}
    >
      <View topSt style={{ width: '10%' }}>
        {data.isActive ? (
          <GreenCheck style={{ width: 15, height: 'auto' }} />
        ) : (
          <Image src={KO} style={{ width: 15, height: 'auto' }} />
        )}
      </View>
      {/* <View topSt style={{ width: '35%' }}>
        <Text ellipsisSt style={{ fontSize: 13, width: '90%' }}>
          {data.name}
        </Text>
      </View> */}
      <View style={{ width: '45%', paddingRight: 10 }} horizontalSt spaceBetweenSt>
        <Text font13St>Virtual: </Text>
        <View autoWidthSt>
          <Text redSt style={{ fontSize: 13 }}>
            {data.virtual === 0
              ? getCurrency(data.virtual, 0, false)
              : getCurrency(data.virtual, 2, false)}
          </Text>
        </View>
      </View>
      <View horizontalSt spaceBetweenSt style={{ width: '45%' }}>
        <Text font13St>Total: </Text>
        <View autoWidthSt>
          <Text redSt style={{ fontSize: 13 }}>
            {data.amountTotal === 0
              ? getCurrency(data.amountTotal, 0, false)
              : getCurrency(data.amountTotal, 2, false)}
          </Text>
        </View>
      </View>
    </View>
  );
};
