import React, { useState, useEffect } from 'react';

import {
  UiWrapper,
  View,
  Text,
  NavigationWrapper,
  Button,
  InputDate,
  Image,
  Page,
} from '@coinscrap/webapp-core';
import moment from 'moment';
// components
import Header from '../../common/Header';
import HeaderRedPrimary from '../../common/HeaderRedPrimary';
import Footer from '../../common/Footer';

// images
import Calendar from '../../assets/images/Calendar.png';
// configs
import colors from 'config/colors';
import routes from 'config/routes';
import { filterController } from 'libs/filter';

const dateArray = [7, 15, 30, 90];

const RuleConfig = () => {
  const {
    query: { from },
  } = Page.use();
  const { useBackground, useHeader, useFooter } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();

  const [active, setActive] = useState(15);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  useBackground(colors.white);

  useEffect(() => {
    const daysBack = moment().subtract(active, 'd');
    setStartDate(daysBack);
  }, [active]);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        center="FILTRO"
        left="atras"
        goBackAction={() =>
          from
            ? navigateTo(routes.home, {
              mode: 'replace',
              handleRedirection: true,
            })
            : goBack()
        }
      />
    </View>,
    [from],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 30 }}
        borderWhiteButtonSt
        onClick={() => {
          filterController.filter = {
            filterStart: moment(startDate).unix(),
            filterEnd: moment(endDate).unix(),
          };
          navigateTo(routes.home, {
            mode: 'replace',
            handleRedirection: true,
          });
        }}
      >
        Aplicar
      </Button>
    </Footer>,
    [startDate, endDate, from],
  );

  return (
    <>
      <View fullHeightSt startSt width86St>
        <View topSt style={{ margin: '20px 0px' }}>
          <Text boldSt font13St>
            Por rango de fechas
          </Text>
          <View bgGrey5St horizontalSt style={{ borderRadius: 6, marginTop: 25 }}>
            {dateArray.map((day) => {
              return (
                <View
                  onClick={() => setActive(day)}
                  transparentBorderSt
                  activeFilterDateSt={active === day}
                  flexGrowSt
                  style={{
                    padding: '20px',
                    textAlign: 'center',
                    height: active === day ? 65 : 70,
                    transition: 'all 0.4s',
                  }}
                >
                  <Text redSt={active === day} boldSt={active === day} font12St>
                    Últimos {day}
                  </Text>
                  <Text redSt={active === day} boldSt={active === day} font12St>
                    días
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
        <View topSt style={{ margin: '20px 0px' }}>
          <Text boldSt font13St style={{ marginBottom: 15 }}>
            Fecha de inicio
          </Text>
          <InputDate
            dateWithBorderSt
            // label="Inicio"
            onChange={(date) => setStartDate(date)}
            toolbarComponent={() => null}
            pickOnSelect={true}
            maxDate={new Date()}
            dateIcon={
              <View autoWidthSt>
                <Image src={Calendar} style={{ width: 'auto', height: '25px' }} />
              </View>
            }
            value={startDate}
          />
        </View>
        <View topSt style={{ margin: '20px 0px' }}>
          <Text boldSt font13St style={{ marginBottom: 15 }}>
            Fecha de fin
          </Text>
          <InputDate
            dateWithBorderSt
            // label="Inicio"
            onChange={(date) => setEndDate(date)}
            toolbarComponent={() => null}
            pickOnSelect={true}
            maxDate={new Date()}
            dateIcon={
              <View autoWidthSt>
                <Image src={Calendar} style={{ width: 'auto', height: '25px' }} />
              </View>
            }
            value={endDate}
          />
        </View>
      </View>
    </>
  );
};

export default RuleConfig;
