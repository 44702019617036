import React, { useState } from 'react';

import { Text, View, Slider } from '@coinscrap/webapp-core';
import { Line, Pie } from 'react-chartjs-2';
import moment from 'moment';
// components

// libs
import { getCurrency } from 'libs/Currency';
// libs
import { stuctureTargetDataForPieGraph, stuctureDataMovements } from 'libs/structureData'; // libs

const Graphs = ({ data, target }) => {
  const [index, setIndex] = useState(0);

  let movements = stuctureDataMovements(target);

  const handleChangeIndex = (i) => {
    setIndex(i);
  };

  const addRealMonth = (d) => {
    var fm = moment(d).add(1, 'M');
    var fmEnd = moment(fm).endOf('month');
    return d.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;
  };

  const createRange = (beginning, end) => {
    const format = 'MM/YY';
    const start = moment(beginning).startOf('month');

    const result = [];
    while (start.isBefore(end)) {
      result.push(start.format(format));
      start.add(1, 'month');
    }
    return result;
  };

  const getLabels = () => {
    return createRange(target.createDate, target.creationData.date);
  };
  const getDataCompleted = () => {
    const numberOfMonths = createRange(target.createDate, moment().toDate()).length;

    let currentMonth = moment(target.createDate);
    let labelsArray = [];
    [...Array(numberOfMonths)].reduce((acc, curr) => {
      labelsArray.push(acc);
      return addRealMonth(acc);
    }, currentMonth);

    // let last6Months = [...movements.slice(-6).map((item) => item.total)];

    const mapped = labelsArray.map((item, index) => {
      const find = movements.find(
        (object) => moment(object.dateObject).format('MM/YY') === item.format('MM/YY'),
      );

      let returnValue = 0;

      if (find) {
        returnValue = find.total;
      }

      /*if (target.creationData?.initialContribution && index === 0) {
        returnValue = returnValue + target.creationData?.initialContribution;
      }*/

      return returnValue;
    });
    // if (last6Months.length === 1) {
    //   last6Months = [0, ...last6Months];
    // }
    let newArray = [];
    mapped.reduce((accumulator, currentValue) => {
      newArray.push(accumulator + currentValue);
      return accumulator + currentValue;
    }, 0);

    return newArray;
  };
  const getDataGoal = () => {
    const numberOfMonths = createRange(target.createDate, target.creationData.date).length;
    const goalPerMonth = target.progress.total / numberOfMonths;

    let goalArray = [];
    [...Array(numberOfMonths)].reduce((accumulator, currentValue) => {
      goalArray.push(accumulator + goalPerMonth);
      return accumulator + goalPerMonth;
    }, 0);

    return goalArray;
  };

  const dataGraph = {
    // labels: ['04/20', '05/20', '06/20', '07/20', '08/20', '09/20'],
    labels: getLabels(),

    datasets: [
      {
        // backgroundColor: 'red',

        borderColor: 'red',
        borderWidth: 3,
        // pointRadius: 0,
        label: 'Completado',
        pointStyle: 'rect',
        pointBackgroundColor: 'white',
        pointBorderColor: 'black',
        pointBorderWidth: 1,
        // data: [25, 80, 75, 90, 200, 325],
        data: getDataCompleted(),
      },
      {
        // backgroundColor: '#F2F2F2',

        borderColor: 'black',
        borderWidth: 3,
        // pointRadius: 0,
        label: 'Objetivo',
        pointStyle: 'rect',
        pointBackgroundColor: 'white',
        pointBorderColor: 'black',
        pointBorderWidth: 1,
        // data: [25, 80, 75, 90, 200, 325],
        data: getDataGoal(),
      },
    ],
  };

  const {
    pending,
    initialContribution,
    monthlyContributions,
    rulesContributions,
  } = stuctureTargetDataForPieGraph(target);

  const dataGraphPie = {
    labels: ['Pendiente', 'Aport. Inicial', 'Aport. Mensu.', 'Reglas ahorro'],

    datasets: [
      {
        backgroundColor: ['#CCCCCC', '#C5281C', '#AF2B25', '#661614'],

        borderColor: 'rgb(233, 30, 99)',
        borderWidth: 2,
        // pointRadius: 0,
        label: [''],
        pointStyle: 'rect',
        pointBackgroundColor: 'white',
        pointBorderColor: 'black',
        pointBorderWidth: 1,
        data: [pending, initialContribution, monthlyContributions, rulesContributions],
      },
    ],
  };
  return (
    <View spaceBetweenSt>
      <View>
        <View spaceBetweenSt horizontalSt style={{ padding: 20, backgroundColor: '#fef7f6' }}>
          <Text style={{ fontSize: 13 }}>GRÁFICAS DE SEGUIMIENTO</Text>
          {/* <View horizontalSt autoWidthSt>
            <Image style={{ height: 23, width: 'auto' }} src={download}></Image>
          </View> */}
        </View>
        {data.length === 0 ? (
          <View width90St style={{ marginTop: 30 }}>
            <Text style={{ textAlign: 'center' }}>
              Actualmente no hay datos suficientes para generar los gráficos.
              <br />
              Los datos se muestran una vez que se consolide el traspaso a tu producto.
            </Text>
          </View>
        ) : (
          <>
            <View style={{ marginTop: 10, padding: 20 }}>
              <Slider
                homeSliderSt
                enableMouseEvents
                index={index}
                onChangeIndex={handleChangeIndex}
              >
                <View>
                  <Line data={dataGraph} options={optionsGraph} />
                  {/* <Text font12St style={{ marginTop: 15, textAlign: 'center' }}>
                La curva de ahorro es positiva, pero deberías ahorrar más rápido para obtener tu
                objetivo en el plazo señalado.
              </Text> */}
                </View>
                <View>
                  <Pie data={dataGraphPie} options={optionsGraphPie} />
                  <Text font12St style={{ marginTop: 15, textAlign: 'center' }}>
                    Ya has conseguido ahorrar un{' '}
                    {((target.progress.completed / target.progress.total) * 100).toFixed(0)}% de tu
                    objetivo ya falta poco para poder disfrutarlo.
                  </Text>
                </View>
              </Slider>
            </View>
            <View style={{ padding: 20 }}>
              <View horizontalSt>
                <DotComponent onClick={() => setIndex(0)} active={index === 0} />
                <DotComponent onClick={() => setIndex(1)} active={index === 1} />
              </View>
            </View>
          </>
        )}
      </View>
      {/* <View horizontalSt startSt style={{ backgroundColor: '#fef7f6', padding: 15 }}>
        <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
        <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
          ¡Aún no has activado ninguna regla de ahorro para este objetivo!
        </Text>
      </View> */}
    </View>
  );
};

export default Graphs;

const DotComponent = ({ active, onClick }) => {
  return (
    <View
      onClick={onClick}
      dotsSt
      dotsActiveSt={active}
      style={{ margin: '0 4px 0 4px' }}
    // onClick={() => switchInfo(id)}
    />
  );
};

const optionsGraph = {
  tooltips: {
    enabled: true,
    callbacks: {
      label: function (tooltipItem, data) {
        const datasetIndex = tooltipItem.datasetIndex;
        const labelIndex = tooltipItem.index;
        const label = data.labels[labelIndex];
        var value = getCurrency(
          Number(data.datasets[datasetIndex].data[labelIndex]).toFixed(2),
          2,
          false,
        );
        return `${label}: ${value}`;
      },
    },
  },
  spanGaps: true,
  legend: {
    display: true,
    position: 'bottom',
  },

  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        // ticks: {
        //   autoSkip: true,
        //   maxTicksLimit: 10,
        // },
      },
    ],
    yAxes: [
      {
        display: true,
        gridLines: {
          display: false,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
        },
      },
    ],
  },
  scaleShowVerticalLines: false,
};

const optionsGraphPie = {
  tooltips: {
    enabled: true,
    callbacks: {
      label: function (tooltipItem, data) {
        const labelIndex = tooltipItem.index;
        const label = data.labels[labelIndex];
        var value = getCurrency(Number(data.datasets[0].data[labelIndex]).toFixed(2), 2, false);
        return `${label}: ${value}`;
      },
    },
  },
  spanGaps: true,
  legend: {
    onClick: (e) => e.stopPropagation(),
    display: true,
    position: 'left',
    labels: {
      boxWidth: 10,
    },
  },
  scales: {
    xAxes: [
      {
        display: false,
        gridLines: {
          display: false,
        },
        // ticks: {
        //   autoSkip: true,
        //   maxTicksLimit: 10,
        // },
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: {
          display: false,
        },
      },
    ],
  },
  scaleShowVerticalLines: false,
};
