import React from 'react';
import colors from '../config/colors';

import { View } from '@coinscrap/webapp-core';
export default ({ style, children }) => {
  let content = null;

  return (
    <View style={{ background: colors.grey3, maxWidth: 850, minHeight: 60, ...style }}>
      {content ? content : children}
    </View>
  );
};
