import { Image, InputSwitch, Text, View } from '@coinscrap/webapp-core';
import colors from 'config/colors';
import React from 'react';
import account from 'assets/images/account.png';
import { getCurrency } from 'libs/Currency';

const Policy = React.memo(({ name, balance, active, onSelect, dark }) => {
  const handleChange = () => {
    !active && onSelect();
  };

  return (
    <View horizontalSt style={{ padding: '5px 20px', backgroundColor: dark && colors.grey3 }}>
      <View
        horizontalSt
        autoWidthSt
        flexGrowSt
        style={{ flexBasis: '0%', justifyContent: 'flex-start' }}
      >
        <View style={{ width: 23 }}>
          <Image src={account} style={{ width: '100%', height: 'auto' }}></Image>
        </View>
        <Text style={{ fontSize: 12, marginLeft: 20 }}>{name}</Text>
      </View>
      <View autoWidthSt flexGrowSt style={{ flexBasis: '0%', paddingLeft: 5 }}>
        <Text style={{ fontSize: 16 }}>{getCurrency(balance)}</Text>
      </View>
      <View autoWidthSt>
        <View autoWidthSt style={{ marginLeft: 'auto' }}>
          <InputSwitch value={active} onChange={handleChange}></InputSwitch>
        </View>
      </View>
    </View>
  );
});

export default Policy;
