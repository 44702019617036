import React from 'react';

export default function Warning(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41.794"
      height="38.925"
      viewBox="0 0 41.794 38.925"
      style={{ ...props.style }}
    >
      <defs>
        <style></style>
      </defs>
      <path
        className="a"
        d="M23.11,1.567,41,34.5a2.8,2.8,0,0,1-.046,2.947,2.935,2.935,0,0,1-1.083,1.076,2.9,2.9,0,0,1-1.479.4H2.609a2.9,2.9,0,0,1-1.479-.4A2.935,2.935,0,0,1,.046,37.451,2.8,2.8,0,0,1,0,34.5L17.892,1.567A2.977,2.977,0,0,1,18.986.421a2.932,2.932,0,0,1,3.029,0A2.98,2.98,0,0,1,23.11,1.567Zm.326,21.849.419-10.738a.517.517,0,0,0-.233-.444.9.9,0,0,0-.559-.257H17.938a.9.9,0,0,0-.559.257.561.561,0,0,0-.233.492l.4,10.691a.449.449,0,0,0,.233.386,1,1,0,0,0,.559.152h4.31a.942.942,0,0,0,.547-.152A.52.52,0,0,0,23.436,23.416Zm.046,8.749V27.72a.747.747,0,0,0-.221-.55.715.715,0,0,0-.524-.222H18.264a.713.713,0,0,0-.524.222.747.747,0,0,0-.222.55v4.445a.748.748,0,0,0,.222.55.713.713,0,0,0,.524.222h4.473a.715.715,0,0,0,.524-.222A.748.748,0,0,0,23.483,32.165Z"
        transform="translate(0.396)"
      />
    </svg>
  );
}
