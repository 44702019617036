import React, { useEffect, useState } from 'react';

import { UiWrapper, NavigationWrapper, View, Text, Button, Enfatize } from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import InfoHeader from 'common/InfoHeader';
import InfoModal from 'common/InfoModal';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

const TargetAccounstIntro = () => {
  const { useBackground, useHeader, useFooter, openModal } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const [openModalState, setOpenModal] = useState(false);

  useBackground(colors.white);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. BANCO." left="atras" />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 10 }}
        borderWhiteButtonSt
        // onClick={() => navigateTo(routes.targetAccountsAdd)}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        ¡Vamos!
      </Button>
    </Footer>,
    [],
  );

  useEffect(() => {
    if (openModalState) {
      openModal(
        (close) => (
          <InfoModal onClose={() => close()}>
            <View style={{ justifyContent: 'space-between', height: '100%' }}>
              <View style={{ padding: 15 }}>
                <Text whiteSt textCenterSt style={{ lineHeight: 1.6 }}>
                  <Enfatize whiteSt boldSt style={{ fontSize: 20 }}>
                    {`100% Seguro. `}
                  </Enfatize>
                  <Enfatize whiteSt boldSt>
                    Solamente utilizaremos tus claves para llevar a cabo los servicios de ahorro.
                    Utilizamos tecnología PSD2
                  </Enfatize>{' '}
                  (Payment Services Directive) es la Directiva Europea que regula los servicios de
                  pago por ej. Transferencias, domiciliaciones, pagos con tarjetas, etc.{' '}
                  <Enfatize boldSt whiteSt>
                    En ningún caso se facilitarán datos de carácter personal a terceros.
                  </Enfatize>
                  {/* Recuerda que todas las aportaciones que hagas para tu objetivo{' '}
              <Enfatize whiteSt boldSt>
                se traspasarán automáticamente a tu cuenta cada viernes si has conseguido ahorrar un
                mínimo de 40€
              </Enfatize>{' '}
              durante la semana. En caso contrario el traspaso se producirá el siguiente viernes
              tras haber obtenido el ahorro mínimo de 40€. */}
                </Text>
              </View>
              <View>
                <Button
                  // style={{ marginBottom: 30 }}
                  whiteFooterButtonSt
                  onClick={() => {
                    navigateTo(routes.targetAccountsAdd);
                    close();
                  }}
                >
                  Siguiente
                </Button>
              </View>
            </View>
          </InfoModal>
        ),
        {
          fullScreen: true,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalState]);

  return (
    <>
      <View fullHeightSt spaceBetweenSt>
        <View>
          <InfoHeader text="4" />

          <View style={{ marginTop: 50 }}>
            <View width75St>
              <Text font27St textCenterSt>
                Configuración de cuenta bancaria
              </Text>
            </View>
            <View width65St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
                Necesitamos que añadas tu cuenta bancaria para comenzar el proyecto de ahorro.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default TargetAccounstIntro;
