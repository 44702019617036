import { useCallback } from "react";
import { OperationsWrapper, UserWrapper } from '@coinscrap/webapp-core';

export const useConsentAccept = (iaId, pId) => {
    const { createOperation } = OperationsWrapper.use();
    const { user } = UserWrapper.use();

    const accept = useCallback(async () => {
        //Create New
        const op = await createOperation(
            'SEND_INFORMED_CONSENT',
            {
                institutionAccountId: iaId,
                productId: pId,
            },
            'AWAIT_FULL_PROCESS'
        )
        return op
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id])

    return { accept }

}