class StartUpController {
  constructor(params) {
    this._params = params;
  }
  set params(params) {
    this._params = params;
  }
  get params() {
    return this._params;
  }
}

export const startUpController = new StartUpController();
