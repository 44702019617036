import React, { useCallback, useState, useRef } from 'react';

import {
  UiWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  NavigationWrapper,
  TextField,
  Image,
  TargetsWrapper,
  TargetsUtilsWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import { LoadingData } from 'common/LoadingData';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';

// images
import pigWhite from '../../../assets/images/cerdo.png';
import CheckMark from '../../../common/svgs/CheckMark';
import Back from '../../../common/svgs/Back';
import ErrorIcon from '../../../common/svgs/Warning';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

// libs
import { onChangeAmountHandler } from '../../../libs/currencyInput';
import RulesTooltip from 'common/RulesTooltip';
import { getCurrency, setCurrency } from 'libs/Currency';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target } = PickerHOC.use();
  const { saveTargetProperty } = TargetsUtilsWrapper.use();

  const { useBackground, useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const [amount, setAmount] = useState(
    target.creationData?.initialContribution
      ? getCurrency(target.creationData.initialContribution, 2, true)
      : 0,
  );
  const [isScroll, setIsScroll] = useState(false);

  const lastRef = useRef(null);
  const observer = useRef(null);
  useBackground(colors.red);
  useLoading(false);
  const saveTarget = useCallback(
    async (isActive) => {
      try {
        await Promise.all([
          saveTargetProperty(target.id, 'creationData', {
            initialContribution: setCurrency(isActive ? amount : 0),
          }),
        ]);
        navigateTo(routes.contributionStep2Periodic, {
          routeParams: {
            idTarget: target.id,
          },
        });
      } catch (error) {
        console.log(error);
        return;
      }
    },
    [amount, navigateTo, saveTargetProperty, target],
  );

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        left={
          <View autoWidthSt style={{ marginLeft: 18 }} onClick={() => goBack()}>
            <Back style={{ fill: colors.white, width: 11, height: 'auto' }} />
          </View>
        }
        style={{ backgroundColor: colors.red }}
        right="none"
      />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.red }}>
      <View style={{ padding: 25 }}>
        <Text boldSt font15St whiteSt style={{ marginBottom: 20 }}>
          {' '}
          ¿Quieres realizar esta aportación?{' '}
        </Text>
        <View horizontalSt>
          <View
            pointerSt
            style={{
              height: 54,
              width: 54,
              borderRadius: '50%',
              background: colors.white,
              marginRight: 32,
            }}
            onClick={() => saveTarget(false)}
          >
            <View
              autoWidthSt
              boldSt
              style={{
                fontSize: 45,
                color: colors.red,
                transform: 'rotate(45deg)',
                paddingBottom: 10,
              }}
            >
              +
            </View>
          </View>
          <View
            pointerSt
            onClick={() => {
              const val = Number(setCurrency(amount));
              if (val < Number(40)) return;
              if (val > 3000) return;
              saveTarget(true);
            }}
            style={{ height: 54, width: 54, borderRadius: '50%', background: colors.white }}
          >
            <CheckMark style={{ width: 20, height: 'auto', fill: colors.green }} />
          </View>
        </View>
      </View>
    </Footer>,
    [saveTarget, amount],
  );

  return (
    <Layout.Container>
      <ScrollArrow
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View
        startSt
        fullHeightSt
        style={{ backgroundColor: colors.red, paddingTop: 10, paddingBottom: 10 }}
      >
        <View width79St>
          <Image src={pigWhite} style={{ width: 80, height: 'auto' }} />

          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <Text font27St boldSt whiteSt style={{ textAlign: 'center', marginRight: 5 }}>
              Aportación inicial
            </Text>
            <RulesTooltip
              content={
                <Text style={{ fontSize: 14 }}>
                  Esta aportación no es obligatoria pero sin duda es la mejor manera de empezar tu
                  objetivo y conseguirlo cuanto antes. Puedes elegir el importe que desees para tu
                  primera aportación y se cargará en tu cuenta en las próximas horas.{' '}
                  <Text style={{ fontSize: 14, marginTop: 10 }}>
                    Si lo prefieres, puedes saltarte este paso y no hacer la aportación inicial.
                  </Text>
                </Text>
              }
            ></RulesTooltip>
          </View>
          <Text textCenterSt font15St whiteSt lineHeight16St style={{ marginTop: 27 }}>
            Elige un importe inicial para impulsar tu ahorro, un pequeño paso para ti, un gran paso
            para obtener tu objetivo.
          </Text>
          <Text font15St boldSt textCenterSt whiteSt style={{ marginTop: 20, width: 300 }}>
            Pon el importe que deseas ahorrar inicialmente
          </Text>
          <View style={{ marginTop: 30 }}>
            <TextField
              createInputSt
              ruleTransparentBackgroundSt
              onChange={(e) => onChangeAmountHandler(e.target.value, setAmount)}
              value={amount}
              startAdornment={
                <View
                  style={{
                    backgroundColor: colors.white,
                    height: 20,
                    width: 20,
                    borderRadius: '50%',
                    opacity: amount ? 1 : 0.7,
                  }}
                >
                  <CheckMark style={{ fill: colors.red, height: 8, width: 'auto' }} />
                </View>
              }
              endAdornment={
                <Text style={{ paddingTop: 2, marginLeft: 5, color: colors.white }}> €</Text>
              }
            />
            {Number(setCurrency(amount)) > 0 && Number(setCurrency(amount)) < Number(40) && (
              <View horizontalSt style={{ marginTop: 5, justifyContent: 'flex-end' }}>
                <ErrorIcon style={{ fill: 'white', marginRight: 2, height: 12 }} />
                <Text font15St style={{ color: 'white' }}>
                  El importe mínimo es de {getCurrency(40, 0, false)}
                </Text>
              </View>
            )}

            {Number(setCurrency(amount)) > 3000 && (
              <View horizontalSt style={{ marginTop: 5, justifyContent: 'flex-end' }}>
                <ErrorIcon style={{ fill: 'white', marginRight: 2, height: 12 }} />
                <Text font15St style={{ color: 'white' }}>
                  El importe máximo es de {getCurrency(3000, 0, false)}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});
