import React, { useRef, useCallback, useState } from 'react';

import { UiWrapper, View, Button } from '@coinscrap/webapp-core';

// components
import { CloseButton } from 'common/closeButton';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TermsArrowDown from 'common/TermsArrowDown';
import Modal from '@material-ui/core/Modal';
// configs
import colors from 'config/colors';

const LegalModal = ({ isOpen, close, children, onClick }) => {
	const faqRef = useRef();
	const { useBackground } = UiWrapper.use();
	const [isScroll, setIsScroll] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	useBackground(colors.white);

	const observer = useRef();

	const lastPlanElementRef = useCallback(
		(node) => {
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[entries.length - 1].isIntersecting) {
					setButtonDisabled(false);
					setIsScroll(false);
				} else {
					setIsScroll(true);
				}
			});
			if (node) observer.current.observe(node);
		},
		[observer],
	);

	return (
		<Modal open={isOpen} onClose={close}>
			<View fullHeightSt style={{ backgroundColor: colors.white, overflowY: 'auto' }}>
				<View fullHeightSt style={{ justifyContent: 'flex-start' }}>
					<View startSt fullHeightSt style={{ paddingTop: 50 }}>
						<View horizontalSt endSt style={{ marginTop: 10, position: 'fixed', top: 0 }}>
							<CloseButton
								color={colors.dark}
								action={() => {
									close();
								}}
							/>
						</View>
						{children ? (
							<View>{children}</View>
						) : (
							<View startSt fullHeightSt>
								{/* <Text boldSt font22St style={{ marginBottom: 10 }}>
                  TÉRMINOS Y CONDICIONES
                </Text> */}
								<View
									startSt
									style={{
										overflowY: 'scroll',
										height: 'calc(100% - 90px)',
										maxWidth: 850,
										padding: 10,
									}}
								>
									<div
										style={{ width: '90%', fontFamily: 'HelveticaNeue !important' }}
										dangerouslySetInnerHTML={{
											__html: `
                      <p style="text-align:center"><span style="font-size:18pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>T&Eacute;RMINOS Y CONDICIONES</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI, entidad aseguradora inscrita en el Registro de Entidades Aseguradoras de la Direcci&oacute;n General de Seguros y Fondos de Pensiones, facilita a los usuarios de la herramienta &ldquo;My Pocket&rdquo; los siguientes datos de informaci&oacute;n general, de acuerdo con el art&iacute;culo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y de Comercio Electr&oacute;nico (la &ldquo;LSSI&rdquo;) y la Ley 22/2007, de 11 de julio, sobre comercializaci&oacute;n a distancia de servicios financieros destinados a los consumidores:</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Denominaci&oacute;n social: GENERALI ESPA&Ntilde;A S.A, DE SEGUROS Y REASEGUROS</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">N&uacute;mero de Identificaci&oacute;n Fiscal:&nbsp;</span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"> Nif. A28007268</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Domicilio social:&nbsp;</span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"> CALLE ORENSE 2, MADRID, 28020</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">E-mail contacto:&nbsp;</span></span></span><span style="font-size:10.5pt"><span style="font-family:'Quattrocento Sans',sans-serif"><span style="color:#000000"> servicioatencionalcliente.es@generali.com</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Inscripci&oacute;n en el Registro Mercantil:&nbsp;</span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"> INSCRITA EN EL REGISTRO MERCANTIL DE MADRID EN EL TOMO 24758, FOLIO 179, SECCION 8&ordf;, HOJA M-54202 INSCRIPCION 1161</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Inscripci&oacute;n en el Registro de Entidades Aseguradoras de la Direcci&oacute;n General de Seguros y Fondos de Pensiones:&nbsp; C&oacute;digo de Conducta C-0072 del Grupo GENERALI disponible en el sitio web </span></span></span><a href="http://www.generali.es" style="text-decoration:none"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#0000ff"><u>www.generali.es</u></span></span></span></a><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">&nbsp;</span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:18pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>Condiciones de uso del Servicio</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Las presentes condiciones generales, as&iacute; como Pol&iacute;tica de Privacidad y de Cookies tienen por finalidad el regular e informar de los servicios que presta GENERALI a trav&eacute;s de la Herramienta &ldquo;My Pocket&rdquo;.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>I. Introducci&oacute;n</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Los t&eacute;rminos y condiciones recogidos en el presente documento regulan el uso de la Herramienta &ldquo;My pocket&rdquo; (en adelante, la &ldquo;Herramienta&rdquo;) que GENERALI pone a disposici&oacute;n de los usuarios (en adelante, el &ldquo;usuario&rdquo; o &ldquo;usuarios&rdquo;) que acceden a la aplicaci&oacute;n Mi Generali (en adelante &ldquo;App M&oacute;vil&rdquo;) con la finalidad de proporcionarles informaci&oacute;n sobre su posici&oacute;n de ahorro y sobre los diferentes servicios/productos y reglas de ahorro disponibles, as&iacute; como posibilitar la contrataci&oacute;n de dichos servicios y/o productos.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>II. Descripci&oacute;n y condiciones de uso del servicio</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI pone al servicio de los usuarios una Herramienta que permite realizar las siguientes gestiones:</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Ahorrar mediante innovadoras metodolog&iacute;as en productos de Seguro de Ahorro e Inversi&oacute;n de la entidad aseguradora GENERALI. Se utiliza el t&eacute;rmino ahorrar como referencia al acto de reservar parte de los ingresos para evitar un gasto o consumo innecesario.</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Acceder de forma r&aacute;pida y sencilla a su posici&oacute;n y salud financiera agregada al poder vincular todas las cuentas de las que disponga de diferentes entidades financieras.</span></span></span></li>
</ul>

<p style="margin-left:48px; text-align:justify">&nbsp;</p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">El uso de la Herramienta por parte del usuario supone su aceptaci&oacute;n sin reserva de los t&eacute;rminos integrantes de las presentes Condiciones;&nbsp; las cuales manifiesta haber conocido previamente a su aceptaci&oacute;n, pudiendo ser las mismas almacenadas y reproducidas.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">El uso de la Herramienta y su contenido es totalmente voluntario y bajo la responsabilidad del usuario.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>III. Acceso a la Herramienta</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Para poder utilizar la Herramienta de ahorro en la aplicaci&oacute;n es imprescindible que el usuario sea cliente de GENERALI y usuario de la aplicaci&oacute;n Mi Generali. Para ello se requerir&aacute; al usuario que inicie sesi&oacute;n en la aplicaci&oacute;n de Mi Generali, como hace de forma habitual, usando sus claves. Si el usuario no recuerda su contrase&ntilde;a debe hacer uso de la herramienta de recuperaci&oacute;n de contrase&ntilde;a dentro de la aplicaci&oacute;n. Adem&aacute;s de la contrase&ntilde;a, tambi&eacute;n puede utilizarse el lector de huellas u otras t&eacute;cnicas de identificaci&oacute;n biom&eacute;trica en funci&oacute;n del dispositivo utilizado. Estos datos ser&aacute;n solicitados cada vez que el usuario acceda a los servicios de la App M&oacute;vil, por lo que servir&aacute;n como procedimiento de identificaci&oacute;n y autenticaci&oacute;n en el sistema.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Una vez conectado podr&aacute; acceder a My Pocket y a las funcionalidades de ahorro de la Herramienta a trav&eacute;s del men&uacute; principal de la app m&oacute;vil. Para el uso de la Herramienta, el usuario podr&aacute; vincular tantas cuentas, y tarjetas de pago , como desee de entre las entidades financieras disponibles en My Pocket en cada momento. GENERALI informa al usuario de que se le enviar&aacute;n notificaciones y correos electr&oacute;nicos de diferente &iacute;ndole y naturaleza relacionados con el servicio contratado para asegurar el correcto funcionamiento y uso exclusivo del usuario de los servicios contratados.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>IV. Funcionamiento operativo de la Herramienta</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La Herramienta es una soluci&oacute;n digital de ahorro generado a partir de la planificaci&oacute;n de diferentes objetivos, reglas de ahorro autom&aacute;ticas y aportaciones peri&oacute;dicas y/o extraordinarias, vinculadas a p&oacute;lizas de seguros de Vida, espec&iacute;ficamente de Ahorro e Inversi&oacute;n. De esta manera, a trav&eacute;s de la utilizaci&oacute;n de esta Herramienta, se habilitar&aacute; la posibilidad de realizar aportaciones a dichos Seguros de Vida Ahorro-Inversi&oacute;n gracias a diferentes t&eacute;cnicas expuestas a continuaci&oacute;n, y ligadas a la actividad de las cuentas de pago, y tarjetas de pago.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">En s&iacute;ntesis, el servicio tendr&aacute; las siguientes funcionalidades, las cuales ser&aacute;n desarrolladas a continuaci&oacute;n:&nbsp;</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Dise&ntilde;o de objetivos de ahorro personalizados.</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Aplicaci&oacute;n de diferentes Reglas de ahorro que permitan conseguir los objetivos anteriores</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Seguimiento de los mismos, para poder modificarlos, o alimentar el ahorro en momentos puntuales.</span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>Caracter&iacute;sticas principales</strong></span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><u>Productos disponibles</u></span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La Herramienta est&aacute; disponible inicialmente, para los productos GENERALI Equilibrio Protecci&oacute;n Plus, GENERALI Multinversion F&aacute;cil, GENERALI Multinversi&oacute;n Selecci&oacute;n y/o GENERALI Fondoselecci&oacute;n Flexible, cuyos riesgos y caracter&iacute;sticas obran expuestos en la documentaci&oacute;n contractual de los citados productos.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">De esta manera, el usuario podr&aacute; elegir sobre qu&eacute; p&oacute;liza de Ahorro-Inversi&oacute;n contratada en GENERALI se activar&aacute; la Herramienta, siendo posible &uacute;nicamente la activaci&oacute;n sobre una p&oacute;liza.&nbsp;</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><u>Informaci&oacute;n sobre cuentas</u></span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">En el proceso de activaci&oacute;n de la Herramienta, el usuario deber&aacute; aceptar los t&eacute;rminos y condiciones de Morpheus Aiolos, S.L. (CIF B-86556420), entidad de pagos autorizada por el Banco de Espa&ntilde;a para prestar el &ldquo;Servicio de informaci&oacute;n sobre cuentas&rdquo;.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">En este sentido, se entender&aacute; por &ldquo;Servicio de informaci&oacute;n sobre cuentas&rdquo; aquel servicio en l&iacute;nea prestado por Morpheus Aiolos, S.L. al usuario con la finalidad de facilitar a GENERALI informaci&oacute;n agregada sobre una o varias cuentas de pago de las que es titular el usuario, bien en uno o en varios proveedores de servicios de pago.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Con base en la informaci&oacute;n obtenida por GENERALI a trav&eacute;s de Morpheus Aiolos, S.L., se efectuar&aacute;n los cargos correspondientes a algunas de las reglas de ahorro configuradas expresamente por el usuario. Dichos cargos se realizar&aacute;n en la cuenta que el usuario predetermine en la Herramienta. A tal fin, el usuario deber&aacute; facilitar, dentro del proceso de alta, las credenciales de acceso de la cuenta/s de pago/s sobre la que deban observarse los movimientos necesarios para la aplicaci&oacute;n de dichas reglas de ahorro.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><u>R&eacute;gimen de aportaciones y reglas de ahorro</u></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La distribuci&oacute;n de las aportaciones entre las opciones de inversi&oacute;n disponibles en el Seguro de Ahorro/Inversi&oacute;n asociado a la Herramienta se realizar&aacute; de acuerdo a la distribuci&oacute;n establecida en la p&oacute;liza, sea &eacute;sta la correspondiente al momento de la emisi&oacute;n, o, en el caso de que se hubiese realizado una modificaci&oacute;n posterior, la establecida por el usuario en dicha modificaci&oacute;n. En el caso de que el usuario desee modificar la distribuci&oacute;n composici&oacute;n de sus aportaciones futuras, deber&aacute; realizarlo a trav&eacute;s del mecanismo de Suplemento al Seguro de Ahorro / Inversi&oacute;n asociado a la Herramienta, a partir del cu&aacute;l se establecer&aacute; una nueva distribuci&oacute;n que afectar&aacute; a todas las aportaciones a realizar sobre la p&oacute;liza.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La Herramienta permitir&aacute; la creaci&oacute;n de objetivos de ahorro personalizados (tanto en lo relativo a la cantidad objetivo, como en t&eacute;rminos de horizontes temporales). No existe ninguna limitaci&oacute;n en lo relativo al n&uacute;mero de objetivos que pueden existir simult&aacute;neamente dentro de MyPocket. De esta manera, toda aportaci&oacute;n sobre la p&oacute;liza de Ahorro- Inversi&oacute;n se realizar&aacute; dentro del marco del/los objetivo/s de ahorro existentes.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">As&iacute;, se podr&aacute;n configurar las siguientes modalidades de aportaci&oacute;n:</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Aportaci&oacute;n inicial de objetivo, siendo posible aportar inicialmente cualquier cantidad dentro de los l&iacute;mites fijados en el objetivo (incluso 0 &euro;)</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Aportaciones peri&oacute;dicas mensuales: el usuario podr&aacute; realizar aportaciones peri&oacute;dicas mensuales dentro del marco del objetivo de ahorro.&nbsp;</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Aportaciones derivadas de las reglas de ahorro:</span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Reglas de ahorro:</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Redondeo: la Herramienta calcula la diferencia entre cualquier movimiento contable de cargo en la cuenta/s y/o tarjetas de pago seleccionadas y 1 &euro;, 2&euro;, 5 &euro; o 10 &euro; al alza, en funci&oacute;n de la cantidad que el usuario escoja.&nbsp; Por ejemplo, si el usuario ha escogido redondear al euro m&aacute;s cercano y este realiza una compra de 1,25&euro;, ser&aacute;n a&ntilde;adidos a su lista de redondeos los 0,75&euro; de diferencia. Si ha escogido redondear a los 2&euro; m&aacute;s cercanos, ser&aacute;n a&ntilde;adidos 1,75&euro; y as&iacute; sucesivamente.&nbsp;</span></span></span></li>
</ul>

<p style="margin-left:48px; text-align:justify">&nbsp;</p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Ingresos: la Herramienta calcula el 1 %, 2%, 5% o 10% de todos los ingresos percibidos en la cuenta de pagos del usuario, para posteriormente aportarlos a la p&oacute;liza de Ahorro e Inversi&oacute;n seleccionada.&nbsp;</span></span></span></li>
</ul>

<p>&nbsp;</p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Sin cafe&iacute;na: si el usuario activa esta Regla, se sumar&aacute; a la hucha virtual la cantidad de 1 &euro; por cada d&iacute;a sin haber consumido cafe&iacute;na. En el caso de que el usuario no cumpla este objetivo, deber&aacute; acceder a la Herramienta para informar que ese d&iacute;a, no se debe contabilizar la cantidad de 1 &euro; anteriormente mencionada. As&iacute;, esta cantidad ser&aacute; restada del total de la cuant&iacute;a acumulada a trav&eacute;s de esta regla.&nbsp;</span></span></span></li>
</ul>

<p>&nbsp;</p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Sin nicotina: si el usuario activa esta Regla, se sumar&aacute; a la hucha virtual la cantidad de 1 &euro; por cada d&iacute;a sin haber fumado un cigarrillo. En el caso de que el usuario no cumpla este objetivo, deber&aacute; acceder a la Herramienta para informar que ese d&iacute;a, no se debe contabilizar la cantidad de 1 &euro; anteriormente mencionada. As&iacute;, esta cantidad ser&aacute; restada del total de la cuant&iacute;a acumulada a trav&eacute;s de esta regla.</span></span></span></li>
</ul>

<p>&nbsp;</p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Equipo de Futbol: el usuario podr&aacute; elegir su equipo de futbol de la Liga Santander, de tal manera que, por cada victoria en un partido oficial de este, se sume la cantidad de 1 &euro; sobre la hucha de ahorro virtual.&nbsp;</span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI se reserva el derecho de incrementar la oferta de reglas de ahorro, as&iacute; como la eliminaci&oacute;n de alguna de ellas o su modificaci&oacute;n respecto de las caracter&iacute;sticas anteriormente indicadas.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Desde un punto de vista operativo, si la suma de todas las reglas anteriormente mencionadas es superior a 40&euro;, se proceder&aacute; a realizar una aportaci&oacute;n a la p&oacute;liza de Ahorro e Inversi&oacute;n del usuario. En el caso de que este no llegue al m&iacute;nimo, se seguir&aacute; acumulando hasta la siguiente semana.&nbsp; El proceso de aportaci&oacute;n se realiza cada viernes, y en funci&oacute;n de la entidad bancaria del usuario, este recibir&aacute; el cargo en su cuenta entre el lunes y el mi&eacute;rcoles siguiente a la orden.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La activaci&oacute;n de la Herramienta conllevar&aacute; la realizaci&oacute;n de las correspondientes aportaciones cuando, de la aplicaci&oacute;n de las reglas de ahorro, se alcance un importe m&iacute;nimo de 40 &euro;. Se proceder&aacute; a realizar la aportaci&oacute;n de dicha cuant&iacute;a sobre la p&oacute;liza de Ahorro/Inversi&oacute;n seleccionada, de manera autom&aacute;tica mediante el cargo de la citada cantidad en la cuenta bancaria que se encuentre asociada a la p&oacute;liza, y con frecuencia semanal (&oacute;rdenes ejecutadas cada viernes, siempre que se haya superado la cantidad anteriormente mencionada). Una vez realizada la aportaci&oacute;n a la p&oacute;liza, ser&aacute;n de aplicaci&oacute;n los criterios relativos al funcionamiento de esta.&nbsp;</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><u>Rescates:&nbsp;</u></span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">En lo relativo a los rescates de las cuant&iacute;as ligadas a la creaci&oacute;n de los objetivos de ahorro, son de aplicaci&oacute;n las indicaciones fijadas en la documentaci&oacute;n contractual del seguro de Ahorro-Inversi&oacute;n que el usuario haya activado. En esta l&iacute;nea, se podr&aacute; solicitar el rescate de las aportaciones realizadas a la p&oacute;liza activada desde el primer d&iacute;a de su realizaci&oacute;n, bajo los gastos de rescate fijados en las Condiciones Particulares del citado seguro.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">El mecanismo de solicitud de este rescate ser&aacute; a trav&eacute;s de los medios habituales, es decir, a trav&eacute;s del mediador del usuario, o seg&uacute;n las instrucciones indicadas en la propia p&oacute;liza o en &ldquo;Posici&oacute;n Financiera&rdquo;, ubicada en la app Mi Generali. O, si estuviera disponible, a trav&eacute;s de la Herramienta. La Herramienta tendr&aacute; un l&iacute;mite anual de aportaciones de </span></span></span><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#000000">5.000 &euro;. </span></span></span><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">De esta manera, el l&iacute;mite anteriormente comentado aplica desde el momento de la activaci&oacute;n del servicio, hasta el 31 de diciembre de cada a&ntilde;o en el que la Herramienta est&eacute; habilitada.&nbsp;</span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><u>Valoracion de objetivos:&nbsp;</u></span></span></span></li>
</ul>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Dentro del men&uacute; de consulta de objetivos, las cuant&iacute;as relativas al seguimiento de los mismos, derivadas de la aplicaci&oacute;n de las diferentes modalidades de aportaci&oacute;n activadas representar&aacute;n &uacute;nicamente el valor de las aportaciones sobre la p&oacute;liza de Ahorro-Inversi&oacute;n seleccionada. De esta manera, para consultar el Valor Acumulado de dichas aportaciones, el usuario debe dirigirse al aplicativo de Posici&oacute;n Financiera, donde se recoger&aacute; el Valor Acumulado del Seguro de Vida-Inversi&oacute;n, </span></span></span><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>que podr&iacute;a variar, al alza o a la baja, respecto a las aportaciones realizadas</strong></span></span></span><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">. En este sentido, </span></span></span><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>al ser la p&oacute;liza de Ahorro-Inversi&oacute;n seleccionada un seguro &ldquo;Unit linked&rdquo;, usted asume el riesgo de la inversi&oacute;n, quedando por tanto la cantidad que usted aporte a la p&oacute;liza sujeta a las fluctuaciones de los mercados financieros, los cuales son ajenos al control de GENERALI.</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Respecto a las condiciones del gasto, la Herramienta no contempla ning&uacute;n coste adicional a los establecidos en las Condiciones Particulares del seguro que usted tenga activado.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>IV. Duraci&oacute;n y baja del servicio</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI pone a disposici&oacute;n del usuario la Herramienta con una duraci&oacute;n indefinida, si bien dicho servicio estar&aacute; vinculado a que el usuario mantenga contratado el &ldquo;Servicio de informaci&oacute;n sobre cuentas&rdquo; de Morpheus Aiolos, S.L. (o tercer proveedor del servicio de informaci&oacute;n sobre cuentas que en su caso designe GENERALI), con el fin de que GENERALI pueda obtener la informaci&oacute;n de pagos requerida para aplicar las referidas reglas de ahorro.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Los usuarios que deseen darse de baja de la Herramienta deber&aacute;n proceder a la cancelaci&oacute;n de los objetivos de ahorro que ten&iacute;an configurados en ese momento.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">As&iacute; mismo, para procesar la baja del servicio de la Herramienta, el usuario deber&aacute; enviar un correo electr&oacute;nico a la siguiente direcci&oacute;n </span></span></span><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#ff0000">(bajas@generali.coinscrap.com</span></span></span><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">), solicitando la cancelaci&oacute;n del servicio de la Herramienta.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">En el caso de que en el momento de cancelaci&oacute;n de un objetivo o de la baja de la Herramienta, exista alguna cuant&iacute;a de ahorro virtual que no se haya aportado en la p&oacute;liza de Ahorro e Inversi&oacute;n por no haber llegado al l&iacute;mite m&iacute;nimo de 40 &euro; anteriormente mencionados, no se incluir&aacute; en el Plan en ninguno de los casos. Sin embargo, en el supuesto de que el usuario decida cancelar un objetivo o darse de baja en la Herramienta en el plazo de tres d&iacute;as naturales posteriores a un viernes en los que se haya producido la aportaci&oacute;n, &eacute;sta se incluir&aacute; en la p&oacute;liza.&nbsp;&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Adicionalmente, el usuario podr&aacute; solicitar el rescate de las aportaciones dinerarias realizadas, las cuales podr&aacute;n variar al alza o a la baja, al ser la p&oacute;liza de Ahorro-Inversi&oacute;n seleccionada un seguro &ldquo;Unit linked&rdquo;, en la cual el Tomador asume el riesgo de la Inversi&oacute;n realizada, quedando por tanto la cantidad aportada sujeta a las fluctuaciones de los mercados financieros, los cuales son ajenos al control de GENERALI.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">En el caso de la consecuci&oacute;n o cancelaci&oacute;n de un objetivo, el usuario tendr&aacute; disponible un men&uacute; de consulta en el que podr&aacute; visualizar las aportaciones relativas a dichos objetivos, as&iacute; como el resto de las funcionalidades de consulta de movimientos aplicables a los objetivos de ahorro activos.&nbsp;&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI podr&aacute; requerir al usuario para que, durante este proceso, rellene alg&uacute;n tipo de formulario relativo a sus impresiones y opiniones sobre la Herramienta. La decisi&oacute;n de cumplimentar y enviar dicho formulario ser&aacute; voluntaria, no pudiendo GENERALI impedir la cancelaci&oacute;n en caso de que el usuario no desee responder.&nbsp;</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>V. Exclusi&oacute;n de garant&iacute;as y responsabilidad</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>Garant&iacute;as</strong></span></span></span></p>

<ul>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Gesti&oacute;n eficaz y servicio post-venta a trav&eacute;s de la aplicaci&oacute;n de Mi Generali, lo que nos permite resolver incidencias en un periodo de tiempo reducido.</span></span></span></li>
	<li style="list-style-type:disc"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Servicio sin coste: My Pocket es parte de una aplicaci&oacute;n m&oacute;vil completamente gratuita; aunque GENERALI se reserva el derecho a introducir en el futuro funcionalidades avanzadas que requieran de un pago para permitir su uso por parte del usuario.</span></span></span></li>
</ul>

<p><br />
&nbsp;</p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>Responsabilidades</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI se reserva el derecho a interrumpir el acceso a la Herramienta, as&iacute; como la prestaci&oacute;n de cualquiera o de todos los contenidos que se prestan a trav&eacute;s de la misma en cualquier momento y sin previo aviso; ya sea por motivos t&eacute;cnicos, de seguridad, de control, de mantenimiento, por fallos de suministro el&eacute;ctrico o por cualquier otra causa fundada. En consecuencia, GENERALI no garantiza la disponibilidad ni la continuidad de la Herramienta ni de los contenidos; sin que, en ning&uacute;n momento, puedan exigirse responsabilidades a GENERALI por la discontinuidad o falta de disponibilidad de sus servicios.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI no ser&aacute; responsable, pues, en caso de que existan interrupciones del servicio, demoras, errores, mal funcionamiento del mismo y, en general, dem&aacute;s inconvenientes que tengan su origen en causas que escapan a su control, y/o debidas a una actuaci&oacute;n dolosa o culposa de los usuarios y/o tengan por origen causas de fuerza mayor. Se entender&aacute;n incluidos en el concepto de fuerza mayor todos aquellos acontecimientos acaecidos fuera del control de GENERALI, tales como: fallo de terceros, operadores o compa&ntilde;&iacute;as de servicios, actos de Gobierno, falta de acceso a redes de terceros, actos u omisiones de las Instituciones P&uacute;blicas, aquellos otros producidos como consecuencia de fen&oacute;menos naturales y el ataque de hackers, crackers u otros terceros a la seguridad o integridad del sistema inform&aacute;tico. En cualquier caso, sea cual fuere la causa, GENERALI no asumir&aacute; responsabilidad alguna ya sea por da&ntilde;os directos o indirectos, da&ntilde;o emergente y/o por lucro cesante.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI excluye cualquier responsabilidad por los da&ntilde;os y perjuicios de toda clase que puedan deberse a la presencia de virus o de otros elementos lesivos en los ordenadores y smartphones de los usuarios, as&iacute; como en los documentos que los usuarios pueden subir a trav&eacute;s de la Herramienta a la hora de contratar alg&uacute;n producto.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">GENERALI no se hace responsable del uso que los usuarios hagan del contenido de la Herramienta as&iacute; como de cualquier otro material contenido en la aplicaci&oacute;n que pueda suponer una violaci&oacute;n de cualquier tipo de norma, nacional o internacional, de los derechos de propiedad intelectual o industrial o cualquier otro derecho de terceros. De igual forma, no se hace responsable de los posibles errores de seguridad que se pudieran producir por el hecho de utilizar versiones de navegadores no actualizadas, o de las consecuencias que se pudieran derivar del mal funcionamiento del navegador, ya sea por configuraci&oacute;n inadecuada, presencia de virus inform&aacute;ticos o cualquier otra causa ajena a GENERALI. Por ello GENERALI recomienda al usuario mantener el dispositivo con el que haga uso de los servicios contratados actualizado y en buen estado para asegurar el correcto funcionamiento de la aplicaci&oacute;n y de la Herramienta. Asimismo, GENERALI no se hace responsable de que los usuarios no puedan usar los contenidos y servicios contratados por obsolescencia de sus dispositivos.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>VI. Seguridad</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La Herramienta funciona sobre un servidor seguro utilizando el protocolo SSL. Este servidor seguro establece una conexi&oacute;n de modo que la informaci&oacute;n se transmite cifrada mediante algoritmos de 256 bits que aseguran que solo sea inteligible para el ordenador/smartphone del usuario y el del sitio web/aplicaci&oacute;n.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">Conforme a la legislaci&oacute;n vigente en materia de protecci&oacute;n de datos, GENERALI ha adoptado los niveles de seguridad adecuados a los datos facilitados por los usuarios y, adem&aacute;s, ha puesto todos los medios y medidas a su alcance para evitar la p&eacute;rdida, mal uso, alteraci&oacute;n, acceso no autorizado y extracci&oacute;n de los mismos.</span></span></span></p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>VII. Propiedad intelectual e industrial</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La totalidad de los contenidos de la Herramienta, entendiendo por &eacute;stos a t&iacute;tulo meramente enunciativo los textos, fotograf&iacute;as, gr&aacute;ficos, im&aacute;genes, iconos, tecnolog&iacute;a, software, links, dominios, marcas y dem&aacute;s contenidos audiovisuales o sonoros, as&iacute; como su dise&ntilde;o gr&aacute;fico y c&oacute;digos fuente, son de la exclusiva propiedad de GENERALI o de terceros, cuyos derechos en su caso reconoce GENERALI, y est&aacute;n sujetos a derechos de propiedad intelectual e industrial protegidos por la legislaci&oacute;n nacional e internacional. Queda estrictamente prohibido la utilizaci&oacute;n de cualquiera de los elementos objeto de propiedad industrial e intelectual para cualquier tipo de finalidad, en especial comercial, as&iacute; como su distribuci&oacute;n, comunicaci&oacute;n p&uacute;blica, modificaci&oacute;n, alteraci&oacute;n o transformaci&oacute;n, salvo autorizaci&oacute;n expresa por escrito por parte de GENERALI</span></span></span></p>

<p><br />
<br />
<br />
<br />
<br />
<br />
<br />
&nbsp;</p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>VIII. Pol&iacute;tica de privacidad y tratamiento de datos personales</strong></span></span></span></p>

<table cellspacing="0" style="border-collapse:collapse; border:none; width:100%">
	<tbody>
		<tr>
			<td colspan="2" style="background-color:#f79646; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:142px">
			<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><strong>Informaci&oacute;n b&aacute;sica sobre Protecci&oacute;n de Datos</strong></span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#d5d0b4; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:142px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Responsable del tratamiento</strong></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:424px">
			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Generali Espa&ntilde;a S.A, de Seguros y Reaseguros (&ldquo;</span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>GENERALI</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&rdquo;).</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#d5d0b4; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:142px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Finalidades del tratamiento</strong></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:424px">
			<ol>
				<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Facilitarle el acceso a un servicio de ahorro, complementario a sus Seguros, a trav&eacute;s de </span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>My Pocket</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">.</span></span></span></li>
				<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Ofrecerle otros productos y servicios de GENERALI, a trav&eacute;s del env&iacute;o de comunicaciones comerciales. Estas comunicaciones se basan en su informaci&oacute;n de pagos, pero &uacute;nicamente en la medida de lo estrictamente necesario para identificar nuestros productos o servicios que puedan ser de su inter&eacute;s.&nbsp;</span></span></span></li>
				<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Comunicar sus datos a terceros &uacute;nicamente cuando sea necesario para cumplir con una obligaci&oacute;n legal o para gestionar este servicio.</span></span></span></li>
			</ol>
			</td>
		</tr>
		<tr>
			<td style="background-color:#d5d0b4; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:142px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Legitimaci&oacute;n del tratamiento</strong></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:424px">
			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Sus Datos Personales ser&aacute;n tratados con base en la ejecuci&oacute;n de un contrato. Asimismo, sus Datos Personales ser&aacute;n tratados con base en su consentimiento para el env&iacute;o de comunicaciones comerciales, caso lo facilite.&nbsp;</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#d5d0b4; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:142px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Potenciales destinatarios de los datos</strong></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:424px">
			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Administraciones P&uacute;blicas y proveedores de GENERALI como Encargados del Tratamiento de los Datos Personales necesarios para gestionar este servicio.</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#d5d0b4; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:142px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Procedencia de los datos</strong></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:424px">
			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Tratamos los siguientes Datos Personales, facilitados por usted en el momento en que se da de alta en </span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>My Pocket</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">:&nbsp;</span></span></span></p>

			<ul>
				<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Sus datos de usuario (nombre, apellidos, correo electr&oacute;nico, numero de m&oacute;vil)&nbsp;</span></span></span></li>
				<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Sus objetivos (fecha, importe a obtener, cantidad ahorrada)</span></span></span></li>
				<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Sus reglas de ahorro (detalles, movimientos, cantidades ahorradas, liquidaci&oacute;n de objetivos y aportaciones).</span></span></span></li>
			</ul>
			&nbsp;

			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Adicionalmente, tratamos su informaci&oacute;n sobre cuentas de pago facilitada por la Entidad de Pagos MORPHEUS AIOLOS, S.L., con CIF B-86556420, domiciliada en la Calle de San Andr&eacute;s 8, Madrid, a quien usted ha solicitado que nos facilite sus Datos Personales.</span></span></span></p>

			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Todos los Datos Personales identificados son necesarios para la prestaci&oacute;n del servicio a trav&eacute;s de My Pocket, por lo que en caso que no los facilite no podremos prestarle el servicio de ahorro.&nbsp;</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#d5d0b4; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:142px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Derechos de protecci&oacute;n de datos</strong></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:424px">
			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El consentimiento prestado para el env&iacute;o de comunicaciones comerciales, en caso de haberlo facilitado, podr&aacute; ser revocado por usted en cualquier momento.&nbsp;</span></span></span></p>

			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Usted puede ejercitar sus derechos de acceso, rectificaci&oacute;n, supresi&oacute;n, limitaci&oacute;n, portabilidad de datos y retirada de su consentimiento, tal como se detalle en la &ldquo;Informaci&oacute;n Adicional&rdquo;.</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#d5d0b4; border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:middle; width:142px">
			<p><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Informaci&oacute;n adicional</strong></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid #000000; border-left:1px solid #000000; border-right:1px solid #000000; border-top:1px solid #000000; vertical-align:top; width:424px">
			<p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Puede consultar la informaci&oacute;n adicional detallada sobre Protecci&oacute;n de Datos en la siguiente web:</span></span></span></p>

			<p style="text-align:justify"><a href="https://www.generali.es/quienes-somos/privacidad" style="text-decoration:none"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span style="color:#0000ff"><u>https://www.generali.es/quienes-somos/privacidad</u></span></span></span></a></p>
			</td>
		</tr>
	</tbody>
</table>

<p style="text-align:justify">&nbsp;</p>

<p><br />
&nbsp;</p>

<p style="text-align:justify"><span style="font-size:13.5pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444"><strong>IX. Ley aplicable y jurisdicci&oacute;n</strong></span></span></span></p>

<p style="text-align:justify"><span style="font-size:12pt"><span style="font-family:Raleway,sans-serif"><span style="color:#444444">La relaci&oacute;n entre GENERALI y el usuario se regir&aacute; por los puntos contenidos en el presente contrato, sujetos en todo caso a la normativa espa&ntilde;ola vigente. Las partes se someten, a su elecci&oacute;n, para la resoluci&oacute;n de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del usuario.</span></span></span></p>

<p>&nbsp;</p>


`,
										}}
									></div>
									<View ref={lastPlanElementRef} style={{ height: 10 }}>
										<View ref={faqRef} />
									</View>
								</View>
								<TermsArrowDown
									style={{ opacity: isScroll ? 1 : 0 }}
									onClick={() => {
										faqRef.current.scrollIntoView({ behavior: 'smooth' });
									}}
								/>

								<Button
									style={{ marginTop: 10, position: 'fixed', bottom: 5 }}
									borderWhiteButtonSt
									disabled={!!buttonDisabled}
									onClick={async () => {
										await onClick();
										close();
									}}
								>
									Aceptar
								</Button>
							</View>
						)}
					</View>
				</View>
			</View>
		</Modal>
	);
};

export default LegalModal;
