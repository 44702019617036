import React from 'react';

import { Image, Text, View } from '@coinscrap/webapp-core';

// images
import GreenCheck from 'common/svgs/GreenCheck';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KO from 'assets/images/KO.png';

// configs
import colors from 'config/colors';

// libs
import { getCurrency } from 'libs/Currency';

const RuleItem = ({ data, onClick, style }) => {
  return (
    <View
      horizontalSt
      style={{ padding: '15px 0px', borderBottom: `1px solid ${colors.grey7}`, ...style }}
      onClick={onClick}
      pointerSt
    >
      <View style={{ width: '15%' }}>
        {data.isActive ? (
          <GreenCheck style={{ width: 15, height: 'auto' }} />
        ) : (
          <Image src={KO} style={{ width: 15, height: 'auto' }} />
        )}
      </View>
      <View topSt style={{ width: '35%' }}>
        <Text ellipsisSt style={{ fontSize: 13, width: '90%' }}>
          {data.name}
        </Text>
      </View>
      {/* <View topSt style={{ width: '15%' }}>
        <Text style={{ fontSize: 13 }}>
          {data.amountVirual === 0
            ? getCurrency(data.amountVirual, 0, false)
            : getCurrency(data.amountVirual, 2, false)}
        </Text>
      </View> */}
      <View topSt style={{ width: '25%' }}>
        <Text style={{ fontSize: 13 }}>
          {data.amountVirual === 0
            ? getCurrency(data.amountVirual, 0, false)
            : getCurrency(data.amountVirual, 2, false)}
        </Text>
      </View>
      <View horizontalSt spaceBetweenSt style={{ width: '25%' }}>
        <View autoWidthSt>
          <Text redSt style={{ fontSize: 13 }}>
            {data.amountTotal === 0
              ? getCurrency(data.amountTotal, 0, false)
              : getCurrency(data.amountTotal, 2, false)}
          </Text>
        </View>
        <ArrowForwardIosIcon style={{ color: '#CCCCCC', fontSize: 13, marginRight: 10 }} />
      </View>
    </View>
  );
};

export default RuleItem;
