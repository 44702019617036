import React, { useState, useCallback } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  Layout,
  Button,
  Image,
  RedirectHOC,
  UserWrapper,
} from '@coinscrap/webapp-core';

// components
import Footer from '../../common/Footer';
import HeaderRedPrimary from '../../common/HeaderRedPrimary';

// configs
import colors from '../../config/colors';
import routes from '../../config/routes';

// data
import { OnboardingSlides } from './data';

export default RedirectHOC(
  {
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
  },
  ({ user }) => {
    return user?.metadata?.termsAccepted && user?.metadata?.policyAccepted && user?.metadata?.signCompleted
      ? { key: routes.fetchPolicies, navigationProps: { mode: 'replace' } }
      : undefined; // navega a la ruta devuelta
  },
)(() => {
  const [slides] = useState(OnboardingSlides);

  const [currentSection, setCurrentSection] = useState(1);

  const { useBackground, useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, useBackRoute } = NavigationWrapper.use();

  useBackground(colors.white);

  useBackRoute(undefined);
  useLoading(false);

  let section = null;

  for (const property in slides) {
    if (slides[property].id === currentSection) {
      section = slides[property];
    }
  }

  const switchInfo = useCallback(
    (id) => {
      setCurrentSection(id);
    },
    [setCurrentSection],
  );

  const nextInfo = useCallback(() => {
    if (currentSection === 4) {
      navigateTo(routes.finished);
      return;
    }
    setCurrentSection((prevState) => prevState + 1);
  }, [setCurrentSection, currentSection, navigateTo]);

  useHeader(
    <View>
      <HeaderRedPrimary />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button regularButtonSt style={{ marginBottom: 50 }} onClick={() => nextInfo()}>
        Siguiente
      </Button>
      <View horizontalSt>
        <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={1} />
        <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={2} />
        <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={3} />
        <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={4} />
      </View>
    </Footer>,
    [switchInfo, currentSection, nextInfo],
  );
  return (
    <Layout.Container>
      <View width90St fullHeightSt justifyCenterSt style={{ paddingTop: 10 }}>
        <View>
          <View style={{ height: 190 }}>
            <Image src={section.image} style={{ width: 188, height: 'auto' }} />
          </View>
          <View style={{ marginTop: 25 }}>
            <Text font27St textCenterSt>
              {section.headline}
            </Text>
            <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
              {section.description}
            </Text>
          </View>
        </View>
        {/* <View style={{ marginBottom: 15 }}>
          <Button regularButtonSt style={{ marginBottom: 50 }} onClick={() => nextInfo()}>
            Siguiente
          </Button>
          <View horizontalSt>
            <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={1} />
            <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={2} />
            <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={3} />
            <DotComponent currentSection={currentSection} switchInfo={switchInfo} id={4} />
          </View>
        </View> */}
      </View>
    </Layout.Container>
  );
});

export const DotComponent = ({ currentSection, switchInfo, id }) => {
  return (
    <View
      dotsSt
      dotsActiveSt={currentSection >= id ? true : false}
      onClick={() => switchInfo(id)}
    />
  );
};
