import { useCallback } from "react";
import { OperationsWrapper, PickerHOC, UserWrapper } from '@coinscrap/webapp-core';

export const useOtp = (payload) => {
    const { parentTarget } = PickerHOC.use();
    const { user } = UserWrapper.use();
    const { createOperation, sendAction, operations, updateOperation } = OperationsWrapper.use();

    const opsArrray = Object.keys(operations || {});

    const combinedId = parentTarget?.creationData?.destProduct?.combinedId;
    const prodInstData = combinedId?.split('-') || [payload.institutionAccountId, payload.productId];
    const sendNew = useCallback(async () => {
        //Discard old ones
        try {
            await new Promise.all(opsArrray.map(op => sendAction(op, {
                actionType: 'DISCARD_OPERATION',
            })))
        } catch (e) { }

        //Create New
        const op = await createOperation(
            'VALIDATE_OTP',
            {
                institutionAccountId: prodInstData[0],
                productId: prodInstData[1],
            },
            'AWAIT_CREATION_ASYNC'
        )
        return op
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prodInstData[0], prodInstData[1], opsArrray, user?.id])

    const validate = useCallback(async (operationId, code) => {
        await sendAction(
            operationId,
            { payloadType: 'OTP_VALIDATION' },
            {
                code,
            }
        );
        await updateOperation(operationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id])

    return { sendNew, validate }

}