const { UiWrapper } = require('@coinscrap/webapp-core');

UiWrapper.defineComponent('image', {
  styles: {
    default: {},

    fullWidthSt: {
      width: '100%',
    },

    topRadiusSt: {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },

    pointerSt: {
      cursor: 'pointer'
    },
  },
});
