/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import {
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  TargetsUtilsWrapper,
  Page,
} from '@coinscrap/webapp-core';
// components
import Header from 'common/Header';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
// import { NavigationScreens } from '../../config/navigationWrapperConfiguration';
//
import { UiWrapper, View, Button, Text, Enfatize } from '@coinscrap/webapp-core';
import routes from '../../config/routes';

import { LoadingData } from 'common/LoadingData';

export default PickerHOC(
  {
    userTargets: {
      arrayItemFilter: {
        itemPropertyPath: 'level',
        type: 'value',
        value: 1,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)((props) => {
  const { navigateTo } = NavigationWrapper.use();
  const { useBackground, useLoading, useHeader } = UiWrapper.use();
  const { userTargets } = PickerHOC.use();
  const {
    params: { idParentTarget },
  } = Page.use();
  const { instanciateNewTarget } = TargetsUtilsWrapper.use();

  const [creating, setCreating] = useState(false);
  const targetsFiltered = (userTargets || []).filter((tp) => tp.status === 'TEMPORARY');

  useLoading(!targetsFiltered?.length || creating);
  useBackground('white');

  const createNew = async () => {
    try {
      setCreating(true);
      const tp = await instanciateNewTarget({ parentTarget: idParentTarget });
      setCreating(false);
      navigateTo(routes.objectiveInfo, {
        mode: 'replace',
        routeParams: {
          idTarget: tp.id,
        },
      });
    } catch (e) {
      setCreating(false);
      console.log('ERROR', e);
    }
  };

  useEffect(() => {
    if (targetsFiltered && targetsFiltered.length > 0) {
      return;
    } else {
      createNew();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="" left="atras" hideFAQ />
    </View>,
    [],
  );

  return targetsFiltered?.length ? (
    <View bodySt fullHeightSt vAlign="center">
      <Text style={{ textAlign: 'center', width: '80%' }}>
        Tienes un objetivo <Enfatize>pendiente de configurar</Enfatize>. Puedes crear un objetivo{' '}
        <Enfatize>nuevo</Enfatize> o continuar con la configuración del <Enfatize>actual</Enfatize>.
      </Text>
      <Button
        style={{ width: 225, padding: 10 }}
        onClick={() => {
          navigateTo(routes.objectiveInfo, {
            mode: 'replace',
            routeParams: {
              idTarget: targetsFiltered[targetsFiltered.length - 1].id,
            },
          });
        }}
      >
        Actual
      </Button>
      <Button
        style={{ width: 225, padding: 10 }}
        id="newButton"
        pillSt
        onClick={async () => {
          await createNew();
        }}
      >
        Uno nuevo
      </Button>
    </View>
  ) : null;
});
