import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';
import fonts from '../fonts';

import commonStyles from './common';

UiWrapper.defineComponent('text', {
  styles: {
    default: {
      color: colors.dark,
      fontSize: 15,
      lineHeight: 1.2,
      fontFamily: fonts.HelveticaNeue,
    },

    underLineSt: {
      textDecoration: 'underline',
    },

    upperCaseSt: {
      textTransform: 'uppercase',
    },

    noMarginSt: {
      margin: 0,
    },

    textCenterSt: {
      textAlign: 'center',
    },

    centerSt: {
      textAlign: 'center',
    },

    leftSt: {
      textAlign: 'left',
    },

    rightSt: {
      textAlign: 'right',
    },

    noLineHeightSt: {
      lineHeight: '100%',
    },

    left16St: { marginLeft: 16 },

    right15St: { marginRight: 15 },
    boldSt: {
      fontFamily: fonts.HelveticaNeueBold,
    },
    boldItalicSt: {
      fontFamily: fonts.HelveticaNeueBoldItalic,
    },
    condensedSt: {
      fontFamily: fonts.HelveticaNeueCondensedBlack,
    },
    condensedBoldSt: {
      fontFamily: fonts.HelveticaNeueCondensedBold,
    },
    italicSt: {
      fontFamily: fonts.HelveticaNeueItalic,
    },

    lightSt: {
      fontFamily: fonts.HelveticaNeueLight,
    },
    lightItalicSt: {
      fontFamily: fonts.HelveticaNeueLightItalic,
    },
    regularSt: {
      fontFamily: fonts.HelveticaNeue,
    },

    mediumSt: {
      fontFamily: fonts.HelveticaNeueMedium,
    },
    mediumItalicSt: {
      fontFamily: fonts.HelveticaNeueMediumItalic,
    },
    thinSt: {
      fontFamily: fonts.HelveticaNeueThin,
    },
    thinItalicSt: {
      fontFamily: fonts.HelveticaNeueThinItalic,
    },
    ultraLightSt: {
      fontFamily: fonts.HelveticaNeueUltraLight,
    },
    ultraLightItalicSt: {
      fontFamily: fonts.HelveticaNeueUltraLightItalic,
    },

    capitalizeSt: {
      textTransform: 'capitalize',
    },

    uppercaseSt: {
      textTransform: 'uppercase',
    },

    whiteSt: {
      color: colors.white,
    },
    blackSt: {
      color: colors.black,
    },
    black2St: {
      color: colors.black2,
    },

    blackLightSt: {
      color: colors.blackLight,
    },
    grey1St: {
      color: colors.grey1,
    },
    grey2St: {
      color: colors.grey2,
    },
    grey4St: {
      color: colors.grey4,
    },

    redSt: {
      color: colors.red,
    },
    greenSt: {
      color: colors.green,
    },

    font10St: {
      fontSize: 10,
    },
    font11St: {
      fontSize: 11,
    },
    font12St: {
      fontSize: 12,
    },
    font13St: {
      fontSize: 13,
    },
    font14St: {
      fontSize: 14,
    },
    font15St: {
      fontSize: 15,
    },
    font16St: {
      fontSize: 16,
    },
    font17St: {
      fontSize: 17,
    },
    font18St: {
      fontSize: 18,
    },
    font24St: {
      fontSize: 24,
    },
    font20St: {
      fontSize: 20,
    },
    font27St: {
      fontSize: 27,
    },
    font28St: {
      fontSize: 28,
    },
    font32St: {
      fontSize: 32,
    },

    lineHeight16St: {
      lineHeight: 1.6,
    },

    ellipsisSt: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    h1St: {
      fontSize: 32,
      fontFamily: fonts.regularSt,
      fontWeight: 300,
      lineHeight: '42px',
    },

    h3St: {
      fontSize: 24,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 700,
      lineHeight: '36px',
    },

    titleHeaderSt: {
      fontSize: 28,
      fontFamily: fonts.SantanderTextLight,
      lineHeight: '40px',
      color: colors.black2,
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 15,
    },

    paragraphMarginSt: {
      marginBottom: 10,
    },
    notActivebuttonSt: {
      textAlign: 'center',
      width: '42%',
      fontWeight: 400,
      padding: '13px 7px',
      borderRadius: 5,
    },
    activebuttonSt: {
      color: colors.green,
      backgroundColor: 'white',
      transition: 'all .5s',
      boxShadow: '1px 1px 2px rgba(155, 195, 211, 0.3)',
      textAlign: 'center',
      width: '42%',
      fontWeight: 800,
    },

    labelSt: {
      fontSize: 16,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.blackLight,
    },

    normalSt: {
      fontSize: 14,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: '18px',
      color: colors.blackLight,
    },

    smallerSt: {
      fontSize: 13,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: '13px',
    },

    smallerBillSt: {
      fontSize: 13,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 400,
      lineHeight: '20px',
      color: colors.greyText2,
    },

    giveBackSt: {
      fontSize: 10,
      fontFamily: fonts.SantanderTextRegular,
      fontWeight: 700,
      lineHeight: '16px',
      textTransform: 'uppercase',
      color: colors.violet,
      borderRadius: 2,
      border: '1px solid ' + colors.violet,
      marginTop: 10,
      padding: '0 9px',
    },
    tabTextSt: {
      // fontSize: '45px',
    },
    faqTextSt: {
      fontSize: 15,
      color: '#888',
      lineHeight: 1.4,
      marginBottom: 7,
      textAlign: 'start',
      width: '100%',
    },

    pointerSt: {
      cursor: 'pointer'
    },

    ...commonStyles.styles,
  },
});
