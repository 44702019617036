import React, { useState } from 'react';
import { Button, Text, TextField, View, UiWrapper } from '@coinscrap/webapp-core';
import colors from 'config/colors';

import { onChangeAmountHandler, amountStringToNumber } from '../../../libs/currencyInput';
import ErrorModal from 'common/ErrorModal';
import InfoIcon from '@material-ui/icons/Info';

const HotModal = ({ isOpen, onClose, user, onConfirmAmount }) => {
  const { openModal } = UiWrapper.use();

  const [hotAmount, setHotAmount] = useState(0);
  return (
    <>
      <View style={{ height: '100%', outline: 'none' }}>
        <View style={{ backgroundColor: colors.white, width: '80%', padding: 30 }}>
          <Text style={{ fontSize: 16, marginBottom: 10 }}>¿Cuanto quieres aportar?</Text>

          <TextField
            value={hotAmount}
            createInputSt
            type="text"
            onChange={(e) => onChangeAmountHandler(e.target.value, setHotAmount)}
            style={{ backgroundColor: '#e2e2e2' }}
            endAdornment={
              <Text style={{ paddingTop: 2, marginLeft: 5, color: colors.black }}> €</Text>
            }
          />
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              style={{
                padding: '9px',
                backgroundColor: colors.white,
                border: `1px solid ${colors.red}`,
                textComponent: {
                  color: colors.red,
                },
              }}
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              style={{ padding: '9px', border: `1px solid ${colors.red}` }}
              disabled={!hotAmount}
              onClick={async () => {
                const amount = Number(amountStringToNumber(hotAmount));
                if (amount > 3000) {
                  openModal(
                    (close) => (
                      <ErrorModal>
                        <Text style={{ textAlign: 'center' }}>
                          Lo sentimos, el importe máximo no puede superar los 3.000€
                        </Text>
                        <View horizontalSt topSt startSt font12St style={{ marginTop: 15 }}>
                          <InfoIcon style={{ fontSize: 16, marginRight: 5, color: colors.red }} />
                          <Text font12St>
                            El importe máximo total entre todas las reglas por año es de 5.000€.
                          </Text>
                        </View>
                        <Button
                          onClick={() => {
                            close();
                          }}
                          style={{ width: 150, marginTop: 25 }}
                        >
                          Volver
                        </Button>
                      </ErrorModal>
                    ),
                    { disableClickAway: false },
                  );
                } else if (amount < 10) {
                  openModal(
                    (close) => (
                      <ErrorModal>
                        <Text style={{ textAlign: 'center' }}>
                          Lo sentimos, el importe mínimo no puede ser inferior a 10€
                        </Text>
                        <View horizontalSt topSt startSt font12St style={{ marginTop: 15 }}>
                          <InfoIcon style={{ fontSize: 16, marginRight: 5, color: colors.red }} />
                          <Text font12St>
                            El importe máximo total entre todas las reglas por año es de 5.000€.
                          </Text>
                        </View>
                        <Button
                          onClick={() => {
                            close();
                          }}
                          style={{ width: 150, marginTop: 25 }}
                        >
                          Volver
                        </Button>
                      </ErrorModal>
                    ),
                    { disableClickAway: false },
                  );
                } else {
                  await onConfirmAmount(amount);
                }
              }}
            >
              Aceptar
            </Button>
          </View>
        </View>
      </View>
    </>
  );
};

export default HotModal;
