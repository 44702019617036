import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';
import fonts from '../fonts';

UiWrapper.defineComponent('textField', {
  styles: {
    default: {
      color: colors.dark,
      containerComponent: {
        width: '80%',
        backgroundColor: colors.grey5,
        // border: "1px solid #f60",
      },
      inputContainerComponent: {
        borderRadius: '30px',
        paddingLeft: 0,
        backgroundColor: 'rgb(100, 100, 100, 0.35)',
        border: '1px solid ' + colors.orange,
        fontFamily: fonts.regular,
      },
      inputComponent: {
        textAlign: 'center',
        color: colors.dark,
        // padding: "16px 0 12px 0",
        // padding: "25px 0px 7px",
      },
      labelComponent: {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: fonts.regular,
        textAlign: 'center',
        width: '90%',
        color: colors.grey,
        // display: "none",
      },
      // noLabelMode: {
      //   inputComponent: {
      //     paddingTop: 7,
      //   },
      // },
    },

    centerSt: {
      inputComponent: {
        textAlign: 'center',
      },
    },
    transparentSt: {
      inputComponent: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },

    integerSt: {
      inputComponent: {
        textAlign: 'center',
        paddingTop: '12px',
        fontSize: '22px',
      },
    },

    squareWithBorderSt: {
      containerComponent: {
        border: '1px solid #DEEDF2',
        boxSizing: 'border-box',
        width: '100%',
        height: '64px',
        borderBottom: 0,
        borderRadius: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: '#FAFBFC',
        borderBottom: '1px solid #9BC3D3',
        borderRadius: 0,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        height: '100%',
      },
      inputComponent: {
        height: '100%',
        paddingBottom: 6,
        fontSize: 20,
        fontFamily: fonts.SantanderTextRegular,
        fontWeight: 400,
        color: colors.blackLight,
        textAlign: 'left',
      },
      labelComponent: {
        // paddingTop: 6,
        fontSize: 14,
        fontFamily: fonts.SantanderTextRegular,
        fontWeight: 400,
        color: colors.grey2,
        textAlign: 'left',
      },
    },

    font16St: {
      containerComponent: {
        height: '48px',
      },
      inputComponent: {
        fontSize: 16,
      },
    },

    height56St: {
      containerComponent: {
        height: '56px',
        marginTop: 0,
      },
    },

    bigSt: {
      containerComponent: {
        width: '100%',
        borderBottom: '2px solid' + colors.blue,
        paddingBottom: 12,
      },
      inputComponent: {
        color: colors.grey,
        fontSize: 35,
        fontWeight: 400,
        textAlign: 'center',
        fontFamily: fonts.SantanderTextRegular,

        padding: 0,
        height: 40,
      },
    },
    lightBcSt: {
      inputContainerComponent: {
        backgroundColor: 'rgba(255,255,255,0)',
      },
      focusedMode: {
        inputContainerComponent: {
          backgroundColor: 'rgba(255,255,255,0)',
        },
      },
    },
    borderBottomSt: {
      inputContainerComponent: {
        paddingBottom: 0,
      },
    },

    afterBanksInputSt: {
      containerComponent: {
        borderBottom: `1px solid #d7d7d7`,
        boxSizing: 'border-box',
        width: '100%',
        height: '43px',
        borderRadius: 0,
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: colors.white,
        borderRadius: 0,
        height: '100%',
      },
      inputComponent: {
        height: '100%',
        paddingBottom: 6,
        fontSize: 16,
        color: colors.dark,
        textAlign: 'left',
      },
      labelComponent: {
        fontSize: 14,
        color: colors.dark,
        textAlign: 'left',
      },
      noLabelMode: {
        inputComponent: {
          paddingTop: 7,
        },
      },
      focusedMode: {
        containerComponent: {
          borderBottom: `2px solid #ff4081`,
        },
        inputContainerComponent: {
          backgroundColor: colors.white,
        },
      },
    },

    createInputSt: {
      containerComponent: {
        borderBottom: `1px solid ${colors.red}`,
        boxSizing: 'border-box',
        width: '100%',
        height: '43px',
        borderRadius: 0,
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: colors.grey5,
        borderRadius: 0,
        height: '100%',
      },
      inputComponent: {
        height: '100%',
        paddingBottom: 6,
        fontSize: 20,
        fontFamily: fonts.HelveticaNeue,
        color: colors.dark,
        textAlign: 'right',
      },
      labelComponent: {
        fontSize: 14,
        fontFamily: fonts.HelveticaNeue,
        color: colors.dark,
        textAlign: 'left',
      },
      noLabelMode: {
        inputComponent: {
          paddingTop: 7,
        },
      },
    },

    ruleTransparentBackgroundSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${colors.white}`,
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
      },
      inputComponent: {
        color: colors.white,
        textAlign: 'right',
      },
    },

    inputImgModalBackgroundSt: {
      containerComponent: {
        width: '100%',
        height: 50,
        backgroundColor: '#EAEAEA',
        borderRadius: '4px 4px 0px 0px',
        paddingBottom: 0,
        justifyContent: 'center',
        padding: '0px 15px',
        borderBottom: '1px solid' + colors.pink,
        overflow: 'hidden',
      },
      inputContainerComponent: {
        backgroundColor: '#EAEAEA',
        height: '100%',
      },
      inputComponent: {
        color: colors.blackLight,
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '24px',
        textAlign: 'left',
        backgroundColor: '#EAEAEA',
        fontFamily: fonts.SantanderTextRegular,
        height: '100%',
        paddingBottom: 20,
      },
      labelComponent: {
        display: 'block',
        fontSize: 16,
        fontFamily: 'SantanderTextRegular',
        fontWeight: 'normal',
        color: colors.grey3,
      },
    },
    smallInputSt: {
      containerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
        width: '65%',
      },
      inputContainerComponent: {
        backgroundColor: 'transparent',
        padding: 0,
        margin: 0,
      },
      inputComponent: {
        backgroundColor: 'transparent',
        fontSize: '18px',
        lineHeight: 1.1,

        textAlign: 'left',
        padding: 0,
        margin: 0,
        color: colors.dark,
        fontWeight: 100,
      },
      focusedMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
      disabledMode: {
        containerComponent: {
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});
