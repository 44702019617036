import React, { useCallback, useRef, useState } from 'react';

import {
  UiWrapper,
  View,
  Text,
  PickerHOC,
  Image,
  Layout,
  NavigationWrapper,
  RuleDefinitionsWrapper,
  UserWrapper,
  TargetsWrapper,
  RulesWrapper,
  Page,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';
import { LoadingData } from 'common/LoadingData';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';

// images
import CheckMark from 'common/svgs/CheckMark';
import Back from 'common/svgs/Back';
import CaffeineImage from 'assets/images/Caffeine.png';

// configs
import colors from 'config/colors';
import routes from 'config/routes';
import RulesTooltip from 'common/RulesTooltip';

// data
import * as Types from 'data/types';
import { useRuleActivation } from './hooks/useRuleActivation';

// TODO: connection to back

const ruleIdentifier = Types.CAFEINE;

export default PickerHOC(
  {
    ruleDefinition: {
      arrayItemMatcher: {
        type: 'value',
        value: ruleIdentifier,
        itemPropertyPath: 'identifier',
      },
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, user } = PickerHOC.use();
  const { getRule, desactivateRule, activateRule, upsertRule } = RulesWrapper.use();

  const rule = getRule(ruleIdentifier, target.id);

  const { useBackground, useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const {
    query: { from },
  } = Page.use();
  const [isScroll, setIsScroll] = useState(false);

  const lastRef = useRef(null);
  const observer = useRef(null);
  useBackground(colors.red);
  useLoading(false);

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  const activateAndContinue = useRuleActivation(
    ruleIdentifier,
    rule,
    target.id,
    user.id,
    !!from,
    routes.nicotineRule,
  );
  const handleNext = useCallback(
    async (isActive) => {
      await activateAndContinue(isActive, [
        {
          identifier: 'cafeine_amount',
          data: { value: Number(1) },
        },
      ]);
    },
    [activateAndContinue],
  );
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        left={
          <View autoWidthSt style={{ marginLeft: 18 }} onClick={() => goBack()}>
            <Back style={{ fill: colors.white, width: 11, height: 'auto' }} />
          </View>
        }
        style={{ backgroundColor: colors.red }}
        right="none"
      />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.red }}>
      <View style={{ padding: 25 }}>
        <Text boldSt font15St whiteSt style={{ marginBottom: 20 }}>
          {' '}
          ¿Quieres activar esta regla?{' '}
        </Text>
        <View horizontalSt>
          <View
            pointerSt
            style={{
              height: 54,
              width: 54,
              borderRadius: '50%',
              background: colors.white,
              marginRight: 32,
            }}
            onClick={() => handleNext(false)}
          >
            <View
              autoWidthSt
              boldSt
              style={{
                fontSize: 45,
                color: colors.red,
                transform: 'rotate(45deg)',
                paddingBottom: 10,
              }}
            >
              +
            </View>
          </View>
          <View
            pointerSt
            onClick={() => handleNext(true)}
            style={{ height: 54, width: 54, borderRadius: '50%', background: colors.white }}
          >
            <CheckMark style={{ width: 20, height: 'auto', fill: colors.green }} />
          </View>
        </View>
      </View>
    </Footer>,
    [handleNext],
  );

  return (
    <Layout.Container>
      <ScrollArrow
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View
        startSt
        fullHeightSt
        style={{ backgroundColor: colors.red, paddingTop: 10, paddingBottom: 10 }}
      >
        <View width79St>
          <Image src={CaffeineImage} style={{ width: 60, height: 'auto' }} />
          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <Text font27St boldSt whiteSt style={{ textAlign: 'center', marginRight: 5 }}>
              Sin cafeína
            </Text>
            <RulesTooltip
              content={
                <Text style={{ fontSize: 14 }}>
                  ¿Crees que tomas demasiado café? Te damos un motivo para reducir su consumo.
                  Activa esta regla y ahorrarás de forma automática 1€ por cada día libre de
                  cafeína. Si caes en la tentación, solo tienes que entrar en el apartado de reglas
                  -Sin cafeína y darle al botón para penalizarte.
                  <Text style={{ fontSize: 14, marginTop: 10 }}>
                    La cantidad acumulada en "cafés no tomados" se sumará al resto de reglas. Si la
                    suma de todas las reglas es superior a 40€, pasaremos una aportación a tu
                    póliza. Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulado
                    hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo
                    de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el
                    miércoles.
                  </Text>
                </Text>
              }
            ></RulesTooltip>
          </View>
          <Text textCenterSt font15St whiteSt lineHeight16St style={{ marginTop: 27 }}>
            Activando esta regla y usando la app, ahorrarás de forma automática 1€ por cada día
            libre de cafeína.
          </Text>
          <Text font15St boldSt textCenterSt whiteSt style={{ marginTop: 20, width: 200 }}>
            El importe es fijo
          </Text>
          <View horizontalSt style={{ marginTop: 25 }}>
            <View roundingMarginSt circleRoundingSt circleRoundingActiveSt>
              1€
            </View>
          </View>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});
