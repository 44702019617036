import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import CloseIcon from '@material-ui/icons/Close';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Enfatize,
  Image,
  Button,
  UserWrapper,
  TargetsWrapper,
  TargetsUtilsWrapper,
} from '@coinscrap/webapp-core';
import moment from 'moment';

// components

// images
import Hand from '../../../assets/images/Hand.png';
import CheckMark from '../../../common/svgs/CheckMark';
import Warning from '../../../common/svgs/Warning';
import XMark from '../../../common/svgs/XMark';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';

// libs
import { getCurrency } from '../../../libs/Currency';
import { useFilterActiveTargets } from '../hooks/useFilterActiveTargets';

const optionsGraph = {
  rotation: 20,
  cutoutPercentage: 70,
  legend: {
    display: false,
    position: 'left',
  },
  tooltips: {
    enabled: false,
  },
  animation: {
    animateRotate: true,
    animateScale: false,
  },
};

const dataGraph = (total, saved) => {
  let remain = total - saved;

  if (remain < 0) {
    remain = 0;
  }
  return {
    datasets: [
      {
        data: [remain, saved === 0 ? total * 0.001 : saved],
        backgroundColor: ['#EBEBEB', colors.red],
        borderWidth: 0,
        hoverBorderWidth: 0,
        weight: 20,
      },
    ],
  };
};

// import routes from "../config/routes";
export default PickerHOC({
  user: { sourcePath: 'user', sourceWrapper: UserWrapper },
  parentTarget: {
    arrayItemMatcher: {
      allowNoExistence: true,
      itemPropertyPath: 'level',
      type: 'value',
      value: 0,
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
  userTargets: {
    arrayItemFilter: {
      itemPropertyPath: 'level',
      type: 'value',
      value: 1,
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
})(({ filterForHistory }) => {
  const { user, parentTarget, userTargets } = PickerHOC.use();
  const { useBackground } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const parentTargetCorrectlyInicializated =
    parentTarget?.status === 'ACTIVE' && parentTarget?.creationData?.destProduct?.identifier;

  useBackground(colors.white);
  console.log(userTargets);
  let userTargetsArray = useFilterActiveTargets(userTargets);

  if (filterForHistory) {
    userTargetsArray = userTargets.filter((target) => target.status === 'FINISHED');
  }

  return (
    <View>
      {userTargetsArray.length === 0 ? (
        <View alignCenterSt>
          {filterForHistory ? (
            <View style={{ marginTop: 25 }}>
              <Text grey1St lineHeight16St font15St textCenterSt>
                Actualmente no tienes ningún objetivo finalizado
              </Text>
            </View>
          ) : (
            <>
              <View style={{ marginTop: 25 }}>
                <Image src={Hand} style={{ height: 'auto', width: 90 }} />
              </View>
              <View width80St style={{ marginTop: 25 }}>
                <Text font27St textCenterSt style={{ marginBottom: 15 }}>
                  ¡Bienvenid@ {user.name}!
                </Text>
                <Text grey1St lineHeight16St font15St textCenterSt>
                  Aún no has empezado a utilizar GENERALI My Pocket. Es muy fácil ya verás.
                </Text>
                <Text grey1St lineHeight16St boldSt font15St textCenterSt>
                  ¿Quieres que creemos nuestro primer objetivo de ahorro?.
                </Text>
              </View>
              {!parentTargetCorrectlyInicializated ? (
                <Button
                  onClick={() => navigateTo(routes.fetchPolicies)}
                  regularButtonSt
                  style={{ marginBottom: 50, marginTop: 25 }}
                >
                  Inicializar ahorro
                </Button>
              ) : (
                <View style={{ marginBottom: 50, marginTop: 25 }}>
                  <Button
                    onClick={() =>
                      navigateTo(
                        routes.targetInstanciation,
                        console.log('ID CREATIN', parentTarget.id) || {
                          routeParams: { idParentTarget: parentTarget.id },
                        },
                      )
                    }
                    regularButtonSt
                  >
                    Crear Objetivo
                  </Button>

                  <Button
                    regularButtonHistorySt
                    onClick={() => navigateTo(routes.history)}
                    style={{ marginTop: 15 }}
                  >
                    Histórico
                  </Button>
                </View>
              )}
            </>
          )}
        </View>
      ) : (
        userTargetsArray.map((target) => {
          return <Card key={target.id} data={target} />;
        })
      )}
    </View>
  );
});

const Card = ({ data }) => {
  const { openModal } = UiWrapper.use();
  const { cancel } = TargetsUtilsWrapper.use();
  // When the objective hasn't been activated (the user never finished the flow)
  let isGoalNotConfigured =
    data.status !== 'ACTIVE' &&
    data.status !== 'FINISHED' &&
    data.creationData?.date &&
    data.status !== 'PENDING_FINALIZATION';

  // When the objective hasn't made it passed the initial setup (before rules)
  let isNotConfigured =
    data.status !== 'ACTIVE' && data.status !== 'FINISHED' && !data.creationData?.date;

  const { navigateTo } = NavigationWrapper.use();

  const handleNext = () => {
    const route = isNotConfigured
      ? routes.objectiveInfo
      : isGoalNotConfigured
        ? routes.contributionInfo
        : routes.followUp;
    navigateTo(route, { routeParams: { idTarget: data.id }, clearItems: ['tab'] });
  };

  const openConfirmDeleteTarget = () => {
    openModal((close) => {
      return (
        <View style={{ height: '100%', outline: 'none', position: 'relative' }}>
          <View style={{ backgroundColor: colors.white, width: '80%', padding: 30 }}>
            <View
              // marginTopSt
              horizontalSt
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
                height: 'auto',
                width: 'auto',
                position: 'absolute',
                top: 15,
                right: 10,
                marginBottom: 25,
                cursor: 'pointer',
              }}
            >
              <CloseIcon
                id="closeDoc"
                style={{
                  width: 25,
                  color: colors.greenDark,
                }}
                onClick={() => close()}
              />
            </View>
            <Text style={{ fontSize: 16, marginBottom: 10, textAlign: 'center' }}>
              Si confirmas se cancelará el objetivo
            </Text>
            <Button
              onClick={async () => {
                try {
                  await cancel(data.id);
                  close();
                } catch (error) {
                  console.error(error);
                }
              }}
              style={{ padding: '10px' }}
            >
              Confirmar
            </Button>
          </View>
        </View>
      );
    });
  };
  return (
    <>
      <View homeSectionBorderBottomGreySt spaceBetweenSt style={{ minHeight: 155 }}>
        <View
          style={{ marginTop: 20, paddingBottom: isGoalNotConfigured ? 0 : 16 }}
          width83St
          pointerSt
          onClick={() => handleNext()}
        >
          <View horizontalSt spaceBetweenSt style={{ marginBottom: 0 }}>
            <View autoWidthSt relativeSt>
              <Text font17St>{isNotConfigured ? 'Sin configurar' : data.name}</Text>
            </View>
            <View autoWidthSt relativeSt>
              <ArrowForwardIosIcon style={{ width: 'auto', height: 12 }} />
            </View>
          </View>
          <View
            horizontalSt
            spaceBetweenSt
          // onClick={() => navigateTo(routes.objectiveDetails)}
          >
            {isNotConfigured ? null : (
              <>
                <View onClick={() => handleNext()} topSt autoWidthSt relativeSt pointerSt>
                  <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
                    <CheckMark style={{ fill: colors.red, width: 12, height: 'auto' }} />
                    <Text font17St style={{ marginLeft: 8, color: colors.grey1 }}>
                      {getCurrency(data.creationData?.price)}
                    </Text>
                  </View>
                  <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
                    <CheckMark style={{ fill: colors.red, width: 12, height: 'auto' }} />
                    <Text font17St style={{ marginLeft: 8, color: colors.grey1 }}>
                      {Math.abs(
                        moment(data.createDate).diff(moment(data.creationData.date), 'months'),
                      )}{' '}
                      Meses
                    </Text>
                  </View>
                  <View autoWidthSt horizontalSt style={{ marginTop: 3 }}>
                    {isGoalNotConfigured ? (
                      <XMark
                        style={{ opacity: 0.4, fill: colors.grey1, width: 12, height: 'auto' }}
                      />
                    ) : (
                      <CheckMark style={{ fill: colors.red, width: 12, height: 'auto' }} />
                    )}

                    <Text font17St style={{ marginLeft: 8, color: colors.grey1 }}>
                      Reglas de ahorro
                    </Text>
                  </View>
                </View>
                <View
                  relativeSt
                  style={{
                    width: 80,
                    height: 80,
                    marginRight: 40,
                    marginBottom: 15,
                  }}
                >
                  <Doughnut
                    data={dataGraph(data.progress.total, data.progress.completed)}
                    options={optionsGraph}
                    style={{ margin: 0 }}
                    height={80}
                    width={80}
                  />
                  <View
                    absoluteCenterSt
                    style={{
                      width: 50,
                    }}
                  >
                    <Text style={{ fontSize: 16, transform: 'translateX(5px)' }}>
                      {((data.progress.completed / data.progress.total) * 100).toFixed(0)}{' '}
                      <Enfatize
                        style={{
                          transform: 'translate(-3px,-5px)',
                          display: 'inline-block',
                          fontSize: 16,
                        }}
                      >
                        %
                      </Enfatize>
                    </Text>

                    <Text style={{ fontSize: 8.5 }}>Objetivo</Text>
                  </View>
                </View>
              </>
            )}
          </View>
        </View>
        {(isGoalNotConfigured || (!isNotConfigured && !data.movements)) && (
          <View>
            <View width83St>
              <View style={{ 'align-items': 'flex-end' }}>
                <Text
                  onClick={() => {
                    openConfirmDeleteTarget();
                    // navigateTo(routes.history);
                  }}
                  regularSt
                  pointerSt
                  style={{
                    fontSize: 14,
                    lineHeight: '24px',
                    color: 'rgb(19, 126, 132)',

                    marginTop: 5,
                    textAlign: 'end',
                  }}
                >
                  Eliminar Objetivo
                </Text>
              </View>
            </View>
            {isGoalNotConfigured && (
              <View style={{ background: '#FEF9F8 0% 0% no-repeat padding-box', height: 33 }}>
                <View width95St horizontalSt startSt>
                  <Warning style={{ fill: colors.red, width: 14, height: 'auto' }} />
                  <Text style={{ fontSize: 10, marginLeft: 10, color: colors.dark2 }}>
                    ¡Aún no has activado ninguna regla de ahorro para este objetivo!
                  </Text>
                </View>
              </View>
            )}
          </View>
        )}
        {isNotConfigured && (
          <View style={{ marginTop: 25 }} width83St>
            <Button pointerSt style={{ padding: 8, width: 180 }} onClick={() => handleNext()}>
              Configurar Objetivo
            </Button>
            <View style={{ 'align-items': 'flex-end' }}>
              <Text
                pointerSt
                onClick={() => {
                  openConfirmDeleteTarget();
                  // navigateTo(routes.history);
                }}
                regularSt
                style={{
                  fontSize: 14,
                  lineHeight: '24px',
                  color: 'rgb(19, 126, 132)',

                  marginTop: 5,
                  textAlign: 'end',
                  width: 'auto',
                }}
              >
                Eliminar Objetivo
              </Text>
            </View>
          </View>
        )}
      </View>
    </>
  );
};
