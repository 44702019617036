import React, { useCallback, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  PickerHOC,
  Layout,
  Button,
  Text,
  Page,
  RuleDefinitionsWrapper,
  TargetsWrapper,
  SoftSwitcher,
  TextField,
  OperationsWrapper
} from '@coinscrap/webapp-core';


// components
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Footer from 'common/Footer';

// configs
import colors from 'config/colors';
import routes from 'config/routes';
import { useAction } from './hooks/useAction';
import { useOtp } from './hooks/useOtp';
import { useInterval } from './hooks/useInterval';
import { OTP_ACTIONS } from 'libs/common';

const MAX_AWAIT_SECONDS = 0;
/*
const opOK = 'FAILURE';
const opKO = 'SUCCESS';
*/
const opOK = 'SUCCESS';
const opKO = 'FAILURE';

// import routes from "../config/routes";
export const OtpCode = PickerHOC({
  ruleDefinitions: {
    sourceWrapper: RuleDefinitionsWrapper,
    sourcePath: 'ruleDefinitions',
  },
  operations: {
    sourceWrapper: OperationsWrapper,
    sourcePath: 'operations',
  },
  parentTarget: {
    arrayItemMatcher: {
      allowNoExistence: true,
      itemPropertyPath: 'level',
      type: 'value',
      value: 0,
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
})(() => {
  const { parentTarget, operations } = PickerHOC.use();
  const { setOperationHandlers } = OperationsWrapper.use();
  const { query: { action, payload, from }, params: { operationId } } = Page.use();
  const { useBackground, useHeader, useFooter, useLoading, } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();

  const operation = operations[operationId];

  const { sendNew, validate } = useOtp(payload);
  const { event } = useAction(action, payload);
  const [validated, setValidated] = useState(operation?.status === opOK);
  const [resending, setResending] = useState();
  const [code, setCode] = useState('');
  const [currentSeconds, setSeconds] = useState(0);

  const operationName = {
    [OTP_ACTIONS.HOT]: 'Su operación de Ahorro hot ha sido realizada correctamente',
    [OTP_ACTIONS.SAVE_ACCOUNTS]: 'Cuentas guardadas correctamente',
    [OTP_ACTIONS.SELECT_POLICY]: "Confirmación de uso de póliza realizado",
    [OTP_ACTIONS.TARGET_FINISH]: "Confirmación de creación de objetivo realizada",
    [OTP_ACTIONS.TOGGLE_RULE]: "Confirmación de modificación de regla realizada",
  }


  const condition = [operation?.status === opKO, operation?.status === opOK, operation?.currentAction?.status === 'CONTINUE', operation?.status === 'PENDING'];
  const codeOk = operation?.status === opOK;

  const canSend = condition.indexOf(true) === 0 || condition.indexOf(true) === 2 || (condition.indexOf(true) === 3 && currentSeconds >= MAX_AWAIT_SECONDS);
  const mustAwait = condition.indexOf(true) === 3 && currentSeconds < MAX_AWAIT_SECONDS;

  /*useInterval(() => {
    setSeconds(s => s + 1);
  }, mustAwait ? 1000 : null)*/


  const handleNext = async () => {
    setValidated(true);
    await validate(operation.id, code);
    /*if (from) {
      navigateTo(routes.otpSms, { clearItems: ['stage', 'action', 'payload', 'targetId'], handleRedirection: true });
      return
    } else {
      goBack()
    }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleFinish = async () => {
    await event();
    if (from) {
      navigateTo(routes.otpSms, { clearItems: ['stage', 'action', 'payload', 'targetId'], handleRedirection: true, mode: 'replace' });
      return
    } else {
      goBack()
    }
  };

  useEffect(() => {
    if (validated && codeOk) {
      handleFinish();
    }
  }, [validated, codeOk])

  useEffect(() => {
    if (!operation) {
      navigateTo(routes.otpSms, { mode: 'replace' })
    } else {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation?.id])

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <View horizontalSt width90St startSt style={{ marginBottom: 5 }} onClick={async () => {
        if (resending || !canSend) {
          return
        }
        setResending(true);
        try {
          console.log('CLICK');
          const op = await sendNew();
          console.log('NAVIGATING');
          setCode('');
          setValidated(false);
          navigateTo(routes.otpCode, { routeParams: { operationId: op.id }, mode: 'replace' });
        } catch (e) {
        } finally {
          setResending(false)
        }
      }}>
        {(canSend || mustAwait) && <ArrowForwardIosOutlinedIcon style={{ marginRight: 7, fontSize: 14 }} />}
        {canSend ? (
          <Text pointerSt font14St>
            {resending ? 'Reenviando' : 'Reenviar código de activación'}
          </Text>
        ) :
          mustAwait && (
            <Text pointerSt font14St>Podrás reenviar en {MAX_AWAIT_SECONDS - currentSeconds} segundos</Text>
          )
        }
      </View>
      <Button
        disabled={validated ? !codeOk : !code}
        regularButtonSt
        style={{ marginBottom: 50 }}
        onClick={validated ? /*handleFinish doned in useEffect*/ undefined : handleNext}
      >
        {validated ? 'Finalizar' : 'Siguiente'}
      </Button>
    </Footer>,
    [code, operation?.id, currentSeconds, validated, resending, canSend, mustAwait, codeOk],
  );
  return (
    <Layout.Container>
      <View width90St startSt topSt style={{ paddingTop: 20 }}>
        <SoftSwitcher evaluate={resending ? 3 : condition.indexOf(true)}>
          <>
            <Text font18St centerSt>
              {/*validated ? operation?.currentAction?.error?.payload?.error?.errorDescription || operation?.currentAction?.error?.message :'Ha ocurrido un error mientras intentábamos enviar el sms.'*/}
              {validated ? 'El OTP introducido es incorrecto. Solicite el SMS de nuevo para intentarlo otra vez.' : 'Ha ocurrido un error mientras intentábamos enviar el sms.'}
            </Text>
          </>
          <>
            <Text font18St centerSt>
              Validación correcta.
            </Text>
            <Text font18St centerSt>
              {operationName[action]}
            </Text>
          </>
          <>
            <Text font18St>
              Por favor, introduce el código de activación que has recibido para completar el
              proceso.
            </Text>
            <TextField
              value={code}
              onChange={v => setCode(v.target.value)}
              afterBanksInputSt
              label="Código de activación"
            />
          </>
          <>
            <Text font18St centerSt>
              Espera mientras enviamos tu código.
            </Text>
            <CircularProgress
              style={{
                // marginTop: '50%',
                width: 40,
                height: 40,
                color: colors.orange,
              }}
            />
          </>

        </SoftSwitcher>
      </View>

    </Layout.Container>
  );
});
