import { UiWrapper } from '@coinscrap/webapp-core';
UiWrapper.defineComponent('softSwitcher', {
  styles: {
    fullSt: {
      softSwitchComponent: {
        height: '100%'
      }
    },
  },
});
