import React, { useState } from 'react';

import Chart from 'chart.js';

export default function MyChartJS(props) {
  const [canvasRef, setCanvasRef] = useState();
  // eslint-disable-next-line no-unused-vars
  let mychart = null;

  //console.log('MyChart.render', canvasRef, props);

  if (canvasRef && props.data) {
    // mychart = null;
    mychart = new Chart(canvasRef, {
      type: props.type,
      data: props.data,
      options: props.options,
    });
  }

  return (
    <canvas
      style={{
        height: props.height || '100%',
        width: props.width || '100%',
        ...props.style,
      }}
      ref={(ref) => setCanvasRef(ref)}
    />
  );
}

export function transformDataFromService(serviceData) {
  //console.log('graphTransformFromService ini',JSON.parse(JSON.stringify(serviceData)),);

  let finalData = { data: { datasets: [] } };
  //serviceData.data.datasets = [];

  for (let i = 0; i < serviceData.data.length; i++) {
    let myDataset = {
      data: [],
    };
    //console.log('trans', i, serviceData.data[i]);
    for (let j = 0; j < serviceData.data[i].length; j++) {
      //console.log('trans', j, serviceData.data[i][j]);
      myDataset.data.push(serviceData.data[i][j] ? serviceData.data[i][j] : 0);
    }
    //console.log('trans A', myDataset);
    finalData.data.datasets.push(myDataset);
    // delete serviceData.data[i];
  }

  for (let i = 0; i < serviceData.series.length; i++) {
    Object.keys(serviceData.series[i]).forEach((keySeries) => {
      //console.log('A ', keySeries, serviceData.series[i][keySeries]);
      if (keySeries === 'type') {
        finalData.data.datasets[i][keySeries] = serviceData.series[i][keySeries].toLowerCase();
      } else if (keySeries === 'title') {
        finalData.data.datasets[i].label = serviceData.series[i].title;
      } else {
        finalData.data.datasets[i][keySeries] = serviceData.series[i][keySeries];
      }
      //console.log('B', keySeries, serviceData.data.datasets[i]);
    });
  }
  //delete serviceData.series;

  finalData.data.labels = serviceData.labels;
  //delete serviceData.labels;

  //console.log('graphTransformFromService fin', serviceData);

  return [finalData.data, finalData.config];
}

export const chartColors = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)',
  grey2: 'rgb(201, 203, 190)',
  //blanco: '#ffffffba',
  //blancoClaro: '#ffffff4a',
  pink: '#f4137b',
  darkGrey: '#615f5f',
  clearPink: '#f5a0c7',
  white: '#ffffffba',
  trasparentWhite: '#ffffff4a',
  trasparentBlue: '#36a2eb94',
  trasparentPink: '#f4137b9e',
};
