import Cerdo from '../../assets/images/cerdo.gif';
import Phone from '../../assets/images/Phone.png';
import Ruler from '../../assets/images/Ruler.png';
import Lock from '../../assets/images/Lock.png';

export const OnboardingSlides = {
  que: {
    id: 1,
    headline: '¿Qué es GENERALI My Pocket?',
    description:
      'La nueva comunidad de ahorradores inteligentes que consiguen ahorrar para sus objetivos más rápido y sin enterarse.',
    image: Cerdo,
  },
  como: {
    id: 2,
    headline: '¿Cómo funciona?',
    description:
      'Eliges un objetivo para el que ahorrar (capricho, red de seguridad, meta familiar, jubilación) configuras los parámetros del mismo y tacháaannn ... ;)',
    image: Phone,
  },
  medida: {
    id: 3,
    headline: 'A tu medida',
    description:
      'Hemos creado un montón de reglas que te ayudarán a ahorrar aún más rápido. Puedes ahorrar si tu equipo de fútbol gana, si quieres dejar de fumar ...',
    image: Ruler,
  },
  seguro: {
    id: 4,
    headline: 'Siempre seguro',
    description:
      'En materia de seguridad disponemos de medidas técnicas y organizativas adaptadas a la normativa de Servicios de Pagos (PSD2) y Protección de Datos.',
    image: Lock,
  },
};

export const legalText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam semper sagittis felis eget blandit. Proin eleifend lacus elit, vel hendrerit tellus hendrerit nec. In quis risus feugiat, interdum enim vitae, tristique nisi. Nullam tempor ut diam at bibendum. Maecenas tristique, ante vel ullamcorper scelerisque, quam dui facilisis nulla, eu finibus ante diam a purus. Nullam suscipit dolor at mollis pulvinar. Vivamus ac cursus risus, vel dictum est. Nullam ornare diam a turpis sollicitudin convallis sit amet eu felis. Vivamus faucibus non nisl nec blandit. Nullam rutrum venenatis sem, ac mattis magna maximus vel. Nullam elementum facilisis sapien non egestas. Suspendisse vitae congue arcu. Integer sed nibh vel leo interdum fringilla eu non arcu. Vivamus tincidunt libero a mollis ornare.

Integer gravida lorem vel eros venenatis consectetur. Cras a lacus ac enim facilisis pretium. Ut vitae blandit ante. Donec ut felis vel risus euismod venenatis ut non augue. Aliquam dolor erat, vulputate in nisi ac, congue ultricies sapien. Donec feugiat dolor id risus convallis auctor. Aliquam erat volutpat. Donec auctor vitae odio vitae pharetra. Mauris ac vehicula est, quis sollicitudin mauris. Vestibulum eu euismod elit.

Sed lobortis arcu semper commodo maximus. Aliquam vestibulum metus non mi auctor, sed tempor tellus egestas. Sed interdum purus vehicula imperdiet rutrum. Vestibulum et dui a tellus pharetra pellentesque. Vestibulum sed enim semper ligula porttitor aliquam ac in dui. Nunc fermentum felis vitae facilisis semper. Sed pharetra ut est id commodo. Curabitur placerat magna lorem, eu vulputate mauris sodales a. Fusce fringilla aliquam tortor ac scelerisque. Aliquam id tincidunt diam, quis tempus odio. Vestibulum purus arcu, posuere in efficitur et, pharetra quis dolor.

Mauris consequat erat mauris, non interdum magna varius eget. In faucibus lacus sem, a tempus nunc porta vitae. Donec sit amet sapien feugiat, malesuada dolor et, mollis purus. Curabitur tincidunt et lectus eu pretium. Nulla vitae tortor purus. Donec sodales tincidunt nisl, nec porttitor ex aliquam id. Nulla mattis ex molestie imperdiet viverra. Nunc commodo congue lorem, vel hendrerit mauris vulputate ut. Vestibulum elit ipsum, dignissim sed cursus eu, varius non dui. Donec nunc lectus, dapibus imperdiet nisi sit amet, congue egestas sem. Sed purus ipsum, viverra a augue nec, semper pretium lacus. Nulla sed sapien vitae orci commodo imperdiet ac ac nibh. Mauris ornare, elit et aliquam pellentesque, nulla felis euismod ipsum, at accumsan nulla risus quis dolor.

Donec faucibus mauris a ligula interdum porttitor. Aliquam faucibus rhoncus odio id tristique. In eu aliquam mi. Maecenas tempor, eros vitae rhoncus placerat, quam sapien convallis odio, vel viverra velit ipsum at diam. Vestibulum pretium ullamcorper ex, sed ullamcorper diam viverra id. Praesent condimentum ornare mi et laoreet. Fusce sed gravida nunc, id aliquet lorem. Duis pellentesque dui ut tincidunt convallis. Nunc laoreet accumsan vulputate. Fusce in nisl sapien. Vestibulum molestie volutpat massa, non commodo augue dignissim vel. Etiam consectetur purus laoreet mattis rhoncus. Cras laoreet nunc eu magna mattis, non vestibulum ipsum egestas.`;
