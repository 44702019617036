import React, { useState } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  Button,
  TargetsWrapper,
  RulesWrapper,
  Image,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';
import { LoadingData } from 'common/LoadingData';
import LegalModal from 'common/LegalModal';

// images
import scooter from 'assets/images/scooter.jpg';
import LogoGenerali from 'assets/images/generali-logo2.png';

// configs
import colors from 'config/colors';
import routes from 'config/routes';

// libs
import { getCurrency } from 'libs/Currency';
import { displayRuleAmount } from 'libs/ruleCases';

// data
import * as Types from 'data/types';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    parentTarget: {
      arrayItemMatcher: {
        allowNoExistence: true,
        itemPropertyPath: 'level',
        type: 'value',
        value: 0,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, parentTarget } = PickerHOC.use();
  const { getRule } = RulesWrapper.use();
  const [showInfoModal, setShowInfoModal] = useState(false);

  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  //  TODO: initial deposit
  // const ruleInitialDeposit = getRule(ruleIdentifier, target.id);

  let product = parentTarget?.creationData?.destProduct?.identifier.slice(0, 3);

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="RESUMEN" left="atras" />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Text font12St style={{ marginTop: 20, width: '80%', textAlign: 'center' }}>
        {product === '90G' &&
          `Al tratarse de un seguro Unit linked el importe ahorrado estará sujeto a las fluctuaciones
        de los mercados`}
        {product === '86G' &&
          'Al tratarse de un Seguro de Ahorro se consigue un capital garantizado que, si bien es algo inferior a las primas pagadas, podrá beneficiarte de una rentabilidad adicional por participación en beneficios.'}
      </Text>
      <Text
        onClick={() => {
          setShowInfoModal(true);
        }}
        font12St
        redSt
        style={{ marginTop: 5, width: '80%', textAlign: 'center', textDecoration: 'underline' }}
        pointerSt
      >
        Más información
      </Text>

      <Button
        style={{ marginTop: 10 }}
        borderWhiteButtonSt
        onClick={() => navigateTo(routes.targetAccountsIntro)}
      >
        ¡Genial!
      </Button>
    </Footer>,
    [],
  );

  const ruleList = [
    displayRuleAmount(Types.PERIODIC_SAVING, getRule(Types.PERIODIC_SAVING, target.id)),
    displayRuleAmount(Types.COINSCRAP_ROUNDS, getRule(Types.COINSCRAP_ROUNDS, target.id)),
    displayRuleAmount(Types.EARNINGS_SAVING, getRule(Types.EARNINGS_SAVING, target.id)),
    displayRuleAmount(Types.TEAM, getRule(Types.TEAM, target.id)),
    displayRuleAmount(Types.CAFEINE, getRule(Types.CAFEINE, target.id)),
    displayRuleAmount(Types.NICOTINE, getRule(Types.NICOTINE, target.id)),
  ];

  return (
    <Layout.Container>
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View style={{ height: 180 }}>
          <View
            style={{
              paddingTop: 50,
              height: '120%',
              width: '120%',
              borderRadius: '50%',
              backgroundImage: `url(${target.image || scooter})`,
              backgroundRepeat: 'no-repeat',
              // backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></View>

          <View style={{ marginTop: 19 }}>
            <View width80St>
              <Text font27St textCenterSt>
                Resumen final
              </Text>
            </View>
            <View width82St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 34 }}>
                {target.creationData?.initialContribution > 0 && (
                  <>
                    Has hecho una aportación inicial de{' '}
                    <Enfatize boldSt redSt>
                      {getCurrency(target.creationData?.initialContribution || 0)}
                    </Enfatize>
                    .{' '}
                  </>
                )}
                {ruleList.map(({ isActive, SummarySentence, summarySentence }) => {
                  if (isActive) {
                    return summarySentence || <SummarySentence />;
                  } else return null;
                })}
                {/* y has activado la regla de redondeo a{' '}
                <Enfatize boldSt redSt>
                  1€
                </Enfatize>{' '} */}
                {/* más próximo.  */}
                Tu objetivo está en camino.
                <Text textCenterSt lineHeight16St grey1St font15St>
                  ¡Vamos allá!.
                </Text>
              </Text>
            </View>
          </View>
        </View>
      </View>
      <LegalModal isOpen={showInfoModal} close={() => setShowInfoModal(false)}>
        <View
          width90St
          style={{
            padding: 10,
            marginTop: 25,
            overflowY: 'scroll',
            height: 450,
            justifyContent: 'flex-start',
          }}
        >
          <Image
            src={LogoGenerali}
            style={{ width: 139, height: 'auto', cursor: 'pointer', marginBottom: 20 }}
          />
          {product === '90G' &&
            `Queda estipulado en las Condiciones Particulares de la póliza que el riesgo de la
              inversión de la provisión matemática de esta póliza será soportado por el Tomador, por
              lo que en el cálculo de dichas provisiones será de aplicación el artículo 136 del Real
              Decreto 1.060/2015 de 20 de noviembre por el que se aprueba el Reglamento de Ordenación,
              Supervisión y Solvencia de las Entidades Aseguradoras y Reaseguradoras, que establece
              que dicha provisión se determinará en función de los activos específicamente afectos o
              de los índices o activos que se hayan fijado como referencia para determinar el valor
              económico de sus derechos. El importe indicado corresponde a la prima a aportar, la cual
              depende de las fluctuaciones en los mercados financieros, ajenos al control de la
              Entidad Aseguradora. De esta manera, el Valor Acumulado puede llegar a ser inferior a
              dicha prima. Los resultados históricos no son indicadores de resultados futuros. En lo
              relativo a los gastos y a las descripciones de las Carteras de Inversión disponibles, es
              de aplicación lo dispuesto en las Condiciones Particulares de la póliza.`}
          {product === '86G' &&
            `El producto Ahorro Futuro está destinado sobre todo para clientes conservadores que quieren constituir capitales garantizados y anteponen la seguridad a la rentabilidad.
Todos los valores garantizados, incluidas las provisiones matemáticas y el capital de supervivencia en la fecha de vencimiento del seguro, son inferiores a las primas pagadas. No obstante, este seguro participará anualmente, según se indica en la cláusula de Participación en Beneficios establecida en las Condiciones Particulares y Generales, en los rendimientos derivados de las inversiones en que se materializan las provisiones matemáticas de los productos que pertenecen a esta modalidad de Seguro.

En lo relativo a los gastos es de aplicación lo dispuesto en las Condiciones Particulares de la póliza.`}
        </View>
      </LegalModal>
    </Layout.Container>
  );
});
