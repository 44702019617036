import React, { useRef } from 'react';

import { UiWrapper, View, Text, Button } from '@coinscrap/webapp-core';

// components
// import { CloseButton } from 'common/closeButton';
import InfoSvg from 'common/svgs/Info';
// configs
import colors from 'config/colors';
import Warning from './svgs/Warning';

// data

const ErrorModal = ({ onClose, children }) => {
  const faqRef = useRef();
  const { useBackground } = UiWrapper.use();
  useBackground(colors.white);

  return (
    <View
      spaceBetweenSt
      style={{ backgroundColor: 'white', opacity: 0.95 }}
    // onClick={onClose}
    >
      <View>
        <View horizontalSt endSt style={{ marginTop: 20 }}>
          {/* <CloseButton color={colors.white} action={onClose} /> */}
        </View>
        <View style={{ padding: 20 }}>
          <Warning style={{ width: 35, height: 'auto', fill: colors.red, marginBottom: 25 }} />
          {/* <Text font27St style={{ marginBottom: 10, color: colors.red }}>
            Error
          </Text> */}

          {children}
        </View>
      </View>
    </View>
  );
};

export default ErrorModal;
