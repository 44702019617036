export default {
  black: '#000000',
  white: '#FFFFFF',
  dark: '#20252B',
  dark2: '#282828',

  grey1: '#B0B2B3',
  grey2: '#D7D7D7',
  grey3: '#EBEBEB',
  grey4: '#CCCCCC',
  grey5: '#F9F9FA',
  grey6: '#D1D1D1',
  grey7: '#D5D5D5',
  grey8: '#E4E4E6',
  red: '#C5281C',
  green: '#649544',
};
