import React, { Component, useEffect, useState } from 'react';
// import { TargetsAPI } from '@coinscrap/webapp-core/components/sdk';

export const DocuSign = ({ url, onProcessFinished }) => {
  const [loads, setLoads] = useState(0);
  
  const onMessageReceivedFromIframe = React.useCallback(
    event => {
      const {data} = event;
      try {
        if (data?.indexOf("coinscrapSignedProcesed") >= 0) {
          onProcessFinished && onProcessFinished(data.split(':')[1])
        }
      } catch (e) {}
    },
    []
  );

  useEffect(() => {
    window.addEventListener("message", onMessageReceivedFromIframe);
    return () =>
      window.removeEventListener("message", onMessageReceivedFromIframe);
  }, [onMessageReceivedFromIframe]);

  return (
    // eslint-disable-next-line react/jsx-fragments
    <React.Fragment>
      {!loads
        ? /*
        Add a overlay component here. Example
        <div style={{position: 'absolute', top: '50%', width: '100%', display: 'flex'}}>
          Procesando Firma
        </div>
        */

          null
        : null}
      <iframe
        src={url}
        onLoad={() => {
          //console.log('LOADED');
          setLoads(prevLoads => prevLoads + 1);
        }}
        title='iframe'
        style={{
          height: '100%',
          backgroundColor: 'white',
          // minHeight: 568,
          width: '100%',
          overflow: 'auto',
          frameBorder: 0,
        }}
      />
    </React.Fragment>
  );
};
