import React from 'react';

import {
  UiWrapper,
  View,
  Text,
  Enfatize,
  Image,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  InstitutionAccountsWrapper,
  Button,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';

// images
import pig from '../../../assets/images/pig.png';

// configs
import colors from 'config/colors';
import routes from 'config/routes';

const TargetAccountsAdd = PickerHOC({
  target: {
    arrayItemMatcher: {
      type: 'param',
      itemPropertyPath: 'id',
      paramName: 'idTarget',
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
  institutionAccounts: {
    sourcePath: 'institutionAccounts',
    sourceWrapper: InstitutionAccountsWrapper,
  },
})(() => {
  const { target, institutionAccounts } = PickerHOC.use();
  const { useBackground, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const products =
    institutionAccounts
      ?.filter((ia) => ia.institutionIdentifier !== 'generali')
      .reduce((acc, act) => [...acc, ...(act.products || [])], [])
      .filter((product) => product.type !== 'insurance') || [];

  // useLoading(true);
  useBackground(colors.white);

  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. BANCO." left="atras" />
    </View>,
    [],
  );

  return (
    <>
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <View topSt>
            <Text boldSt font11St style={{ marginLeft: 16 }}>
              4 de 4
            </Text>
          </View>
          <View style={{ marginTop: 30 }}>
            <Image src={pig} style={{ height: 114, width: 114, borderRadius: '50%' }} />
          </View>
          <View style={{ marginTop: 25 }}>
            <View width80St>
              <Text font27St textCenterSt style={{ fontWeight: 300 }}>
                Vamos a añadir tus datos bancarios
              </Text>
            </View>
            <View width74St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 18 }}>
                <Enfatize style={{ color: colors.red }}>
                  Nunca haremos gestiones con tus claves personales.
                </Enfatize>{' '}
                Utilizamos las últimas normas de cifrado bancario.
                <Enfatize style={{ color: colors.red }}> 100% seguro.</Enfatize>
              </Text>
              <View>
                {!!products.length && (
                  <Button
                    borderWhiteButtonSt
                    onClick={async () => {
                      navigateTo(routes.targetAccountsConfigure);
                    }}
                  >
                    Usar existentes
                  </Button>
                )}
                <View
                  pointerSt
                  style={{
                    marginTop: products.length ? 10 : 30,
                    background: colors.grey5,
                    width: 68,
                    height: 68,
                    borderRadius: '50%',
                  }}
                  onClick={() =>
                    navigateTo(routes.bankConnect, {
                      queryParams: {
                        from: {
                          name: routes.targetAccountsConfigure,
                          routeParams: {
                            idTarget: target.id,
                          },
                        },
                      },
                    })
                  }
                >
                  <Text style={{ fontSize: 30, color: colors.red, marginTop: -5 }}>+</Text>
                </View>
                <Text
                  pointerSt
                  onClick={() =>
                    navigateTo(routes.bankConnect, {
                      queryParams: {
                        from: {
                          name: routes.targetAccountsConfigure,
                          routeParams: {
                            idTarget: target.id,
                          },
                        },
                      },
                    })
                  }
                  style={{ fontSize: 16, marginTop: 20 }}
                >
                  Añade Banco
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* {showBanksModal && (
          <BanksModal
            data={institutions}
            hide={() => setShowBanksModal(false)}
            clickHandler={(selectedBank) => {
              navigateTo(routes.bankConnect, {
                routeParams: {
                  idBank: selectedBank,
                },
              });
              // setShowBanksModal(false) || setShowAfterBanksModal(true)
            }}
          />
        )} */}
      </View>
      {/* <AfterBanksModal isOpen={showAfterBanksModal} onClose={() => setShowAfterBanksModal(false)} /> */}
    </>
  );
});

export default TargetAccountsAdd;
