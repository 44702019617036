import React from 'react';

import { Text, View } from '@coinscrap/webapp-core';

// configs
import colors from 'config/colors';

const Rules = ({ children }) => {
  return (
    <View fullWidthSt>
      <View
        style={{
          flexDirection: 'row',
          padding: '20px 0px',
          borderBottom: `1px solid ${colors.grey3}`,
          backgroundColor: colors.grey3,
        }}
      >
        <View style={{ width: '15%' }}>
          <Text boldSt style={{ fontSize: 13 }}>
            Activa
          </Text>
        </View>
        <View topSt style={{ width: '35%' }}>
          <Text boldSt style={{ fontSize: 13 }}>
            Regla
          </Text>
        </View>
        {/* <View topSt style={{ width: '15%' }}>
          <Text boldSt style={{ fontSize: 13 }}>
            Acum.
          </Text>
        </View> */}
        <View topSt style={{ width: '25%' }}>
          <Text boldSt style={{ fontSize: 13 }}>
            Virtual
          </Text>
        </View>
        <View topSt style={{ width: '25%' }}>
          <Text boldSt style={{ fontSize: 13 }}>
            Total
          </Text>
        </View>
      </View>
      {children}
    </View>
  );
};

export default Rules;
