import React, { useState } from 'react';
import { Button, NavigationWrapper, Text, UiWrapper, View } from '@coinscrap/webapp-core';
import Header from 'common/Header';
import colors from 'config/colors';

// components
import Footer from 'common/Footer';

import card from 'assets/images/card.png';
import account from 'assets/images/account.png';
import santander from 'assets/images/logo-santander.png';
import routes from 'config/routes';
import BanksCollapse from 'common/BanksCollapse';
import BanksItem from 'common/BanksItem';
import BanksModal from 'common/BanksModal';

const Rules = () => {
  const { useBackground, useHeader, useFooter } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const [showBanksModal, setShowBanksModal] = useState(false);

  useBackground(colors.white);

  useHeader(<Header center="AÑADIR CUENTA" left="atras" />, []);

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button borderWhiteButtonSt onClick={() => navigateTo(routes.edit)}>
        Aceptar
      </Button>
    </Footer>,
    [],
  );

  return (
    <>
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <View style={{ marginTop: 30, marginBottom: 30 }}>
            <Text style={{ fontSize: 16 }}>Selecciona cuenta de reglas</Text>
          </View>
        </View>
        <BanksCollapse logo={santander}>
          <BanksItem logo={card} dark />
          <BanksItem logo={account} />
          <BanksItem logo={card} dark />
        </BanksCollapse>
        <View
          pointerSt
          style={{
            marginTop: 30,
            background: colors.grey5,
            width: 68,
            height: 68,
            borderRadius: '50%',
          }}
          onClick={() => setShowBanksModal(true)}
        >
          <Text style={{ fontSize: 30, color: colors.red, marginTop: -5 }}>+</Text>
        </View>
        <Text onClick={() => setShowBanksModal(true)} pointerSt style={{ fontSize: 16, marginTop: 20 }}>Añade otro banco</Text>
        {showBanksModal && (
          <BanksModal setShow={setShowBanksModal} navigate={() => setShowBanksModal(false)} />
        )}
      </View>
    </>
  );
};

export default Rules;
