import React, { useState } from 'react';
import LogoGenerali from 'assets/images/generali-logo2.png';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Enfatize,
  Button,
  Image,
  TargetsWrapper,
  UserWrapper,
} from '@coinscrap/webapp-core';

// components
import Header from '../../../common/Header';
import HeaderRedPrimary from '../../../common/HeaderRedPrimary';
import Footer from '../../../common/Footer';
import LegalModal from 'common/LegalModal';

// images
import scooter from 'assets/images/scooter.jpg';

// configs
import colors from '../../../config/colors';
import routes from '../../../config/routes';
import { dateToNumberOfMonths } from 'libs/date';
import { getCurrency } from 'libs/Currency';

import { LoadingData } from 'common/LoadingData';

export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    parentTarget: {
      arrayItemMatcher: {
        allowNoExistence: true,
        itemPropertyPath: 'level',
        type: 'value',
        value: 0,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, parentTarget } = PickerHOC.use();

  console.log({ parentTarget });

  let product = parentTarget?.creationData?.destProduct?.identifier.slice(0, 3);

  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const [showInfoModal, setShowInfoModal] = useState(false);
  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="RESUMEN" left="atras" />
    </View>,
    [],
  );
  console.log({ product });
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Text font12St style={{ marginTop: 20, width: '80%', textAlign: 'center' }}>
        {product === '90G' &&
          `Al tratarse de un seguro Unit linked el importe ahorrado estará sujeto a las fluctuaciones
        de los mercados`}
        {product === '86G' &&
          'Al tratarse de un Seguro de Ahorro se consigue un capital garantizado que, si bien es algo inferior a las primas pagadas, podrá beneficiarte de una rentabilidad adicional por participación en beneficios.'}
      </Text>
      <Text
        onClick={() => {
          setShowInfoModal(true);
        }}
        font12St
        redSt
        style={{ marginTop: 5, width: '80%', textAlign: 'center', textDecoration: 'underline' }}
        pointerSt
      >
        Más información
      </Text>
      <Button
        style={{ marginTop: 10 }}
        borderWhiteButtonSt
        onClick={() => {
          navigateTo(routes.contributionInfo, {
            routeParams: {
              idTarget: target.id,
            },
          });
        }}
      >
        ¡Genial!
      </Button>
    </Footer>,
    [],
  );

  return (
    <Layout.Container>
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <View style={{ marginTop: 50 }}>
            <Image
              src={target.image || scooter}
              style={{ height: 114, width: 114, borderRadius: '50%' }}
            />
          </View>
          <View style={{ marginTop: 19 }}>
            <View width80St>
              <Text font27St textCenterSt>
                Mi objetivo
              </Text>
            </View>
            <View width82St>
              <Text textCenterSt lineHeight16St grey1St font15St style={{ marginTop: 34 }}>
                Has decidido ahorrar para{' '}
                <Enfatize boldSt redSt>
                  {target.name}
                </Enfatize>{' '}
                valorado en{' '}
                <Enfatize boldSt redSt>
                  {getCurrency(target.creationData.price, 2, false)}
                </Enfatize>{' '}
                y lo quieres conseguir en un plazo de{' '}
                <Enfatize boldSt redSt>
                  {dateToNumberOfMonths(target.createDate, target.creationData.date)} meses
                </Enfatize>
                .
              </Text>
            </View>
          </View>
        </View>
      </View>
      <LegalModal isOpen={showInfoModal} close={() => setShowInfoModal(false)}>
        <View
          width90St
          style={{
            padding: 10,
            marginTop: 25,
            overflowY: 'scroll',
            height: 450,
            justifyContent: 'flex-start',
          }}
        >
          <Image
            src={LogoGenerali}
            style={{ width: 139, height: 'auto', cursor: 'pointer', marginBottom: 20 }}
          />
          <Text font15St style={{ textAlign: 'justify', lineHeight: 1.5 }}>
            {product === '90G' &&
              `Queda estipulado en las Condiciones Particulares de la póliza que el riesgo de la
              inversión de la provisión matemática de esta póliza será soportado por el Tomador, por
              lo que en el cálculo de dichas provisiones será de aplicación el artículo 136 del Real
              Decreto 1.060/2015 de 20 de noviembre por el que se aprueba el Reglamento de Ordenación,
              Supervisión y Solvencia de las Entidades Aseguradoras y Reaseguradoras, que establece
              que dicha provisión se determinará en función de los activos específicamente afectos o
              de los índices o activos que se hayan fijado como referencia para determinar el valor
              económico de sus derechos. El importe indicado corresponde a la prima a aportar, la cual
              depende de las fluctuaciones en los mercados financieros, ajenos al control de la
              Entidad Aseguradora. De esta manera, el Valor Acumulado puede llegar a ser inferior a
              dicha prima. Los resultados históricos no son indicadores de resultados futuros. En lo
              relativo a los gastos y a las descripciones de las Carteras de Inversión disponibles, es
              de aplicación lo dispuesto en las Condiciones Particulares de la póliza.`}
            {product === '86G' &&
              `El producto Ahorro Futuro está destinado sobre todo para clientes conservadores que quieren constituir capitales garantizados y anteponen la seguridad a la rentabilidad.
Todos los valores garantizados, incluidas las provisiones matemáticas y el capital de supervivencia en la fecha de vencimiento del seguro, son inferiores a las primas pagadas. No obstante, este seguro participará anualmente, según se indica en la cláusula de Participación en Beneficios establecida en las Condiciones Particulares y Generales, en los rendimientos derivados de las inversiones en que se materializan las provisiones matemáticas de los productos que pertenecen a esta modalidad de Seguro.

En lo relativo a los gastos es de aplicación lo dispuesto en las Condiciones Particulares de la póliza.`}
          </Text>
        </View>
      </LegalModal>
    </Layout.Container>
  );
});
