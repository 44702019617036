import React, { useCallback, useState, useEffect, useRef } from 'react';

import {
  UiWrapper,
  View,
  Text,
  PickerHOC,
  Image,
  Layout,
  NavigationWrapper,
  RuleDefinitionsWrapper,
  UserWrapper,
  TargetsWrapper,
  RulesWrapper,
  Page,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';
import { LoadingData } from 'common/LoadingData';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';

// images
import CheckMark from 'common/svgs/CheckMark';
import Back from 'common/svgs/Back';
import IncomeImage from 'assets/images/Income.png';

// configs
import colors from 'config/colors';
import routes from 'config/routes';
import RulesTooltip from 'common/RulesTooltip';

// data
import * as Types from 'data/types';
import { useRuleActivation } from './hooks/useRuleActivation';

const ruleIdentifier = Types.EARNINGS_SAVING;

export default PickerHOC(
  {
    ruleDefinition: {
      arrayItemMatcher: {
        type: 'value',
        value: ruleIdentifier,
        itemPropertyPath: 'identifier',
      },
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, user } = PickerHOC.use();

  const { getRule, extractValue, desactivateRule, activateRule, upsertRule } = RulesWrapper.use();

  const rule = getRule(ruleIdentifier, target.id);

  const { useBackground, useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const {
    query: { from },
  } = Page.use();
  const [isScroll, setIsScroll] = useState(false);

  const lastRef = useRef(null);
  const observer = useRef(null);
  const [selectedValue, setSelectedValue] = useState(1);

  useEffect(() => {
    if (rule) {
      const percentValue = extractValue(rule, 'earning_percent');
      setSelectedValue(Number(percentValue || 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule?.id]);

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  useBackground(colors.red)
  useLoading(false);
  const activateAndContinue = useRuleActivation(ruleIdentifier, rule, target.id, user.id, !!from, routes.caffeineRule);
  const handleNext = useCallback(async (isActive) => {
    await activateAndContinue(isActive, [
      {
        identifier: 'earning_percent',
        data: { value: Number(selectedValue) },
      },
      // TODO:
      // {
      //   identifier: 'earning_account',
      //   data: { value: account },
      // },
    ])
  }, [selectedValue, activateAndContinue])

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        left={
          <View autoWidthSt style={{ marginLeft: 18 }} onClick={() => goBack()}>
            <Back style={{ fill: colors.white, width: 11, height: 'auto' }} />
          </View>
        }
        style={{ backgroundColor: colors.red }}
        right="none"
      />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.red }}>
      <View style={{ padding: 25 }}>
        <Text boldSt font15St whiteSt style={{ marginBottom: 20 }}>
          {' '}
          ¿Quieres activar esta regla?{' '}
        </Text>
        <View horizontalSt>
          <View
            pointerSt
            style={{
              height: 54,
              width: 54,
              borderRadius: '50%',
              background: colors.white,
              marginRight: 32,
            }}
            onClick={() => handleNext(false)}
          >
            <View
              autoWidthSt
              boldSt
              style={{
                fontSize: 45,
                color: colors.red,
                transform: 'rotate(45deg)',
                paddingBottom: 10,
              }}
            >
              +
            </View>
          </View>
          <View
            pointerSt
            onClick={() => handleNext(true)}
            style={{ height: 54, width: 54, borderRadius: '50%', background: colors.white }}
          >
            <CheckMark style={{ width: 20, height: 'auto', fill: colors.green }} />
          </View>
        </View>
      </View>
    </Footer>,
    [handleNext],
  );

  return (
    <Layout.Container>
      <ScrollArrow
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View
        startSt
        fullHeightSt
        style={{ backgroundColor: colors.red, paddingTop: 10, paddingBottom: 10 }}
      >
        <View width79St>
          <Image src={IncomeImage} style={{ width: 60, height: 'auto' }} />
          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <Text font27St boldSt whiteSt style={{ textAlign: 'center', marginRight: 5 }}>
              Ingresos
            </Text>
            <RulesTooltip
              content={
                <Text style={{ fontSize: 14 }}>
                  ¡Qué mejor momento para ahorrar que cada vez que recibes un ingreso! Por cada
                  movimiento positivo que tengas en tu cuenta o tarjeta, ahorrarás un porcentaje.
                  <Text style={{ fontSize: 14, marginTop: 10 }}>
                    Esta cantidad se sumará al resto de reglas. Si la suma de todas las reglas es
                    superior a 40€, pasaremos una aportación a tu póliza. Si tu ahorro no llega al
                    mínimo, no te preocupes, seguirás acumulado hasta la siguiente semana. Hacemos
                    este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el
                    cargo en tu cuenta entre el lunes y el miércoles.
                  </Text>
                </Text>
              }
            ></RulesTooltip>
          </View>
          <Text textCenterSt font15St whiteSt lineHeight16St style={{ marginTop: 27 }}>
            Activando esta regla ahorrarás un porcentaje de tus ingresos todos los meses.
          </Text>

          <Text font15St boldSt textCenterSt whiteSt style={{ marginTop: 20, width: 200 }}>
            Selecciona el importe y te lo explicamos mejor
          </Text>
          <View horizontalSt style={{ marginTop: 25 }}>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 1}
              onClick={() => setSelectedValue(1)}
            >
              1%
            </View>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 2}
              onClick={() => setSelectedValue(2)}
            >
              2%
            </View>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 5}
              onClick={() => setSelectedValue(5)}
            >
              5%
            </View>
            <View
              roundingMarginSt
              circleRoundingSt
              circleRoundingActiveSt={selectedValue === 10}
              onClick={() => setSelectedValue(10)}
            >
              10%
            </View>
          </View>
          <Text font15St textCenterSt whiteSt style={{ marginTop: 38, width: 220 }}>
            Si vas a ahorrar el 1% de tus ingresos y cobras 900€, el ahorro final de cada mes será
            de 9€, al 2% será 18€, etc...
          </Text>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});
