class FilterController {
  get filter() {
    return this.__filter || {};
  }
  set filter(value) {
    this.__filter = value;
  }

  clear() {
    this.__filter = {};
  }
}

export const filterController = new FilterController();
