import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';
UiWrapper.defineComponent('inputSuggest', {
  styles: {
    default: {
      containerComponent: {
        width: '80%',
        // border: '1px solid #f60',
      },
      textFieldComponent: {
        containerComponent: {
          width: '100%',
          backgroundColor: 'transparent',
        },
        inputContainerComponent: {
          borderRadius: '0px',
          width: '100%',
          backgroundColor: 'transparent',
        },
        inputComponent: {
          color: colors.white,
          borderBottom: '1px solid white',
        },
      },

      suggestionsContainerComponent: {
        padding: 5,
      },
      suggestionSelectedTextComponent: {
        color: 'orange',
      },
      sectionTitleTextComponent: {
        fontWeight: 'bold',
        fontStyle: 'italic',
        borderBottom: 'solid 1px',
        marginBottom: 5,
      },
    },
  },
});
