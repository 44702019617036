import React, { useCallback } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  PickerHOC,
  Layout,
  Button,
  Text,
  Page,
  RuleDefinitionsWrapper,
  TargetsWrapper,
} from '@coinscrap/webapp-core';


// components
import MailIcon from 'common/svgs/MailIcon';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Footer from 'common/Footer';

// configs
import colors from 'config/colors';
import routes from 'config/routes';
import { useOtp } from './hooks/useOtp';


// import routes from "../config/routes";
export const OtpSms = PickerHOC({
  ruleDefinitions: {
    sourceWrapper: RuleDefinitionsWrapper,
    sourcePath: 'ruleDefinitions',
  },
  parentTarget: {
    arrayItemMatcher: {
      allowNoExistence: true,
      itemPropertyPath: 'level',
      type: 'value',
      value: 0,
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
})(() => {

  const { query: { payload, action } } = Page.use();
  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  useLoading(false);

  const { sendNew } = useOtp(payload);

  const handleNext = useCallback(async () => {
    try {
      (async () => {
        const op = await sendNew();
        navigateTo(routes.otpCode, { routeParams: { operationId: op.id }, mode: 'replace' });
      }
      )()
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBackground(colors.white);

  // useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        regularButtonSt
        style={{ marginBottom: 50 }}
        onClick={handleNext}
      >
        Siguiente
      </Button>
    </Footer>,
    [],
  );

  return (
    <Layout.Container>
      <View width90St fullHeightSt>
        <View topSt rowSt justifyCenterSt style={{ marginBottom: 50 }}>
          <MailIcon style={{ height: 100, width: 100, fill: colors.red }} />
        </View>
        <Text textCenterSt font18St>
          Por seguridad, recibirás en tu teléfono móvil un SMS con un código que deberás
          insertar en la siguiente pantalla para continuar el proceso.
        </Text>
      </View>
    </Layout.Container>
  );
});
