export const editPagesMapping = {
  COINSCRAP_ROUNDS: "redondeo",
  PERIODIC_SAVING: "aportacionperodica",
  NOMINA_SAVING: "nomina",
  COFFEE_SAVING: "sincafeina",
  TEAM: "equipo",
  SMOKE_SAVING: "sinnicotina",
};


export const OTP_ACTIONS = {
  HOT: 'HOT',
  SELECT_POLICY: 'SELECT_POLICY',
  SAVE_ACCOUNTS: 'SAVE_ACCOUNTS',
  TOGGLE_RULE: 'TOGGLE_RULE',
  TARGET_FINISH: 'TARGET_FINISH'
}