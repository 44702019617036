import React from 'react';

import { Text, View } from '@coinscrap/webapp-core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useState } from 'react';

const Collapse = ({ title, children, style, setValueDefault = true }) => {
  const [visible, setVisible] = useState(setValueDefault);
  return (
    <View fullWidthSt style={{ justifyContent: 'start', ...style }}>
      <View fullWidthSt faqsCollapseSt onClick={() => setVisible((v) => !v)}>
        <Text style={{ fontSize: 15, fontWeight: 500 }}>{title}</Text>
        <ArrowForwardIosIcon
          style={{
            transition: 'all 0.3s ease',
            transform: visible && 'rotate(90deg)',
            height: 15,
          }}
        />
      </View>
      <View fullWidthSt faqsCollapseContentSt style={{ maxHeight: visible ? '100%' : 0 }}>
        {children}
      </View>
    </View>
  );
};

export default Collapse;
