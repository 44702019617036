import React, { useCallback, useRef, useState } from 'react';

import {
  Image,
  InputSuggest,
  Layout,
  NavigationWrapper,
  Page,
  PickerHOC,
  RuleDefinitionsWrapper,
  RulesWrapper,
  TargetsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';

// components
import Footer from 'common/Footer';
import Header from 'common/Header';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import { LoadingData } from 'common/LoadingData';
import ScrollArrow from 'common/ScrollArrow/ScrollArrow';
// images
import FutbolImage from 'assets/images/Futbol.png';
import Back from 'common/svgs/Back';
import CheckMark from 'common/svgs/CheckMark';

// configs
import RulesTooltip from 'common/RulesTooltip';
import colors from 'config/colors';
import routes from 'config/routes';

// data
import * as Types from 'data/types';
import { useRuleActivation } from './hooks/useRuleActivation';

const ruleIdentifier = Types.TEAM;

export const teams = [
  { label: 'Athletic', id: 'Athletic Club', active: true },
  { label: 'Atleti', id: 'Club Atlético de Madrid', active: true },
  { label: 'Osasuna', id: 'CA Osasuna', active: true },
  { label: 'Espanyol', id: 'RCD Espanyol de Barcelona', active: true },
  { label: 'Barça', id: 'FC Barcelona', active: true },
  { label: 'Getafe', id: 'Getafe CF', active: true },
  { label: 'Real Madrid', id: 'Real Madrid CF', active: true },
  { label: 'Rayo Vallecano', id: 'Rayo Vallecano de Madrid', active: true },
  { label: 'Mallorca', id: 'RCD Mallorca', active: true },
  { label: 'Real Betis', id: 'Real Betis Balompié', active: true },
  { label: 'Real Sociedad', id: 'Real Sociedad de Fútbol', active: true },
  { label: 'Villarreal', id: 'Villarreal CF', active: true },
  { label: 'Valencia', id: 'Valencia CF', active: true },
  { label: 'Real Valladolid', id: 'Real Valladolid CF', active: true },
  { label: 'Alavés', id: 'Deportivo Alavés', active: true },
  { label: 'Las Palmas', id: 'UD Las Palmas', active: true },
  { label: 'Girona', id: 'Girona FC', active: true },
  { label: 'Celta', id: 'RC Celta de Vigo', active: true },
  { label: 'Sevilla FC', id: 'Sevilla FC', active: true },
  { label: 'CD Leganés', id: 'CD Leganés', active: true }
];

export default PickerHOC(
  {
    ruleDefinition: {
      arrayItemMatcher: {
        type: 'value',
        value: ruleIdentifier,
        itemPropertyPath: 'identifier',
      },
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, user } = PickerHOC.use();
  const { getRule, extractValue, desactivateRule, activateRule, upsertRule } = RulesWrapper.use();

  const rule = getRule(ruleIdentifier, target.id);
  const { useBackground, useFooter, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const {
    query: { from },
  } = Page.use();
  // const { autocompleteRuleField } = RuleDefinitionsWrapper.use();
  const lastRef = useRef(null);
  const observer = useRef(null);

  const [isScroll, setIsScroll] = useState(false);
  const [ruleValue, setRuleValue] = useState(extractValue(rule, 'football_team') || '');
  useBackground(colors.red);
  useLoading(false);

  const activateAndContinue = useRuleActivation(
    ruleIdentifier,
    rule,
    target.id,
    user.id,
    !!from,
    routes.rules,
  );
  const handleNext = useCallback(
    async (isActive) => {
      await activateAndContinue(isActive, [
        {
          identifier: 'football_team',
          data: { value: ruleValue },
        },
        {
          identifier: 'football_amount',
          data: { value: 1 },
        },
      ]);
    },
    [ruleValue, activateAndContinue],
  );

  const lastPlanElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[entries.length - 1].isIntersecting) {
          setIsScroll(false);
        } else {
          setIsScroll(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [observer],
  );

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header
        left={
          <View autoWidthSt style={{ marginLeft: 18 }} onClick={() => goBack()}>
            <Back style={{ fill: colors.white, width: 11, height: 'auto' }} />
          </View>
        }
        style={{ backgroundColor: colors.red }}
        right="none"
      />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.red }}>
      <View style={{ padding: 25 }}>
        <Text boldSt font15St whiteSt style={{ marginBottom: 20 }}>
          {' '}
          ¿Quieres activar esta regla?{' '}
        </Text>
        <View horizontalSt>
          <View
            pointerSt
            onClick={() => handleNext(false)}
            style={{
              height: 54,
              width: 54,
              borderRadius: '50%',
              background: colors.white,
              marginRight: 32,
            }}
          >
            <View
              autoWidthSt
              boldSt
              style={{
                fontSize: 45,
                color: colors.red,
                transform: 'rotate(45deg)',
                paddingBottom: 10,
              }}
            >
              +
            </View>
          </View>
          {/* TODO: add validation */}
          <View
            pointerSt
            onClick={() => {
              if (!ruleValue) return;
              handleNext(true);
            }}
            style={{ height: 54, width: 54, borderRadius: '50%', background: colors.white }}
          >
            <CheckMark style={{ width: 20, height: 'auto', fill: colors.green }} />
          </View>
        </View>
      </View>
    </Footer>,
    [handleNext, ruleValue],
  );

  return (
    <Layout.Container>
      <ScrollArrow
        style={{
          position: 'absolute',
          bottom: 0,
          right: 5,
          width: 50,
          height: 50,
          opacity: isScroll ? 1 : 0,
        }}
        lastItem={lastRef.current}
      />
      <View
        startSt
        fullHeightSt
        style={{ backgroundColor: colors.red, paddingTop: 10, paddingBottom: 10 }}
      >
        <View width79St>
          <Image src={FutbolImage} style={{ width: 60, height: 'auto' }} />
          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <Text font27St boldSt whiteSt style={{ textAlign: 'center', marginRight: 5 }}>
              Equipo de fútbol
            </Text>
            <RulesTooltip
              content={
                <Text style={{ fontSize: 14 }}>
                  ¿Vibras con cada partido de tu equipo? Estamos conectados con todos los partidos
                  oficiales de manera que si tienes esta regla activa, con cada partido que gane tu
                  equipo, ahorrarás 1€. Fácil, ¿verdad?.
                  <Text style={{ fontSize: 14, marginTop: 10 }}>
                    La cantidad que consigas por los éxitos de tu equipo se sumará al resto de
                    reglas. Si la suma de todas las reglas es superior a 40€, pasaremos una
                    aportación a tu póliza. Si tu ahorro no llega al mínimo, no te preocupes,
                    seguirás acumulado hasta la siguiente semana. Hacemos este proceso cada viernes
                    así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre
                    el lunes y el miércoles.
                  </Text>
                </Text>
              }
            ></RulesTooltip>
          </View>
          <Text textCenterSt font15St whiteSt lineHeight16St style={{ marginTop: 27 }}>
            Ahorra automáticamente cada vez que tu equipo gane un partido.
          </Text>
          <Text textCenterSt whiteSt lineHeight16St style={{ fontSize: 12, marginTop: 10 }}>
            *Solo es valido para equipos de 1ª División
          </Text>
          <Text font15St boldSt textCenterSt whiteSt style={{ marginTop: 20, width: 200 }}>
            Selecciona el equipo al que deseas apoyar para ayudarte a ahorrar
          </Text>
          <View horizontalSt style={{ marginTop: 25 }}>
            <View roundingMarginSt circleRoundingSt circleRoundingActiveSt>
              1€
            </View>
          </View>
          <View style={{ position: 'relative' }}>
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: '50%',
                background: colors.white,
                position: 'absolute',
                left: '35px',
                top: '35px',
              }}
            >
              <CheckMark style={{ width: 7, height: 'auto', fill: colors.red }} />
            </View>
            <InputSuggest
              style={{
                containerComponent: { marginTop: 20 },
                textFieldComponent: {
                  noLabelMode: {
                    inputComponent: {
                      paddingTop: 7,
                    },
                  },
                },
              }}
              placeholder="Escribe tu equipo"
              alwaysOpen={false}
              suggestionsSource={teams.filter(at => at.active === true)}
              value={ruleValue}
              onChange={(value) => setRuleValue(value)}
            />
          </View>
        </View>
        <View ref={lastRef}>
          <View ref={lastPlanElementRef} />
        </View>
      </View>
    </Layout.Container>
  );
});
