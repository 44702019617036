import React from 'react';

export default function GreenCheck(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="46.88"
      height="46.88"
      viewBox="0 0 46.88 46.88"
      style={{ ...props.style }}
    >
      <defs>
        <clipPath id="a">
          <rect style={{ fill: 'none' }} className="a" width="18" height="19" />
        </clipPath>
      </defs>
      <g transform="translate(-7019.56 -1553.56)">
        <circle
          style={{ fill: '#649544' }}
          className="c"
          cx="23.44"
          cy="23.44"
          r="23.44"
          transform="translate(7019.56 1553.56)"
        />
        <g style={{ clipPath: 'url(#a)' }} className="d" transform="translate(7034 1567)">
          <path d="M6.565,19a2.069,2.069,0,0,1-.875-.189,1.722,1.722,0,0,1-.684-.566L0,11.723a1.792,1.792,0,0,1-.383-1.415A1.759,1.759,0,0,1,.383,9.055a1.859,1.859,0,0,1,1.436-.377,1.922,1.922,0,0,1,1.3.728L6.4,13.691,14.69.593a1.8,1.8,0,0,1,1.2-.849A1.9,1.9,0,0,1,17.371,0a1.828,1.828,0,0,1,.875,1.186A1.784,1.784,0,0,1,18,2.641L8.207,18.111a1.875,1.875,0,0,1-.657.647,1.742,1.742,0,0,1-.9.243H6.565Z" />
        </g>
        <path
          style={{ fill: '#fff' }}
          className="b"
          d="M7040.592,1586h-.026a2.079,2.079,0,0,1-.876-.189,1.738,1.738,0,0,1-.683-.565l-5.007-6.524v-2.317a1.932,1.932,0,0,1,.384-.352,1.869,1.869,0,0,1,1.436-.377,1.918,1.918,0,0,1,1.3.729l3.283,4.285,8.29-13.1a1.811,1.811,0,0,1,.57-.593h2.111a1.869,1.869,0,0,1,.628.6v2.042l-9.792,15.47a1.906,1.906,0,0,1-.657.648,1.753,1.753,0,0,1-.9.243Z"
        />
      </g>
    </svg>
  );
}
