import React, { useState, useEffect } from 'react';

import {
  UiWrapper,
  View,
  Text,
  Layout,
  Image,
  Button,
  TextField,
  InstitutionsWrapper,
  SoftSwitcher,
  Page,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';

import search from 'assets/images/search.png';

import CheckMark from 'common/svgs/CheckMark';

// configs
import colors from 'config/colors';
import AfterBanksModal from './AfterBanksModal';

const Initial = () => {
  const {
    query: { institutionIdentifier },
  } = Page.use();
  const { institutions: rawInstitutions } = InstitutionsWrapper.use();
  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const [showAfterBanksModal, setShowAfterBanksModal] = useState(false);

  const institutions = (rawInstitutions || []).filter((i) => i.type !== 'INSURER');
  const [selectedBank, setSelectedBank] = useState('');
  const [filterData, setFilterData] = useState(institutions);
  const [isSearch, setIsSearch] = useState(false);
  useLoading(!rawInstitutions);

  useBackground(colors.white);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. BANCO." left="atras" />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white }}>
      <Button
        // style={{ marginTop: 'auto', marginBottom: 60 }}
        borderWhiteButtonSt
        disabled={!selectedBank}
        onClick={() => setShowAfterBanksModal(true)}
      >
        Aceptar
      </Button>
    </Footer>,
    [selectedBank],
  );

  useEffect(() => {
    if (institutionIdentifier) {
      setSelectedBank(institutionIdentifier);
      setShowAfterBanksModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [institutionIdentifier]);

  return (
    <Layout.Container>
      <View
        style={{
          justifyContent: 'flex-start',
        }}
      >
        <Text style={{ fontSize: 16, marginTop: 30 }}>Selecciona tu banco</Text>
        <Text style={{ fontSize: 12, marginTop: 10, textAlign: 'center' }}>
          Después serás conectado con Morpheus Aiolos, una entidad autorizada para gestionar tus
          credenciales de seguridad.
        </Text>

        {isSearch && (
          <View style={{ flexDirection: 'row', position: 'relative', width: '80%', marginTop: 20 }}>
            <TextField
              startAdornment={
                <Image
                  src={search}
                  style={{ position: 'absolute', left: 10, width: 20, height: 20 }}
                />
              }
              style={{
                containerComponent: { width: '100%' },
                inputComponent: {
                  backgroundColor: colors.grey5,
                  borderRadius: 0,
                  borderBottom: `1px solid ${colors.red}`,
                  fontSize: 20,
                },
                noLabelMode: {
                  inputComponent: {
                    padding: '12px 50px 12px 50px',
                  },
                },
              }}
              placeholder="Busca tu banco"
              onChange={(e) => {
                const filteredBanks = institutions.filter((bank) =>
                  bank.name.toLowerCase().includes(e.target.value.toLowerCase()),
                );
                setFilterData(filteredBanks);
              }}
            ></TextField>
          </View>
        )}
        <SoftSwitcher evaluate={isSearch}>
          <>
            <View style={{ marginTop: 25, padding: 10 }} gridSt>
              <View
                pointerSt
                style={{
                  height: 160,
                  width: 160,
                  backgroundColor: colors.white,

                  position: 'relative',
                  border: `1px solid ${colors.red}`,
                }}
                onClick={() => {
                  setIsSearch(true);
                  setFilterData([]);
                }}
              >
                <Image src={search} style={{ width: '15px', objectFit: 'contain' }}></Image>
                <Text style={{ color: 'black', fontSize: 14, marginTop: 5, textAlign: 'center' }}>
                  Buscar tu banco
                </Text>
              </View>

              {(institutions || [])
                .slice(0, 5)

                .map((bank) => {
                  return (
                    <BankCard
                      key={bank.identifier}
                      data={bank}
                      onClick={(identifier) => setSelectedBank(identifier)}
                      selected={bank.identifier === selectedBank}
                    />
                  );
                })}
              {/* <BankCard
                data={{ name: 'Buscar tu banco', image: search }}
                onClick={(identifier) => setIsSearch(true)}
                // selected={bank.identifier === selectedBank}
              /> */}
            </View>
          </>
          <>
            <View style={{ marginTop: 25, padding: 10 }} gridSt>
              {(filterData || []).map((bank) => {
                return (
                  <BankCard
                    key={bank.identifier}
                    data={bank}
                    onClick={(identifier) => setSelectedBank(identifier)}
                    selected={bank.identifier === selectedBank}
                  />
                );
              })}
            </View>
          </>
        </SoftSwitcher>
      </View>

      {selectedBank && showAfterBanksModal && (
        <AfterBanksModal
          selectedBank={selectedBank}
          isOpen={showAfterBanksModal}
          onClose={() => setShowAfterBanksModal(false)}
        />
      )}
    </Layout.Container>
  );
};

const BankCard = ({ selected, data, onClick }) => {
  return (
    <View
      pointerSt
      style={{
        height: 160,
        width: 160,
        backgroundColor: colors.red,

        position: 'relative',
        border: selected ? `1px solid ${colors.red}` : `1px solid white`,
      }}
      onClick={() => onClick(data.identifier)}
    >
      {selected && (
        <View
          style={{
            backgroundColor: colors.red,
            height: 20,
            width: 20,
            borderRadius: '50%',
            position: 'absolute',
            bottom: 10,
            right: 10,
          }}
        >
          <CheckMark style={{ fill: colors.white, height: 8, width: 'auto' }} />
        </View>
      )}
      <Image
        src={data.image}
        style={{ width: '70%', height: '70px', objectFit: 'contain' }}
      ></Image>
      <Text style={{ color: 'white', fontSize: 14, marginTop: 5, textAlign: 'center' }}>
        {data.name}
      </Text>
    </View>
  );
};

export default Initial;
