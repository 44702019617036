import React from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  View,
  Text,
  PickerHOC,
  Layout,
  Button,
  Image,
  TargetsWrapper,
  RulesWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Footer from 'common/Footer';
import { LoadingData } from 'common/LoadingData';

// images
import CheckMark from 'common/svgs/CheckMark';
import Circles from 'common/svgs/Circles';
import StopSmoking from 'common/svgs/StopSmoking';
import Football from 'common/svgs/Football';
// /* import Clock from 'assets/images/Clock.png';
// import Money from 'assets/images/Money.png'; */
import IncomeRed from 'assets/images/IncomeRed.png';
import CaffeineRed from 'assets/images/CaffeineRed.png';

// configs
import colors from 'config/colors';
import routes from 'config/routes';

// data
import * as Types from 'data/types';

// import routes from "../config/routes";
export default PickerHOC(
  {
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target } = PickerHOC.use();
  const { getRule } = RulesWrapper.use();

  const { useBackground, useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  useBackground(colors.white);
  useLoading(false);
  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. REGLAS" left="atras" />
    </View>,
    [],
  );

  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        style={{ marginTop: 30 }}
        borderWhiteButtonSt
        onClick={() =>
          navigateTo(routes.rulesResumen, {
            routeParams: {
              idTarget: target.id,
            },
          })
        }
      >
        Siguiente
      </Button>
    </Footer>,
  );

  return (
    <Layout.Container>
      <View fullHeightSt spaceBetweenSt paddingMainSt>
        <View>
          <View topSt>
            <Text boldSt font11St style={{ marginLeft: 16 }}>
              3 de 4
            </Text>
          </View>

          <View style={{ marginTop: 25 }}>
            <View width80St style={{ marginBottom: 31 }}>
              <Text font27St textCenterSt>
                ¡Tú pones las reglas!
              </Text>
            </View>
            <View width86St>
              <Categories
                onClick={() =>
                  navigateTo(routes.roundRule, {
                    queryParams: { from: routes.rules },
                  })
                }
                Icon={Circles}
                text="Redondeo"
                active={getRule(Types.COINSCRAP_ROUNDS, target.id)?.isActive}
              />
              {/* <Categories
                onClick={() =>
                  navigateTo(routes.contributionStep2Periodic, {
                    queryParams: { from: routes.rulesMain },
                  })
                }
                IconImage={Clock}
                text="Aportación periódica"
                active={!periodic}
              /> */}
              {/* <Categories
                onClick={() => {}}
                IconImage={Money}
                text="Aportación puntual"
                active={!puntual}
              /> */}
              <Categories
                onClick={() =>
                  navigateTo(routes.incomeRule, {
                    queryParams: { from: routes.rules },
                  })
                }
                IconImage={IncomeRed}
                text="Ingresos"
                active={getRule(Types.EARNINGS_SAVING, target.id)?.isActive}
              />

              <Categories
                onClick={() =>
                  navigateTo(routes.caffeineRule, {
                    queryParams: { from: routes.rules },
                  })
                }
                IconImage={CaffeineRed}
                text="Sin cafeína"
                active={getRule(Types.CAFEINE, target.id)?.isActive}
              />
              <Categories
                onClick={() =>
                  navigateTo(routes.nicotineRule, {
                    queryParams: { from: routes.rules },
                  })
                }
                Icon={StopSmoking}
                text="Dejar de fumar"
                active={getRule(Types.NICOTINE, target.id)?.isActive}
              />
              <Categories
                onClick={() =>
                  navigateTo(routes.futbolRule, {
                    queryParams: { from: routes.rules },
                  })
                }
                Icon={Football}
                text="Equipo de fútbol"
                active={getRule(Types.TEAM, target.id)?.isActive}
                bottom
              />
            </View>
          </View>
        </View>
      </View>
    </Layout.Container>
  );
});

const Categories = ({ Icon, IconImage, text, bottom, active, onClick }) => {
  return (
    <View
      pointerSt
      onClick={onClick}
      style={{
        height: 81,
        borderTop: `1px solid ${colors.grey6}`,
        borderBottom: bottom && `1px solid ${colors.grey6}`,
      }}
    >
      <View horizontalSt spaceBetweenSt style={{ paddingLeft: 16, paddingRight: 16 }}>
        <View autoWidthSt horizontalSt>
          {Icon ? (
            <Icon style={{ width: 34, fill: colors.red }} />
          ) : (
            <Image src={IconImage} style={{ width: 34, height: 'auto' }} />
          )}

          <Text style={{ marginLeft: 18, fontSize: 20 }}>{text}</Text>
        </View>
        {active ? (
          <View smallGreenCircleSt>
            <CheckMark style={{ fill: colors.white, height: 10, width: 10 }} />
          </View>
        ) : (
          <View smallRedCircleSt>
            <View autoWidthSt smallRedCirclePlusSt>
              +
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
