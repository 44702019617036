import moment from 'moment';

import { ruleData } from 'data/ruleData';
import * as Types from 'data/types';

const movementRuleConcept = 'Ahorro hucha';

export const stuctureDataListAllRules = (ruleDefinitions, userTargets, userRules) => {
  const targetNotFinished = (target) => {
    let isGoalNotConfigured =
      target.status !== 'ACTIVE' &&
      target.status !== 'FINISHED' &&
      target.creationData?.date &&
      target.status !== 'PENDING_FINALIZATION';

    return isGoalNotConfigured;
  };
  let userRulesRemoveTargetNotFinished = (userRules || []).filter((userRule) => {
    const findTarget = userTargets.find((item) => item.id === userRule.targetId);
    if (findTarget && !targetNotFinished(findTarget)) return true;
    else return false;
  });

  const listRules = ruleDefinitions.map((ruleDef) => {
    console.log({ ruleDef });
    const oneWeekAgo = moment().subtract(7, 'd').unix();
    let amountWeekly = 0;
    let amountTotal = 0;
    let amountVirual = 0;

    (userTargets || [])
      .filter((target) => !targetNotFinished(target))
      .forEach((userTarget) => {
        amountVirual += userTarget.nextSaving?.ruleDetails?.[ruleDef.identifier]?.amount || 0;
        const filterConcepts = userTarget.movements || [];
        // .filter(
        //   (movement) => movement.concept === movementRuleConcept,
        // );

        filterConcepts.forEach((filterConcept) => {
          if (filterConcept.movementLines) {
            filterConcept.movementLines
              .filter((rule) => rule.title === ruleDef.identifier)
              .forEach((item, index) => {
                amountTotal += item.amount;
                const date = moment(item.createDate).unix();
                if (date > oneWeekAgo) amountWeekly += item.amount;
              });
          }
        });
      });

    const ruleListType = userRulesRemoveTargetNotFinished.filter(
      (rule) => rule.ruleDefinition.identifier === ruleDef.identifier,
    );

    const name = ruleData.find((rule) => rule.identifier === ruleDef.identifier);
    const isActive = ruleListType.find((rule) => rule.isActive === true);
    return {
      ruleIdentifier: name.identifier,
      name: name.ruleName || '',
      isActive: !!isActive,
      amountWeekly,
      amountTotal,
      amountVirual,
    };
  });

  return listRules;
};
export const stuctureDataListAllRulesSingleTarget = (ruleDefinitions, target, userRules) => {
  console.log({ target });
  const listRules = ruleDefinitions.map((ruleDef) => {
    const oneWeekAgo = moment().subtract(7, 'd').unix();
    let amountWeekly = 0;
    let amountTotal = 0;
    let amountVirual = target?.nextSaving?.ruleDetails?.[ruleDef.identifier]?.amount || 0;

    if (target.movements) {
      const filterConcepts = target.movements;
      // .filter(
      //   (movement) => movement.concept === movementRuleConcept,
      // );
      filterConcepts.forEach((filterConcept) => {
        if (filterConcept.movementLines) {
          filterConcept.movementLines
            .filter((rule) => rule.title === ruleDef.identifier)
            .forEach((item, index) => {
              amountTotal += item.amount;
              const date = moment(item.createDate).unix();
              if (date > oneWeekAgo) amountWeekly += item.amount;
            });
        }
      });
    }

    const findUserRule = userRules.find(
      (rule) =>
        rule.ruleDefinition.identifier === ruleDef.identifier && rule.targetId === target.id,
    );

    const name = ruleData.find((rule) => rule.identifier === ruleDef.identifier);
    const isActive = findUserRule ? findUserRule.isActive : false;
    return {
      ruleIdentifier: name.identifier,
      name: name.ruleName || '',
      isActive: !!isActive,
      amountWeekly,
      amountTotal,
      amountVirual,
    };
  });

  return listRules;
};
export const stuctureDataListAllUserRulesByType = (userTargets, userRules, ruleIdentifier) => {
  const listRules = userRules
    .filter((userRule) => userRule.ruleDefinition.identifier === ruleIdentifier)
    .map((userRule) => {
      const oneWeekAgo = moment().subtract(7, 'd').unix();
      let amountWeekly = 0;
      let amountTotal = 0;
      let virtual = 0;

      const target = userTargets.find((userTarget) => userTarget.id === userRule.targetId);
      console.log({ target });
      virtual = target?.nextSaving?.ruleDetails?.[ruleIdentifier]?.amount || 0;
      if (target?.movements) {
        const filterConcepts = target.movements;
        // .filter(
        //   (movement) => movement.concept === movementRuleConcept,
        // );
        filterConcepts.forEach((filterConcept) => {
          if (filterConcept.movementLines) {
            filterConcept.movementLines
              .filter((rule) => rule.title === ruleIdentifier)
              .forEach((item, index) => {
                amountTotal += item.amount;
                const date = moment(item.createDate).unix();
                if (date > oneWeekAgo) amountWeekly += item.amount;
              });
          }
        });
      }

      return {
        userRule: userRule,
        target: target,
        name: target?.name || '',
        isActive: userRule.isActive,
        amountWeekly,
        amountTotal,
        virtual,
      };
    });

  return listRules;
};
export const stuctureDataMovements = (target) => {
  let newArray = [];

  if (target.movements) {
    target.movements
      .sort((a, b) => {
        return new Date(b?.createDate) - new Date(a?.createDate);
      })
      .forEach((item) => {
        const monthYear = moment(item.valueDate).format('MMMM YYYY');
        const monthYearForGraph = moment(item.valueDate).format('MM/YY');
        const findDate = newArray.findIndex((arrayItem) => arrayItem.date === monthYear);
        if (findDate === -1) {
          newArray.push({
            dateObject: item.valueDate,
            date: monthYear,
            dateGraph: monthYearForGraph,
            total: item.amount,
            movements: [item],
          });
        } else {
          newArray[findDate] = {
            ...newArray[findDate],
            total: newArray[findDate].total + item.amount,
            movements: [...newArray[findDate].movements, item],
          };
        }
      });
  }

  return newArray;
};

export const stuctureTargetDataForPieGraph = (target) => {
  let monthlyContributions = 0;
  let rulesContributions = 0;

  const filterConcepts = target.movements || [];
  // .filter(
  //   (movement) => movement.concept === movementRuleConcept,
  // );

  filterConcepts.forEach((filterConcept) => {
    if (filterConcept.movementLines) {
      filterConcept.movementLines.forEach((item, index) => {
        if (item.title === Types.PERIODIC_SAVING) {
          monthlyContributions += item.amount;
        } else {
          rulesContributions += item.amount;
        }
      });
    }
  });
  return {
    pending: target.progress.total - target.progress.completed,
    initialContribution: target.creationData?.initialContribution || 0,
    monthlyContributions,
    rulesContributions,
    progress: target.progress,
  };
};
export const stuctureDataMovementsForRule = (target, ruleIdentifier) => {
  let newArray = [];

  if (target.movements) {
    target.movements
      .filter((movement) => !!movement.movementLines)
      .forEach((item) => {
        const monthYear = moment(item.valueDate).format('MMMM YYYY');
        const findDate = newArray.findIndex((arrayItem) => arrayItem.date === monthYear);
        if (findDate === -1) {
          newArray.push({
            date: monthYear,
            total: item.amount,
            movements: [item],
          });
        } else {
          newArray[findDate] = {
            ...newArray[findDate],
            total: newArray[findDate].total + item.amount,
            movements: [...newArray[findDate].movements, item],
          };
        }
      });
  }
  const newMovementList = newArray.map((item) => {
    let movements = item.movements.map((movement) => {
      return {
        ...movement,
        movementLines: movement.movementLines.filter(
          (movementLine) => movementLine.title === ruleIdentifier,
        ),
      };
    });
    return {
      ...item,
      movements: movements.filter((movement) => movement.movementLines.length !== 0),
    };
  });
  return newMovementList;
};
