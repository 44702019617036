import { UiWrapper } from '@coinscrap/webapp-core';
import colors from "../colors";

UiWrapper.defineComponent("textCurrency", {
  styles: {
    default: {
      entireComponent: {
        lineHeight: 1.1,
        color: colors.yellow,
        fontWeight: "bold",
        fontSize: 22,
      },
      decimalComponent: {
        color: colors.yellow,
        fontWeight: "bold",
        fontSize: 20,
      },
    },
  },
});
