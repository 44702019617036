import React from 'react';

export default function MailIcon(props) {
  return (
    <svg
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ background: '#FFFFFF', ...props.style }}
    >
      <title>icons/products/red/G-communication/g02-message</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path
          d="M46.6368,36.9447 L1.3638,36.9447 L1.3638,10.8847 L23.6498,24.2387 C23.8658,24.3687 24.1348,24.3687 24.3508,24.2387 L46.6368,10.8847 L46.6368,36.9447 Z M44.8538,10.3637 L24.0008,22.8597 L3.1468,10.3637 L44.8538,10.3637 Z M47.9978,9.6167 C47.9858,9.4927 47.9408,9.3787 47.8718,9.2827 L47.8718,9.2827 L47.8708,9.2817 C47.8438,9.2437 47.8128,9.2097 47.7788,9.1787 C47.6588,9.0697 47.5008,9.0017 47.3258,8.9997 L47.3258,8.9997 L47.3238,8.9997 L47.3228,8.9997 L47.3218,8.9997 L47.3208,8.9997 L47.3188,8.9997 L0.6818,8.9997 L0.6798,8.9997 L0.6788,8.9997 L0.6788,8.9997 L0.6778,8.9997 L0.6748,8.9997 L0.6748,8.9997 C0.4998,9.0017 0.3418,9.0697 0.2218,9.1787 C0.1878,9.2097 0.1568,9.2437 0.1298,9.2817 L0.1288,9.2827 L0.1288,9.2827 C0.0598,9.3787 0.0148,9.4927 0.0038,9.6167 C0.0008,9.6417 -0.0002,9.6667 -0.0002,9.6907 L-0.0002,37.6267 C-0.0002,38.0037 0.3048,38.3087 0.6818,38.3087 L47.3188,38.3087 C47.6958,38.3087 48.0008,38.0037 48.0008,37.6267 L48.0008,9.6907 C48.0008,9.6667 47.9998,9.6417 47.9978,9.6167 L47.9978,9.6167 Z"
          id="path-1"
        ></path>
      </defs>
      <g
        id="icons/products/red/G-communication/g02-message"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect fill="#FFFFFF" x="0" y="0" width="48" height="48"></rect>
        <g>
          <polygon
            id="Rectangle-path"
            fillOpacity="0"
            fill="#FFFFFF"
            fillRule="nonzero"
            points="-3.091e-13 0 48 0 48 48 -3.091e-13 48"
          ></polygon>
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use id="g02-message" fill="#C5281C" xlinkHref="#path-1" />
          <polygon
            id="Color-Style"
            fill="#C5281C"
            mask="url(#mask-2)"
            points="0 0 48 0 48 48 0 48"
          ></polygon>
        </g>
      </g>
    </svg>
  );
}
