import React from 'react';
import {
  UiWrapper,
  View,
  NavigationWrapper,
  PickerHOC,
  TargetsWrapper,
  RulesWrapper,
  RuleDefinitionsWrapper,
  Page,
  UserWrapper,
} from '@coinscrap/webapp-core';

// components
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import Header from 'common/Header';
import Tabs from './components/Tabs';
import ObjectiveCard from './components/ObjectiveCard';
import Movements from 'common/Movements';
import Rules from './components/Rules';
import Graphs from './components/Graphs';

// configs
import routes from 'config/routes';

import colors from 'config/colors';
import Contributions from './components/Contributions';

// libs
import { stuctureDataMovements, stuctureDataListAllRulesSingleTarget } from 'libs/structureData'; // libs

import { LoadingData } from 'common/LoadingData';

export default PickerHOC(
  {
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    ruleDefinition: {
      sourcePath: 'ruleDefinitions',
      sourceWrapper: RuleDefinitionsWrapper,
    },
    userRules: {
      sourcePath: 'rules',
      sourceWrapper: RulesWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, ruleDefinition, userRules, user } = PickerHOC.use();
  const { useBackground, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const {
    params: { tab: rawTab },
  } = Page.use();
  const tab = rawTab.replace(':tab', '') || 'graph';

  let movements = stuctureDataMovements(target);

  useBackground(colors.white);
  useLoading(false);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="DETALLE DE OBJETIVO" left="atras" />
    </View>,
    [],
  );

  let listRules = stuctureDataListAllRulesSingleTarget(ruleDefinition, target, userRules);

  let finished = false;
  if (target.status === 'FINISHED') {
    finished = true;
  }
  return finished ? (
    <>
      <View fullHeightSt startSt>
        <ObjectiveCard data={target} finished={finished} />
        <Tabs
          finished={finished}
          section={'movements'}
          onChange={(value) => {
            navigateTo(routes.followUp, {
              mode: 'replace',
              // routeParams: { idTarget: data.id },
              routeParams: {
                tab: value,
              },
            });
          }}
        />

        <Movements data={movements} finished={finished} />
      </View>
    </>
  ) : (
    <>
      <View fullHeightSt startSt>
        <ObjectiveCard data={target} />
        <Tabs
          section={tab}
          onChange={(value) => {
            navigateTo(routes.followUp, {
              mode: 'replace',
              // routeParams: { idTarget: data.id },
              routeParams: {
                tab: value,
              },
            });
          }}
        />
        {tab === 'graph' && <Graphs data={movements} target={target} />}
        {tab === 'movements' && <Movements data={movements} />}
        {tab === 'rules' && <Rules data={listRules} />}
        {tab === 'contributions' && <Contributions user={user} target={target} />}
      </View>
    </>
  );
});
