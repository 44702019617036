import React from 'react';
import { InstitutionAccountsWrapper, OperationsWrapper } from '@coinscrap/webapp-core';
import moment from 'moment';
export const useInstitutionAccounts = () => {
  const { operations } = OperationsWrapper.use();
  const ops = operations || {};
  console.log(" OPERATIONS ", operations);
  const { institutionAccounts } = InstitutionAccountsWrapper.use();
  return institutionAccounts
    .filter((account) => !account.isDeleted && account.institutionIdentifier !== 'generali');
};
